import TextareaAutosize from "react-textarea-autosize";
import { useState } from "react";
import Radio from "./Radio";
import ICAstenosisIMG from "../../images/ICAstenosisIMG.png";

const TesticularVolume = ({
  constructorREF,
  setConstructorOpen,
  formState,
  setFormState,
  text,
  setText,
  zaklState,
  setZaklState,
  rybaObject,
  dinamika,
  handlePasteConstructor,
  addedWithButtons,
}) => {
  let p = 0;

  const organIndex = constructorREF.current.organIndex;
  const segmentIndex = constructorREF.current.segmentIndex;
  const buttonIndex = constructorREF.current.buttonIndex;

  //////////////////////
  const [size1, setSize1] = useState(null);
  const [size2, setSize2] = useState(null);
  const [size3, setSize3] = useState(null);
  const [side, setSide] = useState(null);
  const [age, setAge] = useState(null);
  const [notallsizes, setNotallsizes] = useState(false);

  //////////////

  const textHandler = function () {
    ///////
    let zaklStringFinal = "";
    let xx = `${size1}`;
    let zz = `${size2}`;
    let yy = `${size3}`;
    if (!size1 || !size2 || !size3 || !side) {
      return setNotallsizes(true);
    }

    if (xx && zz && yy) {
      setNotallsizes(false);
    }
    let volume = (xx * zz * yy * 0.71) / 1000;
    volume = Math.round(volume);

    let finalMeasure = "";

    finalMeasure = `Максимальные размеры ${
      side === "R" ? "правого" : "левого"
    } яичка в трёх плоскостях ${xx}х${yy}x${zz} мм, расчётный объём ${volume} мл.`;

    if (dinamika) {
      handlePasteConstructor(
        organIndex,
        segmentIndex,
        finalMeasure,
        zaklStringFinal,
        dinamika
      );
      addedWithButtons(finalMeasure);
      addedWithButtons(zaklStringFinal);
      setConstructorOpen(false);
      return;
    }

    console.log(finalMeasure);
    ///////

    let btnObj =
      rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex];
    let rybaLength = rybaObject.organs.length - 1;

    let t = [...text];
    if (
      t[organIndex][segmentIndex] ===
      rybaObject.organs[organIndex].segments[segmentIndex].norm
    ) {
      t[organIndex][segmentIndex] = "";
    }
    t[organIndex][segmentIndex] += btnObj.newline
      ? "\n" + finalMeasure
      : finalMeasure;

    t[organIndex][segmentIndex] = t[organIndex][segmentIndex].trim();
    if (t[rybaLength][0] === rybaObject.organs[rybaLength].segments[0].norm) {
      t[rybaLength][0] = "";
    }
    if (
      zaklStringFinal !== "" &&
      !t[rybaLength][0].includes("перплазия предстательной желез")
    ) {
      t[rybaLength][0] += zaklStringFinal;
    }
    addedWithButtons(finalMeasure);
    addedWithButtons(zaklStringFinal);
    setText(t);
    setConstructorOpen(false);
  };
  const enter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // calculate();
      textHandler();
      setConstructorOpen(false);
    }
  };

  return (
    // <div className="popupContainerConstructor" key={(p += 1)}>
    <div className="constructorWindow" key={(p += 1)}>
      <h2
        key={(p += 1)}
        style={{
          margin: "0px",
        }}
      >
        Расчёт объёма яичка
      </h2>

      {/* <img
        src={ICAstenosisIMG}
        alt="spleen"
        style={{
          width: "300px",
          borderRadius: "10px",
          margin: "10px",
          boxShadow: "0px 3px 10px grey",
        }}
      /> */}
      <div>
        <fieldset
          style={{
            display: "flex",
            justifyContent: "center",
            borderRadius: "6px",
          }}
        >
          <legend>Сторона</legend>
          <label>
            <input
              type="radio"
              name="side"
              onClick={(e) => {
                setSide("R");
              }}
            />
            СПРАВА
          </label>
          <label>
            <input
              type="radio"
              name="side"
              onClick={(e) => {
                setSide("L");
              }}
            />
            СЛЕВА
          </label>
        </fieldset>

        {/* <div className="inputVertical">
          <fieldset
            style={{
              display: "flex",
              justifyContent: "center",
              borderRadius: "6px",
            }}
          >
            <legend>Возраст</legend>

            <input
              placeholder="полных лет"
              type="number"
              name="age"
              onChange={(e) => {
                setAge(e.target.value);
              }}
            />
          </fieldset>
        </div>
      </div> */}

        <div
          className="inputVertical"
          style={{
            display: "flex",
          }}
        >
          <input
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
            autoFocus
            type="number"
            placeholder="Размер 1 мм"
            value={size1}
            ы
            onChange={(e) => setSize1(e.target.value)}
            onKeyDown={enter}
          />

          <input
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
            type="number"
            placeholder="Размер 2 в мм"
            value={size2}
            onChange={(e) => setSize2(e.target.value)}
            onKeyDown={enter}
          />

          <input
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
            type="number"
            placeholder="Размер 3 в мм"
            value={size3}
            onChange={(e) => setSize3(e.target.value)}
            onKeyDown={enter}
          />
        </div>

        <button
          className="calculate"
          onClick={() => {
            // calculate();
            textHandler();
          }}
        >
          Рассчитать
        </button>
        {notallsizes && (
          <p style={{ color: "red" }}> ВВЕДИТЕ ВСЕ РАЗМЕРЫ, СТОРОНУ</p>
        )}

        <button
          className="calculate"
          onClick={() => {
            // calculate();
            setConstructorOpen(false);
          }}
        >
          Закрыть
        </button>
      </div>
    </div>
  );
};
export default TesticularVolume;
