import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
import { getDayMonthYear, getHoursMinutes } from "../hooks/MiscFunctions";
import Icon from "../components/redactorComponents/icons/icons";
import { useAuthContext } from "../hooks/useAuthContext";
import { notificationTheBest } from "../rootComponents/NotificationService";

const CommentSection = ({
  whichIdeaComments,
  openComments,
  ideaIndex,
  handleCommentsChange,
  handleCommentsLikes,
  setOpenComments,
  ideaID,
  idea,
  ideas,
  setIdeas,
  settings,
}) => {
  const [refresh, setRefresh] = useState(false);
  const [commentText, setCommentText] = useState();
  const [commentReplyText, setCommentReplyText] = useState("");
  const [visibleComments, setVisibleComments] = useState(10);
  const { user } = useAuthContext();
  const [reply, setReply] = useState(false);
  const [replyID, setReplyID] = useState();
  ///// ADD COMMENT
  const addComment = async (
    req,
    res,
    comment,
    ideaId,
    ideaAuthorId,
    ideaTitle
  ) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/idea/addComment/${ideaId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          userId: user.id,
          userNickname: settings.nickname,
          comment,
          ideaAuthorId,
          ideaTitle,
        }),
      }
    );
    const idea = await response.json();

    if (response.ok) {
      notificationTheBest("Комментарий успешно опубликован");
      let i = [...ideas];
      console.log(i);
      console.log(ideas.findIndex((i) => i._id === ideaId));
      i[ideas.findIndex((i) => i._id === idea._id)] = idea;
      setIdeas(i);
    }
    if (!response.ok) {
      notificationTheBest("Что-то пошло не так");
      console.log(idea);
    }
  }; ///// ADD COMMENT REPLY
  const addCommentReply = async (
    req,
    res,
    ideaId,
    replyTo,
    replyToId,
    replyShort
  ) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/idea/addCommentReply/${ideaId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          userId: user.id,
          userNickname: settings.nickname,
          comment: commentReplyText,
          replyTo,
          replyToId,
          replyShort,
        }),
      }
    );
    const idea = await response.json();

    if (response.ok) {
      notificationTheBest("Ответ успешно опубликован");
      let i = [...ideas];
      console.log(i);
      console.log(ideas.findIndex((i) => i._id === ideaId));
      i[ideas.findIndex((i) => i._id === idea._id)] = idea;
      setIdeas(i);
    }
    if (!response.ok) {
      notificationTheBest("Что-то пошло не так");
      console.log(idea);
    }
  };
  ///// DELETE COMMENT
  const deleteComment = async (req, res, comment, ideaId) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/idea/deleteComment/${ideaId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          comment,
        }),
      }
    );
    const idea = await response.json();

    if (response.ok) {
      notificationTheBest("Комментарий удалён");
      let i = [...ideas];
      console.log(i);
      console.log(ideas.findIndex((i) => i._id === ideaId));
      i[ideas.findIndex((i) => i._id === idea._id)] = idea;
      setIdeas(i);
    }
    if (!response.ok) {
      notificationTheBest("Что-то пошло не так");
      console.log(idea);
    }
  };
  ///// LIKE COMMENT
  const likeComment = async (
    req,
    res,
    commentId,
    ideaId,
    nick,
    ideaAuthorId,
    commentText
  ) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/idea/likeComment/${ideaId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          commentId,
          userId: user.id,
          nick,
          ideaAuthorId,
          commentText,
        }),
      }
    );
    const idea = await response.json();

    if (response.ok) {
      let i = [...ideas];
      console.log(i);
      console.log(ideas.findIndex((i) => i._id === ideaId));
      i[ideas.findIndex((i) => i._id === idea._id)] = idea;
      setIdeas(i);
    }
    if (!response.ok) {
      notificationTheBest("Что-то пошло не так");
      console.log(idea);
    }
  };
  ///// LIKE COMMENT
  const dislikeComment = async (
    req,
    res,
    commentId,
    ideaId,
    nick,
    ideaAuthorId,
    commentText
  ) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/idea/dislikeComment/${ideaId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          commentId,
          userId: user.id,
          nick,
          ideaAuthorId,
          commentText,
        }),
      }
    );
    const idea = await response.json();

    if (response.ok) {
      let i = [...ideas];
      console.log(i);
      console.log(ideas.findIndex((i) => i._id === ideaId));
      i[ideas.findIndex((i) => i._id === idea._id)] = idea;
      setIdeas(i);
    }
    if (!response.ok) {
      notificationTheBest("Что-то пошло не так");
      console.log(idea);
    }
  };
  ///// LIKE COMMENT
  const refreshComments = async (req, res, ideaId) => {
    setRefresh(true);
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/idea/refreshComments/${ideaId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const idea = await response.json();

    if (response.ok) {
      setRefresh(false);
      let i = [...ideas];
      console.log(ideas.findIndex((i) => i._id === ideaId));
      i[ideas.findIndex((i) => i._id === idea._id)] = idea;
      setIdeas(i);
      notificationTheBest("Комментарии обновлены");
    }
    if (!response.ok) {
      notificationTheBest("Что-то пошло не так");
      console.log(idea);
    }
  };
  // Function to load 10 more comments
  const loadMoreComments = () => {
    setVisibleComments((prevVisibleComments) => prevVisibleComments + 10);
  };

  return (
    whichIdeaComments === ideaIndex &&
    openComments && (
      <div className="commentSectionContainer">
        <div className="comments">
          <div>
            <button
              className="closeComments"
              onClick={() => {
                setOpenComments(false);
              }}
            >
              Скрыть комментарии
            </button>{" "}
            <button
              className="refreshComments"
              style={{
                position: "absolute",
                top: "15px",
              }}
              onClick={() => {
                refreshComments(undefined, undefined, idea._id);
              }}
            >
              <Icon
                onClick={() => {
                  setOpenComments(false);
                }}
                title="refresh"
                fill="#999"
              />
              <span
                style={{
                  position: "absolute",
                  width: "70px",
                  top: "6px",
                  color: "darkgrey",
                  fontWeight: "100",
                  // top: "15px",
                }}
              >
                Обновить
              </span>
            </button>
          </div>

          {idea.comments.length === 0 && (
            <div
              style={{
                fontWeight: "100",
                color: "grey",
                padding: "5px",
                paddingLeft: "0px",
              }}
            >
              Комментариев пока нет
            </div>
          )}
          {refresh && <div className="loaderSpinner"></div>}
          {idea.comments.length > 0 &&
            idea.comments
              // .reverse()
              .slice(0, visibleComments)
              .map((c, index) => (
                <div className="commentContainer">
                  <div className="commentInfo">
                    <span className="commentName">
                      {c.authorName}
                      {c.authorId === "67093dbd69080216404eb5e8" && (
                        <span
                          className="creatorName"
                          style={{
                            paddingRight: "20px",
                            paddingLeft: "5px",
                          }}
                        >
                          Создатель{" "}
                          <Icon
                            title="jesus"
                            height={24}
                            width={24}
                            viewBox="0 0 512 512"
                          />
                        </span>
                      )}
                      {c.authorId === "671b88d9ed7db171b698edb8" && (
                        <span
                          className="creatorName"
                          style={{
                            paddingRight: "20px",
                            paddingLeft: "5px",
                          }}
                        >
                          Главный Внештатный Рыболог{"  "}
                          <Icon
                            title="jesus2"
                            height={24}
                            width={24}
                            viewBox="0 0 56 56"
                          />
                        </span>
                      )}
                    </span>
                    <span className="commentTime">
                      {getDayMonthYear(c.timestamp)}
                    </span>
                    <span className="commentTime">
                      {getHoursMinutes(c.timestamp)}
                    </span>
                  </div>

                  <div className="commentContent">
                    <div>
                      {c.replyTo !== null && (
                        <div className="replyTo">
                          {c.reply !== null && (
                            <span>
                              <Icon
                                title={"reply"}
                                height={13}
                                width={13}
                                viewBox="0 0 32 32"
                                fill={
                                  c.dislikes.includes(user.id)
                                    ? "red"
                                    : "#505050"
                                }
                              />
                              <span
                                style={{
                                  fontStyle: "italic",
                                  color: "grey",
                                }}
                              >
                                {" " + "Ответ на: "}
                              </span>
                              {c.replyTo}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                    {c.content}
                  </div>
                  <div className="commentLikes">
                    <button
                      onClick={() =>
                        likeComment(
                          undefined,
                          undefined,
                          c.id,
                          idea._id,
                          settings.nickname,
                          c.authorId,
                          c.content
                        )
                      }
                    >
                      <Icon
                        title={"like"}
                        height={13}
                        width={13}
                        fill={c.likes.includes(user.id) ? "#51abff" : "#505050"}
                      />{" "}
                      {c.likes.length}
                    </button>
                    <button
                      onClick={() =>
                        dislikeComment(
                          undefined,
                          undefined,
                          c.id,
                          idea._id,
                          settings.nickname,
                          c.authorId,
                          c.content
                        )
                      }
                    >
                      <Icon
                        title={"dislike"}
                        height={13}
                        width={13}
                        fill={c.dislikes.includes(user.id) ? "red" : "#505050"}
                      />{" "}
                      {c.dislikes.length}
                    </button>
                    <button
                      onClick={() => {
                        setReply(true);
                        setReplyID(c.id);
                      }}
                    >
                      <Icon
                        title={"reply"}
                        height={13}
                        width={13}
                        viewBox="0 0 32 32"
                        fill={c.dislikes.includes(user.id) ? "red" : "#505050"}
                      />{" "}
                      ответить
                    </button>
                    {reply && replyID === c.id && (
                      <div className="addComment">
                        <TextareaAutosize
                          placeholder="Введите ответ"
                          value={commentReplyText}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              let replyTo = c.authorName + ": " + c.content;
                              addCommentReply(
                                undefined,
                                undefined,
                                idea._id,
                                replyTo,
                                c.authorId,
                                c.content
                              );
                              setCommentReplyText("");
                              setReply(false);
                            }
                          }}
                          onChange={(e) => {
                            setCommentReplyText(e.target.value);
                          }}
                        />
                        <button
                          onClick={(e) => {
                            let replyTo = c.authorName + ": " + c.content;
                            addCommentReply(
                              undefined,
                              undefined,
                              idea._id,
                              replyTo,
                              c.authorId,
                              c.content
                            );
                            setCommentReplyText("");
                            setReply(false);
                          }}
                        >
                          Ответить
                        </button>
                      </div>
                    )}

                    {c.authorId === user.id && (
                      <button
                        onClick={() =>
                          deleteComment(undefined, undefined, c, idea._id)
                        }
                      >
                        <Icon
                          title={"delete"}
                          height={13}
                          width={13}
                          fill={"#707070"}
                        />{" "}
                        Удалить
                      </button>
                    )}
                  </div>
                </div>
              ))}
          <div className="addComment">
            {/* Display the total number of comments */}
            <span
              style={{
                fontSize: "14px",
                margin: "4px",
                color: "grey",
                marginTop: "11px",
              }}
            >
              Всего комментариев: {idea.comments.length}
            </span>{" "}
            {/* Button to load more comments */}
            {visibleComments < idea.comments.length && (
              <button
                onClick={loadMoreComments}
                style={{
                  margin: "5px",
                  marginRight: "10px",
                  background: "transparent",
                  color: "darkgrey",
                  border: "0.5px solid darkgrey",
                }}
              >
                Показать ещё
              </button>
            )}
          </div>
        </div>
        <div className="addComment">
          <TextareaAutosize
            placeholder="Введите свой комментарий"
            value={commentText}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                addComment(
                  undefined,
                  undefined,
                  commentText,
                  idea._id,
                  idea.authorId,
                  idea.title
                );
                setCommentText("");
              }
            }}
            onChange={(e) => {
              setCommentText(e.target.value);
            }}
          />
          <button
            onClick={() => {
              addComment(
                undefined,
                undefined,
                commentText,
                idea._id,
                idea.authorId,
                idea.title
              );
              setCommentText("");
            }}
          >
            Оставить комментарий
          </button>
        </div>
      </div>
    )
  );
};

export default CommentSection;
