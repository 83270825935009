import { Link } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";
import FishLogo from "./svg/FishLogo";
import { useEffect, useState, useRef } from "react";
import "./Navbar.css";
import Icon from "./redactorComponents/icons/icons";
import { settingsAtom } from "../state/atoms/settings";
import { useRecoilState } from "recoil";
import CloseButton from "./CloseButton";

const Navbar = () => {
  const { user } = useAuthContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpen2, setIsMenuOpen2] = useState(false);
  const [settings, setSettings] = useRecoilState(settingsAtom);
  const [showNotifications, setShowNotifications] = useState(false);
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  let notificationsREF = useRef();
  useEffect(() => {
    if (user) {
      const getSettings = async function () {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/settings/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        const stngs = await response.json();
        console.log("settings object: ", stngs);
        if (response.ok) {
          setSettings(stngs);
        }
      };
      if (user) {
        getSettings();
      }
    }
  }, [user]);
  function processArray(arr, maxLength = 30) {
    console.log(arr);
    if (arr === null) {
      return;
    }

    // Step 1: Remove duplicates based on the "protocol" field
    const uniqueProtocols = new Set();
    const filteredArray = arr.filter((item) => {
      if (!uniqueProtocols.has(item.protocol)) {
        uniqueProtocols.add(item.protocol);
        return true;
      }
      return false;
    });

    // Step 2: Sort the filtered array by "date" (newest to oldest)
    const sortedArray = filteredArray.sort((a, b) => {
      if (a === null) return;
      if (b === null) return;
      // Convert dates to timestamps for comparison
      const dateA = new Date(a.date.split(".").reverse().join("-")).getTime();
      const dateB = new Date(b.date.split(".").reverse().join("-")).getTime();
      return dateB - dateA; // Sort from newest to oldest
    });

    // Step 3: Limit the output array length to `maxLength`
    const cappedArray = sortedArray.slice(0, maxLength);

    // Step 4: Return the new array
    return cappedArray;
  }
  function getDateDifference(inputDate) {
    // Parse the input date string into a Date object
    const [day, month, year] = inputDate.split(".").map(Number);
    const date = new Date(year, month - 1, day); // Note: Month is zero-based in JavaScript

    // Get the current date
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date to start of the day

    // Calculate the difference in milliseconds
    const diffTime = today - date;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days

    // Determine the output based on the difference in days
    if (diffDays === 0) {
      return "Сегодня";
    } else if (diffDays === 1) {
      return "Вчера";
    } else if (diffDays === 2) {
      return "Позавчера";
    } else {
      return `${diffDays} дней`;
    }
  }
  function formatDate(timestamp) {
    const now = Date.now();
    const diff = now - timestamp;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    const date = new Date(timestamp);
    const hoursStr = String(date.getHours()).padStart(2, "0");
    const minutesStr = String(date.getMinutes()).padStart(2, "0");

    if (days === 0) {
      return `Сегодня в ${hoursStr}:${minutesStr}`;
    } else if (days === 1) {
      return "Вчера";
    } else if (days === 2) {
      return "Позавчера";
    } else if (days < 7) {
      return `${days} дней назад`;
    } else if (days < 14) {
      return "Неделю назад";
    } else {
      return "Несколько дней назад";
    }
  }
  function hasUnviewed(arr) {
    if (!Array.isArray(arr)) {
      return false; // Or throw an error: throw new Error("Input must be an array");
    }

    for (let i = 0; i < arr.length; i++) {
      const obj = arr[i];

      if (typeof obj !== "object" || obj === null) {
        continue; // Skip non-object elements or handle them differently
      }

      if (obj.viewed === false) {
        return true; // Found an object with viewed: false
      }
    }

    return false; // No objects with viewed: false found
  }
  const closeNotifications = (e) => {
    if (
      showNotifications === true &&
      !notificationsREF.current?.contains(e.target)
    ) {
      setShowNotifications(false);
      const forStupidIfStatement = hasUnviewed(settings.notifications);
      console.log(forStupidIfStatement);
      if (forStupidIfStatement === true) {
        function markAllNotificationsAsViewed(settings) {
          return {
            ...settings, // Spread the existing settings properties
            notifications: settings.notifications.map((notification) => ({
              ...notification, // Spread the existing notification properties
              viewed: true, // Set "viewed" to true
            })),
          };
        }
        const updatedSettings = markAllNotificationsAsViewed(settings);
        setSettings(updatedSettings);
        updateNotifications(updatedSettings);
      }
    }
  };
  document.addEventListener("mousedown", closeNotifications);

  const updateNotifications = async (s) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/settings/updatenotifications`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          notifications: [...s.notifications],
        }),
      }
    );
    const json = await response.json();

    if (response.ok) {
      console.log("patched settings: ", json);
    }
  };
  return (
    <header
      style={{
        background: !user && "rgba(119, 159, 255, 0.03)",
      }}
    >
      <div className="container ">
        <div className="rybovProdaete" style={{ display: "flex" }}>
          <div
            className="logoIMG"
            style={{
              position: "relative",
            }}
          >
            <FishLogo />
            {/* <img
              src="https://swift.timeweb.cloud/v1/cd5c3c6d-7735d894-d309-4ef5-87a5-a3d5741fd815/c8cb694273ae7bb71f915345176aa46a-Photoroom.png"
              style={{
                position: "absolute",
                width: "140px",
                left: "-20px",
                top: "-36px",
                rotate: "205deg",
                transform: "rotateX(180deg)",
                scale: "0.4",
              }}
              alt="new year"
            /> */}
          </div>
          <div className="logoTXT">
            <Link to="/">
              <h1>
                <>
                  {/* <span className="spanTurbo">TurboRyba</span> */}
                  <span className="spanTurbo">Turboryba</span>
                  {/* <span className="spanRyba">ryba</span> */}
                </>
                <span
                  style={{
                    display: "inline-block",
                    width: "100%",
                    fontSize: "14px",
                    fontWeight: "100",
                    position: "relative",
                    top: "-7px",
                    left: "1px",
                  }}
                >
                  Сегодня домой пораньше
                </span>
              </h1>
            </Link>
          </div>
        </div>
        {user && (
          <div className="navbarLinksContainer ">
            <div className="navbarTop navbar-container">
              <div
                className="navbar-menu-trigger"
                onMouseEnter={() => setIsMenuOpen(true)}
                onMouseLeave={() => setIsMenuOpen(false)}
              >
                <button className="navbar-button">
                  <span>Шаблоны</span>
                  <svg
                    className={`navbar-icon ${
                      isMenuOpen ? "navbar-icon-rotate" : ""
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                {/* Dropdown Menu */}
                {isMenuOpen && (
                  <div className="navbar-dropdown">
                    <div className="navbar-dropdown-items">
                      <Link
                        key={3}
                        onClick={() => {
                          setIsMenuOpen(false);
                        }}
                        to="/rybaListCT2"
                        className="navbar-dropdown-item"
                      >
                        <Icon
                          title="ct"
                          viewBox="0 0 58 58"
                          width={22}
                          fill="#8ba9cd"
                        />
                        КТ
                      </Link>
                      <Link
                        key={1}
                        onClick={() => {
                          setIsMenuOpen(false);
                        }}
                        to="/rybaListMRI2"
                        className="navbar-dropdown-item"
                      >
                        <Icon
                          title="mri"
                          viewBox="0 -1 17 17"
                          width={19}
                          fill="#8ba9cd"
                        />
                        МРТ
                      </Link>
                      <Link
                        key={4}
                        onClick={() => {
                          setIsMenuOpen(false);
                        }}
                        // to="/rybaListMRI2"
                        className="navbar-dropdown-item"
                      >
                        <Icon
                          title="xray"
                          viewBox="0 0 248 256"
                          width={20}
                          fill="#8ba9cd"
                        />
                        РГ
                        <span
                          style={{
                            paddingLeft: "5px",
                            fontSize: "12px",
                            color: "grey",
                          }}
                        >
                          в разработке
                        </span>
                      </Link>
                      <Link
                        key={4}
                        onClick={() => {
                          setIsMenuOpen(false);
                        }}
                        // to="/rybaListMRI2"
                        className="navbar-dropdown-item"
                      >
                        <Icon title="us" viewBox="0 0 24 24" fill="#8ba9cd" />
                        УЗИ
                        <span
                          style={{
                            paddingLeft: "5px",
                            fontSize: "12px",
                            color: "grey",
                          }}
                        >
                          в разработке
                        </span>
                      </Link>
                      <Link
                        key={2}
                        onClick={() => {
                          setIsMenuOpen(false);
                        }}
                        to="/sharedRybas"
                        className="navbar-dropdown-item"
                      >
                        {" "}
                        <Icon
                          title="import"
                          viewBox="0 0 310.681 310.681"
                          fill="#8ba9cd"
                        />
                        Импорт шаблонов
                      </Link>
                    </div>
                    <div className="navbar-dropdown-divider"></div>
                    <Link
                      to="/"
                      onClick={() => {
                        setIsMenuOpen(false);
                      }}
                      className="navbar-dropdown-item navbar-dropdown-settings"
                    >
                      <Icon title="save" viewBox="0 0 24 24" fill="#60a5fa" />
                      Сохранённые
                    </Link>
                  </div>
                )}
              </div>
              <div
                className="navbar-menu-trigger"
                onMouseEnter={() => setIsMenuOpen2(true)}
                onMouseLeave={() => setIsMenuOpen2(false)}
              >
                <button className="navbar-button">
                  <span>Недавние</span>
                  <svg
                    className={`navbar-icon ${
                      isMenuOpen2 ? "navbar-icon-rotate" : ""
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                {/* Dropdown Menu */}
                {isMenuOpen2 && (
                  <div
                    className="navbar-dropdown"
                    style={{
                      width: "280px",
                    }}
                  >
                    <div className="navbar-dropdown-items">
                      {processArray(
                        JSON.parse(
                          localStorage.getItem(`autosavedReports${user.id}`)
                        )
                      ) ? (
                        processArray(
                          JSON.parse(
                            localStorage.getItem(`autosavedReports${user.id}`)
                          )
                        ).map((r) => (
                          <a
                            key={3}
                            onClick={() => {
                              setIsMenuOpen(false);
                            }}
                            href={`/primary/${r.id}`}
                            className="navbar-dropdown-item"
                            style={{
                              padding: "4px",
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                padding: "2px",
                                color: "grey",
                                width: "70px",
                              }}
                            >
                              {getDateDifference(r.date)}
                            </div>{" "}
                            <div
                              style={{
                                padding: "2px",
                                width: "165px",
                              }}
                            >
                              {r.rybaTitle ? r.rybaTitle : r.protocol}
                            </div>
                            <div
                              style={{
                                height: "100%",
                                textAlign: "center",
                                verticalAlign: "bottom",
                              }}
                            >
                              <p
                                style={{
                                  padding: "0px",
                                  margin: "0px",
                                  fontSize: "10px",
                                  color: "grey",
                                }}
                              >
                                {r.modality}
                              </p>
                            </div>
                          </a>
                        ))
                      ) : (
                        <span
                          style={{
                            color: "darkgrey",
                            padding: "5px",
                          }}
                        >
                          В этом браузере пока не работали
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <Link to="/ideas">
                <p className="navbarLink navbar-button">Предложить идею</p>
              </Link>
              <Link to="/calculators">
                <p className="navbarLink navbar-button">Калькуляторы</p>
              </Link>
              <Link to="/stats">
                <p className="navbarLink navbar-button">Моя статистика</p>
              </Link>

              {/* <Link to="/howtouse">
                <p className="navbarLink">Как пользоваться</p>
              </Link> */}
            </div>
            <div className="navbarBottom">
              {/* {" "}
            <Link to="/rybaListCT2">
              <p className="navbarLink">КТ</p>
            </Link>
            <Link to="/rybaListMRI2">
              <p className="navbarLink">МРТ</p>
            </Link> */}
            </div>
          </div>
        )}
        {!user && (
          <div className="navbarLinksContainer">
            <div className="navbarTop">
              {/* <Link to="/">
                <p className="navbarLink">Мои шаблоны</p>
              </Link>
              <Link to="/sharedRybas">
                <p className="navbarLink">Импорт шаблонов</p>
              </Link> */}
              {/* <Link to="/calculators">
                <p className="navbarLink">Калькуляторы</p>
              </Link> */}
              {/* <Link to="/profilePage">
                <p className="navbarLink">Настройки</p>
              </Link>
              <Link to="/howtouse">
                <p className="navbarLink">Как пользоваться</p>
              </Link> */}
            </div>
            <div className="navbarBottom">
              {/* {" "}
            <Link to="/rybaListCT2">
              <p className="navbarLink">КТ</p>
            </Link>
            <Link to="/rybaListMRI2">
              <p className="navbarLink">МРТ</p>
            </Link> */}
            </div>
          </div>
        )}
        {!user && (
          <>
            {" "}
            <div className="logout">
              <Link to="/login">
                <p className="loginButtonNavbar">Войти</p>
              </Link>
            </div>
            <div className="logout">
              <Link to="/signup">
                <button className="signupButton">Регистрация</button>
              </Link>
            </div>
          </>
        )}
        {user && (
          <div
            className="profileButton"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // backgroundColor: "#121212", // Dark background
              padding: "10px",
              borderRadius: "8px",
              width: "200px",
            }}
          >
            <p
              style={{
                color: "white",
                fontSize: "15px",
                margin: "0 0 5px 0",
                textAlign: "center",
              }}
            >
              Добро пожаловать, {user.email}
            </p>
            <div>
              <Link
                key={2}
                onClick={() => {
                  setIsMenuOpen(false);
                }}
                to="/profilePage"
                style={{
                  display: "flex",
                  alignItems: "center", // Vertically center the icon and text
                  textDecoration: "none",
                  fontSize: "14px",
                  padding: "4px 10px",
                  borderRadius: "6px",
                  background: "transparent",
                }}
              >
                <Icon
                  title="profile"
                  fill="darkgrey"
                  style={{
                    background: "transparent",
                    borderRadius: "6px",
                  }}
                />
                <span
                  style={{
                    marginLeft: "8px",
                    fontWeight: "100",
                    background: "transparent",
                  }}
                >
                  Личный кабинет
                </span>
              </Link>
            </div>
          </div>
        )}
        {user && settings && (
          <div
            ref={notificationsREF}
            className="profileButton counterOfNotificationsDiv"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background:
                showNotifications && !loadingNotifications && "#121212",
              padding: "10px",
              borderRadius: "8px",
              width: "20px",
              position: "relative",
            }}
            onClick={() => {
              if (showNotifications === false) {
                setLoadingNotifications(true);
                const getSettings = async function () {
                  const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/settings/`,
                    {
                      method: "GET",
                      headers: {
                        Authorization: `Bearer ${user.token}`,
                      },
                    }
                  );
                  const stngs = await response.json();
                  console.log("settings object: ", stngs);
                  if (response.ok) {
                    setSettings(stngs);
                    setLoadingNotifications(false);
                    setShowNotifications(true);
                  }
                };

                getSettings();
              }
            }}
          >
            {loadingNotifications && (
              <div
                className="loaderSpinner"
                style={{
                  position: "absolute",
                  zIndex: "100000000",
                  background: "transparent",
                }}
              ></div>
            )}
            {settings.notifications.filter(
              (notification) => notification.viewed === false
            ).length > 0 && (
              <div
                className="counterOfNotifications"
                style={{
                  backgroundColor: "#f75a4f", // Dark background
                  color: "black", // Dark background
                }}
              >
                {
                  settings.notifications.filter(
                    (notification) => notification.viewed === false
                  ).length
                }
              </div>
            )}
            <Icon title="notifications" className="counterOfNotificationsDiv" />
            {showNotifications && (
              <div className="notificationsList">
                <h2
                  style={{
                    textAlign: "left",
                    fontWeight: "400",
                    position: "relative",
                  }}
                >
                  Уведомления
                </h2>
                <button
                  onClick={async () => {
                    const response = await fetch(
                      `${process.env.REACT_APP_BACKEND_URL}/settings/updatenotifications`,
                      {
                        method: "PATCH",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${user.token}`,
                        },
                        body: JSON.stringify({
                          notifications: [],
                        }),
                      }
                    );
                    const json = await response.json();

                    if (response.ok) {
                      setSettings(json);
                    }
                  }}
                >
                  Очистить уведомления
                </button>
                <hr
                  style={{
                    border: "0.5px solid #888888",
                  }}
                />
                {settings.notifications.length === 0 && "Пока нет уведомлений"}
                {settings.notifications.length > 0 &&
                  settings.notifications.map((n) => (
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          paddingTop: "6px",
                        }}
                      >
                        {" "}
                        {n.content?.includes("понравился пользователю") && (
                          <Icon
                            title="like"
                            width={24}
                            height={24}
                            fill={!n.viewed ? "#6495ED" : "white"}
                          />
                        )}
                        {n.content?.includes("понравилась") && (
                          <Icon
                            title="like"
                            width={24}
                            height={24}
                            fill={!n.viewed ? "#6495ED" : "white"}
                          />
                        )}{" "}
                        {n.content?.includes("не по нраву") && (
                          <Icon
                            title="dislike"
                            width={24}
                            height={24}
                            fill={!n.viewed ? "#B22222" : "white"}
                          />
                        )}
                        {n.content?.includes("ответил") && (
                          <Icon
                            title="reply"
                            width={24}
                            height={24}
                            fill={!n.viewed ? "#6495ED" : "white"}
                          />
                        )}
                        {n.content?.includes("прокомментировал") && (
                          <Icon
                            title="comment"
                            width={24}
                            height={24}
                            viewBox="0 0 32 32"
                            fill={!n.viewed ? "#6495ED" : "white"}
                          />
                        )}
                      </div>

                      <div>
                        <p>{n?.content}</p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "grey",
                            margin: "0px",
                            padding: "0px",
                            paddingLeft: "6px",
                          }}
                        >
                          {formatDate(n.id)}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        )}
      </div>
    </header>
  );
};

export default Navbar;
