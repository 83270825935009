import React, { useState, useEffect } from "react";
import "./DragDropList.css";
import Icon from "../components/redactorComponents/icons/icons";

const DragDropList = ({ items, top, setZakl, close }) => {
  const [listItems, setListItems] = useState(items);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);

  useEffect(() => {
    setListItems(items);
  }, [items]);

  // Handle real-time reordering during drag
  useEffect(() => {
    if (
      draggedIndex === null ||
      hoverIndex === null ||
      draggedIndex === hoverIndex
    )
      return;

    setListItems((prevItems) => {
      const newItems = [...prevItems];
      const [draggedItem] = newItems.splice(draggedIndex, 1);
      newItems.splice(hoverIndex, 0, draggedItem);
      return newItems;
    });

    setDraggedIndex(hoverIndex);
  }, [hoverIndex, draggedIndex]);

  const handleDragStart = (e, index) => {
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.currentTarget);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
    setHoverIndex(index);
  };

  const handleDragEnd = () => {
    setDraggedIndex(null);
    setHoverIndex(null);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleDragEnd();
  };

  return (
    <div
      className="drag-drop-list"
      style={{
        position: "absolute",
        top: `${150 + top}px`,
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: "10000",
      }}
    >
      <h2>Сортировка элементов заключения</h2>
      <p
        style={{
          fontSize: "14px",
          padding: "1px",
          color: "darkgrey",
        }}
      >
        Нужно просто потянуть и отпустить
      </p>
      {listItems.map((item, index) => (
        <div
          key={index}
          className={`drag-item ${index === draggedIndex ? "dragging" : ""}`}
          draggable="true"
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={(e) => handleDragOver(e, index)}
          onDragEnd={handleDragEnd}
          onDrop={handleDrop}
          style={{
            position: "relative",
          }}
        >
          <span
            className="drag-handle"
            style={{
              position: "relative",
            }}
          >
            ☰
          </span>
          <span
            style={{
              position: "absolute",
              left: "610px",
              zIndex: "1200333300",
            }}
            className="pointer"
            onClick={() => {
              let i = [...listItems];
              i.splice(index, 1);
              setListItems(i);
            }}
          >
            <Icon title="delete" fill="darkgrey" />
          </span>
          {item}
          <div className="drag-ghost" />
        </div>
      ))}
      <button
        className="minimal-button"
        onClick={() => {
          console.log(listItems.join(" "));
          setZakl(listItems.join(" "));
        }}
      >
        Применить сортировку
      </button>
      <button
        onClick={close}
        className="minimal-button"
        style={{
          background: "grey",
          marginLeft: "10px",
        }}
      >
        Отмена
      </button>
    </div>
  );
};

export default DragDropList;
