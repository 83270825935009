import TextareaAutosize from "react-textarea-autosize";
import { useState } from "react";
import Radio from "./Radio";
import Prostate from "../../images/Prostate.png";

const ProstateCalculator = ({
  constructorREF,
  setConstructorOpen,
  formState,
  setFormState,
  text,
  setText,
  zaklState,
  setZaklState,
  rybaObject,
  dinamika,
  dinamikaConstructorFunction,
  handlePaste,
  handlePasteConstructor,
  addedWithButtons,
}) => {
  let p = 0;

  const organIndex = constructorREF.current.organIndex;
  const segmentIndex = constructorREF.current.segmentIndex;
  const buttonIndex = constructorREF.current.buttonIndex;

  //////////////////////
  const [size1, setSize1] = useState(null);
  const [size2, setSize2] = useState(null);
  const [size3, setSize3] = useState(null);
  const [psa, setPsa] = useState(null);
  const [notallsizes, setNotallsizes] = useState(false);

  //////////////

  const textHandler = function () {
    ///////
    let zaklStringFinal = "";
    let xx = `${size1}`;
    let zz = `${size2}`;
    let yy = `${size3}`;
    if (!size1 || !size2 || !size3) {
      return setNotallsizes(true);
    }
    if (xx && zz && yy) {
      setNotallsizes(false);
    }
    let x = (xx * zz * yy * 3.14) / 6000;
    x = Math.round(x);
    let psaDensity;
    if (psa) {
      psaDensity = psa / x;
    }
    let finalMeasure = "";
    if (x !== "0" && x <= 30) {
      finalMeasure = `Предстательная железа не увеличена (измерения ${xx}x${zz}x${yy} мм, расчётный объём ${x} см3, норма <30 см3${
        psa
          ? `${
              psaDensity > 0.15
                ? `; плотность ПСА - ${
                    Math.round(psaDensity * 100) / 100
                  }, условная норма до 0.15, вероятность наличия клинически значимого рака 50%`
                : `; плотность ПСА - ${
                    Math.round(psaDensity * 100) / 100
                  }, условная норма до 0.15`
            }`
          : ""
      }).`;

      zaklStringFinal = "";
    }
    if (x !== "0" && x > 30) {
      finalMeasure = `Предстательная железа увеличена в объёме (измерения ${xx}x${zz}x${yy} мм, расчётный объём ${x} см3, норма <30 см3${
        psa
          ? `${
              psaDensity > 0.15
                ? `; плотность ПСА - ${
                    Math.round(psaDensity * 100) / 100
                  }, условная норма до 0.15, вероятность наличия клинически значимого рака 50%`
                : `; плотность ПСА - ${
                    Math.round(psaDensity * 100) / 100
                  }, условная норма до 0.15`
            }`
          : ""
      }).`;

      zaklStringFinal = `Гиперплазия предстательной железы${
        psa && psaDensity > 0.15
          ? `${", превышение порогового значения плотности ПСА"}`
          : ""
      }. `;
    }
    console.log(finalMeasure);
    ///////
    if (dinamika) {
      handlePasteConstructor(
        organIndex,
        segmentIndex,
        finalMeasure,
        zaklStringFinal,
        dinamika
      );
      addedWithButtons(finalMeasure);
      addedWithButtons(zaklStringFinal);
      setConstructorOpen(false);
      return;
    }

    let btnObj =
      rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex];
    let rybaLength = rybaObject.organs.length - 1;

    let t = [...text];
    if (
      t[organIndex][segmentIndex] ===
      rybaObject.organs[organIndex].segments[segmentIndex].norm
    ) {
      t[organIndex][segmentIndex] = "";
    }
    t[organIndex][segmentIndex] += btnObj.newline
      ? "\n" + finalMeasure
      : finalMeasure;

    t[organIndex][segmentIndex] = t[organIndex][segmentIndex].trim();
    if (t[rybaLength][0] === rybaObject.organs[rybaLength].segments[0].norm) {
      t[rybaLength][0] = "";
    }
    if (
      zaklStringFinal !== "" &&
      !t[rybaLength][0].includes("перплазия предстательной желез")
    ) {
      t[rybaLength][0] += zaklStringFinal;
    }
    addedWithButtons(finalMeasure);
    addedWithButtons(zaklStringFinal);
    setText(t);
    setConstructorOpen(false);
  };
  const enter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // calculate();
      textHandler();
      setConstructorOpen(false);
    }
  };

  return (
    // <div className="popupContainerConstructor" key={(p += 1)}>
    <div className="constructorWindow" key={(p += 1)}>
      <h1 key={(p += 1)}>Расчёт объёма предстательной железы</h1>
      <img
        src={Prostate}
        alt="prostate"
        style={{
          width: "400px",
          borderRadius: "10px",
          margin: "10px",
          boxShadow: "0px 3px 10px grey",
        }}
      />
      <div className="inputVertical">
        <input
          autoFocus
          type="text"
          placeholder="Размер 1 в мм"
          value={size1}
          onChange={(e) => setSize1(e.target.value)}
          onKeyDown={enter}
        />
        <input
          type="text"
          placeholder="Размер 2 в мм"
          value={size2}
          onChange={(e) => setSize2(e.target.value)}
          onKeyDown={enter}
        />
        <input
          type="text"
          placeholder="Размер 3 в мм"
          value={size3}
          onChange={(e) => setSize3(e.target.value)}
          onKeyDown={enter}
        />
      </div>{" "}
      <div
        className="inputVertical"
        style={{
          display: "flex",
          marginTop: "20px",
        }}
      >
        <fieldset
          style={{
            display: "flex",
            justifyContent: "center",
            borderRadius: "6px",
          }}
        >
          <legend>Расчёт плотности ПСА (опционально; нг/дл)</legend>

          <label>
            <input
              onKeyDown={enter}
              placeholder="нг/дл"
              type="number"
              name="age"
              onChange={(e) => {
                setPsa(e.target.value);
              }}
            />
          </label>
        </fieldset>
      </div>
      <button
        className="calculate"
        onClick={() => {
          // calculate();
          textHandler();
        }}
      >
        Рассчитать
      </button>
      {notallsizes && <p style={{ color: "red" }}> ВВЕДИТЕ ВСЕ РАЗМЕРЫ</p>}
      <button
        className="calculate"
        onClick={() => {
          // calculate();
          setConstructorOpen(false);
        }}
      >
        Закрыть
      </button>
    </div>
    // </div>
  );
};
export default ProstateCalculator;
