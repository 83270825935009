function countDots(str) {
  return str.trim().split("...").length;
}

function getDayMonthYear(isoDate) {
  // Parse the ISO date string into a Date object
  const date = new Date(isoDate);

  // Extract day, month, and year
  const day = date.getDate(); // Day of the month (1–31)
  const month = date.getMonth() + 1; // Month (0–11, so we add 1)
  const year = date.getFullYear(); // Full year (e.g., 2025)

  // Format as "day.month.year" with leading zeros if needed
  const formattedDate = `${String(day).padStart(2, "0")}.${String(
    month
  ).padStart(2, "0")}.${year}`;

  return formattedDate;
}
function getHoursMinutes(isoDate) {
  // Parse the ISO date string into a Date object
  const date = new Date(isoDate);

  // Extract hours and minutes
  const hours = date.getUTCHours(); // Get hours in UTC
  const minutes = date.getUTCMinutes(); // Get minutes in UTC

  // Format as "hour:minutes" with leading zeros if needed
  const formattedTime = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}`;

  return formattedTime;
}

module.exports = { countDots, getDayMonthYear, getHoursMinutes };
