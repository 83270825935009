import { useRecoilState } from "recoil";
import { useState, useEffect, useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { settingsAtom } from "../../state/atoms/settings";
import { rybaIDatom } from "../../state/atoms/rybaID";
import { fullRYBAatom } from "../../state/atoms/fullRYBAatom";
import { textState } from "../../state/atoms/textareaText";
import { useDebounce, useDebouncedCallback } from "use-debounce";

const Textarea = ({
  organFocus,
  setOrganFocus,
  organIndex,
  segmentIndex,
  animationREF,
  setAnimationRef,
  settings,
  setSettings,
}) => {
  const [text, setText] = useRecoilState(textState);
  const [localTextState, setLocalTextState] = useState(
    text[organIndex][segmentIndex]
  );
  const [rybaObject] = useRecoilState(fullRYBAatom);
  ////// animation states
  const [characterCount, setCharacterCount] = useState(0);
  const [showButtonAnimation, setShowButtonAnimation] = useState(false);
  const [showTypeAnimation, setShowTypeAnimation] = useState(false);
  const [buttonAddedChars, setButtonAddedChars] = useState(0);
  const [typeAddedChars, setTypeAddedChars] = useState(0);
  const textareaRef = useRef(null);
  const typeIndicatorRef = useRef(null);
  const buttonIndicatorRef = useRef(null);
  const typeDebounceTimer = useRef(null);
  //////

  const rL = function (rO) {
    return rO.organs.length - 1;
  };

  const updateGeneralState = useDebouncedCallback((text) => {
    let x = [...text];
    x[organIndex][segmentIndex] = localTextState;
    setText(x);
  }, 300);

  useEffect(() => {
    if (
      animationREF.current.organIndex === organIndex &&
      animationREF.current.segmentIndex === segmentIndex &&
      animationREF.current.opisLength > 0
    ) {
      setButtonAddedChars(animationREF.current.opisLength);
      setShowButtonAnimation(true);
      setTimeout(() => setShowButtonAnimation(false), 1000);
      animationREF.current.opisLength = 0;
    }
    if (
      animationREF.current.zaklLength > 0 &&
      segmentIndex === 0 &&
      organIndex === rybaObject.organs.length - 1
    ) {
      setButtonAddedChars(animationREF.current.zaklLength);
      setShowButtonAnimation(true);
      console.log("тут длина заключения: ", animationREF.current.zaklLength);
      setTimeout(() => setShowButtonAnimation(false), 1000);
      animationREF.current.zaklLength = 0;
    }
    // setAnimationRef(0, 0, undefined, undefined);
    setLocalTextState(text[organIndex][segmentIndex]);
  }, [text]);

  // Refs to store the start time, previous text, and deleted characters count
  const startTimeRef = useRef(null);
  const previousTextRef = useRef("");
  const deletedCharsRef = useRef(0);
  const totalCharsRef = useRef(0); // Track total characters typed (including deleted ones)
  // Add a new ref to track the previous text length for delta calculation
  const previousLengthRef = useRef(0);

  // Debounced callback for calculating typing speed and accuracy
  const calculateTypingSpeed = useDebouncedCallback((textWhole) => {
    const endTime = Date.now();
    const startTime = startTimeRef.current;

    // Calculate time elapsed in minutes
    const timeElapsed = (endTime - startTime) / 1000 / 60;

    // Calculate DELTA characters since the last call (fixes overcounting)
    const currentLength = textWhole.length;
    const deltaCharacters = currentLength - previousLengthRef.current;
    previousLengthRef.current = currentLength; // Update for next calculation

    // Calculate accuracy using DELTA characters and deletions in this period
    const totalChars = deltaCharacters + deletedCharsRef.current;
    const correctChars = Math.max(0, deltaCharacters - deletedCharsRef.current);
    const accuracy =
      totalChars > 0 ? Math.round((correctChars / totalChars) * 100) : 100;

    const charactersPerMinute = Math.round(deltaCharacters / timeElapsed);

    if (charactersPerMinute >= 60 && charactersPerMinute <= 1500) {
      let prevSettings = { ...settings };

      let newTypingTime = prevSettings.stats.typingTime + timeElapsed;

      // Update arrays (same as before)
      let newWordsPerMinute = [...prevSettings.stats.wordsPerMinute];
      let newCharactersPerMinute = [...prevSettings.stats.charactersPerMinute];
      let newAccuracy = [...prevSettings.stats.accuracy];

      if (newWordsPerMinute.length >= 50) newWordsPerMinute.shift();
      if (newCharactersPerMinute.length >= 50) newCharactersPerMinute.shift();
      if (newAccuracy.length >= 50) newAccuracy.shift();

      newCharactersPerMinute.push(charactersPerMinute);
      newAccuracy.push(accuracy);

      // Use DELTA to avoid overcounting
      let newTypedCharacters =
        Number(prevSettings.stats.typedCharacters) + deltaCharacters;

      console.log("Delta:", deltaCharacters, "Total:", newTypedCharacters);
      if (!settings.achievements.typing1080 && newCharactersPerMinute > 1080) {
        prevSettings.achievements.typing1080 = true;
        window.achievement(
          "ТУРБОМАШИНА",
          `Ваша скорость печати в моменте превысила мировой рекорд в 1080 знаков в минуту и составила ${newCharactersPerMinute}`,
          "https://swift.timeweb.cloud/v1/cd5c3c6d-7735d894-d309-4ef5-87a5-a3d5741fd815/achievements/typingCat.gif"
        );
      }
      prevSettings.stats.charactersPerMinute = newCharactersPerMinute;
      prevSettings.stats.accuracy = newAccuracy;
      prevSettings.stats.typingTime = newTypingTime;
      prevSettings.stats.typedCharacters = newTypedCharacters;
      setSettings(prevSettings);
    }

    // Reset counters for next interval
    startTimeRef.current = Date.now();
    deletedCharsRef.current = 0;
    previousTextRef.current = textWhole;
  }, 1000);

  // Updated handleChange to work with delta
  const handleChange = (e) => {
    const textWhole = e.target.value;

    // Track deletions compared to previous state
    if (textWhole.length < previousTextRef.current.length) {
      deletedCharsRef.current +=
        previousTextRef.current.length - textWhole.length;
    }

    // Initialize start time on first keystroke
    if (!startTimeRef.current) {
      startTimeRef.current = Date.now();
    }

    // Trigger debounced calculation
    calculateTypingSpeed(textWhole);

    // Update previous text state AFTER debounced call
    previousTextRef.current = textWhole;
  };

  return (
    <div className="segmentText">
      {/* Button animation indicator */}
      {showButtonAnimation && (
        <span
          ref={buttonIndicatorRef}
          className="textarea-added-indicator textarea-button-indicator"
        >
          +{buttonAddedChars}
        </span>
      )}
      <TextareaAutosize
        key={segmentIndex}
        autoCorrect={false}
        spellCheck={false}
        className={`segmentTextTextarea ${
          organIndex === 0 && segmentIndex === 0 && "topRight"
        } ${organIndex === 1 && "bottomRight"} ${
          organIndex === 2 && segmentIndex === 0 && "topRight"
        } ${
          organIndex === rL(rybaObject) - 1 &&
          segmentIndex ===
            rybaObject.organs[rL(rybaObject) - 1].segments.length - 1 &&
          "bottomRight"
        } ${
          organIndex === rL(rybaObject) &&
          segmentIndex === 0 &&
          "topRight bottomRight"
        } ${showButtonAnimation ? "textarea-button-highlight" : ""} ${
          showTypeAnimation ? "textarea-type-highlight" : ""
        }`}
        value={localTextState}
        onChange={(e) => {
          handleChange(e);
          setLocalTextState(e.target.value);
          updateGeneralState(text);
        }}
        onFocus={(e) => {
          setOrganFocus(organIndex);
        }}
        placeholder="-"
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            setOrganFocus(null);
          }
          if (e.key === "Tab") {
            e.preventDefault();
          }
        }}
        style={{
          backgroundColor:
            localTextState !==
              rybaObject.organs[organIndex].segments[segmentIndex].norm &&
            settings.changedTextColor,
        }}
      ></TextareaAutosize>
    </div>
  );
};

export default Textarea;
