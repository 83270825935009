import { useState } from "react";
import prostate from "../images/Prostate.png";
import { notificationTheBest } from "../../rootComponents/NotificationService";

const OvarianVolume = ({ notification }) => {
  const [notallsizes, setNotallsizes] = useState(false);
  const [result, setResult] = useState("0");
  const [age, setAge] = useState(null);
  const [size1, setSize1] = useState();
  const [size2, setSize2] = useState();
  const [size3, setSize3] = useState();
  const [calculated, setCalculated] = useState(false);
  const [string, setString] = useState(undefined);
  // let size1;
  // let size2;
  // let size3;
  const calculate = () => {
    let zaklStringFinal = "";
    let xx = `${size1}`;
    let zz = `${size2}`;
    let yy = `${size3}`;
    if (!size1 || !size2 || !size3 || !age) {
      return setNotallsizes(true);
    }

    if (xx && zz && yy) {
      setNotallsizes(false);
    }
    let volume = (xx * zz * yy * 3.14) / 6000;
    volume = Math.round(volume);
    setResult(volume);

    let finalMeasure = "";
    const assessVolume = function (age, volume) {
      if (age < 30) {
        return volume > 15
          ? " - выше двух стандартных отклонений над медианой для указанного возраста (95% в популяции < 15см3; значения усреднённые и должны оцениваться с учётом клинического контекста)"
          : "- не увеличен, объём ниже двух стандартных отклонений над медианой для указанного возраста (95% в популяции < 15см3; значения усреднённые и должны оцениваться с учётом клинического контекста)";
      }
      if (age < 40 && age >= 30) {
        return volume > 13
          ? " - выше двух стандартных отклонений над медианой для указанного возраста (95% в популяции < 13см3; значения усреднённые и должны оцениваться с учётом клинического контекста)"
          : "- не увеличен, объём ниже двух стандартных отклонений над медианой для указанного возраста (95% в популяции < 13см3; значения усреднённые и должны оцениваться с учётом клинического контекста)";
      }
      if (age < 50 && age >= 40) {
        return volume > 11
          ? " - выше двух стандартных отклонений над медианой для указанного возраста (95% в популяции < 11см3; значения усреднённые и должны оцениваться с учётом клинического контекста)"
          : "- не увеличен, объём ниже двух стандартных отклонений над медианой для указанного возраста (95% в популяции < 11см3; значения усреднённые и должны оцениваться с учётом клинического контекста)";
      }
      if (age < 60 && age >= 50) {
        return volume > 6
          ? " - выше двух стандартных отклонений над медианой для указанного возраста (95% в популяции < 6см3; значения усреднённые и должны оцениваться с учётом клинического контекста)"
          : "- не увеличен, объём ниже двух стандартных отклонений над медианой для указанного возраста (95% в популяции < 6см3; значения усреднённые и должны оцениваться с учётом клинического контекста)";
      }
      if (age >= 60) {
        return volume > 5
          ? " - выше двух стандартных отклонений над медианой для указанного возраста (95% в популяции < 5см3; значения усреднённые и должны оцениваться с учётом клинического контекста)"
          : "- не увеличен, объём ниже двух стандартных отклонений над медианой для указанного возраста (95% в популяции < 5см3; значения усреднённые и должны оцениваться с учётом клинического контекста)";
      }
    };

    finalMeasure = `Максимальные размеры яичника в трёх плоскостях ${xx}х${yy}x${zz} мм, расчётный объём ${volume} мл ${assessVolume(
      age,
      volume
    )}.`;
    setString(finalMeasure);
    notificationTheBest(
      "Формулировка для заключения автоматически скопирована в буфер обмена",
      3000
    );
    setCalculated((c) => (c = !c));
    setSize1(undefined);
    setSize2(undefined);
    setSize3(undefined);
  };

  const enter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      calculate();
    }
  };

  return (
    <div className="calculator">
      <p className="name">Объём яичника</p>
      <div className="legend">
        <p>
          {
            // "Подойдёт для любой сферы или эллипсоида (например, для округлой гематомы). "
          }
        </p>
      </div>
      <div className="inputVertical">
        <fieldset
          style={{
            justifyContent: "center",
            borderRadius: "6px",
            border: "none",
            background: "#171717",
            color: "grey",
            margin: "5px",
          }}
          key={calculated}
        >
          Возраст
          <label>
            <input
              placeholder="полных лет"
              type="number"
              name="age"
              onChange={(e) => {
                setAge(e.target.value);
              }}
            />
          </label>
        </fieldset>
      </div>
      <div className="inputVertical" key={calculated}>
        <input
          type="number"
          placeholder="Размер 1 в мм"
          value={size1}
          onChange={(e) => setSize1(e.target.value)}
          onKeyDown={enter}
        />
        <input
          type="number"
          placeholder="Размер 2 в мм"
          value={size2}
          onChange={(e) => setSize2(e.target.value)}
          onKeyDown={enter}
        />
        <input
          type="number"
          placeholder="Размер 3 в мм"
          value={size3}
          onChange={(e) => setSize3(e.target.value)}
          onKeyDown={enter}
        />
      </div>
      <div className="results">{result} см3</div>
      <button
        className="calculate"
        onClick={() => {
          calculate();
        }}
      >
        Рассчитать (Enter)
      </button>
      <div className="legend">
        <p>{string}</p>

        {notallsizes && (
          <p style={{ color: "red" }}> ВВЕДИТЕ ВСЕ РАЗМЕРЫ И ВОЗРАСТ</p>
        )}
      </div>
    </div>
  );
};

export default OvarianVolume;
