import { useEffect, useState, useRef } from "react";
import InputPrompt from "./editing/InputPrompt";
import { useRecoilState } from "recoil";
import { textState } from "../state/atoms/textareaText";
import { promptStateAtom } from "../state/atoms/promptState";
import { formStateAtom } from "../state/atoms/formState";
import { fullRYBAatom } from "../state/atoms/fullRYBAatom";
import { zaklStateAtom } from "../state/atoms/zaklState";
import { useParams } from "react-router-dom";
import { rybaIDatom } from "../state/atoms/rybaID";
import Notification from "./editing/NotificationWindow";
import "./Ryba4.css";
import "./editing/NotificationWindow";
import { useAuthContext } from "../hooks/useAuthContext";
import CopyAll from "./svg/CopyAll";
import ResetToNorm from "./svg/ResetToNorm";
import EditRyba from "./svg/EditRyba";
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import React from "react";
import ConstructorRouter from "./smallComponents/constructorRouter";
import CopyProtocol from "./svg/CopyProtocol";
import CopyImpression from "./svg/CopyImpression";
import TextareaAutosize from "react-textarea-autosize";
import Buttons from "../components/smallComponents/buttons";
import ZoomIn from "./svg/ZoomIn";
import ZoomOut from "./svg/ZoomOut";
import SaveButton from "./svg/SaveButton";
import TXT from "./svg/TXT";
import { saveAs } from "file-saver";
import DOC from "./svg/DOC";
import { Document, Packer, Paragraph, TextRun, AlignmentType } from "docx";
import OK from "./svg/OK";
import Textarea from "./rybaComponents/textarea";
import { settingsAtom } from "../state/atoms/settings";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import SadFish from "./images/SadFish.png";
import Shape from "./shapes/shapes";
import Shape2 from "./shapes/shapes2";
import Brain from "./shapes/Nuclei.png";
import ImageComponent from "./smallComponents/imageComponent";
import OverlayImages from "./smallComponents/OverlayImages";
import InputPromptZakl from "./editing/InputPromptZakl";
import AddButton from "./editing/AddButton";
import FindButton from "./editing/FindButton";
import TypingSpeedCalculator from "./TypingSpeedCalculator";
import SnakeGame from "./SnakeGame";
import { notificationTheBest } from "../rootComponents/NotificationService";
import SearchComponent from "./editing/SearchComponent";
import ButtonEditRyba4 from "../AIcomponents/ButtonEditRyba4";
import ButtonEditWindow from "./Ryba4ButtonEdit";
import Icon from "./redactorComponents/icons/icons";
import AreYouSureGeneric from "./smallComponents/AreYouShureGeneric";
import ZaklDrag2 from "../AIcomponents/ZaklDrag2";
import CALCULATOR from "./editing/CALCULATOR";
import CalculatorRedactor from "./smallComponents/buttonsRedactorVariants/CalculatorRedactor";
import TextareaAnimation from "./smallComponents/buttonsRedactorVariants/AnimatedTextarea";

const Ryba4 = () => {
  const [rybaObject, setRybaObject] = useRecoilState(fullRYBAatom);
  const [originalRybaJSON, setOriginalRybaJSON] = useState(null);
  const [editedButtonJSON, setEditedButtonJSON] = useState(null);
  const [text, setText] = useRecoilState(textState); // recoil state of the textarea
  // const [rybaObject, setRybaObject] = useRecoilState(fullRYBAatom);
  // const [text, setText] = useRecoilState(textState);
  const [promptText, setPromptText] = useState();
  const [formState, setFormState] = useRecoilState(formStateAtom);
  const [zaklState, setZaklState] = useRecoilState(zaklStateAtom);
  const [settings, setSettings] = useRecoilState(settingsAtom);
  const { id } = useParams();
  const [notificationWindowOpen, setNotificationWindowOpen] = useState(false);
  const [inputPromptOpen, setInputPromptOpen] = useState(false);
  const [inputPromptZaklOpen, setInputPromptZaklOpen] = useState(false);
  const [rated, setRated] = useState(true);
  const { user } = useAuthContext();
  ////////// Ryba4 state ////////
  const [organFocus, setOrganFocus] = useState(2000);
  const [rybaLoaded, setRybaLoaded] = useState(false);
  const [reset, setReset] = useState(false);
  const [constructorOpen, setConstructorOpen] = useState(false);
  const [printData, setPrintData] = useState({});
  const [dinamika, setDinamika] = useState(false);
  const [notes, setNotes] = useState();
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(-360);
  const [pressed, setPressed] = useState(false);
  const [nameReportOpen, setNameReportOpen] = useState(false);
  const [reportTitle, setReportTitle] = useState(null);
  const [reportTitle2, setReportTitle2] = useState(null);
  const [saved, setSaved] = useState(false);
  const [beenModified, setBeenModified] = useState(false);
  const [error, setError] = useState("");
  const [zoom, setZoom] = useState();
  const [waitedForLoading, setWaitedForLoading] = useState(false);
  const [choicesCount, setChoicesCount] = useState(0);
  const [promptButton, setPromptButton] = useState();
  const [overlayImages, setOverlayImages] = useState(false);
  const [typeSpeed, setTypeSpeed] = useState(0);
  const [addButtonOpen, setAddButtonOpen] = useState(false);
  const [findButtonOpen, setFindButtonOpen] = useState(false);
  const [editButtonOpen, setEditButtonOpen] = useState(false);
  const [editedButton, setEditedButton] = useState();
  const [scrollY, setScrollY] = useState();
  const [sureDeleteButton, setSureDeleteButton] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [sortedZaklText, setSortedZaklText] = useState();
  const [sortedZaklOpen, setSortedZaklOpen] = useState(false);
  const [haveBeenSaved, setHaveBeenSaved] = useState(false);
  const [CALCULATORopen, setCALCULATORopen] = useState(false);
  const [calculatorEditorOpen, setCalculatorEditorOpen] = useState(false);

  useEffect(() => {
    const getFullRyba = async (req, res) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/full/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();
      if (response.status === 401) {
        window.localStorage.removeItem("user");
        window.location.reload();
      }
      // setting up ryba obj
      let r = { ...json };
      // console.log("rybaObject: ", r);
      const localStorageTXT = JSON.parse(localStorage.getItem(id));

      const z = JSON.parse(localStorage.getItem("zoom"));
      setZoom(z);
      if (!z) {
        setZoom(1);
      }
      const notes = JSON.parse(localStorage.getItem("notes" + id));
      // console.log("длина рыбы совпадает?", r.organs, localStorageTXT);
      if (response.ok) {
        console.log("rybaObject: ", r);
        setRybaObject(r);
        setOriginalRybaJSON(JSON.stringify(r));
        setRybaLoaded(true);
        // set text state
        if (notes) {
          setNotes(notes);
        }
        if (!localStorageTXT) {
          // set raw text
          let t = [];
          let c = [];
          r.organs.map((o, organIndex) => {
            t.push([]);
            c.push([]);
            o.segments.map((s, segmentIndex) => {
              t[organIndex].push(s.norm);
              c[organIndex].push([]);
              s.buttons.map((b) => {
                c[organIndex][segmentIndex].push(false);
              });
            });
          });
          setText(t);
          setReset(c);

          let h = [];

          r.organs.map((o, organIndex) => {
            h.push([]);
            o.segments.map((s, segmentIndex) => {
              h[organIndex].push([]);
              if (s.buttons) {
                s.buttons.map((b, buttonIndex) => {
                  h[organIndex][segmentIndex].push("");
                });
              }
            });
          });
        }

        if (localStorageTXT) {
          if (localStorageTXT.length !== r.organs.length) {
            setBeenModified(true);
          }
          let t = [];
          r.organs.map((o, organIndex) => {
            t.push([]);

            o.segments.map((s, segmentIndex) => {
              if (localStorageTXT[organIndex] !== undefined || null) {
                localStorageTXT[organIndex]
                  ? t[organIndex].push(
                      localStorageTXT[organIndex][segmentIndex]
                    )
                  : t[organIndex].push(s.norm);
                if (o.segments.length !== localStorageTXT[organIndex].length) {
                  setBeenModified(true);
                }
              }
            });
          });
          setText(t);
          // setText(localStorageTXT);
          let c = [];
          r.organs.map((o, organIndex) => {
            c.push([]);
            o.segments.map((s, segmentIndex) => {
              c[organIndex].push([]);
              s.buttons.map((b) => {
                c[organIndex][segmentIndex].push(false);
              });
            });
          });
          setReset(c);
        }
      }
      if (!response.ok) {
        setError(json);
      }
    };

    if (user) {
      getFullRyba();
    }
    setTimeout(() => {
      setWaitedForLoading(true);
    }, 3000);
  }, [user]);

  // счётчики сегментов и текстовых полей рыбы
  let keyCounter = 0;
  let zaklRef = useRef("");
  let notificationText = useRef();
  let segmentNumber = useRef();
  let organNumber = useRef();
  let whichClick = useRef();
  let radioINDEX = useRef();
  let buttonINDEX = useRef("");
  let segmentINDEX = useRef("");
  let organINDEX = useRef("");
  let constructorREF = useRef({});
  let imagesREF = useRef();
  let editedButtonTypeREF = useRef();
  let editedButtonREF = useRef();
  let animationREF = useRef({
    opisLength: 0,
    zaklLength: 0,
    organIndex: undefined,
    segmentIndex: undefined,
  });
  ///// stats update functions
  function addedWithButtons(length) {
    const numCharacters = Number(length.length);
    let s = { ...settings };
    const prevCharacters = Number(s.stats.addedWithButtons);
    s.stats.addedWithButtons = numCharacters + prevCharacters;
    setSettings(s);
  }
  function trimWhitespaceAndNewlines(str) {
    // Use a regular expression to match whitespace and newlines at the beginning and end of the string
    return str.replace(/^[\s\n\r]+|[\s\n\r]+$/g, "");
  }
  ///////// DATE AND TIME FUNCTIONS ///////////
  const createDate = () => {
    const currentdate = new Date();
    const day = `${
      currentdate.getDate() > 9
        ? currentdate.getDate()
        : "0" + currentdate.getDate()
    }`;
    const month = `${
      currentdate.getMonth() + 1 > 9
        ? currentdate.getMonth() + 1
        : "0" + Number(currentdate.getMonth() + 1)
    }`;
    const year = `${currentdate.getFullYear()}`;
    return `${day}.${month}.${year}`;
  };
  const createTime = () => {
    const currentdate = new Date();
    const minutes = `${
      currentdate.getMinutes() > 9
        ? currentdate.getMinutes()
        : "0" + currentdate.getMinutes()
    }`;
    const hours = `${
      currentdate.getHours() > 9
        ? currentdate.getHours()
        : "0" + currentdate.getHours()
    }`;
    return `${hours}:${minutes}`;
  };

  // BUTTON copy text from ALL textareas
  const clickHandlerCopyToClipboardALL = function (e) {
    autosaveReport();
    const x = [...text];
    const orgns = [...rybaObject.organs];
    let rybaString = "";
    orgns.map((organ, organIndex) => {
      let organTextString = "";
      organ.segments.map((segment, segmentIndex) => {
        let segmentText = x[organIndex][segmentIndex];
        if (segmentText) {
          segmentText = segmentText.trim();
        }
        if (segmentText.slice(0, 1) === "-") {
          segmentText = "\n" + segmentText;
        }
        if (segmentText.slice(-1) !== "." && segmentText !== "") {
          segmentText += ". ";
        }
        if (segmentText.slice(-1) === ".") {
          segmentText += " ";
        }
        organTextString += segmentText;
      });

      let organTitleString = organ.title;
      organTitleString = organTitleString.trim();
      if (organTitleString.slice(-1) !== ":") {
        organTitleString += ": ";
      }
      if (organTitleString.slice(-1) === ":") {
        organTitleString += " ";
      }

      if (organIndex === 0) {
        rybaString += organTextString + "\n";
      }
      if (
        organIndex > 0 &&
        organIndex < rybaObject.organs.length - 1 &&
        organTextString.trim() !== ""
      ) {
        rybaString += organTitleString + organTextString + "\n";
      }
      if (organIndex === rybaObject.organs.length - 1) {
        rybaString += "\n" + organTitleString + organTextString;
      }
      // rybaString += organTitleString + organTextString + "\n";
    });

    navigator.clipboard.writeText(rybaString);
    notificationTheBest("Текст описания и заключения успешно скопирован 💾");
  };

  const clickHandlerCopyToClipboardProtocol = function (e) {
    autosaveReport();
    const x = [...text];
    const orgns = [...rybaObject.organs];
    orgns.splice(-1, 1);
    let rybaString = "";

    orgns.map((organ, organIndex) => {
      let organTextString = "";
      organ.segments.map((segment, segmentIndex) => {
        let segmentText = x[organIndex][segmentIndex];
        if (segmentText) {
          segmentText = segmentText.trim();
        }
        if (segmentText.slice(0, 1) === "-") {
          segmentText = "\n" + segmentText;
        }
        if (segmentText.slice(-1) !== "." && segmentText !== "") {
          segmentText += ". ";
        }
        if (segmentText.slice(-1) === ".") {
          segmentText += " ";
        }
        organTextString += segmentText;
      });

      let organTitleString = organ.title;
      organTitleString = organTitleString.trim();
      if (organTitleString.slice(-1) !== ":") {
        organTitleString += ": ";
      }
      if (organTitleString.slice(-1) === ":") {
        organTitleString += " ";
      }

      if (organIndex === 0) {
        rybaString += organTextString + "\n";
      }
      if (
        organIndex > 0 &&
        organIndex < rybaObject.organs.length - 1 &&
        organTextString.trim() !== ""
      ) {
        rybaString += organTitleString + organTextString + "\n";
      }
      if (organIndex === rybaObject.organs.length - 1) {
        rybaString += "\n" + organTitleString + organTextString;
      }
    });

    navigator.clipboard.writeText(rybaString.trim());
    notificationTheBest("Текст протокола успешно скопирован 💾");
  };

  const clickHandlerCopyToClipboardImpression = function (e) {
    autosaveReport();
    const x = [...text];
    const orgns = [...rybaObject.organs];

    let rybaString = "";

    orgns.map((organ, organIndex) => {
      let organTextString = "";
      organ.segments.map((segment, segmentIndex) => {
        let segmentText = x[organIndex][segmentIndex];
        if (segmentText) {
          segmentText = segmentText.trim();
        }

        if (segmentText.slice(-1) !== "." && segmentText !== "") {
          segmentText += ". ";
        }
        if (segmentText.slice(-1) === ".") {
          segmentText += " ";
        }
        organTextString += segmentText;
      });

      let organTitleString = organ.title;
      organTitleString = organTitleString.trim();
      if (organTitleString.slice(-1) !== ":") {
        organTitleString += ": ";
      }
      if (organTitleString.slice(-1) === ":") {
        organTitleString += " ";
      }

      if (organIndex === 0) {
        return;
      }
      if (organIndex > 0 && organIndex < rybaObject.organs.length - 1) {
        return;
      }
      if (organIndex === rybaObject.organs.length - 1) {
        rybaString = organTextString;
      }
    });

    navigator.clipboard.writeText(rybaString.trim());
    notificationTheBest("Текст заключения успешно скопирован 💾");
  };

  //////////////////// RATE IMPORTED RYBA ////////////////////
  const rateSharedRyba = async (req, res, parentRybaIdAttribute, grade) => {
    /// get parent ryba
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/pond/${parentRybaIdAttribute}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const rate = Number(grade);
    const parentRyba = await response.json();
    let ratings = [...parentRyba.ratings, rate];
    const averageRating =
      ratings.reduce((a, b) => a * 1 + b * 1) / ratings.length;

    /// patch parent ryba
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/pond/${parentRybaIdAttribute}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          averageRating,
          ratings,
          ratingsLength: ratings.length,
        }),
      }
    );
    // reset this ryba isChild (so you can't rate it again)
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/pond/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        isChild: false,
      }),
    });

    setRated(false);
  };

  ///////
  const rateButtons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  ////////////////////////
  const setAnimationRef = (
    opisLength = 0,
    zaklLength = 0,
    organIndex,
    segmentIndex
  ) => {
    animationREF.current.opisLength = opisLength;
    animationREF.current.zaklLength = zaklLength;
    animationREF.current.organIndex = organIndex;
    animationREF.current.segmentIndex = segmentIndex;
  };

  const buttonClick = (
    e,
    organIndex,
    segmentIndex,
    buttonIndex,
    zakl,
    buttonForChoices
  ) => {
    let t = [...text];
    if (
      t[organIndex][segmentIndex] ===
      rybaObject.organs[organIndex].segments[segmentIndex].norm
    ) {
      t[organIndex][segmentIndex] = "";
    }
    if (
      e.target.checked === true &&
      rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex]
        .type === "constructor"
    ) {
      return;
    }
    let r = { ...rybaObject };
    r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex].used += 1;
    setRybaObject(r);
    const rybaLength = rybaObject.organs.length - 1;
    const btnObj =
      rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex];
    if (
      // t[rybaLength][0] &&
      !t[rybaLength][0].includes(zakl) &&
      zakl !== undefined &&
      // zakl !== "" &&
      !zakl.includes("...")
    ) {
      if (t[rybaLength][0] === rybaObject.organs[rybaLength].segments[0].norm) {
        t[rybaLength][0] = "";
      }
      if (!t[rybaLength][0].includes(zakl.trim())) {
        t[rybaLength][0] = t[rybaLength][0].trim() + " " + zakl.trim();
        addedWithButtons(zakl);
      }
      t[rybaLength][0] = t[rybaLength][0].trim();
    }

    checkForDots(e.target.value, buttonForChoices);

    if (buttonForChoices.content.includes("...")) return;
    if (zakl !== undefined && zakl !== "") {
      checkForDotsZakl(zakl, buttonForChoices);
    }

    t[organIndex][segmentIndex] = t[organIndex][segmentIndex].trim() + " ";
    t[organIndex][segmentIndex] += btnObj.newline
      ? "\n" + buttonForChoices.content.trim()
      : buttonForChoices.content.trim();
    t[organIndex][segmentIndex] = t[organIndex][segmentIndex].trim();
    addedWithButtons(buttonForChoices.content.trim());
    setAnimationRef(
      buttonForChoices.content !== undefined &&
        buttonForChoices.content.trim().length,
      zakl !== undefined && zakl.length,
      organIndex,
      segmentIndex
    );
    setText(t);
  };
  const buttonClickCalculator = (o, z, newLine) => {
    let t = [...text];
    const addedString = newLine ? "\n" + o.trim() : o.trim();
    if (
      t[organINDEX.current][segmentINDEX.current] ===
      rybaObject.organs[organINDEX.current].segments[segmentINDEX.current].norm
    ) {
      t[organINDEX.current][segmentINDEX.current] = "";
    }
    t[organINDEX.current][segmentINDEX.current] =
      t[organINDEX.current][segmentINDEX.current].trim() + " " + addedString;

    t[organINDEX.current][segmentINDEX.current].trim();
    if (
      !z &&
      t[t.length - 1][0] === rybaObject.organs[t.length - 1].segments[0].norm
    ) {
      t[organINDEX.current][segmentINDEX.current] = trimWhitespaceAndNewlines(
        t[organINDEX.current][segmentINDEX.current]
      );
      setText(t);
      return;
    }
    const addedStringZakl = newLine ? "\n" + z.trim() : z.trim();
    if (
      t[t.length - 1][0] === rybaObject.organs[t.length - 1].segments[0].norm
    ) {
      t[t.length - 1][0] = "";
    }
    t[t.length - 1][0] = t[t.length - 1][0].trim() + " " + addedStringZakl;
    t[organINDEX.current][segmentINDEX.current] = trimWhitespaceAndNewlines(
      t[organINDEX.current][segmentINDEX.current]
    );
    t[t.length - 1][0] = trimWhitespaceAndNewlines(t[t.length - 1][0]);
    setAnimationRef(
      o !== undefined && o.length,
      z !== undefined && z.length,
      organINDEX.current,
      segmentINDEX.current
    );
    setText(t);
  };

  const handlePasteConstructor = (
    organIndex,
    segmentIndex,
    content,
    zakl,
    dinamica
  ) => {
    if (dinamica) {
      return;
    }
    let t = [...text];
    if (
      t[organIndex][segmentIndex] ===
      rybaObject.organs[organIndex].segments[segmentIndex].norm
    ) {
      t[organIndex][segmentIndex] = "";
    }
    const rybaLength = rybaObject.organs.length - 1;

    if (
      // t[rybaLength][0] &&
      !t[rybaLength][0].includes(zakl) &&
      zakl !== undefined &&
      // zakl !== "" &&
      !zakl.includes("...")
    ) {
      if (t[rybaLength][0] === rybaObject.organs[rybaLength].segments[0].norm) {
        t[rybaLength][0] = "";
      }
      if (!t[rybaLength][0].includes(zakl.trim())) {
        t[rybaLength][0] = t[rybaLength][0].trim() + " " + zakl.trim();
      }
      t[rybaLength][0] = t[rybaLength][0].trim();
    }

    t[organIndex][segmentIndex] =
      t[organIndex][segmentIndex].trim() + " " + content.trim();

    setText(t);
    // let b = [...undoBuffer];

    // b.splice(0, 0, t);
    // setUndoBuffer(b);
    // undoCounter.current = 0;
    // console.log(b);
  };

  const tut9 = false;

  // BUTTON adding text to the textarea
  const checkForDots = function (bTextContent, button) {
    if (
      bTextContent === "" ||
      bTextContent === undefined ||
      bTextContent === null
    ) {
      return;
    }
    let y = bTextContent;
    console.log("check for dots Y: ", y);

    if (y.includes("...")) {
      setChoicesCount(0);
      setPromptButton(button);
      setInputPromptOpen(true);
      setScrollY(window.scrollY);
      let g = [];
      g[0] = y.slice(0, y.indexOf("..."));
      g[1] = "";
      g[2] = y.slice(y.indexOf("...") + 3);

      return setPromptText(g);
    }
  };
  const checkForDotsZakl = function (zaklText, button) {
    if (zaklText === "" || zaklText === undefined || zaklText === null) {
      return;
    }
    let y = zaklText;
    console.log("check for dots ZAKL: ", y);

    if (y.includes("...")) {
      setChoicesCount(0);
      setPromptButton(button);
      setInputPromptZaklOpen(true);
      setScrollY(window.scrollY);
      let g = [];
      g[0] = y.slice(0, y.indexOf("..."));
      g[1] = "";
      g[2] = y.slice(y.indexOf("...") + 3);

      return setPromptText(g);
    }
  };

  const rL = function (rO) {
    return rO.organs.length - 1;
  };

  // dinamika constructor function
  const dinamikaConstructorFunction = function (opisanie, zakl) {
    navigator.clipboard.writeText(opisanie);
    notificationTheBest("Текст для описания скопирован");
    let t = `${text}`;
    if (!t.includes(zakl) && zakl) {
      t += zakl;
    }
  };

  const rateColor = [
    "red",
    "red",
    "orange",
    "orange",
    "yellow",
    "yellow",
    "lightgreen",
    "lightgreen",
    "green",
    "green",
  ];

  const saveReport = () => {
    ////////////////////////////////
    const reports = JSON.parse(localStorage.getItem(`savedReports${user.id}`));
    let contentForExport = "";
    const x = [...text];
    const orgns = [...rybaObject.organs];
    orgns.map((organ, organIndex) => {
      let organTextString = "";
      organ.segments.map((segment, segmentIndex) => {
        let segmentText = x[organIndex][segmentIndex];
        if (segmentText) {
          segmentText = segmentText.trim();
        }
        if (segmentText.slice(0, 1) === "-") {
          segmentText = "\n" + segmentText;
        }
        if (segmentText.slice(-1) !== "." && segmentText !== "") {
          segmentText += ". ";
        }
        if (segmentText.slice(-1) === ".") {
          segmentText += " ";
        }
        organTextString += segmentText;
      });

      let organTitleString = organ.title;
      organTitleString = organTitleString.trim();
      if (organTitleString.slice(-1) !== ":") {
        organTitleString += ": ";
      }
      if (organTitleString.slice(-1) === ":") {
        organTitleString += " ";
      }

      if (organIndex === 0) {
        contentForExport += organTextString + "\n";
      }
      if (
        organIndex > 0 &&
        organIndex < rybaObject.organs.length - 1 &&
        organTextString.trim() !== ""
      ) {
        contentForExport += organTitleString + organTextString + "\n";
      }
      if (organIndex === rybaObject.organs.length - 1) {
        contentForExport += "\n" + organTitleString + organTextString;
      }
      // rybaString += organTitleString + organTextString + "\n";
    });
    let reportObject = {
      id: id,
      title: reportTitle,
      date: createDate(),
      time: createTime(),
      contentForExport,
      content: text,
      protocol: rybaObject.protocol,
      modality: rybaObject.modality,
      timeFull: Date.now(),
      dinamika: false,
    };
    if (!reports) {
      window.localStorage.setItem(
        `savedReports${user.id}`,
        JSON.stringify([reportObject])
      );
    }

    if (reports) {
      reports.unshift(reportObject);
      window.localStorage.setItem(
        `savedReports${user.id}`,
        JSON.stringify(reports)
      );
    }
    notificationTheBest("Описание успешно сохранено");
    setReportTitle("");

    setSaved(true);
  };
  function calculateMedian(arr) {
    if (arr.length === 0) {
      return;
    }

    // Step 1: Sort the array in ascending order
    const sortedArr = arr.slice().sort((a, b) => a - b);

    // Step 2: Find the middle index
    const midIndex = Math.floor(sortedArr.length / 2);

    // Step 3: Calculate the median
    if (sortedArr.length % 2 === 0) {
      // If the array has an even number of elements, return the average of the two middle values
      return (sortedArr[midIndex - 1] + sortedArr[midIndex]) / 2;
    } else {
      // If the array has an odd number of elements, return the middle value
      return sortedArr[midIndex];
    }
  }
  const autosaveReport = async () => {
    ////////////////////////////////
    const lastSaved = JSON.parse(localStorage.getItem(`lastSaved${user.id}`));

    const reports = JSON.parse(
      localStorage.getItem(`autosavedReports${user.id}`)
    );

    if (
      reports !== null &&
      reports.filter((a) => {
        return (
          a.id === id && JSON.stringify(a.content) === JSON.stringify(text)
        );
      }).length !== 0
    ) {
      return;
    }

    let contentForExport = "";
    const x = [...text];
    const orgns = [...rybaObject.organs];
    orgns.map((organ, organIndex) => {
      let organTextString = "";
      organ.segments.map((segment, segmentIndex) => {
        let segmentText = x[organIndex][segmentIndex];
        if (segmentText) {
          segmentText = segmentText.trim();
        }
        if (segmentText.slice(0, 1) === "-") {
          segmentText = "\n" + segmentText;
        }
        if (segmentText.slice(-1) !== "." && segmentText !== "") {
          segmentText += ". ";
        }
        if (segmentText.slice(-1) === ".") {
          segmentText += " ";
        }
        organTextString += segmentText;
      });

      let organTitleString = organ.title;
      organTitleString = organTitleString.trim();
      if (organTitleString.slice(-1) !== ":") {
        organTitleString += ": ";
      }
      if (organTitleString.slice(-1) === ":") {
        organTitleString += " ";
      }

      if (organIndex === 0) {
        contentForExport += organTextString + "\n";
      }
      if (
        organIndex > 0 &&
        organIndex < rybaObject.organs.length - 1 &&
        organTextString.trim() !== ""
      ) {
        contentForExport += organTitleString + organTextString + "\n";
      }
      if (organIndex === rybaObject.organs.length - 1) {
        contentForExport += "\n" + organTitleString + organTextString;
      }
      // rybaString += organTitleString + organTextString + "\n";
    });

    let reportObject = {
      id: id,
      title: "Автосохранение",
      date: createDate(),
      time: createTime(),
      content: text,
      contentForExport,
      zakl: text[rybaObject.organs.length - 1].join(" "),
      // zakl: x[rybaObject.organs.length - 1].join(" ").slice(0, 150) + "...",
      protocol: rybaObject.protocol,
      rybaTitle: rybaObject.title,
      modality: rybaObject.modality,
      timeFull: Date.now(),
      dinamika: false,
    };
    // universal patch request

    let s = { ...settings };
    const numCharacters = Number(contentForExport.length);
    const prevCharacters = Number(s.stats.numCharactersAllTime);

    // Check if an object with the given property exists in the array
    const existingObject = s.stats.protocolsUsed.find((obj) =>
      obj.hasOwnProperty(rybaObject.protocol)
    );

    if (existingObject) {
      // If the object exists, increment its value by 1
      if (lastSaved !== id) {
        existingObject[rybaObject.protocol] += 1;
      }
    } else {
      // If no such object exists, add a new object with the property set to 1
      if (lastSaved !== id) {
        s.stats.protocolsUsed.push({ [rybaObject.protocol]: 1 });
      }
    }
    if (lastSaved !== id) {
      s.stats.totalProtocols += 1;
      s.stats.numCharactersAllTime = numCharacters + prevCharacters;
    }
    s.stats.medianTypingSpeed = calculateMedian(s.stats.charactersPerMinute);
    // setSettings(s);

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/settings/updatestats`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          ...s.stats,
        }),
      }
    );
    const json = await response.json();

    if (response.ok) {
      setSettings(json);
      if (lastSaved === null || lastSaved !== id) {
        notificationTheBest(
          "Автосохранили ваше описание (можно открыть в сохранённых)"
        );
      }
      if (lastSaved === id) {
        notificationTheBest("Подправили автосохранение 🖊️");
      }
    }
    if (!reports) {
      return window.localStorage.setItem(
        `autosavedReports${user.id}`,
        JSON.stringify([reportObject])
      );
    }

    if (reports && !reports.includes(reportObject)) {
      if (lastSaved === id) {
        reports[0] = reportObject;
        window.localStorage.setItem(
          `autosavedReports${user.id}`,
          JSON.stringify(reports)
        );
      }
      console.log("дошёл досюда");
      if (lastSaved === null || lastSaved !== id) {
        reports.unshift(reportObject);
        window.localStorage.setItem(
          `autosavedReports${user.id}`,
          JSON.stringify(reports)
        );
        window.localStorage.setItem(`lastSaved${user.id}`, JSON.stringify(id));
        const response2 = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/pond/numberTimesUsed/${id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",

              Authorization: `Bearer ${user.token}`,
            },
            body: JSON.stringify({
              rybaObject,
            }),
          }
        );
        const json2 = await response2.json();

        if (response2.ok) {
          console.log(json2);
          // setRybaObject(json2);
        }
      }
    }

    setReportTitle("");
    /// patch parent ryba

    setHaveBeenSaved(true);
  };

  const exportTXT = () => {
    ////////// CREATE REPORT ///////
    const x = [...text];
    const orgns = [...rybaObject.organs];
    let rybaString = "";
    rybaString += "Модальность: " + rybaObject.modality + "\n";
    rybaString += "Исследование: " + rybaObject.protocol + "\n";

    rybaString += "Дата: " + createDate() + "\n\n";

    orgns.map((organ, organIndex) => {
      let organTextString = "";
      organ.segments.map((segment, segmentIndex) => {
        let segmentText = x[organIndex][segmentIndex];
        if (segmentText) {
          segmentText = segmentText.trim();
        }

        if (segmentText.slice(-1) !== "." && segmentText !== "") {
          segmentText += ". ";
        }
        if (segmentText.slice(-1) === ".") {
          segmentText += " ";
        }
        organTextString += segmentText;
      });

      let organTitleString = organ.title;
      organTitleString = organTitleString.trim();
      if (organTitleString.slice(-1) !== ":") {
        organTitleString += ": ";
      }
      if (organTitleString.slice(-1) === ":") {
        organTitleString += " ";
      }

      if (organIndex === 0) {
        rybaString += organTextString + "\n";
      }
      if (
        organIndex > 0 &&
        organIndex < rybaObject.organs.length - 1 &&
        organTextString.trim() !== ""
      ) {
        rybaString += organTitleString + organTextString + "\n";
      }
      if (organIndex === rybaObject.organs.length - 1) {
        rybaString += "\n" + organTitleString + organTextString;
      }
      // rybaString += organTitleString + organTextString + "\n";
    });

    ////////////////////////////////
    // const fileData = JSON.stringify(rybaString);
    const file = new Blob([rybaString], { type: "text/plain;charset=utf-8" });
    saveAs(file, `${rybaObject.protocol} ${createDate()}.txt`);
  };

  const exportWORD = async () => {
    ////////// CREATE REPORT ///////
    const x = [...text];
    const orgns = [...rybaObject.organs];
    let rybaString = "";

    const currentdate = new Date();

    orgns.map((organ, organIndex) => {
      let organTextString = "";
      organ.segments.map((segment, segmentIndex) => {
        let segmentText = x[organIndex][segmentIndex];
        if (segmentText) {
          segmentText = segmentText.trim();
        }

        if (segmentText.slice(-1) !== "." && segmentText !== "") {
          segmentText += ". ";
        }
        if (segmentText.slice(-1) === ".") {
          segmentText += " ";
        }
        organTextString += segmentText;
      });

      let organTitleString = organ.title;
      organTitleString = organTitleString.trim();
      if (organTitleString.slice(-1) !== ":") {
        organTitleString += ": ";
      }
      if (organTitleString.slice(-1) === ":") {
        organTitleString += " ";
      }

      if (organIndex === 0) {
        rybaString += organTextString + "\n";
      }
      if (
        organIndex > 0 &&
        organIndex < rybaObject.organs.length - 1 &&
        organTextString.trim() !== ""
      ) {
        rybaString += organTitleString + organTextString + "\n";
      }
      if (organIndex === rybaObject.organs.length - 1) {
        rybaString += "\n" + organTitleString + organTextString;
      }
      // rybaString += organTitleString + organTextString + "\n";
    });

    /////// CREATE DOCX //////

    const doc = new Document({
      styles: {
        default: {
          document: {
            run: {
              size: "12pt",
              font: "Arial",
            },
          },
        },
      },
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: settings.rybaHeaders[0]
                .split("\n")
                .map((line) => new TextRun({ break: 1, text: line })),
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: "ФИО: ",
                }),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Дата рождения: ",
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Пол: ",
                }),
              ],
            }),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: rybaObject.protocol,
                  bold: true,
                  size: "16pt",
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({}),
            new Paragraph({}),

            new Paragraph({}),
            new Paragraph({
              children: rybaString
                .split("\n")
                .map(
                  (line) => new TextRun({ break: 1, text: line, size: "11pt" })
                ),
            }),

            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Врач-рентгенолог",
                  alignment: AlignmentType.LEFT,
                }),
                new TextRun({}),
              ],
            }),
            new Paragraph({
              text: `${settings.lastname} ${settings.firstname.slice(
                0,
                1
              )}. ${settings.middlename.slice(0, 1)}.`,
              alignment: AlignmentType.RIGHT,
            }),
            new Paragraph({
              text: `Дата: ${createDate()}`,
            }),
          ],
        },
      ],
    });
    //////////////////////////
    Packer.toBlob(doc).then((blob) => {
      // saveAs from FileSaver will download the file
      saveAs(blob, `${rybaObject.protocol} ${createDate()}.docx`);
    });

    ////////////////////////////////
    // const file = new Blob([doc], { type: "text/plain;charset=utf-8" });
    // saveAs(file, `${rybaObject.protocol} ${date}.docx`);
  };

  const updateLocalStorage = useDebouncedCallback((text) => {
    window.localStorage.setItem(`${id}`, JSON.stringify(text));
  }, 400);

  useEffect(() => {
    if (saved) {
      setSaved(false);
    }
    if (waitedForLoading === true) {
      updateLocalStorage(text);
    }
  }, [text]);

  // universal patch request
  const updateSettings = async function (settings) {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/settings`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({ ...settings }),
      }
    );
    const json = await response.json();

    if (response.ok) {
      console.log("patched settings: ", json);
    }
  };

  // BUTTON go to default ALL
  const goToDefaultAll = (e) => {
    window.localStorage.removeItem(`lastSaved${user.id}`);
    notificationTheBest("Шаблон сброшен до нормы");
    setSaved(false);
    const rybaLength = rybaObject.organs.length - 1;
    let r = { ...rybaObject };
    let t = [];
    let c = [];
    let pupu = [];
    let prevReset = [...reset];
    r.organs.map((o, organIndex) => {
      t.push([]);
      c.push([]);
      pupu.push([]);

      o.segments.map((s, index) => {
        t[organIndex].push(s.norm);
        pupu[organIndex].push(false);
        c[organIndex][index] = !prevReset[organIndex][index];
      });
    });

    setText(t);
    setReset(c);

    let h = [];
    r.organs.map((o, organIndex) => {
      h.push([]);
      o.segments.map((s, segmentIndex) => {
        h[organIndex].push([]);
        s.buttons.map((b, buttonIndex) => {
          h[organIndex][segmentIndex].push(false);
        });
      });
    });
    t[rybaLength][0] = r.organs[rybaLength].segments[0].norm;
    setReset(h);
  };

  const showOverlayImages = (boo, imagesArray) => {
    if (boo === true) {
      imagesREF.current = imagesArray;
      setOverlayImages(true);
    }
    if (boo === false) {
      setOverlayImages(false);
    }
  };
  const saveRybaChangesCalculatorRedactor = async (req, res) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/pond/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          ...rybaObject,
        }),
      }
    );
    const json = await response.json();
    console.log(response);
    if (response.ok) {
      setRybaObject(json);
      notificationTheBest("Изменения успешно сохранены");
    }
    if (!response.ok) {
      notificationTheBest("Что-то пошло не так");
      console.log(json);
    }
  };
  const addButtonToRyba4 = function (title, content, zakl, alreadyMadeButton) {
    if (alreadyMadeButton) {
      const r = { ...rybaObject };
      r.organs[organINDEX.current].segments[segmentINDEX.current].buttons.push(
        alreadyMadeButton
      );

      console.log(r);
      setRybaObject(r);
    }
    if (!alreadyMadeButton) {
      const newButton = {
        title: title,
        content: content,
        zakl: zakl,
        type: "checkbox",
        newline: true,
      };

      const r = { ...rybaObject };
      r.organs[organINDEX.current].segments[segmentINDEX.current].buttons.push(
        newButton
      );

      console.log(r);
      setRybaObject(r);
    }

    const saveRybaChanges = async (req, res) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            ...rybaObject,
          }),
        }
      );
      const json = await response.json();
      console.log(response);
      if (response.ok) {
        setAddButtonOpen(false);
        if (!settings.achievements.firstButton) {
          let s = { ...settings };
          s.achievements.firstButton = true;
          setSettings(s);
          updateSettings(s);
          window.achievement(
            "ПЕРВЫЕ ШАГИ",
            "Вы успешно создали первую в своей жизни кнопку, так держать",
            "https://swift.timeweb.cloud/v1/cd5c3c6d-7735d894-d309-4ef5-87a5-a3d5741fd815/achievements/firstSteps.gif"
          );
        }

        notificationTheBest("Изменения успешно сохранены");
        console.log(json);
      }
      if (!response.ok) {
        notificationTheBest("Что-то пошло не так");
        console.log(json);
      }
    };
    saveRybaChanges();
  };
  const saveRybaChanges = async (req, res, r, pB, cB, toAllButtons) => {
    if (toAllButtons === true) {
      const applyToAllButtons = (previousButton, currentButton) => {
        const applyToAllButtons = async (req, res) => {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/pond/applytoallbuttonsryba`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",

                Authorization: `Bearer ${user.token}`,
              },
              body: JSON.stringify({
                previousButton,
                currentButton,
                r,
              }),
            }
          );
          const json = await response.json();
          console.log(response);
          if (response.ok) {
            notificationTheBest("Долетело");
            setRybaObject(json);
            console.log(json);
          }
          if (!response.ok) {
            console.log(json);
          }
        };
        applyToAllButtons();
      };
      applyToAllButtons(pB, cB);
    } else {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            ...r,
          }),
        }
      );
      const json = await response.json();
      console.log(response);
      if (response.ok) {
        setRybaObject(r);
        notificationTheBest("Изменения успешно сохранены");
        console.log(json);
      }
      if (!response.ok) {
        notificationTheBest(
          "Не удалось сохранить изменения, что-то пошло не так"
        );
        console.log(json);
      }
    }
  };
  const deleteButton = (req, res) => {
    let r = { ...rybaObject };
    if (editedButtonTypeREF.current === "calculator") {
      r.organs[organINDEX.current].segments[
        segmentINDEX.current
      ].buttons.splice(buttonINDEX.current, 1);
    }
    if (editedButtonTypeREF.current === "checkbox") {
      r.organs[organINDEX.current].segments[
        segmentINDEX.current
      ].buttons.splice(buttonINDEX.current, 1);
    }
    if (editedButtonTypeREF.current === "radio") {
      delete r.organs[organINDEX.current].segments[
        segmentINDEX.current
      ].buttons[buttonINDEX.current].radios.splice(radioINDEX.current, 1);
    }
    if (editedButtonTypeREF.current === "select") {
      delete r.organs[organINDEX.current].segments[
        segmentINDEX.current
      ].buttons[buttonINDEX.current].options.splice(radioINDEX.current, 1);
    }
    saveRybaChanges(undefined, undefined, r);
  };
  const duplicateButton = (
    organINDEX,
    segmentINDEX,
    buttonINDEX,
    radioINDEX,
    rybaObject,
    setRybaObject,
    setButtonContextMenuOpen,
    buttonType
  ) => {
    if (buttonType === "calculator") {
      const r = { ...rybaObject };
      const copiedButton = JSON.parse(
        JSON.stringify(
          r.organs[organINDEX.current].segments[segmentINDEX.current].buttons[
            buttonINDEX.current
          ]
        )
      );
      copiedButton.used = 0;
      r.organs[organINDEX.current].segments[
        segmentINDEX.current
      ].buttons.splice(buttonINDEX.current, 0, copiedButton);
      saveRybaChanges(undefined, undefined, r);
    }
    if (buttonType === "checkbox") {
      const r = { ...rybaObject };
      const copiedButton = JSON.parse(
        JSON.stringify(
          r.organs[organINDEX.current].segments[segmentINDEX.current].buttons[
            buttonINDEX.current
          ]
        )
      );
      copiedButton.used = 0;
      r.organs[organINDEX.current].segments[
        segmentINDEX.current
      ].buttons.splice(buttonINDEX.current, 0, copiedButton);
      saveRybaChanges(undefined, undefined, r);
    }
    if (buttonType === "radio") {
      const r = { ...rybaObject };
      const copiedButton = JSON.parse(
        JSON.stringify(
          r.organs[organINDEX.current].segments[segmentINDEX.current].buttons[
            buttonINDEX.current
          ].radios[radioINDEX.current]
        )
      );
      copiedButton.used = 0;

      r.organs[organINDEX.current].segments[segmentINDEX.current].buttons[
        buttonINDEX.current
      ].radios.splice(radioINDEX.current, 0, copiedButton);

      saveRybaChanges(undefined, undefined, r);
    }
    if (buttonType === "select") {
      const r = { ...rybaObject };
      const copiedButton = JSON.parse(
        JSON.stringify(
          r.organs[organINDEX.current].segments[segmentINDEX.current].buttons[
            buttonINDEX.current
          ].options[radioINDEX.current]
        )
      );
      copiedButton.used = 0;
      r.organs[organINDEX.current].segments[segmentINDEX.current].buttons[
        buttonINDEX.current
      ].options.splice(radioINDEX.current, 0, copiedButton);
      saveRybaChanges(undefined, undefined, r);
    }
    setButtonContextMenuOpen(false);
  };

  const applyToAllButtons = (previousButton, currentButton) => {
    const applyToAllButtons = async (req, res) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/applytoallbuttons`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            previousButton,
            currentButton,
          }),
        }
      );
      const json = await response.json();
      console.log(response);
      if (response.ok) {
        notificationTheBest("Долетело");
        console.log(json);
      }
      if (!response.ok) {
        console.log(json);
      }
    };
    applyToAllButtons();
  };

  if (error) {
    return (
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: "100vw",
          textAlign: "center",
          marginTop: "200px",
        }}
      >
        <span
          style={{
            fontSize: "28px",
            color: "pink",
          }}
        >
          {error}
        </span>

        <div>
          <img
            src={SadFish}
            alt="Sad Fish"
            style={{
              width: "450px",
              margin: "30px",
              borderRadius: "10px",
              boxShadow: "0px 0px 15px 1px darkgrey",
            }}
          />
        </div>
      </div>
    );
  }
  if (!error) {
    return (
      rybaLoaded &&
      settings && (
        <div className="RYBA4rybaContainer">
          {calculatorEditorOpen && (
            <CalculatorRedactor
              {...{
                rybaObject,
                organIndex: organINDEX.current,
                segmentIndex: segmentINDEX.current,
                buttonIndex: buttonINDEX.current,
                setRybaObject,
                button:
                  rybaObject.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current],
                setCalculatorEditorOpen,
                saveRybaChanges: saveRybaChangesCalculatorRedactor,
                setOriginalRyba: console.log(),
                id,
                isInRyba4: true,
              }}
            />
          )}
          {overlayImages && (
            <OverlayImages
              images={imagesREF.current}
              setOverlayImages={setOverlayImages}
            />
          )}
          {nameReportOpen && (
            <div className="popupContainer">
              <div
                className="popupContent"
                style={{
                  position: "fixed",
                  top: `250px`,
                }}
              >
                <p>Название сохраняемого документа</p>
                <TextareaAutosize
                  autoFocus
                  className="inputPromptTextarea"
                  value={reportTitle}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      saveReport();
                      setNameReportOpen(false);
                    }
                    if (e.key === "Escape") {
                      setNameReportOpen(false);
                      setReportTitle("");
                    }
                  }}
                  onChange={(e) => {
                    setReportTitle(e.target.value);
                    setReportTitle2(e.target.value);
                  }}
                ></TextareaAutosize>
                {/* <p>Дополнительная информация (что угодно; необязательно)</p>
            <TextareaAutosize
              autoFocus
              className="inputPromptTextarea"
              value={info}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  saveReport();
                  setNameReportOpen(false);
                }
                if (e.key === "Escape") {
                  setNameReportOpen(false);
                  setReportTitle("");
                }
              }}
              onChange={(e) => {
                setInfo(e.target.value);
              }}
            ></TextareaAutosize> */}
                <button
                  className="saveChanges"
                  onClick={() => {
                    saveReport();
                    setNameReportOpen(false);
                    setReportTitle("");
                  }}
                >
                  Сохранить (Enter)
                </button>
                <button
                  className="closeTextEditWindow"
                  onClick={() => {
                    setNameReportOpen(false);
                    setReportTitle("");
                  }}
                >
                  Отмена (Esc)
                </button>
              </div>
            </div>
          )}
          {editButtonOpen && (
            <ButtonEditWindow
              {...{
                rybaObject,
                setEditedButton,
                editedButton,
                setRybaObject,
                radioINDEX,
                buttonINDEX,
                organINDEX,
                segmentINDEX,
                id,
                editedButtonTypeREF,
                editedButtonREF,
                setEditButtonOpen,
                scrollY,
                saveRybaChanges,
                applyToAllButtons,
                originalRybaJSON,
                editedButtonJSON,
              }}
            />
          )}
          {constructorOpen && (
            <ConstructorRouter
              {...{
                constructorREF,
                setConstructorOpen,
                formState,
                setFormState,
                text,
                setText,
                zaklState,
                setZaklState,
                rybaObject,
                dinamika,
                dinamikaConstructorFunction,
                tut9,
                handlePasteConstructor,
                addedWithButtons,
              }}
            />
          )}
          {sureDeleteButton && (
            <AreYouSureGeneric
              {...{
                setAreYouSureOpen: setSureDeleteButton,
                shureFunction: deleteButton,
                text: "Вы уверены, что хотите удалить кнопку?",
              }}
            />
          )}
          {CALCULATORopen && (
            <CALCULATOR
              {...{
                rybaObject,
                organIndex: organINDEX.current,
                segmentIndex: segmentINDEX.current,
                buttonIndex: buttonINDEX.current,
                setCALCULATORopen,
                scrollY,
                buttonClickCalculator,
              }}
            />
          )}
          {inputPromptOpen && (
            <InputPrompt
              {...{
                setPromptText,
                promptText,
                setInputPromptOpen,
                choicesCount,
                promptButton,
                setChoicesCount,
                organINDEX,
                segmentINDEX,
                buttonINDEX,
                text,
                setText,
                rybaObject,
                checkForDotsZakl,
                addedWithButtons,
                scrollY,
                animationREF,
                setAnimationRef,
              }}
            />
          )}
          {addButtonOpen && (
            <AddButton
              {...{
                organINDEX,
                segmentINDEX,
                rybaObject,
                addButtonToRyba4,
                setAddButtonOpen,
                setFindButtonOpen,
                scrollY,
              }}
            />
          )}
          {findButtonOpen && (
            <FindButton
              {...{
                organINDEX,
                segmentINDEX,
                rybaObject,
                addButtonToRyba4,
                setFindButtonOpen,
                scrollY,
              }}
            />
          )}
          {inputPromptZaklOpen && (
            <InputPromptZakl
              {...{
                setPromptText,
                promptText,
                setInputPromptOpen,
                choicesCount,
                promptButton,
                setChoicesCount,
                organINDEX,
                segmentINDEX,
                buttonINDEX,
                text,
                setText,
                rybaObject,
                setInputPromptZaklOpen,
                addedWithButtons,
                scrollY,
              }}
            />
          )}
          <div
            className="RYBA4ryba"
            key={2342616133453}
            style={{
              zoom: zoom,
            }}
          >
            {/* <div>
          <TextareaAutosize
            value={buffer2}
            onChange={(e) => {
              setBuffer2(e.target.value);
            }}
          ></TextareaAutosize>

          <button
            onClick={() => {
              undo2();
              console.log(buffer);
            }}
          >
            Undo
          </button>
          <button
            onClick={() => {
              redo2();
            }}
          >
            redo
          </button>
        </div> */}
            <div className="anchorForNotes">
              {settings && settings.showNotes && (
                <div
                  className="notes"
                  style={{
                    top: `${top}px`,
                    left: `${left}px`,
                    cursor: `${pressed ? "grab" : "pointer"}`,
                  }}
                >
                  <div
                    className="notesTitle"
                    onMouseDown={(e) => {
                      setPressed(true);
                    }}
                    onMouseEnter={() => {
                      setPressed(false);
                    }}
                    onMouseUp={(e) => {
                      setPressed(false);
                    }}
                    onMouseMove={(e) => {
                      if (pressed) {
                        setLeft((l) => l + e.movementX);
                        setTop((t) => t + e.movementY);
                      }
                    }}
                  >
                    Заметки
                  </div>
                  <div className="notesText">
                    <TextareaAutosize
                      value={notes}
                      onChange={(e) => {
                        setNotes(e.target.value);

                        window.localStorage.setItem(
                          `notes${id}`,
                          JSON.stringify(e.target.value)
                        );
                      }}
                    ></TextareaAutosize>
                  </div>
                </div>
              )}
            </div>

            {!rybaLoaded && <div className="loader"></div>}
            {rybaLoaded && settings && (
              <>
                {notificationWindowOpen && (
                  <Notification notificationText={notificationText.current} />
                )}
                {rybaObject.organs.length > 2 && (
                  <div className="RYBA4topButtons" key={11212313}>
                    <div className="RYBA4topButtonsBorder">
                      <div
                        className="RYBA4topButton copyAll"
                        key={141241432423}
                        onClick={clickHandlerCopyToClipboardALL}
                        data-tooltip-id="skopirovatVse"
                        data-tooltip-delay-show="300"
                      >
                        <CopyAll height="96" width="96" />
                        <Tooltip
                          id="skopirovatVse"
                          content="Скопировать описание и заключение"
                          place="bottom"
                          style={{ background: "black" }}
                        />
                      </div>

                      <div className="RYBA4topButtonsSmallAndTxtFormat">
                        <span
                          style={{
                            fontSize: "12px",
                            color: "grey",
                            marginLeft: "70px",
                          }}
                        >
                          Работа с шаблоном
                        </span>
                        <div className="RYBA4ropButtonsSmall">
                          <div
                            className="RYBA4topButton"
                            key={787273456}
                            onClick={clickHandlerCopyToClipboardProtocol}
                            data-tooltip-id="CopyProtocol"
                            data-tooltip-delay-show="300"
                          >
                            <CopyProtocol />
                            <Tooltip
                              id="CopyProtocol"
                              content="Скопировать только описание"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                          </div>
                          <div
                            className="RYBA4topButton"
                            key={2527715}
                            onClick={clickHandlerCopyToClipboardImpression}
                            data-tooltip-id="CopyImp"
                            data-tooltip-delay-show="300"
                          >
                            <CopyImpression />
                            <Tooltip
                              id="CopyImp"
                              content="Скопировать только заключение"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                          </div>
                          <div
                            className="RYBA4topButton"
                            key={2342617}
                            onClick={goToDefaultAll}
                            data-tooltip-id="goToNorm"
                            data-tooltip-delay-show="300"
                          >
                            <ResetToNorm height="48" width="48" />
                            <Tooltip
                              id="goToNorm"
                              content="Сбросить всё до нормы"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                          </div>

                          {/* <div
                        className="RYBA4topButton"
                        key={(keyCounter += 1)}
                        onClick={clickHandlerDeleteTextALL}
                        data-tooltip-id="clearAll"
                        data-tooltip-delay-show="300"
                      >
                        <DeleteAllText width="48" height="48" />
                        <Tooltip
                          id="clearAll"
                          content="Очистить все поля"
                          place="bottom"
                          style={{ background: "black" }}
                        />
                      </div> */}
                          <Link
                            to={`/editRyba2/${rybaObject._id}`}
                            style={{
                              border: "none",
                              padding: "0px",
                              margin: "0px",
                            }}
                            // target="_blank"
                          >
                            <div
                              className="RYBA4topButton"
                              data-tooltip-id="editRYba"
                              data-tooltip-delay-show="300"
                            >
                              <EditRyba />
                              <Tooltip
                                id="editRYba"
                                content="Редактировать шаблон"
                                place="bottom"
                                style={{ background: "black" }}
                              />
                            </div>
                          </Link>
                          <div
                            className="RYBA4topButton saveButtonDiv"
                            data-tooltip-id="Save"
                            data-tooltip-delay-show="300"
                            onClick={() => {
                              setNameReportOpen(true);
                            }}
                          >
                            <SaveButton />
                            <Tooltip
                              id="Save"
                              content="Сохранить заключение"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                            {saved && (
                              <div className="saveButtonDivOK">
                                <OK />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="dinamikaAndPrimary">
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            {/* <div
                          className="RYBA4topButton"
                          onClick={(e) => {
                            undo();
                          }}
                          data-tooltip-id="undo"
                          data-tooltip-delay-show="300"
                        >
                          <DOundo />

                          <Tooltip
                            id="undo"
                            content="Отменить действие"
                            place="bottom"
                            style={{ background: "black" }}
                          />
                        </div>
                        <div
                          className="RYBA4topButton"
                          onClick={(e) => {
                            redo();
                          }}
                          data-tooltip-id="redo"
                          data-tooltip-delay-show="300"
                        >
                          <DOredo />
                          <Tooltip
                            id="redo"
                            content="Повторить действие"
                            place="bottom"
                            style={{ background: "black" }}
                          />
                        </div> */}

                            <div
                              className="RYBA4topButton"
                              onClick={(e) => {
                                window.localStorage.setItem(
                                  `zoom`,
                                  JSON.stringify(zoom + 0.05)
                                );
                                setZoom((z) => z + 0.05);
                              }}
                              data-tooltip-id="ZoomIn"
                              data-tooltip-delay-show="300"
                            >
                              <ZoomIn />
                              <Tooltip
                                id="ZoomIn"
                                content="Увеличить мастштаб (приблизить)"
                                place="bottom"
                                style={{ background: "black" }}
                              />
                            </div>
                            <div
                              className="RYBA4topButton"
                              onClick={(e) => {
                                window.localStorage.setItem(
                                  `zoom`,
                                  JSON.stringify(zoom - 0.05)
                                );
                                setZoom((z) => z - 0.05);
                              }}
                              data-tooltip-id="ZoomOut"
                              data-tooltip-delay-show="300"
                            >
                              <ZoomOut />
                              <Tooltip
                                id="ZoomOut"
                                content="Уменьшить масштаб (отдалить)"
                                place="bottom"
                                style={{ background: "black" }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <button className="dinamika dinActive">
                              Конструктор
                            </button>{" "}
                            <Link
                              to={`/followup/${rybaObject._id}`}
                              style={{
                                border: "none",
                                padding: "0px",
                                margin: "0px",
                              }}
                              // target="_blank"
                            >
                              <button className="dinamika dinInactive">
                                Полотно
                              </button>
                            </Link>
                            <div
                              className="RYBA4topButton"
                              data-tooltip-id="DOC"
                              data-tooltip-delay-show="300"
                              onClick={() => {
                                exportWORD();
                              }}
                            >
                              <DOC />
                              <Tooltip
                                id="DOC"
                                content="Экспорт в WORD"
                                place="bottom"
                                style={{ background: "black" }}
                              />
                            </div>
                            <div
                              className="RYBA4topButton"
                              data-tooltip-id="TXT"
                              data-tooltip-delay-show="300"
                              onClick={() => {
                                exportTXT();
                              }}
                            >
                              <TXT />
                              <Tooltip
                                id="TXT"
                                content="Экспорт в TXT"
                                place="bottom"
                                style={{ background: "black" }}
                              />
                            </div>
                            {/* <div
                          className="RYBA4topButton"
                          data-tooltip-id="PDF"
                          data-tooltip-delay-show="300"
                          onClick={() => {
                            // print();
                            // window.open("/exportPDF", "_blank");
                            savePDF();
                          }}
                        >
                          <PDF />
                          <Tooltip
                            id="PDF"
                            content="Экспорт в PDF"
                            place="bottom"
                            style={{ background: "black" }}
                          />
                        </div> */}
                          </div>
                        </div>
                      </div>

                      <div className="RYBA4topButtonsBorder2">
                        {/* <p
                      style={{
                        textAlign: "center",
                        color: "grey",
                        fontSize: "0.8rem",
                        paddingTop: "10px",
                        margin: "0px",
                        marginTop: "5px",
                        marginBottom: "9px",
                      }}
                    >
                      Модальность: {rybaObject && rybaObject.modality}
                    </p> */}

                        <p
                          style={{
                            textAlign: "center",
                            color: "grey",
                            fontSize: "0.8rem",
                            paddingTop: "10px",
                            margin: "14px",
                            marginBottom: "13px",
                          }}
                        >
                          {/* Протокол:  */}
                          {rybaObject && rybaObject.protocol}
                        </p>
                        <h2 className="RYBA4title" key={234235162}>
                          {rybaObject.title}
                        </h2>
                      </div>

                      {saved && (
                        <div className="copiedText">
                          <p
                            style={{
                              padding: "0px",
                              margin: "0px",
                              marginBottom: "5px",
                              fontWeight: "500",
                            }}
                          >
                            Описание сохранено под названием "{reportTitle2}"
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/* <p>Скорость печати: {typeSpeed}</p> */}
                {rybaObject.isChild && rated && (
                  <div className="RYBA4rateRybaDiv">
                    <p>
                      Вы импортировали этот шаблон. Пожалуйста, оцените его от 1
                      до 10, это поможет другим коллегам в выборе!
                    </p>
                    {rateButtons.map((n, index) => (
                      <button
                        parentryba={rybaObject.parentRybaSharedID}
                        style={{
                          background: rateColor[index],
                        }}
                        value={n}
                        onClick={(e) => {
                          rateSharedRyba(
                            undefined,
                            undefined,
                            e.target.attributes[0].value,
                            e.target.value
                          );
                        }}
                        className="RYBA4rateRyba"
                      >
                        {n}
                      </button>
                    ))}
                  </div>
                )}
                {/* <SnakeGame /> */}

                {/* <FloppyRyba /> */}
                {sortedZaklOpen && (
                  <ZaklDrag2
                    items={sortedZaklText}
                    top={scrollY}
                    close={() => setSortedZaklOpen(false)}
                    setZakl={(modifiedZakl) => {
                      let t = [...text];
                      t[rybaObject.organs.length - 1][0] = modifiedZakl;
                      setText(t);
                      setSortedZaklOpen(false);
                    }}
                  />
                )}
                {beenModified && (
                  <div className="RYBA4rateRybaDiv">
                    <p>
                      Структура шаблона и локальной резервной копии не совпадает
                      (скорее всего, Вы отредактировали шаблон на другом
                      устройстве, добавили или удалили орган или раздел),
                      текстовые блоки наверняка съехали; можно сбросить до
                      нормы, и всё восстановится
                    </p>

                    <button
                      onClick={(e) => {
                        setBeenModified(false);
                      }}
                    >
                      Ясно
                    </button>
                  </div>
                )}
                {/* <div className="svgAndImgContainer">
                  <img
                    src={Brain}
                    alt="Brain"
                    style={{
                      width: "320px",
                      height: "320px",
                    }}
                  ></img>
                  {<Shape2 {...{ height: 320, width: 320, doSmth }} />}
                </div> */}
                {rybaObject.organs.length === 3 && (
                  <>
                    <p style={{ textAlign: "center", paddingTop: "20px" }}>
                      Это пустой шаблон. Нажмите кнопку ниже, чтобы начать
                      добавлять в неё содержимое.
                    </p>

                    <Link
                      to={`/editRyba2/${rybaObject._id}`}
                      style={{
                        border: "none",
                        padding: "0px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <div
                        className="RYBA4topButton"
                        data-tooltip-id="editRYba2"
                        data-tooltip-delay-show="300"
                        style={{
                          border: "none",
                          padding: "10px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <EditRyba />
                        <Tooltip
                          id="editRYba2"
                          content="Редактировать шаблон"
                          place="bottom"
                          style={{ background: "black", zIndex: "10" }}
                        />
                      </div>
                    </Link>
                  </>
                )}
                <div className="RYBA4segmentsContainer">
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <div className="imagesContainer" style={{}}>
                      {Object.hasOwn(rybaObject, "shpora") &&
                        rybaObject.shpora.map((shpora, shporaIndex) => (
                          <ImageComponent {...{ shpora, shporaIndex }} />
                        ))}
                    </div>
                  </div>

                  {rybaObject.organs.map((organ, organIndex) => (
                    <div
                      className={`organ ${organIndex === 0 && "topLeft"} ${
                        organIndex === 1 && "bottomLeft marginBottom"
                      } ${organIndex === 2 && "topLeft"} ${
                        organIndex === rL(rybaObject) - 1 &&
                        "bottomLeft marginBottom"
                      } ${
                        organIndex === rL(rybaObject) && "bottomLeft topLeft"
                      }`}
                    >
                      {" "}
                      <div
                        className={`organTitle ${
                          organIndex === 0 && "topLeft"
                        } ${organIndex === 1 && "bottomLeft"} ${
                          organIndex === 2 && "topLeft"
                        } ${
                          organIndex === rL(rybaObject) - 1 && "bottomLeft"
                        } ${
                          organIndex === rL(rybaObject) && "bottomLeft topLeft"
                        }`}
                        style={{
                          color: organFocus === organIndex && "white",
                          backgroundColor:
                            organFocus === organIndex && "rgb(16, 16, 16)",
                        }}
                        onClick={() => {
                          setOrganFocus(organIndex);
                        }}
                      >
                        {organ.title}
                      </div>
                      <div className="organSegments">
                        {organ.segments &&
                          organ.segments.map((segment, segmentIndex) => (
                            <div
                              className="sgmnt"
                              key={segmentIndex}
                              index={segmentIndex}
                            >
                              <Textarea
                                {...{
                                  organFocus,
                                  setOrganFocus,
                                  organIndex,
                                  segmentIndex,
                                  setTypeSpeed,
                                  settings,
                                  setSettings,
                                  animationREF,
                                  setAnimationRef,
                                }}
                              />

                              {organFocus === organIndex && (
                                <div
                                  className="segmentButtons"
                                  style={{
                                    zIndex:
                                      organFocus === organIndex &&
                                      segmentIndex === 0 &&
                                      "5",
                                    opacity:
                                      organFocus === organIndex &&
                                      segmentIndex === 0 &&
                                      "1",
                                    padding:
                                      organ.segments.length === 1 && "0px",
                                  }}
                                >
                                  <div
                                    className="closeButtonsButton"
                                    onClick={() => {
                                      setOrganFocus(undefined);
                                    }}
                                  >
                                    ❌
                                  </div>
                                  <p className="buttonsOrganTitle">
                                    {organ.title}
                                  </p>
                                  {organ.segments &&
                                    organ.segments.map(
                                      (segment, segmentIndex) => (
                                        <div
                                          className="segmentOne"
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          <span
                                            className="pointer addButton"
                                            style={{
                                              marginLeft: "328px",
                                            }}
                                            onClick={(e) => {
                                              organINDEX.current = organIndex;
                                              segmentINDEX.current =
                                                segmentIndex;
                                              setScrollY(window.scrollY);
                                              setFindButtonOpen(true);
                                            }}
                                          >
                                            <Icon
                                              title="buttonSearch"
                                              fill="#323232"
                                            />
                                          </span>{" "}
                                          <span
                                            className="pointer addButton"
                                            style={{
                                              marginLeft: "358px",
                                            }}
                                            onClick={(e) => {
                                              organINDEX.current = organIndex;
                                              segmentINDEX.current =
                                                segmentIndex;
                                              setAddButtonOpen(true);
                                              setScrollY(window.scrollY);
                                            }}
                                          >
                                            <Icon
                                              title="buttonAdd"
                                              fill="#323232"
                                            />
                                          </span>
                                          <div
                                            className="fieldsetLegend"
                                            style={{
                                              color:
                                                text[organIndex][
                                                  segmentIndex
                                                ] ===
                                                rybaObject.organs[organIndex]
                                                  .segments[segmentIndex].norm
                                                  ? "darkgrey"
                                                  : settings.changedSegmentColor,
                                            }}
                                          >
                                            {organ.segments.length !== 1 &&
                                              segment.title}
                                          </div>
                                          {segment &&
                                            segment.buttons.length === 0 && (
                                              <p
                                                style={{
                                                  color: "grey",
                                                  fontSize: "15px",
                                                  paddingLeft: "3px",
                                                }}
                                              >
                                                Кнопок нет
                                              </p>
                                            )}
                                          {segment &&
                                            segment.buttons.map(
                                              (button, buttonIndex) => (
                                                <div>
                                                  <Buttons
                                                    {...{
                                                      button,
                                                      buttonIndex,
                                                      buttonINDEX,
                                                      organINDEX,
                                                      segmentINDEX,
                                                      zaklRef,
                                                      setConstructorOpen,
                                                      constructorREF,
                                                      organIndex,
                                                      segmentIndex,
                                                      rybaObject,
                                                      setRybaObject,
                                                      id,
                                                      buttonClick,
                                                      reset,
                                                      setReset,
                                                      showOverlayImages,
                                                      radioINDEX,
                                                      editedButtonTypeREF,
                                                      setEditButtonOpen,
                                                      editedButtonREF,
                                                      editedButton,
                                                      setEditedButton,
                                                      setScrollY,
                                                      setSureDeleteButton,
                                                      duplicateButton,
                                                      editedButtonJSON,
                                                      setEditedButtonJSON,
                                                      setCALCULATORopen,
                                                      calculatorEditorOpen,
                                                      setCalculatorEditorOpen,
                                                    }}
                                                  />
                                                </div>
                                              )
                                            )}
                                          {text[organIndex][segmentIndex] !==
                                            rybaObject.organs[organIndex]
                                              .segments[segmentIndex].norm && (
                                            <button
                                              className="goToNorm"
                                              value="Сбросить до нормы"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                let x = [...text];
                                                x[organIndex][segmentIndex] =
                                                  segment.norm;
                                                const rybaLength =
                                                  rybaObject.organs.length - 1;

                                                rybaObject.organs[
                                                  organIndex
                                                ].segments[
                                                  segmentIndex
                                                ].buttons.map((button) => {
                                                  if (
                                                    button.type ===
                                                      "checkbox" &&
                                                    button.zakl
                                                  ) {
                                                    x[rybaLength][0] = x[
                                                      rybaLength
                                                    ][0].replace(
                                                      button.zakl.trim(),
                                                      ""
                                                    );
                                                  }
                                                  if (button.type === "radio") {
                                                    button.radios.map((r) => {
                                                      if (r.zakl) {
                                                        x[rybaLength][0] = x[
                                                          rybaLength
                                                        ][0].replace(
                                                          r.zakl.trim(),
                                                          ""
                                                        );
                                                      }
                                                    });
                                                  }
                                                });
                                                setText(x);
                                              }}
                                            >
                                              Сбросить до нормы
                                            </button>
                                          )}
                                          {text[organIndex][segmentIndex] !==
                                            rybaObject.organs[organIndex]
                                              .segments[segmentIndex].norm &&
                                            rybaObject.organs.length - 1 ===
                                              organIndex && (
                                              <button
                                                className="goToNorm"
                                                value="Сбросить до нормы"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setScrollY(window.scrollY);
                                                  let x = JSON.parse(
                                                    JSON.stringify(
                                                      text[organIndex][
                                                        segmentIndex
                                                      ]
                                                    )
                                                  );
                                                  const sentenceRegex =
                                                    /(?<!\w\.\w.)(?<![A-Z][a-z]\.)(?<!см\.)(?<=\.|\?|!)\s/g;
                                                  const sentences =
                                                    x.split(sentenceRegex);

                                                  // Trim whitespace from each sentence and filter out empty strings.
                                                  const trimmedSentences =
                                                    sentences
                                                      .map((sentence) =>
                                                        sentence.trim()
                                                      )
                                                      .filter(
                                                        (sentence) =>
                                                          sentence !== ""
                                                      );
                                                  setSortedZaklText(
                                                    trimmedSentences
                                                  );
                                                  setSortedZaklOpen(true);
                                                }}
                                              >
                                                Сортировать заключение
                                              </button>
                                            )}
                                        </div>
                                      )
                                    )}
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      )
    );
  }
};

export default Ryba4;
