import "./buttons.css";
import { useRef } from "react";
import CheckboxsVG from "../svg/CheckboxSVG";

import ConstructorSVG from "../svg/ConstructorSVG";
import Dropdown from "../svg/Dropdown";
import { notificationTheBest } from "../../rootComponents/NotificationService";

const ButtonsFollowup = ({
  button,
  buttonIndex,
  zaklRef,
  setConstructorOpen,
  constructorREF,
  organIndex,
  segmentIndex,
  buttonINDEX,
  organINDEX,
  segmentINDEX,
  text,
  handlePaste,
  rybaObject,
}) => {
  if (button.type === "checkbox")
    return (
      <>
        <button
          className="simpleButton3"
          onClick={(e) => {
            let t = text;
            if (!t.includes(button.zakl) && button.zakl) {
              t += button.zakl;
            }
            handlePaste(button.content, button.zakl, button);
          }}
        >
          <span
            style={{
              marginTop: "9px",
            }}
          >
            <CheckboxsVG
              width={12}
              height={12}
              color={
                // reset[organIndex][segmentIndex][buttonIndex] ? "yellow" :
                "grey"
              }
            />{" "}
          </span>
          {button.title}
        </button>
      </>
    );
  if (button.type === "radio")
    return (
      <>
        <fieldset className="buttonBox">
          <legend>
            {/* {" "}
            <RadioSVG
              style={{ verticalAlign: "center" }}
              height={14}
              width={14}
              color={
                // reset[organIndex][segmentIndex][buttonIndex] ? "yellow" :
                "grey"
              }
            />{" "} */}
            {button.title}
          </legend>
          {button.radios.map((r, index) => (
            <button
              value={r.content}
              onClick={(e) => {
                handlePaste(r.content, r.zakl, r);
              }}
            >
              {r.title}
            </button>
          ))}
        </fieldset>
      </>
    );

  if (button.type === "constructor")
    return (
      <>
        <ConstructorSVG
          width={16}
          height={16}
          color={
            // reset[organIndex][segmentIndex][buttonIndex] ? "yellow" :
            "grey"
          }
        />{" "}
        <button
          className="simpleButton2"
          value={button.title}
          onClick={(e) => {
            console.log("constructor button", button.title);
            console.log("constructor button", constructorREF.current);
            organINDEX.current = organIndex;
            segmentINDEX.current = segmentIndex;
            buttonINDEX.current = buttonIndex;
            constructorREF.current.constructor = button.title;
            constructorREF.current.organIndex = organIndex;
            constructorREF.current.segmentIndex = segmentIndex;
            constructorREF.current.buttonIndex = buttonIndex;
            setConstructorOpen(true);
          }}
        >
          {button.title} 🎛️
        </button>
      </>
    );
  if (button.type === "select")
    return (
      <>
        <label>
          <Dropdown
            width={16}
            height={16}
            color={
              // reset[organIndex][segmentIndex][buttonIndex] ? "yellow" :
              "grey"
            }
          />{" "}
          <select
            onChange={(e) => {
              let t = `${text}`;
              if (button.options[e.target.selectedIndex].zakl) {
                t += button.options[e.target.selectedIndex].zakl;
              }
              console.log(button.options[e.target.selectedIndex].zakl);
              handlePaste(
                button.options[e.target.selectedIndex].content,
                button.options[e.target.selectedIndex].zakl,
                button.options[e.target.selectedIndex]
              );
            }}
          >
            {button.options.map((s) => (
              <option value={s.content}>{s.title} </option>
            ))}
          </select>
          {button.title}
        </label>
      </>
    );
};

export default ButtonsFollowup;
