import React, { useState, useRef } from "react";
import { evaluate } from "mathjs";
import TextareaAutosize from "react-textarea-autosize";
import Icon from "../../redactorComponents/icons/icons";

const ConstructorOfLogic = ({
  calculator,
  setCalculator,
  generalCalculation,
  steps,
  stepIndex,
}) => {
  // State for variables
  const [openedStep, setOpenedStep] = useState(0);
  const [openedStepZakl, setOpenedStepZakl] = useState(0);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeIndexZakl, setActiveIndexZakl] = useState(null);
  const [addLogicOpen, setAddLogicOpen] = useState({
    opened: false,
    stepIndex: 0,
  });
  const [addLogicOpenZakl, setAddLogicOpenZakl] = useState({
    opened: false,
    stepIndex: 0,
  });

  //
  const defaultConditionsArray = [">", ">=", "<", "<=", "="];

  const stepDefault = {
    logic: [],
    logicType: "and",
    output: "",
    outputZakl: "",
  };
  const handleVariableOrFormulaCondition = () => {
    let optionsArray = [];
    const vars = calculator.values.filter((v) => v.type === "variable");
    vars.length > 0 &&
      vars.map((v) => {
        optionsArray.push(v.title);
      });
    calculator.formulas.length > 0 &&
      calculator.formulas.map((f, index) => {
        optionsArray.push(`формула${index + 1}`);
      });

    return optionsArray;
  };
  const handleAllValues = () => {
    let optionsArray = [];
    calculator.values.length > 0 &&
      calculator.values.map((v) => {
        optionsArray.push(v.title);
      });
    calculator.formulas.length > 0 &&
      calculator.formulas.map((f, index) => {
        optionsArray.push(`формула${index + 1}`);
      });

    return optionsArray;
  };

  const handleHover = (index) => {
    setActiveIndex(index);
  };
  const handleHoverZakl = (index) => {
    setActiveIndexZakl(index);
  };

  const handleLeave = () => {
    setActiveIndex(null);
  };

  const handleLeaveZakl = () => {
    setActiveIndexZakl(null);
  };

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      <div>
        <div
          style={{
            backgroundColor: "#121212",
            padding: "10px",
            fontWeight: "100",
          }}
        >
          <legend>
            {/* Стадии формирования описания сверху вниз{" "}
            <p
              style={{
                fontStyle: "italic",
                color: "grey",
              }}
            >
              Можно добавить фильтрующие логические правила или оставить, как
              есть, тогда текст по умолчанию прибавится к описанию и заключению
            </p>
            <p
              style={{
                fontStyle: "italic",
                color: "grey",
              }}
            >
              Каждый шаг добавляет текст к ранее добавленному тексту без
              пробелов и знаков препинания.
            </p> */}
            <div>
              <div className="addElementsCalcContainer">
                <h2>
                  В ОПИСАНИЕ{" "}
                  <button
                    style={{
                      padding: "7px",
                    }}
                    onClick={() => {
                      let c = { ...calculator };

                      c.steps.push(stepDefault);
                      setCalculator(c);
                      setOpenedStep(c.steps.length - 1);
                    }}
                  >
                    Добавить шаг
                  </button>
                </h2>{" "}
              </div>

              {calculator.steps.length > 0 &&
                calculator.steps.map((stepObject, stepIndex) =>
                  stepIndex === openedStep ? (
                    <fieldset className="openedStep">
                      <legend>Шаг {stepIndex + 1}</legend>
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            left: "0",
                            top: "0",
                          }}
                        >
                          <button
                            className="deleteButtonUniversal"
                            onMouseEnter={() => {
                              setAddLogicOpen({
                                opened: true,
                                stepIndex,
                              });
                            }}
                          >
                            Добавить фильтры{" "}
                          </button>
                        </div>

                        <div
                          style={{
                            position: "absolute",
                            right: "0",
                            top: "0",
                          }}
                        >
                          <button
                            className="logicItemsButton"
                            onClick={() => {
                              let c = { ...calculator };
                              let a = JSON.parse(
                                JSON.stringify(c.steps[stepIndex])
                              );
                              c.steps.splice(stepIndex, 0, a);
                              setOpenedStep((s) => s + 1);
                              setCalculator(c);
                            }}
                          >
                            <Icon title="duplicate" />
                          </button>
                          {stepIndex > 0 && (
                            <button
                              className="logicItemsButton"
                              onClick={() => {
                                let c = { ...calculator };
                                let a = c.steps[stepIndex];
                                let b = c.steps[stepIndex - 1];
                                c.steps[stepIndex] = b;
                                c.steps[stepIndex - 1] = a;
                                setOpenedStep((s) => s - 1);
                                setCalculator(c);
                              }}
                            >
                              <Icon title="arrowUp" />
                            </button>
                          )}
                          {stepIndex < calculator.steps.length - 1 && (
                            <button
                              className="logicItemsButton"
                              onClick={() => {
                                let c = { ...calculator };
                                let a = c.steps[stepIndex];
                                let b = c.steps[stepIndex + 1];
                                c.steps[stepIndex] = b;
                                c.steps[stepIndex + 1] = a;
                                setOpenedStep((s) => s + 1);
                                setCalculator(c);
                              }}
                            >
                              <Icon title="arrowDown" />
                            </button>
                          )}
                          <button
                            className="logicItemsButton"
                            onClick={() => {
                              let c = { ...calculator };
                              c.steps.splice(stepIndex, 1);
                              if (stepIndex > 0) {
                                setOpenedStep((s) => s - 1);
                              }
                              setCalculator(c);
                            }}
                          >
                            <Icon title={"delete"} />
                          </button>
                        </div>

                        {addLogicOpen.opened === true && (
                          <div
                            className="logicContextMenu"
                            style={{
                              position: "absolute",
                              top: "0px",
                              left: "0px",
                              display: "flex",
                              flexDirection: "column",
                              width: "140px",
                              zIndex: "100000000000",
                            }}
                            onMouseLeave={() => {
                              setAddLogicOpen({
                                opened: false,
                                stepIndex,
                              });
                            }}
                          >
                            <p
                              onClick={() => {
                                let c = { ...calculator };
                                c.steps[stepIndex].logic.push({
                                  title: undefined,
                                  type: "=",
                                  value: 0,
                                });
                                setCalculator(c);

                                setAddLogicOpen({
                                  opened: false,
                                  stepIndex: 0,
                                });
                              }}
                            >
                              Числовое условие
                            </p>
                            <p
                              onClick={() => {
                                let c = { ...calculator };
                                c.steps[stepIndex].logic.push({
                                  title: undefined,
                                  title2: undefined,
                                  type: "comparison",
                                  type2: "=",
                                });
                                setCalculator(c);
                                setAddLogicOpen({
                                  opened: false,
                                  stepIndex: 0,
                                });
                              }}
                            >
                              Сравнение чисел
                            </p>
                            <p
                              onClick={() => {
                                let c = { ...calculator };
                                c.steps[stepIndex].logic.push({
                                  title: undefined,
                                  value: "Да",
                                  type: "isDefined",
                                });
                                setCalculator(c);
                                setAddLogicOpen({
                                  opened: false,
                                  stepIndex: 0,
                                });
                              }}
                            >
                              Указано ли значение
                            </p>
                            <p
                              onMouseEnter={() => handleHover(2)}
                              onMouseLeave={handleLeave}
                            >
                              Чекбоксы
                              <div
                                className="sideMenu"
                                style={{
                                  display: activeIndex === 2 ? "block" : "none",
                                }}
                              >
                                {calculator.values.filter(
                                  (v) => v.type === "boolean"
                                ).length === 0 && (
                                  <p className="greySpan">Чекбоксов нет</p>
                                )}
                                {calculator.values.filter(
                                  (v) => v.type === "boolean"
                                ).length > 0 &&
                                  calculator.values
                                    .filter((v) => v.type === "boolean")
                                    .map((s) => (
                                      <p
                                        onClick={() => {
                                          let c = { ...calculator };
                                          c.steps[stepIndex].logic.push({
                                            type: "boolean",
                                            value: "Да",
                                            title: s.title,
                                          });
                                          setCalculator(c);
                                          setActiveIndex(null);
                                          setAddLogicOpen({
                                            opened: false,
                                            stepIndex: 0,
                                          });
                                        }}
                                      >
                                        {s.title}
                                      </p>
                                    ))}
                              </div>
                            </p>
                            <p
                              onMouseEnter={() => handleHover(3)}
                              onMouseLeave={handleLeave}
                            >
                              Тестики
                              <div
                                className="sideMenu"
                                style={{
                                  display: activeIndex === 3 ? "block" : "none",
                                }}
                              >
                                {calculator.values.filter(
                                  (v) => v.type === "radio"
                                ).length === 0 && (
                                  <p className="greySpan">Тестиков нет</p>
                                )}
                                {calculator.values.filter(
                                  (v) => v.type === "radio"
                                ).length > 0 &&
                                  calculator.values
                                    .filter((v) => v.type === "radio")
                                    .map((r, radioIndex) => (
                                      <p
                                        onClick={() => {
                                          let c = { ...calculator };
                                          let popo = {
                                            ...r,
                                          };
                                          popo.value = r.radios[0];
                                          c.steps[stepIndex].logic.push(popo);
                                          setCalculator(c);
                                          setActiveIndex(null);
                                          setAddLogicOpen({
                                            opened: false,
                                            stepIndex: 0,
                                          });
                                        }}
                                      >
                                        {r.title}
                                      </p>
                                    ))}
                              </div>
                            </p>
                            <p
                              onMouseEnter={() => handleHover(4)}
                              onMouseLeave={handleLeave}
                            >
                              Меню
                              <div
                                className="sideMenu"
                                style={{
                                  display: activeIndex === 4 ? "block" : "none",
                                }}
                              >
                                {calculator.values.filter(
                                  (v) => v.type === "menu"
                                ).length === 0 && (
                                  <p className="greySpan">Меню нет</p>
                                )}
                                {calculator.values.filter(
                                  (v) => v.type === "menu"
                                ).length > 0 &&
                                  calculator.values
                                    .filter((v) => v.type === "menu")
                                    .map((r, menuIndex) => (
                                      <p
                                        onClick={() => {
                                          let c = { ...calculator };
                                          let popo = {
                                            ...r,
                                          };
                                          popo.value = r.radios[0];
                                          c.steps[stepIndex].logic.push(popo);
                                          setCalculator(c);
                                          setActiveIndex(null);
                                          setAddLogicOpen({
                                            opened: false,
                                            stepIndex: 0,
                                          });
                                        }}
                                      >
                                        {r.title}
                                      </p>
                                    ))}
                              </div>
                            </p>
                          </div>
                        )}
                      </div>

                      {stepObject.logic.length > 0 ? (
                        <div>
                          <div
                            className="logicContainerDiv"
                            style={{
                              marginTop: "30px",
                            }}
                          >
                            {stepObject.logic.map((l, ind) => (
                              <>
                                {l.type === "boolean" && (
                                  <p
                                    style={{
                                      display: "inline-block",
                                    }}
                                  >
                                    {" "}
                                    {l.title}
                                    <select
                                      className="calculatorSelect"
                                      value={l.value}
                                      onChange={(e) => {
                                        let c = { ...calculator };
                                        c.steps[stepIndex].logic[ind].value =
                                          e.target.value;
                                        setCalculator(c);
                                      }}
                                    >
                                      <option value="Да">Да</option>
                                      <option value="Нет">Нет</option>
                                    </select>
                                    <button
                                      className="svgButtonCalc"
                                      style={{
                                        display: "inline-block",
                                      }}
                                      onClick={() => {
                                        let c = { ...calculator };
                                        c.steps[stepIndex].logic.splice(ind, 1);
                                        setCalculator(c);
                                      }}
                                    >
                                      <Icon title="delete" />
                                    </button>
                                  </p>
                                )}
                                {l.type === "isDefined" && (
                                  <p
                                    style={{
                                      display: "inline-block",
                                    }}
                                  >
                                    {" "}
                                    Указано ли значение?
                                    <select
                                      className="calculatorSelect"
                                      value={l.title}
                                      onChange={(e) => {
                                        let c = { ...calculator };
                                        c.steps[stepIndex].logic[ind].title =
                                          e.target.value;
                                        console.log(
                                          c.steps[stepIndex].logic[ind]
                                        );
                                        setCalculator(c);
                                      }}
                                    >
                                      {" "}
                                      <option value="">
                                        Выберите значение
                                      </option>
                                      {calculator.values.map((c) => (
                                        <option value={c.title}>
                                          {c.title}
                                        </option>
                                      ))}
                                      {/* {calculator.values
                                        .filter((v) => v.type === "list")
                                        .map((l) => (
                                          <option value={l.title}>
                                            {l.title}
                                          </option>
                                        ))} */}
                                    </select>
                                    <select
                                      className="calculatorSelect"
                                      value={l.value}
                                      onChange={(e) => {
                                        let c = { ...calculator };
                                        c.steps[stepIndex].logic[ind].value =
                                          e.target.value;
                                        setCalculator(c);
                                      }}
                                    >
                                      <option value="Да">Да</option>
                                      <option value="Нет">Нет</option>
                                    </select>
                                    <button
                                      className="svgButtonCalc"
                                      style={{
                                        display: "inline-block",
                                      }}
                                      onClick={() => {
                                        let c = { ...calculator };
                                        c.steps[stepIndex].logic.splice(ind, 1);
                                        setCalculator(c);
                                      }}
                                    >
                                      <Icon title="delete" />
                                    </button>
                                  </p>
                                )}
                                {l.type === "radio" && (
                                  <p>
                                    {" "}
                                    {l.title}
                                    <select
                                      className="calculatorSelect"
                                      value={l.value}
                                      onChange={(e) => {
                                        let c = { ...calculator };
                                        c.steps[stepIndex].logic[ind].value =
                                          e.target.value;
                                        setCalculator(c);
                                      }}
                                    >
                                      {l.radios.map((r) => (
                                        <option value={r}>{r}</option>
                                      ))}
                                    </select>
                                    <button
                                      className="svgButtonCalc"
                                      style={{
                                        display: "inline-block",
                                      }}
                                      onClick={() => {
                                        let c = { ...calculator };
                                        c.steps[stepIndex].logic.splice(ind, 1);
                                        setCalculator(c);
                                      }}
                                    >
                                      <Icon title="delete" />
                                    </button>
                                  </p>
                                )}{" "}
                                {l.type === "menu" && (
                                  <p>
                                    {" "}
                                    {l.title}
                                    <select
                                      className="calculatorSelect"
                                      value={l.value}
                                      onChange={(e) => {
                                        let c = { ...calculator };
                                        c.steps[stepIndex].logic[ind].value =
                                          e.target.value;
                                        setCalculator(c);
                                      }}
                                    >
                                      {l.radios.map((r) => (
                                        <option value={r}>{r}</option>
                                      ))}
                                    </select>
                                    <button
                                      className="svgButtonCalc"
                                      style={{
                                        display: "inline-block",
                                      }}
                                      onClick={() => {
                                        let c = { ...calculator };
                                        c.steps[stepIndex].logic.splice(ind, 1);
                                        setCalculator(c);
                                      }}
                                    >
                                      <Icon title="delete" />
                                    </button>
                                  </p>
                                )}{" "}
                                {l.type === "comparison" && (
                                  <p>
                                    <select
                                      className="calculatorSelect"
                                      value={l.title}
                                      onChange={(e) => {
                                        let c = { ...calculator };
                                        c.steps[stepIndex].logic[ind].title =
                                          e.target.value;
                                        console.log(
                                          c.steps[stepIndex].logic[ind]
                                        );
                                        setCalculator(c);
                                      }}
                                    >
                                      {" "}
                                      <option value="">
                                        Выберите значение
                                      </option>
                                      {handleVariableOrFormulaCondition().map(
                                        (c) => (
                                          <option value={c}>{c}</option>
                                        )
                                      )}
                                    </select>
                                    <select
                                      className="calculatorSelectMoreOrLess"
                                      value={l.type2}
                                      onChange={(e) => {
                                        let c = { ...calculator };
                                        c.steps[stepIndex].logic[ind].type2 =
                                          e.target.value;
                                        setCalculator(c);
                                      }}
                                    >
                                      {defaultConditionsArray.map((c) => (
                                        <option value={c}>{c}</option>
                                      ))}
                                    </select>
                                    <select
                                      className="calculatorSelect"
                                      value={l.title2}
                                      onChange={(e) => {
                                        let c = { ...calculator };
                                        c.steps[stepIndex].logic[ind].title2 =
                                          e.target.value;
                                        console.log(
                                          c.steps[stepIndex].logic[ind]
                                        );
                                        setCalculator(c);
                                      }}
                                    >
                                      {" "}
                                      <option value="">
                                        Выберите значение
                                      </option>
                                      {handleVariableOrFormulaCondition().map(
                                        (c) => (
                                          <option value={c}>{c}</option>
                                        )
                                      )}
                                    </select>
                                    <button
                                      className="svgButtonCalc"
                                      style={{
                                        display: "inline-block",
                                      }}
                                      onClick={() => {
                                        let c = { ...calculator };
                                        c.steps[stepIndex].logic.splice(ind, 1);
                                        setCalculator(c);
                                      }}
                                    >
                                      <Icon title="delete" />
                                    </button>
                                  </p>
                                )}
                                {l.type !== "boolean" &&
                                  l.type !== "radio" &&
                                  l.type !== "menu" &&
                                  l.type !== "comparison" &&
                                  l.type !== "isDefined" && (
                                    <p>
                                      <select
                                        className="calculatorSelect"
                                        value={l.title}
                                        style={{
                                          color: "orange",
                                          fontWeight: "500",
                                        }}
                                        onChange={(e) => {
                                          let c = { ...calculator };
                                          c.steps[stepIndex].logic[ind].title =
                                            e.target.value;
                                          console.log(
                                            c.steps[stepIndex].logic[ind]
                                          );
                                          setCalculator(c);
                                        }}
                                      >
                                        {" "}
                                        <option value="">
                                          Выберите значение
                                        </option>
                                        {handleVariableOrFormulaCondition().map(
                                          (c) => (
                                            <option value={c}>{c}</option>
                                          )
                                        )}
                                      </select>
                                      <select
                                        className="calculatorSelectMoreOrLess"
                                        value={l.type}
                                        onChange={(e) => {
                                          let c = { ...calculator };
                                          c.steps[stepIndex].logic[ind].type =
                                            e.target.value;
                                          setCalculator(c);
                                        }}
                                      >
                                        {defaultConditionsArray.map((c) => (
                                          <option value={c}>{c}</option>
                                        ))}
                                      </select>
                                      <input
                                        className="calculatorSelect"
                                        type="number"
                                        value={l.value}
                                        placeholder="число"
                                        onChange={(e) => {
                                          let c = { ...calculator };
                                          c.steps[stepIndex].logic[ind].value =
                                            e.target.value;
                                          setCalculator(c);
                                        }}
                                      />
                                      <button
                                        className="svgButtonCalc"
                                        style={{
                                          display: "inline-block",
                                        }}
                                        onClick={() => {
                                          let c = { ...calculator };
                                          c.steps[stepIndex].logic.splice(
                                            ind,
                                            1
                                          );
                                          setCalculator(c);
                                        }}
                                      >
                                        <Icon title="delete" />
                                      </button>
                                    </p>
                                  )}
                                {ind < stepObject.logic.length - 1 && (
                                  <div
                                    style={{
                                      width: "50px",
                                      margin: "auto",
                                    }}
                                    className="pointer logicTypeSpan"
                                    onClick={() => {
                                      let c = { ...calculator };
                                      c.steps[stepIndex].logicType === "and"
                                        ? (c.steps[stepIndex].logicType = "or")
                                        : (c.steps[stepIndex].logicType =
                                            "and");
                                      setCalculator(c);
                                    }}
                                  >
                                    {stepObject.logicType === "and"
                                      ? "И"
                                      : "ИЛИ"}
                                  </div>
                                )}
                              </>
                            ))}{" "}
                          </div>

                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <TextareaAutosize
                                  value={stepObject.output}
                                  onChange={(e) => {
                                    let c = { ...calculator };
                                    c.steps[stepIndex].output = e.target.value;
                                    setCalculator(c);
                                  }}
                                  style={{
                                    width: "580px",
                                    height: "30px",
                                  }}
                                  placeholder="Текст в ОПИСАНИЕ. Сюда можно вставлять значения переменных, расчёт формул, выбранные опции из тестиков или меню, для этого нажмите 'Добавить значение'"
                                />

                                <select
                                  className="calculatorSelect"
                                  value={"Добавить значение"}
                                  onChange={(e) => {
                                    let c = { ...calculator };
                                    c.steps[
                                      stepIndex
                                    ].output += `(${e.target.value})`;
                                    setCalculator(c);
                                  }}
                                >
                                  <option value="">Добавить значение</option>
                                  {handleAllValues().map((c) => (
                                    <option value={c}>{c}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            marginTop: "30px",
                          }}
                        >
                          <p
                            style={{
                              fontStyle: "italic",
                              color: "grey",
                            }}
                          >
                            Фильтров нет. Текст добавится по умолчанию
                          </p>
                          <div>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <TextareaAutosize
                                value={stepObject.output}
                                onChange={(e) => {
                                  let c = { ...calculator };
                                  c.steps[stepIndex].output = e.target.value;
                                  setCalculator(c);
                                }}
                                style={{
                                  width: "580px",
                                  height: "30px",
                                }}
                                placeholder="Текст в ОПИСАНИЕ. Сюда можно вставлять значения переменных, расчёт формул, выбранные опции из тестиков или меню, для этого нажмите 'Добавить значение'"
                              />

                              <select
                                className="calculatorSelect"
                                value={"Добавить значение"}
                                onChange={(e) => {
                                  let c = { ...calculator };
                                  c.steps[
                                    stepIndex
                                  ].output += `(${e.target.value})`;
                                  setCalculator(c);
                                }}
                              >
                                <option value="">Добавить значение</option>
                                {handleAllValues().map((c) => (
                                  <option value={c}>{c}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                    </fieldset>
                  ) : (
                    <div
                      className="pointer notFocusedStep"
                      onClick={() => {
                        setOpenedStep(stepIndex);
                      }}
                    >
                      <div
                        style={{
                          width: "70px",
                          marginRight: "7px",
                        }}
                      >
                        <p
                          style={{
                            margin: "8px",
                          }}
                        >
                          {`Шаг ${stepIndex + 1}`}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "280px",
                          textAlign: "center",
                          marginRight: "5px",
                        }}
                      >
                        {stepObject.logic.length === 0 && (
                          <span
                            style={{
                              fontStyle: "italic",
                              color: "grey",
                              width: "200px",
                            }}
                          >
                            Фильтров нет. Текст добавится по умолчанию
                          </span>
                        )}
                        {stepObject.logic.map((l, ind) => (
                          <div
                            style={{
                              padding: "1px",

                              width: "100%",
                              height: "100%",
                            }}
                          >
                            {l.type === "boolean" && (
                              <p
                                style={{
                                  display: "inline-block",
                                }}
                              >
                                {l.title}{" "}
                                <span className="orangeSpan">{l.value}</span>{" "}
                              </p>
                            )}
                            {l.type === "isDefined" && (
                              <p
                                style={{
                                  display: "inline-block",
                                }}
                              >
                                {" "}
                                Значение{" "}
                                <span className="orangeSpan">
                                  {l.title}
                                </span>{" "}
                                {l.value === "Да" ? (
                                  <span
                                    style={{
                                      color: "lightgreen",
                                    }}
                                  >
                                    указано
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    НЕ указано
                                  </span>
                                )}
                              </p>
                            )}
                            {l.type === "radio" && (
                              <p>
                                {" "}
                                {l.title}{" "}
                                <span className="orangeSpan">{l.value}</span>
                              </p>
                            )}{" "}
                            {l.type === "menu" && (
                              <p>
                                {" "}
                                {l.title}{" "}
                                <span className="orangeSpan">{l.value}</span>
                              </p>
                            )}{" "}
                            {l.type === "comparison" && (
                              <p>
                                <span
                                  style={{
                                    minWidth: "30px",
                                    color: "orange",
                                  }}
                                >
                                  {l.title}
                                </span>
                                <span
                                  style={{
                                    color: "dark",
                                    minWidth: "30px",
                                  }}
                                >
                                  {" "}
                                  {l.type2}{" "}
                                </span>
                                <span
                                  style={{
                                    minWidth: "30px",
                                    color: "orange",
                                  }}
                                >
                                  {l.title2}
                                </span>
                              </p>
                            )}
                            {l.type !== "boolean" &&
                              l.type !== "radio" &&
                              l.type !== "menu" &&
                              l.type !== "comparison" &&
                              l.type !== "isDefined" && (
                                <p>
                                  <span
                                    style={{
                                      minWidth: "30px",
                                      color: "orange",
                                    }}
                                  >
                                    {l.title}{" "}
                                  </span>
                                  <span
                                    style={{
                                      color: "dark",
                                      minWidth: "30px",
                                    }}
                                  >
                                    {l.type}{" "}
                                  </span>
                                  <span
                                    style={{
                                      minWidth: "30px",
                                      color: "darkgrey",
                                    }}
                                  >
                                    {l.value}
                                  </span>
                                </p>
                              )}
                            {/* {ind < stepObject.logic.length - 1 && "и"} */}
                            {ind < stepObject.logic.length - 1 && (
                              <div
                                style={{
                                  width: "50px",
                                  margin: "auto",
                                }}
                                className="pointer logicTypeSpan"
                              >
                                {stepObject.logicType === "and" ? "И" : "ИЛИ"}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          justifyItems: "center",
                          textAlign: "center",
                          width: "380px",
                        }}
                      >
                        <div
                          style={{
                            margin: "auto",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          {stepObject.output === "" ? "-" : stepObject.output}
                        </div>
                      </div>
                    </div>
                  )
                )}
            </div>
            <div>
              <div className="addElementsCalcContainer">
                <h2>
                  В ЗАКЛЮЧЕНИЕ
                  <button
                    style={{
                      padding: "7px",
                    }}
                    onClick={() => {
                      let c = { ...calculator };

                      c.stepsZakl.push(stepDefault);
                      setCalculator(c);
                      setOpenedStepZakl(c.stepsZakl.length - 1);
                    }}
                  >
                    Добавить шаг
                  </button>
                </h2>{" "}
              </div>
              {calculator.stepsZakl.length > 0 &&
                calculator.stepsZakl.map((stepObject, stepIndex) =>
                  stepIndex === openedStepZakl ? (
                    <fieldset className="openedStep">
                      <legend>Шаг {stepIndex + 1}</legend>
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            left: "0",
                            top: "0",
                          }}
                        >
                          <button
                            className="deleteButtonUniversal"
                            onMouseEnter={() => {
                              setAddLogicOpenZakl({
                                opened: true,
                                stepIndex,
                              });
                            }}
                          >
                            Добавить фильтры{" "}
                          </button>
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            right: "0",
                            top: "0",
                          }}
                        >
                          <button
                            className="logicItemsButton"
                            onClick={() => {
                              let c = { ...calculator };
                              let a = JSON.parse(
                                JSON.stringify(c.stepsZakl[stepIndex])
                              );
                              c.stepsZakl.splice(stepIndex, 0, a);
                              setOpenedStepZakl((s) => s + 1);
                              setCalculator(c);
                            }}
                          >
                            <Icon title="duplicate" />
                          </button>
                          {stepIndex > 0 && (
                            <button
                              className="logicItemsButton"
                              onClick={() => {
                                let c = { ...calculator };
                                let a = c.stepsZakl[stepIndex];
                                let b = c.stepsZakl[stepIndex - 1];
                                c.stepsZakl[stepIndex] = b;
                                c.stepsZakl[stepIndex - 1] = a;
                                setOpenedStepZakl((s) => s - 1);
                                setCalculator(c);
                              }}
                            >
                              <Icon title="arrowUp" />
                            </button>
                          )}
                          {stepIndex < calculator.stepsZakl.length - 1 && (
                            <button
                              className="logicItemsButton"
                              onClick={() => {
                                let c = { ...calculator };
                                let a = c.stepsZakl[stepIndex];
                                let b = c.stepsZakl[stepIndex + 1];
                                c.stepsZakl[stepIndex] = b;
                                c.stepsZakl[stepIndex + 1] = a;
                                setOpenedStepZakl((s) => s + 1);
                                setCalculator(c);
                              }}
                            >
                              <Icon title="arrowDown" />
                            </button>
                          )}
                          <button
                            className="logicItemsButton"
                            onClick={() => {
                              let c = { ...calculator };
                              c.stepsZakl.splice(stepIndex, 1);
                              if (stepIndex > 0) {
                                setOpenedStepZakl((s) => s - 1);
                              }
                              setCalculator(c);
                            }}
                          >
                            <Icon title="delete" />
                          </button>
                        </div>

                        {addLogicOpenZakl.opened === true && (
                          <div
                            className="logicContextMenu"
                            style={{
                              position: "absolute",
                              top: "0px",
                              left: "0px",
                              display: "flex",
                              flexDirection: "column",
                              width: "140px",
                              zIndex: "100000000000",
                            }}
                            onMouseLeave={() => {
                              setAddLogicOpenZakl({
                                opened: false,
                                stepIndex,
                              });
                            }}
                          >
                            <p
                              onClick={() => {
                                let c = { ...calculator };
                                c.stepsZakl[stepIndex].logic.push({
                                  title: undefined,
                                  type: "=",
                                  value: 0,
                                });
                                setCalculator(c);

                                setAddLogicOpenZakl({
                                  opened: false,
                                  stepIndex: 0,
                                });
                              }}
                            >
                              Числовое условие
                            </p>
                            <p
                              onClick={() => {
                                let c = { ...calculator };
                                c.stepsZakl[stepIndex].logic.push({
                                  title: undefined,
                                  title2: undefined,
                                  type: "comparison",
                                  type2: "=",
                                });
                                setCalculator(c);
                                setAddLogicOpenZakl({
                                  opened: false,
                                  stepIndex: 0,
                                });
                              }}
                            >
                              Сравнение чисел
                            </p>
                            <p
                              onClick={() => {
                                let c = { ...calculator };
                                c.stepsZakl[stepIndex].logic.push({
                                  title: undefined,
                                  value: "Да",
                                  type: "isDefined",
                                });
                                setCalculator(c);
                                setAddLogicOpenZakl({
                                  opened: false,
                                  stepIndex: 0,
                                });
                              }}
                            >
                              Указано ли значение
                            </p>

                            <p
                              onMouseEnter={() => handleHoverZakl(2)}
                              onMouseLeave={handleLeaveZakl}
                            >
                              Чекбоксы
                              <div
                                className="sideMenu"
                                style={{
                                  display:
                                    activeIndexZakl === 2 ? "block" : "none",
                                }}
                              >
                                {calculator.values.filter(
                                  (v) => v.type === "boolean"
                                ).length === 0 && (
                                  <p className="greySpan">Чекбоксов нет</p>
                                )}
                                {calculator.values.filter(
                                  (v) => v.type === "boolean"
                                ).length > 0 &&
                                  calculator.values
                                    .filter((v) => v.type === "boolean")
                                    .map((s) => (
                                      <p
                                        onClick={() => {
                                          let c = { ...calculator };
                                          c.stepsZakl[stepIndex].logic.push({
                                            type: "boolean",
                                            value: "Да",
                                            title: s.title,
                                          });
                                          setCalculator(c);
                                          setActiveIndexZakl(null);
                                          setAddLogicOpenZakl({
                                            opened: false,
                                            stepIndex: 0,
                                          });
                                        }}
                                      >
                                        {s.title}
                                      </p>
                                    ))}
                              </div>
                            </p>
                            <p
                              onMouseEnter={() => handleHoverZakl(3)}
                              onMouseLeave={handleLeaveZakl}
                            >
                              Тестики
                              <div
                                className="sideMenu"
                                style={{
                                  display:
                                    activeIndexZakl === 3 ? "block" : "none",
                                }}
                              >
                                {calculator.values.filter(
                                  (v) => v.type === "radio"
                                ).length === 0 && (
                                  <p className="greySpan">Тестиков нет</p>
                                )}
                                {calculator.values.filter(
                                  (v) => v.type === "radio"
                                ).length > 0 &&
                                  calculator.values
                                    .filter((v) => v.type === "radio")
                                    .map((r, radioIndex) => (
                                      <p
                                        onClick={() => {
                                          let c = { ...calculator };
                                          let popo = {
                                            ...r,
                                          };
                                          popo.value = r.radios[0];
                                          c.stepsZakl[stepIndex].logic.push(
                                            popo
                                          );
                                          setCalculator(c);
                                          setActiveIndexZakl(null);
                                          setAddLogicOpenZakl({
                                            opened: false,
                                            stepIndex: 0,
                                          });
                                        }}
                                      >
                                        {r.title}
                                      </p>
                                    ))}
                              </div>
                            </p>
                            <p
                              onMouseEnter={() => handleHoverZakl(4)}
                              onMouseLeave={handleLeaveZakl}
                            >
                              Меню
                              <div
                                className="sideMenu"
                                style={{
                                  display:
                                    activeIndexZakl === 4 ? "block" : "none",
                                }}
                              >
                                {calculator.values.filter(
                                  (v) => v.type === "menu"
                                ).length === 0 && (
                                  <p className="greySpan">Меню нет</p>
                                )}
                                {calculator.values.filter(
                                  (v) => v.type === "menu"
                                ).length > 0 &&
                                  calculator.values
                                    .filter((v) => v.type === "menu")
                                    .map((r, menuIndex) => (
                                      <p
                                        onClick={() => {
                                          let c = { ...calculator };
                                          let popo = {
                                            ...r,
                                          };
                                          popo.value = r.radios[0];
                                          c.stepsZakl[stepIndex].logic.push(
                                            popo
                                          );
                                          setCalculator(c);
                                          setActiveIndexZakl(null);
                                          setAddLogicOpenZakl({
                                            opened: false,
                                            stepIndex: 0,
                                          });
                                        }}
                                      >
                                        {r.title}
                                      </p>
                                    ))}
                              </div>
                            </p>
                          </div>
                        )}
                      </div>

                      {stepObject.logic.length > 0 ? (
                        <div>
                          <div
                            className="logicContainerDiv"
                            style={{
                              marginTop: "30px",
                            }}
                          >
                            {stepObject.logic.map((l, ind) => (
                              <>
                                {l.type === "boolean" && (
                                  <p
                                    style={{
                                      display: "inline-block",
                                    }}
                                  >
                                    {" "}
                                    {l.title}
                                    <select
                                      className="calculatorSelect"
                                      value={l.value}
                                      onChange={(e) => {
                                        let c = { ...calculator };
                                        c.stepsZakl[stepIndex].logic[
                                          ind
                                        ].value = e.target.value;
                                        setCalculator(c);
                                      }}
                                    >
                                      <option value="Да">Да</option>
                                      <option value="Нет">Нет</option>
                                    </select>
                                    <button
                                      className="svgButtonCalc"
                                      style={{
                                        display: "inline-block",
                                      }}
                                      onClick={() => {
                                        let c = { ...calculator };
                                        c.stepsZakl[stepIndex].logic.splice(
                                          ind,
                                          1
                                        );
                                        setCalculator(c);
                                      }}
                                    >
                                      <Icon title="delete" />
                                    </button>
                                  </p>
                                )}
                                {l.type === "isDefined" && (
                                  <p
                                    style={{
                                      display: "inline-block",
                                    }}
                                  >
                                    {" "}
                                    Указано ли значение?
                                    <select
                                      className="calculatorSelect"
                                      value={l.title}
                                      onChange={(e) => {
                                        let c = { ...calculator };
                                        c.stepsZakl[stepIndex].logic[
                                          ind
                                        ].title = e.target.value;
                                        console.log(
                                          c.steps[stepIndex].logic[ind]
                                        );
                                        setCalculator(c);
                                      }}
                                    >
                                      {" "}
                                      <option value="">
                                        Выберите значение
                                      </option>
                                      {calculator.values.map((c) => (
                                        <option value={c.title}>
                                          {c.title}
                                        </option>
                                      ))}
                                      {/* {calculator.values
                                        .filter((v) => v.type === "list")
                                        .map((l) => (
                                          <option value={l.title}>
                                            {l.title}
                                          </option>
                                        ))} */}
                                    </select>
                                    <select
                                      className="calculatorSelect"
                                      value={l.value}
                                      onChange={(e) => {
                                        let c = { ...calculator };
                                        c.stepsZakl[stepIndex].logic[
                                          ind
                                        ].value = e.target.value;
                                        setCalculator(c);
                                      }}
                                    >
                                      <option value="Да">Да</option>
                                      <option value="Нет">Нет</option>
                                    </select>
                                    <button
                                      className="svgButtonCalc"
                                      style={{
                                        display: "inline-block",
                                      }}
                                      onClick={() => {
                                        let c = { ...calculator };
                                        c.stepsZakl[stepIndex].logic.splice(
                                          ind,
                                          1
                                        );
                                        setCalculator(c);
                                      }}
                                    >
                                      <Icon title="delete" />
                                    </button>
                                  </p>
                                )}
                                {l.type === "radio" && (
                                  <p>
                                    {" "}
                                    {l.title}
                                    <select
                                      className="calculatorSelect"
                                      value={l.value}
                                      onChange={(e) => {
                                        let c = { ...calculator };
                                        c.stepsZakl[stepIndex].logic[
                                          ind
                                        ].value = e.target.value;
                                        setCalculator(c);
                                      }}
                                    >
                                      {l.radios.map((r) => (
                                        <option value={r}>{r}</option>
                                      ))}
                                    </select>
                                    <button
                                      className="svgButtonCalc"
                                      style={{
                                        display: "inline-block",
                                      }}
                                      onClick={() => {
                                        let c = { ...calculator };
                                        c.stepsZakl[stepIndex].logic.splice(
                                          ind,
                                          1
                                        );
                                        setCalculator(c);
                                      }}
                                    >
                                      <Icon title="delete" />
                                    </button>
                                  </p>
                                )}{" "}
                                {l.type === "menu" && (
                                  <p>
                                    {" "}
                                    {l.title}
                                    <select
                                      className="calculatorSelect"
                                      value={l.value}
                                      onChange={(e) => {
                                        let c = { ...calculator };
                                        c.stepsZakl[stepIndex].logic[
                                          ind
                                        ].value = e.target.value;
                                        setCalculator(c);
                                      }}
                                    >
                                      {l.radios.map((r) => (
                                        <option value={r}>{r}</option>
                                      ))}
                                    </select>
                                    <button
                                      className="svgButtonCalc"
                                      style={{
                                        display: "inline-block",
                                      }}
                                      onClick={() => {
                                        let c = { ...calculator };
                                        c.stepsZakl[stepIndex].logic.splice(
                                          ind,
                                          1
                                        );
                                        setCalculator(c);
                                      }}
                                    >
                                      <Icon title="delete" />
                                    </button>
                                  </p>
                                )}{" "}
                                {l.type === "comparison" && (
                                  <p>
                                    <select
                                      className="calculatorSelect"
                                      value={l.title}
                                      onChange={(e) => {
                                        let c = { ...calculator };
                                        c.stepsZakl[stepIndex].logic[
                                          ind
                                        ].title = e.target.value;
                                        console.log(
                                          c.stepsZakl[stepIndex].logic[ind]
                                        );
                                        setCalculator(c);
                                      }}
                                    >
                                      {" "}
                                      <option value="">
                                        Выберите значение
                                      </option>
                                      {handleVariableOrFormulaCondition().map(
                                        (c) => (
                                          <option value={c}>{c}</option>
                                        )
                                      )}
                                    </select>
                                    <select
                                      className="calculatorSelectMoreOrLess"
                                      value={l.type2}
                                      onChange={(e) => {
                                        let c = { ...calculator };
                                        c.stepsZakl[stepIndex].logic[
                                          ind
                                        ].type2 = e.target.value;
                                        setCalculator(c);
                                      }}
                                    >
                                      {defaultConditionsArray.map((c) => (
                                        <option value={c}>{c}</option>
                                      ))}
                                    </select>
                                    <select
                                      className="calculatorSelect"
                                      value={l.title2}
                                      onChange={(e) => {
                                        let c = { ...calculator };
                                        c.stepsZakl[stepIndex].logic[
                                          ind
                                        ].title2 = e.target.value;
                                        console.log(
                                          c.stepsZakl[stepIndex].logic[ind]
                                        );
                                        setCalculator(c);
                                      }}
                                    >
                                      {" "}
                                      <option value="">
                                        Выберите значение
                                      </option>
                                      {handleVariableOrFormulaCondition().map(
                                        (c) => (
                                          <option value={c}>{c}</option>
                                        )
                                      )}
                                    </select>
                                    <button
                                      className="svgButtonCalc"
                                      style={{
                                        display: "inline-block",
                                      }}
                                      onClick={() => {
                                        let c = { ...calculator };
                                        c.stepsZakl[stepIndex].logic.splice(
                                          ind,
                                          1
                                        );
                                        setCalculator(c);
                                      }}
                                    >
                                      <Icon title="delete" />
                                    </button>
                                  </p>
                                )}
                                {l.type !== "boolean" &&
                                  l.type !== "radio" &&
                                  l.type !== "menu" &&
                                  l.type !== "comparison" &&
                                  l.type !== "isDefined" && (
                                    <p>
                                      <select
                                        className="calculatorSelect"
                                        value={l.title}
                                        style={{
                                          color: "orange",
                                          fontWeight: "500",
                                        }}
                                        onChange={(e) => {
                                          let c = { ...calculator };
                                          c.stepsZakl[stepIndex].logic[
                                            ind
                                          ].title = e.target.value;
                                          console.log(
                                            c.stepsZakl[stepIndex].logic[ind]
                                          );
                                          setCalculator(c);
                                        }}
                                      >
                                        {" "}
                                        <option value="">
                                          Выберите значение
                                        </option>
                                        {handleVariableOrFormulaCondition().map(
                                          (c) => (
                                            <option value={c}>{c}</option>
                                          )
                                        )}
                                      </select>
                                      <select
                                        className="calculatorSelectMoreOrLess"
                                        value={l.type}
                                        onChange={(e) => {
                                          let c = { ...calculator };
                                          c.stepsZakl[stepIndex].logic[
                                            ind
                                          ].type = e.target.value;
                                          setCalculator(c);
                                        }}
                                      >
                                        {defaultConditionsArray.map((c) => (
                                          <option value={c}>{c}</option>
                                        ))}
                                      </select>
                                      <input
                                        className="calculatorSelect"
                                        type="number"
                                        value={l.value}
                                        placeholder="число"
                                        onChange={(e) => {
                                          let c = { ...calculator };
                                          c.stepsZakl[stepIndex].logic[
                                            ind
                                          ].value = e.target.value;
                                          setCalculator(c);
                                        }}
                                      />
                                      <button
                                        className="svgButtonCalc"
                                        style={{
                                          display: "inline-block",
                                        }}
                                        onClick={() => {
                                          let c = { ...calculator };
                                          c.stepsZakl[stepIndex].logic.splice(
                                            ind,
                                            1
                                          );
                                          setCalculator(c);
                                        }}
                                      >
                                        <Icon title="delete" />
                                      </button>
                                    </p>
                                  )}
                                {ind < stepObject.logic.length - 1 && (
                                  <div
                                    style={{
                                      width: "50px",
                                      margin: "auto",
                                    }}
                                    className="pointer logicTypeSpan"
                                    onClick={() => {
                                      let c = { ...calculator };
                                      c.stepsZakl[stepIndex].logicType === "and"
                                        ? (c.stepsZakl[stepIndex].logicType =
                                            "or")
                                        : (c.stepsZakl[stepIndex].logicType =
                                            "and");
                                      setCalculator(c);
                                    }}
                                  >
                                    {stepObject.logicType === "and"
                                      ? "И"
                                      : "ИЛИ"}
                                  </div>
                                )}
                              </>
                            ))}{" "}
                          </div>

                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <TextareaAutosize
                                  value={stepObject.outputZakl}
                                  onChange={(e) => {
                                    let c = { ...calculator };
                                    c.stepsZakl[stepIndex].outputZakl =
                                      e.target.value;
                                    setCalculator(c);
                                  }}
                                  style={{
                                    width: "580px",
                                    height: "30px",
                                  }}
                                  placeholder="Текст в ЗАКЛЮЧЕНИЕ. Сюда можно вставлять значения переменных, расчёт формул, выбранные опции из тестиков или меню, для этого нажмите 'Добавить значение'"
                                />

                                <select
                                  className="calculatorSelect"
                                  value={"Добавить значение"}
                                  onChange={(e) => {
                                    let c = { ...calculator };
                                    c.stepsZakl[
                                      stepIndex
                                    ].outputZakl += `(${e.target.value})`;
                                    setCalculator(c);
                                  }}
                                >
                                  <option value="">Добавить значение</option>
                                  {handleAllValues().map((c) => (
                                    <option value={c}>{c}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            marginTop: "50px",
                          }}
                        >
                          <p
                            style={{
                              fontStyle: "italic",
                              color: "grey",
                            }}
                          >
                            Фильтров нет. Текст добавится по умолчанию
                          </p>
                          <div>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <TextareaAutosize
                                value={stepObject.outputZakl}
                                onChange={(e) => {
                                  let c = { ...calculator };
                                  c.stepsZakl[stepIndex].outputZakl =
                                    e.target.value;
                                  setCalculator(c);
                                }}
                                style={{
                                  width: "500px",
                                  height: "30px",
                                }}
                                placeholder="Текст ДЛЯ ЗАКЛЮЧЕНИЯ. Обозначьте, куда вставить результат расчёте, через '...' без кавычек"
                              />

                              <select
                                className="calculatorSelect"
                                value={"Добавить значение"}
                                onChange={(e) => {
                                  let c = { ...calculator };
                                  c.stepsZakl[
                                    stepIndex
                                  ].outputZakl += `(${e.target.value})`;
                                  setCalculator(c);
                                }}
                              >
                                <option value="">Добавить значение</option>
                                {handleAllValues().map((c) => (
                                  <option value={c}>{c}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                    </fieldset>
                  ) : (
                    <div
                      className="pointer notFocusedStep"
                      onClick={() => {
                        setOpenedStepZakl(stepIndex);
                      }}
                    >
                      <div
                        style={{
                          width: "70px",
                          marginRight: "7px",
                        }}
                      >
                        <p
                          style={{
                            margin: "8px",
                          }}
                        >
                          {`Шаг ${stepIndex + 1}`}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "280px",
                          textAlign: "center",
                          marginRight: "5px",
                        }}
                      >
                        {stepObject.logic.length === 0 && (
                          <span
                            style={{
                              fontStyle: "italic",
                              color: "grey",
                              width: "200px",
                            }}
                          >
                            Фильтров нет. Текст добавится по умолчанию
                          </span>
                        )}
                        {stepObject.logic.map((l, ind) => (
                          <div
                            style={{
                              padding: "1px",

                              width: "100%",
                              height: "100%",
                            }}
                          >
                            {l.type === "boolean" && (
                              <p
                                style={{
                                  display: "inline-block",
                                }}
                              >
                                {l.title}{" "}
                                <span className="orangeSpan">{l.value}</span>{" "}
                              </p>
                            )}
                            {l.type === "isDefined" && (
                              <p
                                style={{
                                  display: "inline-block",
                                }}
                              >
                                {" "}
                                Значение{" "}
                                <span className="orangeSpan">
                                  {l.title}
                                </span>{" "}
                                {l.value === "Да" ? (
                                  <span
                                    style={{
                                      color: "lightgreen",
                                    }}
                                  >
                                    указано
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    НЕ указано
                                  </span>
                                )}
                              </p>
                            )}
                            {l.type === "radio" && (
                              <p>
                                {" "}
                                {l.title}{" "}
                                <span className="orangeSpan">{l.value}</span>
                              </p>
                            )}{" "}
                            {l.type === "menu" && (
                              <p>
                                {" "}
                                {l.title}{" "}
                                <span className="orangeSpan">{l.value}</span>
                              </p>
                            )}{" "}
                            {l.type === "comparison" && (
                              <p>
                                <span
                                  style={{
                                    minWidth: "30px",
                                    color: "orange",
                                  }}
                                >
                                  {l.title}
                                </span>
                                <span
                                  style={{
                                    color: "dark",
                                    minWidth: "30px",
                                  }}
                                >
                                  {" "}
                                  {l.type2}{" "}
                                </span>
                                <span
                                  style={{
                                    minWidth: "30px",
                                    color: "orange",
                                  }}
                                >
                                  {l.title2}
                                </span>
                              </p>
                            )}
                            {l.type !== "boolean" &&
                              l.type !== "radio" &&
                              l.type !== "menu" &&
                              l.type !== "comparison" &&
                              l.type !== "isDefined" && (
                                <p>
                                  <span
                                    style={{
                                      minWidth: "30px",
                                      color: "orange",
                                    }}
                                  >
                                    {l.title}{" "}
                                  </span>
                                  <span
                                    style={{
                                      color: "dark",
                                      minWidth: "30px",
                                    }}
                                  >
                                    {l.type}{" "}
                                  </span>
                                  <span
                                    style={{
                                      minWidth: "30px",
                                      color: "darkgrey",
                                    }}
                                  >
                                    {l.value}
                                  </span>
                                </p>
                              )}
                            {/* {ind < stepObject.logic.length - 1 && "и"} */}
                            {ind < stepObject.logic.length - 1 && (
                              <div
                                style={{
                                  width: "50px",
                                  margin: "auto",
                                }}
                                className="pointer logicTypeSpan"
                              >
                                {stepObject.logicType === "and" ? "И" : "ИЛИ"}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          justifyItems: "center",
                          textAlign: "center",
                          width: "380px",
                        }}
                      >
                        <div
                          style={{
                            margin: "auto",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          {stepObject.outputZakl === ""
                            ? "-"
                            : stepObject.outputZakl}
                        </div>
                      </div>
                    </div>
                  )
                )}
            </div>
          </legend>
        </div>
      </div>
      {/* <div>
        <fieldset>
          <legend>Выход</legend>
          <button
            onClick={() => {
              makeCalculation();
            }}
          >
            Пощетат
          </button>
          {result && <p>ЦИФРА: {result}</p>}
          {resultString && <p>ОПИСАНИЕ: {resultString}</p>}
          {resultStringZakl && <p>ЗАКЛЮЧЕНИЕ: {resultStringZakl}</p>}
        </fieldset>
      </div> */}
    </div>
  );
};

export default ConstructorOfLogic;
