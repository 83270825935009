import { useEffect, useState, useRef } from "react";
import { useRecoilState } from "recoil";
import { useParams, useNavigate } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import "./RybaRedactor2.css";
import "./Popup.css";
import "./editing/NotificationWindow";
import { useAuthContext } from "../hooks/useAuthContext";
import { textState2 } from "../state/atoms/textareaText2";
import { fullRYBAatom2 } from "../state/atoms/fullRYBAatom2";
import AreYouSure from "./smallComponents/AreYouShure";
import { Tooltip } from "react-tooltip";
import { protocolsCT } from "../data/protocolsCT";
import { protocolsMRI } from "../data/protocolsMRI";
/// SVGs

import DeleteIcon from "./svg/DeleteIcon";
import ArrowDown from "./svg/ArrowDown";
import ArrowUp from "./svg/ArrowUp";
import Duplicate from "./svg/Duplicate";
import Dropdown from "./svg/Dropdown";
import CheckboxsVG from "./svg/CheckboxSVG";
import RadioSVG from "./svg/RadioSVG";
import ConstructorSVG from "./svg/ConstructorSVG";

/// components

import Notification from "./editing/NotificationWindow";
import ButtonsRedactor from "./smallComponents/buttonsRedactor";
import ImagesToTheRightRedactor from "./smallComponents/ImagesToTheRightRedactor";
import ZoomIn from "./svg/ZoomIn";
import ZoomOut from "./svg/ZoomOut";
import { notificationTheBest } from "../rootComponents/NotificationService";
import CloseButton from "./CloseButton";
import Icon from "./redactorComponents/icons/icons";
import OriginalButton from "../AIcomponents/OriginalButton";
import PressableButton3D from "../AIcomponents/OriginalButton";
import { settingsAtom } from "../state/atoms/settings";
import FindButton from "./editing/FindButton";

const Ryba = () => {
  const { user } = useAuthContext();
  // const [rybaObject, setRybaObject] = useState();
  const [rybaObject, setRybaObject] = useRecoilState(fullRYBAatom2);
  const [rybaObjectOriginal, setRybaObjectOriginal] = useState();
  const [text, setText] = useRecoilState(textState2); // recoil state of the textarea
  const { id } = useParams();
  const [organTitle, setOrganTitle] = useState("");
  const [notificationWindowOpen, setNotificationWindowOpen] = useState(false);
  // windows states
  const [isRybaShared, setIsRybaShared] = useState(false);
  const [editedSegment, setEditedSegment] = useState(0);
  const [editedOrgan, setEditedOrgan] = useState(2);
  const [originalRyba, setOriginalRyba] = useState(null);
  const [originalRybaJSON, setOriginalRybaJSON] = useState(null);
  const [areYouSureOpen, setAreYouSureOpen] = useState(false);
  const [modalityState, setModalityState] = useState(undefined);
  const [localStorageTXTtemp, setLocalStorageTXTtemp] = useState(undefined);
  const [saving, setSaving] = useState(false);
  const [organContextMenu, setOrganContextMenu] = useState(false);
  const [topOrganContextMenu, setTopOrganContextMenu] = useState(undefined);
  const [leftOrganContextMenu, setLeftOrganContextMenu] = useState(undefined);
  const [segmentContextMenu, setSegmentContextMenu] = useState(false);
  const [topSegmentContextMenu, setTopSegmentContextMenu] = useState(undefined);
  const [leftSegmentContextMenu, setLeftSegmentContextMenu] =
    useState(undefined);
  const [buttonContextMenu, setButtonContextMenu] = useState(false);
  const [topButtonContextMenu, setTopButtonContextMenu] = useState(undefined);
  const [leftButtonContextMenu, setLeftButtonContextMenu] = useState(undefined);
  const [contextHighlight, setContextHighlight] = useState();
  const [rightImagesRedactor, setRightImagesRedactor] = useState(false);
  const [zoom, setZoom] = useState();
  const [settings, setSettings] = useRecoilState(settingsAtom);
  const [findButtonOpen, setFindButtonOpen] = useState(false);
  const [scrollY, setScrollY] = useState();
  // window refs
  let organContextMenuRef = useRef();
  let segmentContextMenuRef = useRef();
  let buttonContextMenuRef = useRef();

  // variables for requests
  let notificationText = useRef();
  let newSegmentNorm = useRef("");
  let radioINDEX = useRef("");
  let buttonINDEX = useRef("");
  let segmentINDEX = useRef("");
  let organINDEX = useRef("");
  // useNavigate consts
  const navigate = useNavigate();

  useEffect(() => {
    const getFullRyba = async (req, res) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/full/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();
      if (response.status === 401) {
        window.localStorage.removeItem("user");
        window.location.reload();
      }
      const r = { ...json };
      let localStorageTXT = JSON.parse(localStorage.getItem(id));
      const z = JSON.parse(localStorage.getItem("zoomRedactor"));
      setZoom(z);
      if (!z) {
        setZoom(1);
      }
      /////////
      setIsRybaShared(r.shared);
      if (response.ok) {
        setRybaObject(r);
        setModalityState(r.modality);
        setOriginalRyba(r);
        setOriginalRybaJSON(JSON.stringify(r));
        const t = [];
        r.organs.map((o, organIndex) => {
          t.push([]);
          o.segments.map((s) => {
            t[organIndex].push(s.norm);
          });
          setText(t);
        });
        if (localStorageTXT) {
          setLocalStorageTXTtemp(localStorageTXT);
        }
      }
    };

    if (user) {
      getFullRyba();
    }
  }, [user]);

  // счётчики сегментов и текстовых полей рыбы
  let keyCounter = 0;

  /////////////////////////////////////////////////////////////////
  ////////////////////////// ⌨️ TEXTAREAS STATE ⌨️ //////////////////////////

  // update textState
  const updateTextState = () => {
    let r = { ...rybaObject };
    let t = [];
    r.organs.map((o, organIndex) => {
      t.push([]);
      o.segments.map((s) => {
        t[organIndex].push(s.norm);
      });
      setText(t);
    });
  };

  // handling RYBA title edit in textarea

  /////////////////////////////////////////////////////////////////
  ////////////////////////// ☎️ REQUESTS ☎️ //////////////////////////
  function setUsedToZero(obj) {
    // If the input is an object, iterate through its keys
    if (typeof obj === "object" && obj !== null) {
      for (let key in obj) {
        if (key === "used") {
          // Set the "used" property to 0
          obj[key] = 0;
        } else if (typeof obj[key] === "object") {
          // Recursively call the function for nested objects or arrays
          setUsedToZero(obj[key]);
        }
      }
    }
    return obj;
  }
  //////////////// button requests ////////////////////

  // DELETE button request
  const deleteButtonRequest = function (
    e,
    buttonIndex,
    segmentIndex,
    organIndex
  ) {
    const r = { ...rybaObject };
    r.organs[organIndex].segments[segmentIndex].buttons.splice(buttonIndex, 1);
    setRybaObject(r);
  };

  // CREATE checkbox
  const createNewCheckbox = function (e) {
    const newButton = {
      title: "Название кнопки",
      content: "Текст кнопки. ",
      zakl: "",
      type: "checkbox",
      used: 0,
      newline: true,
    };

    const r = { ...rybaObject };
    r.organs[organINDEX.current].segments[segmentINDEX.current].buttons.push(
      newButton
    );

    console.log(r);
    setRybaObject(r);
    notificationTheBest("Кнопка успешно создана");
  };

  const createNewConstructor = function (e) {
    const newButton = {
      title: "Кисты почек Bosniak I",
      content: "",
      zakl: "",
      type: "constructor",
      constructor: "renalCysts",
      options: undefined,
      newline: true,
    };

    const r = { ...rybaObject };
    r.organs[organINDEX.current].segments[segmentINDEX.current].buttons.push(
      newButton
    );

    console.log(r);
    setRybaObject(r);
    notificationTheBest("Кнопка успешно создана");
  };
  const createCalculator = function (e) {
    const calculatorDefault = {
      title: "Новый калькулятор",
      type: "calculator",
      description: "",
      newline: true,
      image: {
        URL: undefined,
        key: undefined,
        name: undefined,
      },
      used: 0,
      values: [],
      variables: [],
      formulas: [],
      booleans: [],
      radioArray: [],
      menuArray: [],
      steps: [
        {
          logic: [],
          logicType: "and",
          output: "",
          outputZakl: "",
        },
      ],
      stepsZakl: [
        {
          logic: [],
          logicType: "and",
          output: "",
          outputZakl: "",
        },
      ],
    };

    const r = { ...rybaObject };
    r.organs[organINDEX.current].segments[segmentINDEX.current].buttons.push(
      calculatorDefault
    );

    console.log(r);
    setRybaObject(r);
    notificationTheBest("Кнопка успешно создана");
  };

  const createNewRadio = function () {
    const newButton = {
      type: "radio",
      title: "Название группы кнопок",
      used: 0,
      newline: true,
      radios: [
        {
          title: "Название кнопки",
          content: "Текст кнопки. ",
          zakl: "",
        },
      ],
    };

    const r = { ...rybaObject };
    r.organs[organINDEX.current].segments[segmentINDEX.current].buttons.push(
      newButton
    );

    console.log(r);
    setRybaObject(r);
    notificationTheBest("Кнопка успешно создана");
  };

  const createNewSelect = function () {
    const newButton = {
      type: "select",
      title: "Название меню",
      used: 0,
      newline: true,
      options: [
        {
          title: "-",
          content: "",
          zakl: "",
        },
        {
          title: "Название опции",
          content: "Текст опции. ",
          zakl: "",
        },
      ],
    };

    const r = { ...rybaObject };
    r.organs[organINDEX.current].segments[segmentINDEX.current].buttons.push(
      newButton
    );

    console.log(r);
    setRybaObject(r);
    notificationTheBest("Кнопка успешно создана");
  };

  ///////////////////////////////   DUPLITE BUTTON    ////////////////////////////////////
  const duplicateButton = function (e) {
    // arr.splice(index, 0, item);
    const r = { ...rybaObject };
    const copiedButton = JSON.parse(
      JSON.stringify(
        r.organs[organINDEX.current].segments[segmentINDEX.current].buttons[
          buttonINDEX.current
        ]
      )
    );
    copiedButton.used = 0;
    r.organs[organINDEX.current].segments[segmentINDEX.current].buttons.splice(
      buttonINDEX.current,
      0,
      copiedButton
    );

    setRybaObject(r);

    notificationTheBest("Кнопка дублирована");
  };

  //////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////     segment requests      ////////////////////////////

  // CREATE segment request
  const createNewSegment = function (number) {
    const newSegment = {
      title: `Раздел ${rybaObject.organs[number].segments.length + 1}`,
      norm: "",
      buttons: [],
    };

    const r = { ...rybaObject };

    r.organs[organINDEX.current].segments.push(newSegment);
    setRybaObject(r);

    newSegmentNorm.current = "";
    // local txt
    if (localStorageTXTtemp) {
      let l = [...localStorageTXTtemp];
      l[organINDEX.current].push("");
      setLocalStorageTXTtemp(l);
    }
    //
  };

  // DELETE segment request
  const deleteSegmentRequest = function (e, segmentIndex, organIndex) {
    const r = { ...rybaObject };

    r.organs[organIndex].segments.splice(segmentIndex, 1);
    setEditedSegment((s) => {
      return s - 1;
    });

    setRybaObject(r);

    // local txt
    if (localStorageTXTtemp) {
      let l = [...localStorageTXTtemp];
      l[organIndex].splice(segmentIndex, 1);
      setLocalStorageTXTtemp(l);
    }
    //
  };

  ////////// DUPLICATE SEGMENT REQUEST //////////
  const duplicateSegment = function (e) {
    const r = { ...rybaObject };
    const copiedSegment = JSON.parse(
      JSON.stringify(
        r.organs[organINDEX.current].segments[segmentINDEX.current]
      )
    );
    r.organs[organINDEX.current].segments.splice(
      segmentINDEX.current,
      0,
      setUsedToZero(copiedSegment)
    );
    setRybaObject(r);
    updateTextState();
    // local txt
    if (localStorageTXTtemp) {
      let l = [...localStorageTXTtemp];
      l[organINDEX.current].splice(
        segmentINDEX.current,
        1,
        l[organINDEX.current][segmentINDEX.current]
      );
      setLocalStorageTXTtemp(l);
    }
    //
  };

  //////////////////////// ryba requests /////////////////////////

  const saveRybaChanges = async (req, res, ryba) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/pond/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          ...rybaObject,
        }),
      }
    );
    const json = await response.json();
    console.log(response);
    if (response.ok) {
      setSaving(false);
      notificationTheBest("Изменения успешно сохранены");
      console.log(json);
    }
    if (!response.ok) {
      console.log(json);
    }
  };

  // share RYBA
  const shareRyba = function (e) {
    const shareRyba = async (req, res) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            shared: `true`,
          }),
        }
      );
      const json = await response.json();
      if (response.ok) {
        console.log(json);
      }
    };
    shareRyba();
    setIsRybaShared(true);
  };

  // hide RYBA
  const hideRyba = function (e) {
    const hideR = async (req, res) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            shared: `false`,
          }),
        }
      );
      const json = await response.json();
      if (response.ok) {
        console.log(json);
      }
    };
    hideR();
    setIsRybaShared(false);
  };

  // delete ryba request

  const deleteRybaRequest = function (e) {
    const deleteRyba = async (req, res) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();

      if (response.ok) {
        console.log(json);
        navigateHome();
      }
    };
    deleteRyba();
  };

  // navigation functions

  const navigateHome = () => {
    navigate("/");
  };

  // notification function
  const notification = function (message) {
    notificationText.current = message;
    console.log(notificationText.current);
    setNotificationWindowOpen(true);
    setTimeout(() => {
      setNotificationWindowOpen(false);
    }, 1500);
  };

  // move UPWARD segment /////////////
  const moveSegmentUpward = (organIndex, segmentIndex) => {
    const r = { ...rybaObject };

    const a = r.organs[organIndex].segments[segmentIndex];
    const b = r.organs[organIndex].segments[segmentIndex - 1];
    r.organs[organIndex].segments[segmentIndex] = b;
    r.organs[organIndex].segments[segmentIndex - 1] = a;
    setRybaObject(r);
    notificationTheBest("Хоба");
    // local text state update
    if (localStorageTXTtemp) {
      const l = [...localStorageTXTtemp];
      const c = l[organIndex][segmentIndex];
      const d = l[organIndex][segmentIndex - 1];
      l[organIndex][segmentIndex] = d;
      l[organIndex][segmentIndex - 1] = c;
      setLocalStorageTXTtemp(l);
    }
    //
  };

  // move DOWNWARD segment

  const moveSegmentDownward = (organIndex, segmentIndex) => {
    const r = { ...rybaObject };

    const a = r.organs[organIndex].segments[segmentIndex];
    const b = r.organs[organIndex].segments[segmentIndex + 1];
    r.organs[organIndex].segments[segmentIndex] = b;
    r.organs[organIndex].segments[segmentIndex + 1] = a;
    setRybaObject(r);
    notificationTheBest("Хоба");
    // local text state update
    if (localStorageTXTtemp) {
      const l = [...localStorageTXTtemp];
      const c = l[organIndex][segmentIndex];
      const d = l[organIndex][segmentIndex + 1];
      l[organIndex][segmentIndex] = d;
      l[organIndex][segmentIndex + 1] = c;
      setLocalStorageTXTtemp(l);
    }
    //
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////// MOVE BUTTON UPWARD //////////////////////////////////////

  const moveButtonUpward = (organIndex, segmentIndex, buttonIndex) => {
    const r = { ...rybaObject };

    const a = r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex];
    const b =
      r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex - 1];
    r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex] = b;
    r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex - 1] = a;
    setRybaObject(r);
    notificationTheBest("Хоба");
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////// MOVE BUTTON DOWNWARD //////////////////////////////////////
  const moveButtonDownward = (organIndex, segmentIndex, buttonIndex) => {
    console.log(organIndex, segmentIndex, buttonIndex);

    const r = { ...rybaObject };

    const a = r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex];
    const b =
      r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex + 1];
    r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex] = b;
    r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex + 1] = a;
    setRybaObject(r);
    notificationTheBest("Хоба");
  };
  ////////////////////// ORGAN REQUESTS ///////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  // CREATE organ request
  const createOrgan = function (e) {
    const newOrgan = {
      title: "НОВЫЙ ОРГАН",
      segments: [
        {
          title: "Раздел 1",
          norm: "Патологических изменений не отмечено. ",
          buttons: [],
        },
      ],
    };

    const s = { ...rybaObject };

    s.organs.splice(s.organs.length - 1, 0, newOrgan);
    if (localStorageTXTtemp) {
      let l = [...localStorageTXTtemp];
      l.splice(s.organs.length - 1, 0, [newOrgan.segments[0].norm]);
      console.log(l);
      setLocalStorageTXTtemp(l);
    }
    setRybaObject(s);
    setEditedSegment(0);
    updateTextState();
    setOrganTitle("");

    setEditedOrgan(s.organs.length - 2);
  };

  // // DELETE organ request
  const deleteOrgan = function (e) {
    const r = { ...rybaObject };
    r.organs.splice(organINDEX.current, 1);

    setRybaObject(r);
    updateTextState();
    notificationTheBest("Орган удалён");
    if (localStorageTXTtemp) {
      const l = [...localStorageTXTtemp];
      l.splice(organINDEX.current, 1);
      setLocalStorageTXTtemp(l);
    }
  };

  // MOVE ORGAN UP
  const moveOrganUP = function () {
    const r = { ...rybaObject };
    const aboveOrganIndex = Number(organINDEX.current) - Number(1);
    const a = r.organs[organINDEX.current];
    const b = r.organs[aboveOrganIndex];

    r.organs[organINDEX.current] = b;
    r.organs[aboveOrganIndex] = a;
    // local text state update
    if (localStorageTXTtemp) {
      const l = [...localStorageTXTtemp];
      const c = l[organINDEX.current];
      const d = l[aboveOrganIndex];
      l[organINDEX.current] = d;
      l[aboveOrganIndex] = c;
      setLocalStorageTXTtemp(l);
    }
    //
    setRybaObject(r);
    updateTextState();

    notificationTheBest("Орган перемещён");
  };

  // MOVE ORGAN DOWN
  const moveOrganDOWN = function () {
    const r = { ...rybaObject };
    const belowOrganIndex = Number(organINDEX.current) + Number(1);
    const a = r.organs[organINDEX.current];
    const b = r.organs[belowOrganIndex];
    r.organs[organINDEX.current] = b;
    r.organs[belowOrganIndex] = a;
    // local text state update
    if (localStorageTXTtemp) {
      const l = [...localStorageTXTtemp];
      const c = l[organINDEX.current];
      const d = l[belowOrganIndex];
      l[organINDEX.current] = d;
      l[belowOrganIndex] = c;
      setLocalStorageTXTtemp(l);
    }
    //
    setRybaObject(r);
    updateTextState();

    notificationTheBest("Орган перемещён");
  };

  // DUPLICATE ORGAN
  const duplicateOrgan = function () {
    const r = { ...rybaObject };
    const copiedOrgan = JSON.parse(
      JSON.stringify(r.organs[organINDEX.current])
    );
    r.organs.splice(organINDEX.current, 0, setUsedToZero(copiedOrgan));
    // local text state update
    if (localStorageTXTtemp) {
      const l = [...localStorageTXTtemp];
      l.splice(organINDEX.current, 0, l[organINDEX.current]);
      setLocalStorageTXTtemp(l);
    }
    //
    setRybaObject(r);
    updateTextState();

    notificationTheBest("Орган дублирован");
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////

  const locateSegment = (organIndex, segmentIndex) => {
    organINDEX.current = organIndex;
    segmentINDEX.current = segmentIndex;
  };

  const locateButton = (organIndex, segmentIndex, buttonIndex) => {
    organINDEX.current = organIndex;
    segmentINDEX.current = segmentIndex;
    buttonINDEX.current = buttonIndex;
  };

  const closeOpenOrganContextMenu = (e) => {
    if (organContextMenu && !organContextMenuRef.current?.contains(e.target)) {
      setOrganContextMenu(false);
    }
    if (
      buttonContextMenu &&
      !buttonContextMenuRef.current?.contains(e.target)
    ) {
      setButtonContextMenu(false);
    }
    if (
      segmentContextMenu &&
      !segmentContextMenuRef.current?.contains(e.target)
    ) {
      setSegmentContextMenu(false);
    }
  };
  document.addEventListener("mousedown", closeOpenOrganContextMenu);

  const contextMenuButton = (e, organIndex, segmentIndex, buttonIndex) => {
    e.preventDefault();
    organINDEX.current = organIndex;
    segmentINDEX.current = segmentIndex;
    buttonINDEX.current = buttonIndex;
    setLeftButtonContextMenu(e.pageX);
    setTopButtonContextMenu(e.pageY);
    setButtonContextMenu(true);
  };

  const uploadImage = async (e, image) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image, image.name);
    console.log(formData);
    const response = await fetch(`https://api.defaultuploader.com/v1/upload`, {
      method: "POST",
      headers: {
        Authorization: `uuLsBfnWTEuSXX6mJCQm1A`,
      },
      body: formData,
    });

    console.log(response);
    const json = await response.json();
    if (response.ok) {
      console.log(json);
      console.log(response);

      let r = { ...rybaObject };
      r.images.push(json);
      console.log(r.images);
      setRybaObject(r);
    }
    if (!response.ok) {
      console.log(json);
      console.log(response);
    }
  };
  // universal patch request
  const updateSettings = async function (settings) {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/settings`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({ ...settings }),
      }
    );
    const json = await response.json();

    if (response.ok) {
      console.log("patched settings: ", json);
    }
  };

  const addButtonToRyba4 = function (title, content, zakl, alreadyMadeButton) {
    if (alreadyMadeButton) {
      const r = { ...rybaObject };
      r.organs[organINDEX.current].segments[segmentINDEX.current].buttons.push(
        alreadyMadeButton
      );
      setRybaObject(r);
    }
    if (!alreadyMadeButton) {
      const newButton = {
        title: title,
        content: content,
        zakl: zakl,
        type: "checkbox",
        newline: true,
      };

      const r = { ...rybaObject };
      r.organs[organINDEX.current].segments[segmentINDEX.current].buttons.push(
        newButton
      );
      setRybaObject(r);
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////   REACT RETURN   //////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div
      className="redactorContainer"
      style={{
        zoom: zoom,
      }}
    >
      {rightImagesRedactor && (
        <ImagesToTheRightRedactor
          {...{ rybaObject, setRybaObject, setRightImagesRedactor }}
        />
      )}
      {areYouSureOpen && (
        <AreYouSure {...{ setAreYouSureOpen, deleteRybaRequest }} />
      )}
      {findButtonOpen && (
        <FindButton
          {...{
            organINDEX,
            segmentINDEX,
            rybaObject,
            addButtonToRyba4,
            setFindButtonOpen,
            scrollY,
          }}
        />
      )}
      {organContextMenu && (
        <div
          className="contextMenu"
          ref={organContextMenuRef}
          style={{
            top: `${topOrganContextMenu}px`,
            left: `${leftOrganContextMenu}px`,
            zIndex: "50",
            width: "120px",
          }}
        >
          <button
            className="contextMenuButton"
            onClick={(e) => {
              e.preventDefault();
              notificationTheBest("Орган скопирован");
              window.localStorage.setItem(
                `copiedORGAN`,
                JSON.stringify(rybaObject.organs[organINDEX.current])
              );
              setOrganContextMenu(false);
              // navigate(`/editRyba2/${rybaOBJECT.current._id}`);
            }}
          >
            <Icon title="copy" />
            Копировать
          </button>
          <button
            className="contextMenuButton"
            onClick={(e) => {
              e.preventDefault();
              notificationTheBest("Орган скопирован");
              window.localStorage.setItem(
                `copiedORGAN`,
                JSON.stringify(rybaObject.organs[organINDEX.current])
              );

              deleteOrgan();
              setOrganContextMenu(false);
              // navigate(`/editRyba2/${rybaOBJECT.current._id}`);
            }}
          >
            <Icon title="cut" />
            Вырезать
          </button>
          <button
            className="contextMenuButton"
            onClick={(e) => {
              if (!JSON.parse(window.localStorage.getItem("copiedORGAN"))) {
                alert("Нет скопированных органов");
                return;
              }
              const copiedOrgan = JSON.parse(
                window.localStorage.getItem("copiedORGAN")
              );
              let r = { ...rybaObject };
              const length = r.organs.length - 1;
              r.organs.splice(
                organINDEX.current + 1,
                0,
                setUsedToZero(copiedOrgan)
              );
              setRybaObject(r);
              notificationTheBest("Орган из буфера обмена добавлен");
              if (localStorageTXTtemp) {
                let l = [...localStorageTXTtemp];
                let sa = [];
                copiedOrgan.segments.map((s) => {
                  sa.push(s.norm);
                });
                l.splice(organINDEX.current + 1, 0, sa);
                setLocalStorageTXTtemp(l);
              }
              setEditedOrgan((o) => o + 1);
              setOrganContextMenu(false);
            }}
          >
            <Icon title="pasteBelow" />
            Вставить ниже
          </button>

          <button
            className="contextMenuButton"
            onClick={(e) => {
              duplicateOrgan();
              setEditedOrgan((o) => o + 1);
              setEditedSegment(0);
              setOrganContextMenu(false);
            }}
          >
            <Icon title="duplicate" />
            Дублировать
          </button>
          {rybaObject.organs.length - 1 !== editedOrgan && (
            <button
              className="contextMenuButton"
              onClick={(e) => {
                organINDEX.current = editedOrgan;
                createNewSegment(editedOrgan);
                setOrganContextMenu(false);
                setEditedSegment(
                  (o) =>
                    (o = rybaObject.organs[editedOrgan].segments.length - 1)
                );
              }}
            >
              <Icon title="addSegment" />
              Добавить подраздел
            </button>
          )}
          {organINDEX.current > 2 && (
            <button
              className="contextMenuButton"
              onClick={(e) => {
                moveOrganUP();
                setEditedOrgan((o) => o - 1);
                setEditedSegment(0);
                setOrganContextMenu(false);
              }}
            >
              <Icon title="arrowUp" />
              Сдвинуть вверх
            </button>
          )}
          {organINDEX.current < rybaObject.organs.length - 2 && (
            // <div className="contextMenuButtonDiv">
            //   <div className="svgButtonDiv">
            //     <ArrowDown />
            //   </div>
            <button
              className="contextMenuButton"
              onClick={(e) => {
                moveOrganDOWN();
                setEditedOrgan((o) => o + 1);
                setEditedSegment(0);
                setOrganContextMenu(false);
              }}
            >
              <Icon title="arrowDown" />
              Сдвинуть вниз
            </button>
            // </div>
          )}

          <button
            className="contextMenuButton"
            onClick={(e) => {
              deleteOrgan();
              setOrganContextMenu(false);
            }}
          >
            <Icon title="delete" />
            Удалить
          </button>
        </div>
      )}
      {segmentContextMenu && editedOrgan !== rybaObject.organs.length - 1 && (
        <div
          className="contextMenu"
          ref={segmentContextMenuRef}
          style={{
            top: `${topSegmentContextMenu}px`,
            left: `${leftSegmentContextMenu}px`,
            zIndex: "50",
            width: "120px",
          }}
        >
          <button
            className="contextMenuButton"
            onClick={(e) => {
              notificationTheBest("Раздел скопирован");
              window.localStorage.setItem(
                `copiedSEGMENT`,
                JSON.stringify(
                  rybaObject.organs[editedOrgan].segments[editedSegment]
                )
              );
              setSegmentContextMenu(false);
              // navigate(`/editRyba2/${rybaOBJECT.current._id}`);
            }}
          >
            <Icon title="copy" />
            Копировать
          </button>
          <button
            className="contextMenuButton"
            onClick={(e) => {
              notificationTheBest("Раздел скопирован");
              window.localStorage.setItem(
                `copiedSEGMENT`,
                JSON.stringify(
                  rybaObject.organs[editedOrgan].segments[editedSegment]
                )
              );
              deleteSegmentRequest(e, editedSegment, editedOrgan);
              setSegmentContextMenu(false);
              // navigate(`/editRyba2/${rybaOBJECT.current._id}`);
            }}
          >
            <Icon title="cut" />
            Вырезать
          </button>
          <button
            className="contextMenuButton"
            onClick={(e) => {
              e.preventDefault();

              if (!window.localStorage.getItem("copiedSEGMENT")) {
                alert("В буфере обмена нет скопированных разделов");
                return;
              }

              let r = { ...rybaObject };
              const newSegment = JSON.parse(
                window.localStorage.getItem("copiedSEGMENT")
              );
              r.organs[editedOrgan].segments.splice(
                segmentINDEX.current + 1,
                0,
                setUsedToZero(newSegment)
              );
              notificationTheBest("Раздел из буфера обмена добавлен");
              setRybaObject(r);
              if (localStorageTXTtemp) {
                let l = [...localStorageTXTtemp];
                l[editedOrgan].splice(
                  segmentINDEX.current + 1,
                  0,
                  newSegment.norm
                );
                setLocalStorageTXTtemp(l);
              }
              setSegmentContextMenu(false);
            }}
          >
            <Icon title="pasteBelow" />
            Вставить ниже
          </button>

          <button
            className="contextMenuButton"
            onClick={(e) => {
              locateSegment(editedOrgan, editedSegment);
              duplicateSegment();
              setEditedSegment((o) => o + 1);
              setSegmentContextMenu(false);
            }}
          >
            <Icon title="duplicate" />
            Дублировать
          </button>
          {segmentINDEX.current > 0 && (
            <button
              className="contextMenuButton"
              onClick={(e) => {
                organINDEX.current = editedOrgan;
                segmentINDEX.current = editedSegment;
                moveSegmentUpward(editedOrgan, editedSegment);
                setEditedSegment((o) => o - 1);
                setSegmentContextMenu(false);
              }}
            >
              <Icon title="arrowUp" />
              Сдвинуть вверх
            </button>
          )}
          {segmentINDEX.current <
            rybaObject.organs[editedOrgan].segments.length - 1 && (
            <button
              className="contextMenuButton"
              onClick={(e) => {
                organINDEX.current = editedOrgan;
                segmentINDEX.current = editedSegment;
                moveSegmentDownward(editedOrgan, editedSegment);
                setEditedSegment((o) => o + 1);
                setSegmentContextMenu(false);
              }}
            >
              <Icon title="arrowDown" />
              Сдвинуть вниз
            </button>
          )}

          {rybaObject.organs[editedOrgan].segments.length > 1 && (
            <button
              className="contextMenuButton"
              onClick={(e) => {
                deleteSegmentRequest(e, editedSegment, editedOrgan);
                setSegmentContextMenu(false);
              }}
            >
              <Icon title="delete" />
              Удалить
            </button>
          )}
        </div>
      )}
      {buttonContextMenu && (
        <div
          className="contextMenu"
          ref={buttonContextMenuRef}
          style={{
            top: `${topButtonContextMenu}px`,
            left: `${leftButtonContextMenu}px`,
            zIndex: "50",
            width: "130px",
          }}
        >
          <button
            className="contextMenuButton"
            onClick={(e) => {
              notificationTheBest("Кнопка скопирована", 2000, 80);
              let r = { ...rybaObject };
              window.localStorage.setItem(
                `copiedBUTTON`,
                JSON.stringify(
                  r.organs[organINDEX.current].segments[segmentINDEX.current]
                    .buttons[buttonINDEX.current]
                )
              );
              setButtonContextMenu(false);
            }}
          >
            <Icon title="copy" />
            Копировать
          </button>
          <button
            className="contextMenuButton"
            onClick={(e) => {
              let r = { ...rybaObject };
              window.localStorage.setItem(
                `copiedBUTTON`,
                JSON.stringify(
                  r.organs[organINDEX.current].segments[segmentINDEX.current]
                    .buttons[buttonINDEX.current]
                )
              );
              deleteButtonRequest(
                e,
                buttonINDEX.current,
                segmentINDEX.current,
                organINDEX.current
              );
              setButtonContextMenu(false);
            }}
          >
            <Icon title="cut" />
            Вырезать
          </button>
          <button
            className="contextMenuButton"
            onClick={(e) => {
              e.preventDefault();
              if (!window.localStorage.getItem("copiedBUTTON")) {
                alert("В буфере обмена нет скопированных кнопок");
                return;
              }
              let r = { ...rybaObject };
              const newButton = JSON.parse(
                window.localStorage.getItem("copiedBUTTON")
              );
              r.organs[organINDEX.current].segments[
                segmentINDEX.current
              ].buttons.splice(
                buttonINDEX.current + 1,
                0,
                setUsedToZero(newButton)
              );
              notificationTheBest("Кнопка из буфера обмена добавлена");
              setRybaObject(r);
              setButtonContextMenu(false);
            }}
          >
            <Icon title="pasteBelow" />
            Вставить ниже
          </button>
          {rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
            .buttons[buttonINDEX.current].type === "radio" &&
            window.localStorage.getItem("copiedBUTTON") !== undefined && (
              <button
                className="contextMenuButton"
                onClick={(e) => {
                  if (!window.localStorage.getItem("copiedBUTTON")) {
                    alert("В буфере обмена нет скопированных кнопок");
                    return;
                  }
                  let r = { ...rybaObject };
                  const newButton = JSON.parse(
                    window.localStorage.getItem("copiedBUTTON")
                  );
                  delete newButton.type;
                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].radios.push(newButton);
                  notificationTheBest("Кнопка из буфера обмена добавлена");
                  setRybaObject(r);
                  setButtonContextMenu(false);
                }}
              >
                <Icon title="addToMenu" />
                Вставить кнопку в группу
              </button>
            )}
          {rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
            .buttons[buttonINDEX.current].type === "radio" &&
            window.localStorage.getItem("copiedBUTTON") !== undefined && (
              <button
                className="contextMenuButton"
                onClick={(e) => {
                  let r = { ...rybaObject };
                  console.log(
                    r.organs[organINDEX.current].segments[segmentINDEX.current]
                      .buttons[buttonINDEX.current]
                  );
                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].type = "select";
                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].options =
                    r.organs[organINDEX.current].segments[
                      segmentINDEX.current
                    ].buttons[buttonINDEX.current].radios;
                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].options.unshift({
                    title: "-",
                    content: "",
                    zakl: "",
                  });
                  delete r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].radios;
                  setRybaObject(r);
                  setButtonContextMenu(false);
                  notificationTheBest("Трансформировано успешно");
                }}
              >
                <Icon title={"transformToMenu"} />
                Превратить группу в меню
              </button>
            )}
          {rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
            .buttons[buttonINDEX.current].type === "select" &&
            window.localStorage.getItem("copiedBUTTON") !== undefined && (
              <button
                className="contextMenuButton"
                onClick={(e) => {
                  let r = { ...rybaObject };
                  console.log(
                    r.organs[organINDEX.current].segments[segmentINDEX.current]
                      .buttons[buttonINDEX.current]
                  );
                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].type = "radio";
                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].radios =
                    r.organs[organINDEX.current].segments[
                      segmentINDEX.current
                    ].buttons[buttonINDEX.current].options;
                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].radios.shift();
                  delete r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].options;
                  setRybaObject(r);
                  setButtonContextMenu(false);
                  notificationTheBest("Трансформировано успешно");
                }}
              >
                <Icon title={"transformToMenu"} />
                Превратить меню в группу
              </button>
            )}
          {rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
            .buttons[buttonINDEX.current].type === "select" &&
            window.localStorage.getItem("copiedBUTTON") !== undefined && (
              <button
                className="contextMenuButton"
                onClick={(e) => {
                  if (!window.localStorage.getItem("copiedBUTTON")) {
                    alert("В буфере обмена нет скопированных кнопок");
                    return;
                  }
                  let r = { ...rybaObject };
                  const newButton = JSON.parse(
                    window.localStorage.getItem("copiedBUTTON")
                  );
                  delete newButton.type;
                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].options.push(newButton);
                  notificationTheBest("Кнопка из буфера обмена добавлена");
                  setRybaObject(r);
                  setButtonContextMenu(false);
                }}
              >
                <Icon title="addToMenu" />
                Вставить кнопку в меню
              </button>
            )}

          <button
            className="contextMenuButton"
            onClick={(e) => {
              duplicateButton();
              setButtonContextMenu(false);
            }}
          >
            <Icon title="duplicate" />
            Дублировать
          </button>
          {buttonINDEX.current > 0 && (
            <button
              className="contextMenuButton"
              onClick={(e) => {
                moveButtonUpward(
                  organINDEX.current,
                  segmentINDEX.current,
                  buttonINDEX.current
                );
                setButtonContextMenu(false);
              }}
            >
              <Icon title="arrowUp" />
              Сдвинуть вверх
            </button>
          )}
          {buttonINDEX.current <
            rybaObject.organs[editedOrgan].segments[editedSegment].buttons
              .length -
              1 && (
            <button
              className="contextMenuButton"
              onClick={(e) => {
                moveButtonDownward(
                  organINDEX.current,
                  segmentINDEX.current,
                  buttonINDEX.current
                );
                setButtonContextMenu(false);
              }}
            >
              <Icon title="arrowDown" />
              Сдвинуть вниз
            </button>
          )}
          {!Object.hasOwn(
            rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
              .buttons[buttonINDEX.current],
            "choices"
          ) &&
            rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
              .buttons[buttonINDEX.current].type === "checkbox" &&
            rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
              .buttons[buttonINDEX.current].type !== "constructor" && (
              <button
                className="contextMenuButton"
                onClick={(e) => {
                  let r = { ...rybaObject };
                  const button =
                    r.organs[organINDEX.current].segments[segmentINDEX.current]
                      .buttons[buttonINDEX.current];

                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].choices = [[]];

                  if (button.type === "radio") {
                    r.organs[organINDEX.current].segments[
                      segmentINDEX.current
                    ].buttons[buttonINDEX.current].radios.map((r) => {
                      r.choices = [[]];
                    });
                  }
                  if (button.type === "select") {
                    r.organs[organINDEX.current].segments[
                      segmentINDEX.current
                    ].buttons[buttonINDEX.current].options.map((o) => {
                      o.choices = [[]];
                    });
                  }
                  console.log(r);
                  notificationTheBest("Поле опций добавлено");
                  setRybaObject(r);
                  setButtonContextMenu(false);
                }}
              >
                <Icon title="addChoices" />
                Добавить опции для описания
              </button>
            )}

          {Object.hasOwn(
            rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
              .buttons[buttonINDEX.current],
            "choices"
          ) &&
            rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
              .buttons[buttonINDEX.current].type === "checkbox" && (
              <button
                className="contextMenuButton"
                onClick={(e) => {
                  let r = { ...rybaObject };
                  let button =
                    r.organs[organINDEX.current].segments[segmentINDEX.current]
                      .buttons[buttonINDEX.current];
                  if (button.type === "checkbox") {
                    delete r.organs[organINDEX.current].segments[
                      segmentINDEX.current
                    ].buttons[buttonINDEX.current].choices;
                  }
                  if (button.type === "radio") {
                    r.organs[organINDEX.current].segments[
                      segmentINDEX.current
                    ].buttons[buttonINDEX.current].radios.map((r) => {
                      delete r.choices;
                    });
                  }
                  if (button.type === "select") {
                    r.organs[organINDEX.current].segments[
                      segmentINDEX.current
                    ].buttons[buttonINDEX.current].options.map((o) => {
                      delete o.choices;
                    });
                  }
                  delete r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].choices;
                  notificationTheBest("Поле опций удалено");
                  setRybaObject(r);
                  setButtonContextMenu(false);
                }}
              >
                <Icon title="deleteChoices" />
                Удалить опции для описания
              </button>
            )}
          {!Object.hasOwn(
            rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
              .buttons[buttonINDEX.current],
            "choicesZakl"
          ) &&
            rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
              .buttons[buttonINDEX.current].type === "checkbox" && (
              <button
                className="contextMenuButton"
                onClick={(e) => {
                  let r = { ...rybaObject };

                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].choicesZakl = [[]];

                  console.log(r);
                  notificationTheBest("Поле опций добавлено");
                  setRybaObject(r);
                  setButtonContextMenu(false);
                }}
              >
                <Icon title="addChoicesZakl" />
                Добавить опции для заключения
              </button>
            )}

          {Object.hasOwn(
            rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
              .buttons[buttonINDEX.current],
            "choicesZakl"
          ) &&
            rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
              .buttons[buttonINDEX.current].type === "checkbox" && (
              <button
                className="contextMenuButton"
                onClick={(e) => {
                  let r = { ...rybaObject };
                  let button =
                    r.organs[organINDEX.current].segments[segmentINDEX.current]
                      .buttons[buttonINDEX.current];
                  if (button.type === "checkbox") {
                    delete r.organs[organINDEX.current].segments[
                      segmentINDEX.current
                    ].buttons[buttonINDEX.current].choicesZakl;
                  }
                  if (button.type === "radio") {
                    r.organs[organINDEX.current].segments[
                      segmentINDEX.current
                    ].buttons[buttonINDEX.current].radios.map((r) => {
                      delete r.choicesZakl;
                    });
                  }
                  if (button.type === "select") {
                    r.organs[organINDEX.current].segments[
                      segmentINDEX.current
                    ].buttons[buttonINDEX.current].options.map((o) => {
                      delete o.choicesZakl;
                    });
                  }
                  delete r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].choicesZakl;
                  notificationTheBest("Поле опций удалено");
                  setRybaObject(r);
                  setButtonContextMenu(false);
                }}
              >
                <Icon title="deleteChoicesZakl" />
                Удалить опции для заключения
              </button>
            )}

          <button
            className="contextMenuButton redOnHover"
            onClick={(e) => {
              deleteButtonRequest(
                e,
                buttonINDEX.current,
                segmentINDEX.current,
                organINDEX.current
              );
              setButtonContextMenu(false);
              notificationTheBest("Кнопка удалена");
            }}
          >
            <Icon title="delete" />
            Удалить
          </button>
        </div>
      )}
      {rybaObject && modalityState && (
        <div>
          <div className="topButtons" key={(keyCounter += 1)}>
            <div className="titleEditing">
              <TextareaAutosize
                value={rybaObject.title}
                onChange={(e) => {
                  console.log(e.target.value);
                  let r = { ...rybaObject };
                  r.title = e.target.value;
                  setRybaObject(r);
                }}
              ></TextareaAutosize>
            </div>

            <div
              className="rybaModalityProtocolShareDiv"
              style={{
                marginLeft: "10px",
              }}
            >
              <div>
                <label>
                  <span
                    style={{
                      display: "block",
                    }}
                  >
                    Протокол
                  </span>

                  {modalityState === "КТ" && (
                    <select
                      value={rybaObject.protocol}
                      className="protocolSelectSELECT"
                      onChange={(e) => {
                        let r = { ...rybaObject };
                        r.protocol = e.target.value;
                        setRybaObject(r);
                      }}
                      name="selectProtocol"
                      id="2"
                      style={{
                        width: "200px",
                        textAlign: "left",
                      }}
                    >
                      <option value="">Выберите протокол</option>
                      {protocolsCT.map((p) => (
                        <option value={p}>{p}</option>
                      ))}
                    </select>
                  )}
                  {modalityState === "МРТ" && (
                    <select
                      style={{
                        width: "200px",
                        textAlign: "left",
                      }}
                      value={rybaObject.protocol}
                      className="protocolSelectSELECT"
                      onChange={(e) => {
                        let r = { ...rybaObject };
                        r.protocol = e.target.value;
                        setRybaObject(r);
                      }}
                      name="selectProtocol"
                      id="3"
                    >
                      <option value="">Выберите протокол</option>
                      {protocolsMRI.map((p) => (
                        <option value={p}>{p}</option>
                      ))}
                    </select>
                  )}
                </label>
              </div>
              <div>
                <label>
                  {" "}
                  <span
                    style={{
                      display: "block",
                    }}
                  >
                    Модальность
                  </span>
                  <select
                    value={rybaObject.modality}
                    className="protocolSelectSELECT"
                    onChange={(e) => {
                      let r = { ...rybaObject };
                      r.modality = e.target.value;
                      setRybaObject(r);
                    }}
                    name="selectProtocol"
                    id="2"
                  >
                    <option value="">Выберите модальность</option>
                    <option value="КТ">КТ</option>
                    <option value="МРТ">МРТ</option>
                    <option value="Классика">Классика</option>
                    <option value="УЗИ">УЗИ</option>
                    <option value="Маммография">Маммография</option>
                    <option value="ПЭТ">ПЭТ</option>
                  </select>
                </label>
              </div>
              <div>
                <label
                  style={{
                    display: "block",
                  }}
                >
                  <span
                    style={{
                      display: "block",
                    }}
                  >
                    Делиться шаблоном
                  </span>
                  <select
                    value={rybaObject.shared}
                    onChange={(e) => {
                      let r = { ...rybaObject };
                      r.shared = e.target.value;
                      setRybaObject(r);
                    }}
                    className="protocolSelectSELECT"
                  >
                    <option value={false}>Нет</option>
                    <option value={true}>Да</option>
                  </select>
                </label>
              </div>
              {rybaObject === originalRyba && (
                <div
                  className="saveButtonsDiv"
                  style={{
                    display: "inline-block",
                  }}
                >
                  <button
                    disabled={true}
                    style={{
                      backgroundColor: rybaObject === originalRyba && "grey",
                      marginTop: "6px",
                      height: "47px",
                      width: "195px",
                      fontWeight: "100",
                      background: "transparent",
                      color: "darkgrey",
                      fontSize: "15px",
                      cursor: "default",
                    }}
                    className="bigSaveButton"
                    onClick={() => {
                      setSaving(true);
                      // window.localStorage.removeItem(id);
                      saveRybaChanges();

                      setOriginalRyba(rybaObject);
                      if (localStorageTXTtemp) {
                        window.localStorage.setItem(
                          `${id}`,
                          JSON.stringify(localStorageTXTtemp)
                        );
                      }

                      // setTimeout(() => {
                      //   navigate(`/primary/${id}`);
                      // }, 1000);
                    }}
                  >
                    Нет изменений для сохранения
                  </button>
                </div>
              )}
              {rybaObject !== originalRyba && (
                <div
                  className="saveButtonsDiv"
                  style={{
                    display: "inline-block",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: rybaObject === originalRyba && "grey",
                      marginTop: "6px",
                      height: "47px",
                      width: "195px",
                      fontSize: "19px",
                      fontWeight: "100",
                    }}
                    className="bigSaveButton"
                    onClick={() => {
                      setSaving(true);
                      saveRybaChanges();

                      setOriginalRyba(rybaObject);
                      if (localStorageTXTtemp) {
                        window.localStorage.setItem(
                          `${id}`,
                          JSON.stringify(localStorageTXTtemp)
                        );
                      }

                      // setTimeout(() => {
                      //   navigate(`/primary/${id}`);
                      // }, 1000);
                    }}
                  >
                    {!saving &&
                      rybaObject === originalRyba &&
                      "Нечего сохранять"}
                    {!saving && rybaObject !== originalRyba && "СОХРАНИТЬ"}
                    {saving && "Сохраняется..."}
                  </button>
                </div>
              )}
              <div
                className="saveButtonsDiv"
                style={{
                  display: "inline-block",
                }}
              >
                <button
                  style={{
                    backgroundColor: "grey",
                    marginTop: "6px",
                    height: "47px",
                    width: "195px",
                    fontSize: "17px",
                    fontWeight: "100",
                    paddingTop: rybaObject !== originalRyba && "3px",
                  }}
                  className="bigSaveButton"
                  onClick={() => {
                    setRybaObject(undefined);
                    navigate(`/primary/${id}`);
                  }}
                >
                  {rybaObject === originalRyba && "Вернутья к шаблону"}
                  {rybaObject !== originalRyba && "Закрыть без сохранения"}
                </button>
              </div>
            </div>

            {/* {!isRybaShared && (
              <>
                <p className="shareRybaP">
                  Этот шаблон приватный. Хотите поделиться шаблоном с коллегами
                  в общий доступ?
                </p>
                <button onClick={shareRyba} className="shareRybaButton">
                  Поделиться
                </button>
              </>
            )}
            {isRybaShared && (
              <>
                <p className="shareRybaP">
                  Спасибо, что поделились свои шаблоном! Коллеги будут Вам
                  благодарны.
                </p>
                <button onClick={hideRyba} className="shareRybaButton">
                  Снова сделать шаблон приватным
                </button>
              </>
            )} */}
            {/* <div className="imagesUploadDiv">
           

              <button
                onClick={() => {
                  setRightImagesRedactor(true);
                }}
              >
                Открыть редактор шпаргалки
              </button>
            </div> */}
            {/* <div
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
              }}
            >
              {rybaObject === originalRyba && (
                <CloseButton
                  onClick={() => {
                    // window.localStorage.removeItem(id);
                    setRybaObject(undefined);
                    navigate(`/primary/${id}`);
                  }}
                  disabled={saving}
                  size={40}
                  color="#888"
                  hoverColor="#ff4d4d"
                />
              )}
              {rybaObject !== originalRyba && (
                <CloseButton
                  onClick={() => {
                    window.localStorage.removeItem(id);
                    setRybaObject(undefined);
                    navigate(`/primary/${id}`);
                  }}
                  size={40}
                  color="#888"
                  hoverColor="#ff4d4d"
                />
              )}
            </div> */}
          </div>

          <div className="rooDivEdit">
            <div className="rybaRedactorNew" key={(keyCounter += 1)}>
              <>
                {notificationWindowOpen && (
                  <Notification notificationText={notificationText.current} />
                )}

                <div className="RYBA4segmentsContainerEditRIGHT">
                  {/* <p
                    style={{
                      margin: "0px",
                      textAlign: "left",
                      paddingLeft: "14px",
                      fontSize: "22px",
                      fontWeight: "100",
                      color: "darkgrey",
                    }}
                  >
                    Органы
                  </p>
                  <hr /> */}
                  {rybaObject.organs.map((organ, organIndex) =>
                    editedOrgan === organIndex ? (
                      <div
                        className="organEdit"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          className="organTitleEdit"
                          onContextMenu={(e) => {
                            e.preventDefault();
                            if (
                              organIndex < 2 ||
                              organIndex === rybaObject.organs.length - 1
                            ) {
                              return;
                            }
                            organINDEX.current = organIndex;
                            setEditedOrgan(organIndex);
                            setEditedSegment(0);
                            setLeftOrganContextMenu(e.pageX);
                            setTopOrganContextMenu(e.pageY);
                            setOrganContextMenu(true);
                          }}
                        >
                          <TextareaAutosize
                            className={`organTitleTextarea ${
                              editedOrgan === organIndex
                                ? "whiteBorder"
                                : "notWhiteBorder"
                            }`}
                            autoFocus
                            value={rybaObject.organs[organIndex].title}
                            onChange={(e) => {
                              console.log(e.target.value);
                              let r = { ...rybaObject };
                              r.organs[organIndex].title =
                                e.target.value.toUpperCase();
                              setRybaObject(r);
                            }}
                          ></TextareaAutosize>
                        </div>
                        {rybaObject.organs[organIndex].segments.length > 1 && (
                          <div
                            className="rybaRedactorNew"
                            key={(keyCounter += 1)}
                            style={{
                              padding: "0px",
                              width: "220px",
                              marginLeft: "35px",
                              marginTop: "15px",
                              marginBottom: "15px",
                            }}
                          >
                            <>
                              {notificationWindowOpen && (
                                <Notification
                                  notificationText={notificationText.current}
                                />
                              )}

                              <div className="RYBA4segmentsContainerEditRIGHT">
                                {/* <p
                          style={{
                            margin: "0px",
                            textAlign: "left",
                            paddingLeft: "14px",
                            fontSize: "19px",
                            fontWeight: "100",
                            color: "darkgrey",
                          }}
                        >
                          Разделы
                        </p>
                        <hr
                          style={{
                            border: "0.5px solid grey",
                            width: "94%",
                          }}
                        /> */}
                                {rybaObject.organs[editedOrgan].segments &&
                                  rybaObject.organs[editedOrgan].segments
                                    .length === 0 && (
                                    <>
                                      <p>
                                        Разделов нет. Нажмите "добавить раздел"
                                      </p>
                                    </>
                                  )}

                                {rybaObject.organs[editedOrgan].segments.map(
                                  (segment, segmentIndex) =>
                                    editedSegment === segmentIndex ? (
                                      <div className="organEdit">
                                        <div
                                          className="organTitleEdit"
                                          onContextMenu={(e) => {
                                            e.preventDefault();
                                            organINDEX.current = editedOrgan;
                                            segmentINDEX.current = segmentIndex;
                                            setEditedSegment(segmentIndex);
                                            setLeftSegmentContextMenu(e.pageX);
                                            setTopSegmentContextMenu(e.pageY);
                                            setSegmentContextMenu(true);
                                          }}
                                        >
                                          <TextareaAutosize
                                            className={`organTitleTextarea ${
                                              editedSegment === segmentIndex
                                                ? "whiteBorder segmentHighlight"
                                                : "notWhiteBorder"
                                            }`}
                                            autoFocus
                                            style={{
                                              fontSize: "18px",
                                              fontWeight: "100",
                                              outline:
                                                segmentIndex ===
                                                  editedSegment &&
                                                "2px solid #ffbd03",
                                              background:
                                                segmentIndex ===
                                                  editedSegment && "#e6dfc8",
                                            }}
                                            value={
                                              rybaObject.organs[editedOrgan]
                                                .segments[editedSegment].title
                                            }
                                            onChange={(e) => {
                                              if (
                                                editedOrgan ===
                                                rybaObject.organs.length - 1
                                              ) {
                                                return;
                                              }
                                              console.log(e.target.value);
                                              let r = { ...rybaObject };
                                              r.organs[editedOrgan].segments[
                                                editedSegment
                                              ].title = e.target.value;
                                              setRybaObject(r);
                                            }}
                                          ></TextareaAutosize>
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        className="organTitleEdit"
                                        onContextMenu={(e) => {
                                          e.preventDefault();

                                          organINDEX.current = editedOrgan;
                                          segmentINDEX.current = segmentIndex;
                                          setEditedSegment(segmentIndex);
                                          setLeftSegmentContextMenu(e.pageX);
                                          setTopSegmentContextMenu(e.pageY);
                                          setSegmentContextMenu(true);
                                        }}
                                      >
                                        <TextareaAutosize
                                          className="organTitleTextareaNOTEDITED"
                                          style={{
                                            fontSize: "18px",
                                            fontWeight: "100",
                                          }}
                                          value={
                                            rybaObject.organs[editedOrgan]
                                              .segments[segmentIndex].title
                                          }
                                          onFocus={(e) =>
                                            setEditedSegment(segmentIndex)
                                          }
                                          onChange={(e) => {
                                            console.log(e.target.value);
                                            let r = { ...rybaObject };
                                            r.organs[editedOrgan].segments[
                                              editedSegment
                                            ].title = e.target.value;
                                            setRybaObject(r);
                                          }}
                                        ></TextareaAutosize>
                                      </div>
                                      //   <div className="notEditedSegment">
                                      //   <p
                                      //     style={{
                                      //       textAlign: "center",
                                      //     }}
                                      //     onClick={(e) => setEditedOrgan(organIndex)}
                                      //   >
                                      //     {organ.title}
                                      //   </p>
                                      // </div>
                                    )
                                )}

                                {rybaObject.organs.length - 1 !==
                                  editedOrgan && (
                                  <div
                                    className="topButtonsRedactor"
                                    key={(keyCounter += 1)}
                                  >
                                    <button
                                      className="addNewOrgan"
                                      onClick={(e) => {
                                        organINDEX.current = editedOrgan;
                                        createNewSegment(editedOrgan);
                                        setEditedSegment(
                                          (o) =>
                                            (o =
                                              rybaObject.organs[editedOrgan]
                                                .segments.length - 1)
                                        );
                                      }}
                                      style={{
                                        width: "205px",
                                        fontSize: "15px",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      ➕ Создать новый раздел
                                    </button>
                                  </div>
                                )}
                              </div>
                            </>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="organTitleEdit">
                        <TextareaAutosize
                          className="organTitleTextareaNOTEDITED"
                          value={rybaObject.organs[organIndex].title}
                          onFocus={(e) => {
                            setEditedOrgan(organIndex);
                            setEditedSegment(0);
                          }}
                          onChange={(e) => {
                            console.log(e.target.value);
                            let r = { ...rybaObject };
                            r.organs[organIndex].title =
                              e.target.value.toUpperCase();
                            setRybaObject(r);
                          }}
                        ></TextareaAutosize>
                      </div>
                      //   <div className="notEditedSegment">
                      //   <p
                      //     style={{
                      //       textAlign: "center",
                      //     }}
                      //     onClick={(e) => setEditedOrgan(organIndex)}
                      //   >
                      //     {organ.title}
                      //   </p>
                      // </div>
                    )
                  )}

                  <div className="topButtonsRedactor" key={(keyCounter += 1)}>
                    <button
                      className="addNewOrgan"
                      onClick={() => {
                        createOrgan();
                      }}
                    >
                      ➕ Создать новый орган
                    </button>
                  </div>
                </div>
              </>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {rybaObject.organs[editedOrgan].segments.length > 1 && (
                <div className="forSummary">
                  <div className="finalOrganText">
                    <div>
                      {editedOrgan !== 0 &&
                        `${rybaObject.organs[editedOrgan].title}: `}
                      {rybaObject.organs[editedOrgan].segments.map(
                        (s, index) => (
                          <span
                            style={{
                              color: index === editedSegment && "#ffbd03",
                            }}
                          >
                            {s.norm.trim() + " "}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div className="organRedactor">
                    <div className="RYBA4segmentsContainerEdit">
                      {rybaObject.organs.map(
                        (organ, organIndex) =>
                          editedOrgan === organIndex && (
                            <div className="organEdit">
                              <div className="organSegmentsEdit">
                                {organ.segments &&
                                  organ.segments.map(
                                    (segment, segmentIndex) =>
                                      segmentIndex === editedSegment && (
                                        <div
                                          className="sgmntEdit"
                                          key={(keyCounter += 1)}
                                          index={segmentIndex}
                                        >
                                          <div className="segmentTitleDivEdit">
                                            <div
                                              style={{
                                                width: "100%",
                                                paddingTop: "8px",
                                              }}
                                            >
                                              <TextareaAutosize
                                                className="segmentTextTextareaEdit"
                                                placeholder="-"
                                                style={{
                                                  outline:
                                                    rybaObject.organs[
                                                      organIndex
                                                    ].segments.length > 1
                                                      ? "4px solid #ffbd03"
                                                      : "2px solid darkgrey",
                                                  background:
                                                    segmentIndex ===
                                                      editedSegment &&
                                                    "#f0ecdb",
                                                }}
                                                value={
                                                  rybaObject.organs[organIndex]
                                                    .segments[segmentIndex].norm
                                                }
                                                onChange={(e) => {
                                                  const r = { ...rybaObject };
                                                  r.organs[organIndex].segments[
                                                    segmentIndex
                                                  ].norm = e.target.value;
                                                  setRybaObject(r);
                                                }}
                                              ></TextareaAutosize>
                                            </div>
                                          </div>

                                          <div className="">
                                            {segment.buttons.length !== 0 && (
                                              <div
                                                key={
                                                  rybaObject.organs[organIndex]
                                                    .segments
                                                }
                                              >
                                                {segment.buttons.map(
                                                  (button, buttonIndex) => (
                                                    <ButtonsRedactor
                                                      {...{
                                                        segment,
                                                        button,
                                                        rybaObject,
                                                        organIndex,
                                                        segmentIndex,
                                                        buttonIndex,
                                                        setRybaObject,
                                                        deleteButtonRequest,
                                                        locateButton,
                                                        duplicateButton,
                                                        moveButtonUpward,
                                                        moveButtonDownward,
                                                        notification,
                                                        contextMenuButton,
                                                        buttonContextMenu,
                                                        contextHighlight,
                                                        setContextHighlight,
                                                        originalRyba,
                                                        originalRybaJSON,
                                                        setSaving,
                                                        saveRybaChanges,
                                                        setOriginalRyba,
                                                        localStorageTXTtemp,
                                                        id,
                                                      }}
                                                    />
                                                  )
                                                )}
                                                <div
                                                  style={{
                                                    textAlign: "center",
                                                    color: "grey",
                                                    // border: "0.5px solid grey",
                                                    width: "250px",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                    padding: "10px",
                                                    fontWeight: "100",
                                                  }}
                                                >
                                                  <div>
                                                    <button
                                                      className="button-1"
                                                      onClick={() => {
                                                        segmentINDEX.current =
                                                          segmentIndex;
                                                        organINDEX.current =
                                                          organIndex;
                                                        createNewCheckbox();
                                                        if (
                                                          !settings.achievements
                                                            .firstButton
                                                        ) {
                                                          let s = {
                                                            ...settings,
                                                          };
                                                          s.achievements.firstButton = true;
                                                          setSettings(s);
                                                          updateSettings(s);
                                                          window.achievement(
                                                            "ПЕРВЫЕ ШАГИ",
                                                            "Вы успешно создали первую в своей жизни кнопку, так держать",
                                                            "https://swift.timeweb.cloud/v1/cd5c3c6d-7735d894-d309-4ef5-87a5-a3d5741fd815/achievements/firstSteps.gif"
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      <span className="button-1-shadow"></span>
                                                      <span className="button-1-edge"></span>
                                                      <span className="button-1-front text">
                                                        Создать кнопку
                                                      </span>
                                                    </button>
                                                  </div>
                                                  <div className="addButtonsDiv">
                                                    <button
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                      className="buttonCreateButton"
                                                      onClick={() => {
                                                        segmentINDEX.current =
                                                          segmentIndex;
                                                        organINDEX.current =
                                                          organIndex;
                                                        createNewRadio();
                                                      }}
                                                      data-tooltip-id="radio"
                                                      data-tooltip-delay-show="300"
                                                    >
                                                      <RadioSVG
                                                        width={32}
                                                        height={32}
                                                        color={"black"}
                                                      />
                                                      <Tooltip
                                                        id="radio"
                                                        content="Добавить группу кнопок (кнопки, обведённые в рамку с названием; поможет визуально связать общие по смыслу штуки)"
                                                        place="bottom"
                                                        style={{
                                                          background: "black",
                                                          zIndex: "90",
                                                        }}
                                                      />
                                                      {/* Создать радиокнопку */}
                                                    </button>
                                                    <button
                                                      className="buttonCreateButton"
                                                      onClick={() => {
                                                        segmentINDEX.current =
                                                          segmentIndex;
                                                        organINDEX.current =
                                                          organIndex;
                                                        createNewSelect();
                                                      }}
                                                      data-tooltip-id="dropdown"
                                                      data-tooltip-delay-show="300"
                                                    >
                                                      {" "}
                                                      <Dropdown
                                                        width={32}
                                                        height={32}
                                                        color={"black"}
                                                      />
                                                      <Tooltip
                                                        id="dropdown"
                                                        content="Добавить меню (кнопки, сгруппированные в выпадающее меню для компактности"
                                                        place="bottom"
                                                        style={{
                                                          background: "black",
                                                          zIndex: "90",
                                                        }}
                                                      />
                                                      {/* Создать селект */}
                                                    </button>

                                                    <button
                                                      className="buttonCreateButton"
                                                      onClick={() => {
                                                        segmentINDEX.current =
                                                          segmentIndex;
                                                        organINDEX.current =
                                                          organIndex;
                                                        createCalculator();
                                                      }}
                                                      data-tooltip-id="constructor"
                                                      data-tooltip-delay-show="300"
                                                    >
                                                      {" "}
                                                      <ConstructorSVG
                                                        width={32}
                                                        height={32}
                                                        color={"black"}
                                                      />
                                                      <Tooltip
                                                        id="constructor"
                                                        content="Создать калькулятор (суперкнопка с кучей функций, вершина рыбной пищевой цепи)"
                                                        place="bottom"
                                                        style={{
                                                          background: "black",
                                                          zIndex: "90",
                                                        }}
                                                      />
                                                      {/* Добавить конструктор */}
                                                    </button>
                                                    <button
                                                      className="buttonCreateButton"
                                                      onClick={() => {
                                                        let r = {
                                                          ...rybaObject,
                                                        };

                                                        r.organs[
                                                          organIndex
                                                        ].segments[
                                                          segmentIndex
                                                        ].buttons.push(
                                                          JSON.parse(
                                                            window.localStorage.getItem(
                                                              "copiedBUTTON"
                                                            )
                                                          )
                                                        );
                                                        setRybaObject(r);
                                                        notificationTheBest(
                                                          "Кнопка из буфера обмена добавлена"
                                                        );
                                                      }}
                                                      data-tooltip-id="dcsdcs"
                                                      data-tooltip-delay-show="300"
                                                      style={{
                                                        width: "120px",
                                                      }}
                                                    >
                                                      <Tooltip
                                                        id="dcsdcs"
                                                        content="Вставит ранее скопированную кнопку"
                                                        place="bottom"
                                                        style={{
                                                          background: "black",
                                                          zIndex: "90",
                                                        }}
                                                      />
                                                      Вставить скопированную
                                                    </button>
                                                  </div>
                                                  <div
                                                    className="searchButtonDiv pointer"
                                                    onClick={(e) => {
                                                      setScrollY(
                                                        window.scrollY
                                                      );
                                                      organINDEX.current =
                                                        organIndex;
                                                      segmentINDEX.current =
                                                        segmentIndex;
                                                      setFindButtonOpen(true);
                                                    }}
                                                  >
                                                    <Icon
                                                      title="buttonSearch"
                                                      fill="#ededed"
                                                      width={36}
                                                      height={36}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                            {segment.buttons.length === 0 && (
                                              <div
                                                style={{
                                                  textAlign: "center",

                                                  color: "grey",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    textAlign: "center",
                                                    color: "grey",
                                                    // border: "0.5px solid grey",
                                                    width: "250px",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                    padding: "10px",
                                                    fontWeight: "100",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      textAlign: "center",
                                                      paddingBottom: "10px",
                                                      color: "grey",
                                                    }}
                                                  >
                                                    <span>
                                                      Кнопок ещё нет. Нажмите
                                                      "Создать кнопку"
                                                    </span>
                                                  </div>

                                                  <div>
                                                    <button
                                                      className="button-1"
                                                      onClick={() => {
                                                        segmentINDEX.current =
                                                          segmentIndex;
                                                        organINDEX.current =
                                                          organIndex;
                                                        createNewCheckbox();
                                                      }}
                                                    >
                                                      <span className="button-1-shadow"></span>
                                                      <span className="button-1-edge"></span>
                                                      <span className="button-1-front text">
                                                        Создать кнопку
                                                      </span>
                                                    </button>
                                                  </div>
                                                  <div className="addButtonsDiv">
                                                    <button
                                                      style={{
                                                        paddingLeft: "2px",
                                                      }}
                                                      className="buttonCreateButton"
                                                      onClick={() => {
                                                        segmentINDEX.current =
                                                          segmentIndex;
                                                        organINDEX.current =
                                                          organIndex;
                                                        createNewRadio();
                                                      }}
                                                      data-tooltip-id="radio"
                                                      data-tooltip-delay-show="300"
                                                    >
                                                      <RadioSVG
                                                        width={32}
                                                        height={32}
                                                        color={"black"}
                                                      />
                                                      <Tooltip
                                                        id="radio"
                                                        content="Добавить группу кнопок (кнопки, обведённые в рамку с названием; поможет визуально связать общие по смыслу штуки)"
                                                        place="bottom"
                                                        style={{
                                                          background: "black",
                                                          zIndex: "90",
                                                        }}
                                                      />
                                                      {/* Создать радиокнопку */}
                                                    </button>
                                                    <button
                                                      className="buttonCreateButton"
                                                      onClick={() => {
                                                        segmentINDEX.current =
                                                          segmentIndex;
                                                        organINDEX.current =
                                                          organIndex;
                                                        createNewSelect();
                                                      }}
                                                      data-tooltip-id="dropdown"
                                                      data-tooltip-delay-show="300"
                                                    >
                                                      {" "}
                                                      <Dropdown
                                                        width={32}
                                                        height={32}
                                                        color={"black"}
                                                      />
                                                      <Tooltip
                                                        id="dropdown"
                                                        content="Добавить меню (кнопки, сгруппированные в выпадающее меню для компактности"
                                                        place="bottom"
                                                        style={{
                                                          background: "black",
                                                          zIndex: "90",
                                                        }}
                                                      />
                                                      {/* Создать селект */}
                                                    </button>

                                                    <button
                                                      className="buttonCreateButton"
                                                      onClick={() => {
                                                        segmentINDEX.current =
                                                          segmentIndex;
                                                        organINDEX.current =
                                                          organIndex;
                                                        createCalculator();
                                                      }}
                                                      data-tooltip-id="constructor"
                                                      data-tooltip-delay-show="300"
                                                    >
                                                      {" "}
                                                      <ConstructorSVG
                                                        width={32}
                                                        height={32}
                                                        color={"black"}
                                                      />
                                                      <Tooltip
                                                        id="constructor"
                                                        content="Создать калькулятор (суперкнопка с кучей функций, вершина рыбной пищевой цепи)"
                                                        place="bottom"
                                                        style={{
                                                          background: "black",
                                                          zIndex: "90",
                                                        }}
                                                      />
                                                      {/* Добавить конструктор */}
                                                    </button>
                                                    <button
                                                      className="buttonCreateButton"
                                                      onClick={() => {
                                                        let r = {
                                                          ...rybaObject,
                                                        };
                                                        const length =
                                                          r.organs[organIndex]
                                                            .segments[
                                                            segmentIndex
                                                          ].buttons.length;
                                                        r.organs[
                                                          organIndex
                                                        ].segments[
                                                          segmentIndex
                                                        ].buttons.push(
                                                          JSON.parse(
                                                            window.localStorage.getItem(
                                                              "copiedBUTTON"
                                                            )
                                                          )
                                                        );
                                                        setRybaObject(r);
                                                        notificationTheBest(
                                                          "Кнопка из буфера обмена добавлена"
                                                        );
                                                      }}
                                                      data-tooltip-id="dcsdcs"
                                                      data-tooltip-delay-show="300"
                                                      style={{
                                                        width: "120px",
                                                      }}
                                                    >
                                                      <Tooltip
                                                        id="dcsdcs"
                                                        content="Вставит ранее скопированную кнопку"
                                                        place="bottom"
                                                        style={{
                                                          background: "black",
                                                          zIndex: "90",
                                                        }}
                                                      />
                                                      Вставить скопированную
                                                    </button>
                                                  </div>
                                                  <div
                                                    className="searchButtonDiv pointer"
                                                    onClick={(e) => {
                                                      setScrollY(
                                                        window.scrollY
                                                      );
                                                      organINDEX.current =
                                                        organIndex;
                                                      segmentINDEX.current =
                                                        segmentIndex;
                                                      setFindButtonOpen(true);
                                                    }}
                                                  >
                                                    <Icon
                                                      title="buttonSearch"
                                                      fill="#ededed"
                                                      width={36}
                                                      height={36}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )
                                  )}
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Ryba;
