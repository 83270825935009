import React from "react";
import styled, { keyframes } from "styled-components";

// Keyframes for animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

// Styled component for the Tag
const TagWrapper = styled.div`
  display: inline-block;
  padding: 4px 8px;
  margin: 4px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: ${(props) => (props.isSelected ? "#51abff" : "#5555")};
  color: ${(props) => (props.isSelected ? "#ffffff" : "darkgrey")};
  animation: ${fadeIn} 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.isSelected ? "#4a5568" : "#4a5568")};
    color: #ffffff;
  }
`;

// Tag Component
const Tag = ({ tags, setTags, title }) => {
  const isSelected = tags.includes(title);

  const handleClick = () => {
    if (isSelected) {
      // Remove the tag if it's already in the array
      setTags(tags.filter((tag) => tag !== title));
    } else {
      // Add the tag if it's not in the array
      setTags([...tags, title]);
    }
  };

  return (
    <TagWrapper isSelected={isSelected} onClick={handleClick}>
      {title}
    </TagWrapper>
  );
};

export default Tag;
