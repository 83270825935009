import React from "react";
import "./HowToImport.css";

const HowToImport = ({
  setHowToImport,
  loadStandardRybaWithoutButtons,
  loadStandardRyba,
}) => {
  return (
    <div className="modal-overlay" onClick={() => setHowToImport(false)}>
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
        style={{
          textAlign: "center",
        }}
      >
        <h2>Как вы хотите импортировать шаблон?</h2>
        <button
          className="primary-button"
          onClick={() => {
            loadStandardRyba();
            setHowToImport(false);
          }}
        >
          Импортировать шаблон вместе со стандартными кнопками (рекомендуется)
        </button>
        <button
          className="secondary-button"
          onClick={() => {
            loadStandardRybaWithoutButtons();
            setHowToImport(false);
          }}
        >
          Импортировать только скелет шаблона с нормой без кнопок (можете
          сделать все кнопки с нуля под себя, не придётся удалять старые)
        </button>
        <button
          className="secondary-button"
          style={{
            width: "250px",
            textAlign: "center",
          }}
          onClick={() => {
            setHowToImport(false);
          }}
        >
          Отмена
        </button>
      </div>
    </div>
  );
};

export default HowToImport;
