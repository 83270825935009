import React, { useState, useEffect } from "react";
import "./Achievements.css";
import Icon from "../components/redactorComponents/icons/icons";

const Achievements = () => {
  const [achievements, setAchievements] = useState([]);

  const addAchievement = (title, text, image) => {
    setAchievements((prevAchievements) => [
      ...prevAchievements,
      { title, text, image, id: Date.now() },
    ]);
  };

  const removeAchievement = (id) => {
    setAchievements((prevAchievements) =>
      prevAchievements.filter((achievement) => achievement.id !== id)
    );
  };

  // Make the addAchievement function globally accessible
  useEffect(() => {
    window.achievement = addAchievement;

    return () => {
      // Clean up the global function when the component unmounts
      delete window.achievement;
    };
  }, []);

  return (
    <div className="achievements-container">
      {achievements.map((achievement) => (
        <div key={achievement.id} className="achievement-item">
          <div class="pyro">
            <div class="before"></div>
            <div class="after"></div>
          </div>
          <button
            className="close-button"
            onClick={() => removeAchievement(achievement.id)}
          >
            <Icon title="close" fill="black" />
          </button>
          <p className="achievementTop">Достижение ⭐️</p>
          <h3 className="achievement-title">{achievement.title}</h3>
          <img
            src={achievement.image}
            alt="Achievement"
            className="achievement-image"
          />
          <p className="achievement-text">{achievement.text}</p>
        </div>
      ))}
    </div>
  );
};

export default Achievements;
