import DeleteIcon from "../svg/DeleteIcon";
import ArrowUp from "../svg/ArrowUp";
import ArrowDown from "../svg/ArrowDown";
import Duplicate from "../svg/Duplicate";
import TextareaAutosize from "react-textarea-autosize";
import Dropdown from "../svg/Dropdown";
import CheckboxsVG from "../svg/CheckboxSVG";
import RadioSVG from "../svg/RadioSVG";
import ConstructorSVG from "../svg/ConstructorSVG";
import { Tooltip } from "react-tooltip";
import { useEffect, useState, useRef } from "react";
import RadioRedactor from "./buttonsRedactorVariants/RadioRedactor";
import CheckboxRedactor from "./buttonsRedactorVariants/CheckboxRedactor";
import ConstructorRedactor from "./buttonsRedactorVariants/ConstructorRedactor";
import SelectRedactor from "./buttonsRedactorVariants/SelectRedactor";
import Img from "./Img";
import { useAuthContext } from "../../hooks/useAuthContext";
import ChoicesConstructor from "./buttonsRedactorVariants/ChoicesConstructor";
import ChoicesConstructorZakl from "./buttonsRedactorVariants/ChoicesConstructorZakl";
import { notificationTheBest } from "../../rootComponents/NotificationService";
import AreYouSureGeneric from "./AreYouShureGeneric";
import CalculatorRedactor from "./buttonsRedactorVariants/CalculatorRedactor";
const lodash = require("lodash");

const ButtonsRedactor = ({
  segment,
  button,
  rybaObject,
  organIndex,
  segmentIndex,
  buttonIndex,
  setRybaObject,
  deleteButtonRequest,
  locateButton,
  duplicateButton,
  moveButtonUpward,
  moveButtonDownward,
  notification,
  contextMenuButton,
  buttonContextMenu,
  rybaObjectOriginal,
  contextHighlight,
  setContextHighlight,
  originalRyba,
  originalRybaJSON,
  setSaving,
  saveRybaChanges,
  setOriginalRyba,
  localStorageTXTtemp,
  id,
}) => {
  const [addingOption, setAddingOption] = useState(false);
  const [optionText, setOptionText] = useState();
  const [whichOption, setWhichOption] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { user } = useAuthContext();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [buttonChanged, setButtonChanged] = useState(false);
  const [changedButton, setChangedButton] = useState(
    rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex]
  );
  const [originalButton, setOriginalButton] = useState();
  const [mounted, setMounted] = useState(false);
  const [changedButtonIndex, setChangedButtonIndex] = useState();
  const [areYouSureOpen, setAreYouSureOpen] = useState(false);
  const [calculatorEditorOpen, setCalculatorEditorOpen] = useState(false);

  useEffect(() => {
    if (
      JSON.stringify(
        JSON.parse(originalRybaJSON).organs[organIndex]?.segments[segmentIndex]
          ?.buttons[buttonIndex] !== undefined
      ) &&
      rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex]
        .length !== 0 &&
      JSON.stringify(
        rybaObject.organs[organIndex].segments[segmentIndex].buttons[
          buttonIndex
        ]
      ) !==
        JSON.stringify(
          JSON.parse(originalRybaJSON).organs[organIndex]?.segments[
            segmentIndex
          ]?.buttons[buttonIndex]
        )
    ) {
      setButtonChanged(true);
    }
  }, [rybaObject]);

  const applyToAllButtons = (previousButton, currentButton) => {
    const applyToAllButtons = async (req, res) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/applytoallbuttons`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            previousButton,
            currentButton,
          }),
        }
      );
      const json = await response.json();
      console.log(response);
      if (response.ok) {
        notificationTheBest("Долетело");
        console.log(json);
      }
      if (!response.ok) {
        console.log(json);
      }
    };
    applyToAllButtons();
  };

  const constructorsArray = [
    "Объём печени",
    "Спленомегалия",
    "Гидроторакс",
    "Объём простаты",
    "Объём яичника",
    "Объём яичка",
    "Индекс вымывания (надпочечники)",
    "Калькулятор стеноза артерии",
    "Стеноз ВСА по ECST и NASCET",
  ];

  const uploadImageToButton = async (e, image) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image);
    console.log(formData);
    const response = await fetch(`https://api.defaultuploader.com/v1/upload`, {
      method: "POST",
      headers: {
        Authorization: `uuLsBfnWTEuSXX6mJCQm1A`,
      },
      body: formData,
    });

    console.log(response);
    const json = await response.json();
    if (response.ok) {
      json[0].author = user.id;
      console.log(json);
      console.log(response);
      let r = { ...rybaObject };
      r.organs[organIndex].segments[segmentIndex].buttons[
        buttonIndex
      ].images.push(json[0]);
      setRybaObject(r);
      setLoading(false);
    }
    if (!response.ok) {
      console.log(json);
      console.log(response);
    }
  };

  const deleteImageToButton = async (e, pathOriginal, img, index) => {
    if (user.id !== img.author) {
      let r = { ...rybaObject };
      r.organs[organIndex].segments[segmentIndex].buttons[
        buttonIndex
      ].images.splice(index, 1);
      setRybaObject(r);
      setDeleting(false);
    }
    const response = await fetch(
      `https://api.defaultuploader.com/v1/upload/${pathOriginal}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `uuLsBfnWTEuSXX6mJCQm1A`,
        },
      }
    );

    const json = await response;
    if (response.ok) {
      console.log("успешно удалено, джесон: ", json);
      console.log("успешно удалено, сам респонс: ", response);
      let r = { ...rybaObject };
      r.organs[organIndex].segments[segmentIndex].buttons[
        buttonIndex
      ].images.splice(index, 1);
      setRybaObject(r);
      setDeleting(false);
    }
    if (!response.ok) {
      console.log(json);
      console.log(response);
    }
  };

  const setChoices = (
    buttonType,
    choicesObject,
    organIndex,
    segmentIndex,
    buttonIndex,
    radioIndex,
    optionIndex
  ) => {
    let r = { ...rybaObject };
    if (buttonType === "checkbox") {
      r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex].choices =
        choicesObject;
    }
    if (buttonType === "radio") {
      r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex].radios[
        radioIndex
      ].choices = choicesObject;
    }
    if (buttonType === "select") {
      r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex].options[
        optionIndex
      ].choices = choicesObject;
    }
    setRybaObject(r);
  };

  const setChoicesZakl = (
    buttonType,
    choicesObject,
    organIndex,
    segmentIndex,
    buttonIndex,
    radioIndex,
    optionIndex
  ) => {
    let r = { ...rybaObject };
    if (buttonType === "checkbox") {
      r.organs[organIndex].segments[segmentIndex].buttons[
        buttonIndex
      ].choicesZakl = choicesObject;
    }
    if (buttonType === "radio") {
      r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex].radios[
        radioIndex
      ].choicesZakl = choicesObject;
    }
    if (buttonType === "select") {
      r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex].options[
        optionIndex
      ].choicesZakl = choicesObject;
    }
    setRybaObject(r);
  };

  function createNewObject(obj) {
    const newObj = {};

    if (obj.hasOwnProperty("title")) {
      newObj.title = obj.title;
    }

    if (obj.hasOwnProperty("type")) {
      newObj.type = obj.type;
    }

    if (obj.hasOwnProperty("content")) {
      newObj.content = obj.content;
    }

    if (obj.hasOwnProperty("radios")) {
      newObj.radios = obj.radios;
    }

    if (obj.hasOwnProperty("zakl")) {
      newObj.zakl = obj.zakl;
    }

    if (obj.hasOwnProperty("options")) {
      newObj.options = obj.options;
    }

    if (obj.hasOwnProperty("choices")) {
      newObj.choices = obj.choices;
    }

    if (obj.hasOwnProperty("choicesZakl")) {
      newObj.choicesZakl = obj.choicesZakl;
    }

    return newObj;
  }

  return (
    <div
      className="buttonAndContent"
      onContextMenu={(e) => {
        contextMenuButton(e, organIndex, segmentIndex, buttonIndex);
        setContextHighlight(buttonIndex);
      }}
      style={{
        opacity: buttonContextMenu && contextHighlight === buttonIndex && "0.5",
        // outline: buttonChanged && "1px solid orange",
      }}
    >
      {" "}
      <div className="editDiv2">
        <div className="buttonItself" index={buttonIndex}>
          {button.type === "radio" && (
            <RadioSVG
              width={48}
              height={48}
              color={buttonChanged ? "orange" : "white"}
            />
          )}
          {button.type === "checkbox" && (
            <CheckboxsVG
              width={48}
              height={48}
              color={buttonChanged ? "orange" : "white"}
            />
          )}
          {button.type === "select" && (
            <Dropdown
              width={48}
              height={48}
              color={buttonChanged ? "orange" : "white"}
            />
          )}
          {button.type === "constructor" && (
            <ConstructorSVG
              width={32}
              height={32}
              color={buttonChanged ? "orange" : "white"}
            />
          )}{" "}
          {button.type === "calculator" && (
            <ConstructorSVG
              width={32}
              height={32}
              color={buttonChanged ? "orange" : "white"}
            />
          )}
        </div>
      </div>
      <div className="buttonContentDiv">
        <div className="buttonTITLEradioORselect">
          {buttonChanged && (
            <button
              className="applyToAllButtons"
              onClick={() => {
                setAreYouSureOpen(true);
              }}
            >
              Применить изменения ко всем кнопкам
            </button>
          )}
          {areYouSureOpen && (
            <AreYouSureGeneric
              setAreYouSureOpen={setAreYouSureOpen}
              shureFunction={() => {
                if (
                  rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ].type !== "calculator"
                ) {
                  applyToAllButtons(
                    createNewObject(
                      JSON.parse(originalRybaJSON).organs[organIndex].segments[
                        segmentIndex
                      ].buttons[buttonIndex]
                    ),
                    createNewObject(
                      rybaObject.organs[organIndex].segments[segmentIndex]
                        .buttons[buttonIndex]
                    )
                  );
                } else {
                  applyToAllButtons(
                    {
                      ...JSON.parse(originalRybaJSON).organs[organIndex]
                        .segments[segmentIndex].buttons[buttonIndex],
                    },
                    {
                      ...rybaObject.organs[organIndex].segments[segmentIndex]
                        .buttons[buttonIndex],
                    }
                  );
                }
              }}
              text={
                "Это действие применит изменения ко ВСЕМ таким же кнопкам во всех Ваших шаблонах, продолжить?"
              }
            />
          )}
          <TextareaAutosize
            value={
              rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                buttonIndex
              ].title
            }
            onChange={(e) => {
              if (button.type === "constructor") {
                return;
              }
              let r = { ...rybaObject };
              r.organs[organIndex].segments[segmentIndex].buttons[
                buttonIndex
              ].title = e.target.value;
              setRybaObject(r);
            }}
          ></TextareaAutosize>{" "}
          <label>
            Добавлять текст с новой строки
            <input
              type="checkbox"
              checked={
                rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                  buttonIndex
                ].newline
              }
              onChange={(e) => {
                let r = { ...rybaObject };
                r.organs[organIndex].segments[segmentIndex].buttons[
                  buttonIndex
                ].newline = e.target.checked;
                setRybaObject(r);
              }}
            />
          </label>
        </div>
        {button.type === "checkbox" && (
          <CheckboxRedactor
            {...{
              rybaObject,
              organIndex,
              segmentIndex,
              buttonIndex,
              setRybaObject,
              setChoices,
              setChoicesZakl,
            }}
          />
        )}{" "}
        {button.type === "radio" && (
          <RadioRedactor
            {...{
              button,
              rybaObject,
              organIndex,
              segmentIndex,
              buttonIndex,
              setRybaObject,
              notification,
              setChoices,
              setChoicesZakl,
            }}
          />
        )}{" "}
        {button.type === "select" && (
          <SelectRedactor
            {...{
              button,
              rybaObject,
              organIndex,
              segmentIndex,
              buttonIndex,
              setRybaObject,
              notification,
              setChoices,
              setChoicesZakl,
            }}
          />
        )}{" "}
        {button.type === "constructor" && (
          <ConstructorRedactor
            {...{
              rybaObject,
              organIndex,
              segmentIndex,
              buttonIndex,
              setRybaObject,
              constructorsArray,
              setSaving,
              saveRybaChanges,
              setOriginalRyba,
              localStorageTXTtemp,
              id,
            }}
          />
        )}{" "}
        {button.type === "calculator" && (
          <div>
            {!calculatorEditorOpen && (
              <button
                onClick={() => {
                  setCalculatorEditorOpen(true);
                }}
              >
                Редактировать конструктор
              </button>
            )}
            {calculatorEditorOpen && (
              <CalculatorRedactor
                {...{
                  rybaObject,
                  organIndex,
                  segmentIndex,
                  buttonIndex,
                  setRybaObject,
                  constructorsArray,
                  button,
                  setCalculatorEditorOpen,
                  setSaving,
                  saveRybaChanges,
                  setOriginalRyba,
                  localStorageTXTtemp,
                  id,
                }}
              />
            )}
          </div>
        )}
        {/* <div
          style={{
            display: "flex",
          }}
        >
          <div>
            {Object.hasOwn(button, "choices") && button.type === "checkbox" && (
              <ChoicesConstructor
                buttonItself={
                  rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ]
                }
                setChoices={setChoices}
                buttonType={"checkbox"}
                organIndex={organIndex}
                segmentIndex={segmentIndex}
                buttonIndex={buttonIndex}
                radioIndex={undefined}
                optionIndex={undefined}
              />
            )}
          </div>
          <div>
            {Object.hasOwn(button, "choicesZakl") &&
              button.type === "checkbox" && (
                <ChoicesConstructorZakl
                  buttonItself={
                    rybaObject.organs[organIndex].segments[segmentIndex]
                      .buttons[buttonIndex]
                  }
                  setChoicesZakl={setChoicesZakl}
                  buttonType={"checkbox"}
                  organIndex={organIndex}
                  segmentIndex={segmentIndex}
                  buttonIndex={buttonIndex}
                  radioIndex={undefined}
                  optionIndex={undefined}
                />
              )}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ButtonsRedactor;
