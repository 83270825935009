import { useEffect, useState, useRef } from "react";
import { rybaTitleAtom } from "../state/atoms/rybaTitle";
import { useRecoilState } from "recoil";
import RybaCreate from "./editing/RybaCreate";
import { useAuthContext } from "../hooks/useAuthContext";
import "./RybaListModality.css";

import {
  protocolsMRI,
  mskArray,
  neuroArray,
  spinalArray,
  abdArray,
  uroGinoArray,
} from "../data/protocolsMRI";
import { Link, useNavigate } from "react-router-dom";
import AreYouSureGeneric from "./smallComponents/AreYouShureGeneric";
import ModalityRybaList from "./smallComponents/modalityRybaList";
import RybaRow from "./smallComponents/rybaRow";
import { notificationTheBest } from "../rootComponents/NotificationService";
import HowToImport from "../AIcomponents/HowToImport";

const RybaListMRI2 = () => {
  const [rybaList, setRybaList] = useState(null);
  const [standardRybas, setStandardRybas] = useState(null);
  const [msk, setMsk] = useState(null);
  const [abd, setAbd] = useState(null);
  const [neuro, setNeuro] = useState(null);
  const [uroGino, setUroGino] = useState(null);
  const [other, setOther] = useState(null);
  const [openRybaCreateWindow, setOpenRybaCreateWindow] = useState(false);
  const [modalityState, setModalityState] = useState(null);
  const [rybaTitleEdit, setRybaTitleEdit] = useRecoilState(rybaTitleAtom);
  const { user } = useAuthContext();
  const [loaded, setLoaded] = useState(false);
  const [openContext, setOpenContext] = useState(false);
  const [areYouSureOpen, setAreYouSureOpen] = useState(false);
  const [functionProps, setFunctionProps] = useState();
  const [top, setTop] = useState();
  const [left, setLeft] = useState();
  const [rybaContext, setRybaContext] = useState();
  const [howToImport, setHowToImport] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  // ref variables
  let modality = useRef("");
  let protocol = useRef("");
  let rybaID = useRef();
  let rybaOBJECT = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchRybaList = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/?modality=МРТ`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();
      if (response.status === 401) {
        window.localStorage.removeItem("user");
        window.location.reload();
      }
      if (response.ok) {
        setRybaList(json.rybas);
        setStandardRybas(json.standard);
        setLoaded(true);
      }
    };
    if (user) {
      fetchRybaList();
    }
    setModalityState("МРТ");
  }, [user]);

  // delete ryba request

  const deleteRybaRequest = function () {
    const deleteRyba = async (req, res) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/${rybaOBJECT.current._id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();

      if (response.ok) {
        console.log(json);
      }
      let l = [...rybaList];
      console.log(l.indexOf(rybaOBJECT.current));
      l.splice(l.indexOf(rybaOBJECT.current), 1);
      setRybaList(l);
    };
    deleteRyba();
  };
  // duplicate ryba request

  const duplicateRyba = async (ryba) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/pond/duplicate/${ryba._id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const json = await response.json();

    if (response.ok) {
      console.log(json);
      let l = [...rybaList];
      l.splice(l.indexOf(ryba), 0, json);
      setRybaList(l);
    }
  };

  const saveRybaChanges = async (ryba) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/pond/${ryba._id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          ...ryba,
        }),
      }
    );
    const json = await response.json();
    if (response.ok) {
      console.log(json);
    }
  };

  let keyCounter = 0;
  // handle ryba title input change
  const titleEditHandler = (e) => {
    setRybaTitleEdit(e.target.value);
    console.log(rybaTitleEdit);
  };

  // handle select option change
  const onSelectOptionChangeHandler = (e) => {
    modality.current = `${e.target.value}`;
    setModalityState(e.target.value);
    console.log(modality.current);
  };

  //// create New Ryba

  const createNewRybaRequest = function (e) {
    if (protocol.current === "") {
      alert("выберите протокол");
      return;
    }

    if (rybaTitleEdit === "") {
      alert("Введите название новой рыбки");
      return;
    }
    const createRyba = async (req, res) => {
      console.log(rybaTitleEdit);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            title: `${rybaTitleEdit}`,
            modality: `${modalityState}`,
            protocol: `${protocol.current}`,
          }),
        }
      );
      const json = await response.json();
      if (response.ok) {
        console.log(json);
        window.location.reload();
      }
    };
    createRyba();
  };

  // open create ryba window
  const openRybaCreateWindowOnclick = () => {
    setOpenRybaCreateWindow(true);
  };
  // close create rybe window
  const closeRybaCreateWindowOnclick = () => {
    setOpenRybaCreateWindow(false);
  };

  const dateNormalizer = (date) => {
    return date.slice(8, 10) + "." + date.slice(5, 7) + "." + date.slice(0, 4);
  };
  const loadStandardRyba = () => {
    //////////////////////////////// NEW IMPORT RYBA ////////////////////////////////////////////////
    const importRyba = async (req, res) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/import/${rybaID.current}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();

      if (response.ok) {
        console.log(json);
        let l = [...rybaList];
        l.push(json);
        setRybaList(l);
        notificationTheBest("Эта рыбка теперь ваша, берегите её");
      }
      // notificationTheBest("Рыбка успешно импортирована!");
    };
    importRyba();
    /////////////////////////////////////////////////////////////////////////////////////////////////
  };
  const loadStandardRybaWithoutButtons = () => {
    //////////////////////////////// NEW IMPORT RYBA ////////////////////////////////////////////////
    const importRyba = async (req, res) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/import/withoutbuttons/${rybaID.current}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();

      if (response.ok) {
        console.log(json);
        let l = [...rybaList];
        l.push(json);
        setRybaList(l);
        notificationTheBest("Эта рыбка теперь ваша, берегите её");
      }
    };
    importRyba();
    /////////////////////////////////////////////////////////////////////////////////////////////////
  };

  return (
    <div className="rybaListContainer" key={(keyCounter += 1)}>
      {howToImport && (
        <HowToImport
          {...{
            setHowToImport,
            rybaID,
            loadStandardRybaWithoutButtons,
            loadStandardRyba,
          }}
        />
      )}
      {openRybaCreateWindow && (
        <RybaCreate
          {...{
            openRybaCreateWindowOnclick,
            closeRybaCreateWindowOnclick,
            createNewRybaRequest,
            titleEditHandler,
            rybaTitleEdit,
            onSelectOptionChangeHandler,
            modalityState,
            protocol,
            scrollY,
          }}
        />
      )}
      <h1
        style={{
          color: "lightgrey",
          // margin: "5px",
          padding: "0px",
          fontSize: "2.2rem",
        }}
      >
        МРТ
      </h1>
      {/* <h1 style={{ color: "grey" }}>МРТ</h1> */}
      {areYouSureOpen && (
        <AreYouSureGeneric
          {...{
            setAreYouSureOpen,
            shureFunction: deleteRybaRequest,
            text: "Вы уверены, что хотите удалить шаблон? Отменить это действие будет невозможно. ",
          }}
        />
      )}
      {openContext && (
        <div
          className="contextMenu"
          style={{
            top: `${top}px`,
            left: `${left}px`,
          }}
        >
          {!rybaOBJECT.current.favorite && (
            <button
              onClick={(e) => {
                let l = [...rybaList];
                l[l.indexOf(rybaOBJECT.current)].favorite = true;
                const updatedRyba = l[l.indexOf(rybaOBJECT.current)];
                console.log(updatedRyba);
                saveRybaChanges(updatedRyba);
                setRybaList(l);
                setOpenContext(false);
              }}
            >
              В избранное
            </button>
          )}
          {rybaOBJECT.current.favorite && (
            <button
              onClick={(e) => {
                let l = [...rybaList];
                l[l.indexOf(rybaOBJECT.current)].favorite = false;
                const updatedRyba = l[l.indexOf(rybaOBJECT.current)];
                console.log(updatedRyba);
                saveRybaChanges(updatedRyba);
                setRybaList(l);
                setOpenContext(false);
              }}
            >
              Убрать из избранного
            </button>
          )}
          <button
            onClick={(e) => {
              navigate(`/editRyba2/${rybaOBJECT.current._id}`);
            }}
          >
            Редактировать
          </button>
          <button
            onClick={(e) => {
              duplicateRyba(rybaOBJECT.current);
              setOpenContext(false);
            }}
          >
            Дублировать
          </button>
          <button
            onClick={(e) => {
              setAreYouSureOpen(true);
              setOpenContext(false);
            }}
          >
            Удалить
          </button>
          <button onClick={() => setOpenContext(false)}>Закрыть</button>
        </div>
      )}
      <button
        className="createRyba"
        onClick={() => {
          openRybaCreateWindowOnclick();
        }}
      >
        Создать новый шаблон
      </button>
      <div className="rybaListDividerContainer">
        <div className="rybaListSubcontainer1">
          <div className="modalityrybaList" key={(keyCounter += 1)}>
            <p>Избранное ⭐️</p>
            <hr className="rybaListHr" />
            {!loaded && <div className="loaderSpinner"></div>}
            {rybaList &&
              rybaList.filter((a) => {
                return a.favorite;
              }).length === 0 && (
                <span className="rybovNet">Избранных нет</span>
              )}
            {rybaList &&
              rybaList
                .filter((a) => {
                  return a.favorite;
                })
                .map((ryba) => (
                  <Link
                    to={`/primary/${ryba._id}`}
                    key={(keyCounter += 1)}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setRybaContext(ryba);
                      rybaOBJECT.current = ryba;
                      setOpenContext(true);
                      setLeft(e.pageX);
                      setTop(e.pageY);
                    }}
                  >
                    <li ryba={ryba._id} key={(keyCounter += 1)}>
                      {ryba.title}
                      {ryba.isChild && " 🆕"}
                    </li>
                  </Link>
                ))}
          </div>
          <div
            className="modalityrybaList"
            key={(keyCounter += 1)}
            style={{
              width: "190px",
            }}
          >
            <p>Часто используемые</p>
            <hr className="rybaListHr" />
            {!loaded && <div className="loaderSpinner"></div>}
            {rybaList && rybaList.length === 0 && (
              <span className="rybovNet">Шаблонов нет</span>
            )}
            {rybaList &&
              rybaList
                .sort((a, b) => b.numberTimesUsed - a.numberTimesUsed)
                .filter((a) => {
                  return a.numberTimesUsed > 0;
                })
                .slice(0, 20)
                .map((ryba) => (
                  <Link
                    to={`/primary/${ryba._id}`}
                    key={(keyCounter += 1)}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setRybaContext(ryba);
                      rybaOBJECT.current = ryba;
                      setOpenContext(true);
                      setLeft(e.pageX);
                      setTop(e.pageY);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <li ryba={ryba._id} key={(keyCounter += 1)}>
                      {ryba.title}
                    </li>
                    <span
                      style={{
                        color: "grey",
                        textAlign: "right",
                        fontSize: "15px",
                      }}
                    >
                      {ryba.numberTimesUsed}
                    </span>
                  </Link>
                ))}
          </div>
        </div>
        <div>
          <div className="rybaListSubcontainer1">
            <ModalityRybaList
              {...{
                array: neuroArray,
                listTitle: "Голова",
                loaded,
                rybaList,
                setRybaContext,
                rybaOBJECT,
                setOpenContext,
                setLeft,
                setTop,
              }}
            />{" "}
            <ModalityRybaList
              {...{
                array: spinalArray,
                listTitle: "Позвоночник, сплетения",
                loaded,
                rybaList,
                setRybaContext,
                rybaOBJECT,
                setOpenContext,
                setLeft,
                setTop,
              }}
            />
            <ModalityRybaList
              {...{
                array: mskArray,
                listTitle: "Скелетно-мышечные",
                loaded,
                rybaList,
                setRybaContext,
                rybaOBJECT,
                setOpenContext,
                setLeft,
                setTop,
              }}
            />
          </div>
          <div className="rybaListSubcontainer1">
            <ModalityRybaList
              {...{
                array: uroGinoArray,
                listTitle: "Урогенитальные",
                loaded,
                rybaList,
                setRybaContext,
                rybaOBJECT,
                setOpenContext,
                setLeft,
                setTop,
              }}
            />
            <ModalityRybaList
              {...{
                array: abdArray,
                listTitle: "Абдоминальные",
                loaded,
                rybaList,
                setRybaContext,
                rybaOBJECT,
                setOpenContext,
                setLeft,
                setTop,
              }}
            />
            <div className="modalityrybaList" key={(keyCounter += 1)}>
              <p>Другое</p>
              <hr className="rybaListHr" />
              {!loaded && <div className="loaderSpinner"></div>}
              {rybaList &&
                rybaList.filter(
                  (a) =>
                    !neuroArray.includes(a.protocol) &&
                    !mskArray.includes(a.protocol) &&
                    !uroGinoArray.includes(a.protocol) &&
                    !abdArray.includes(a.protocol) &&
                    !spinalArray.includes(a.protocol)
                ).length === 0 && (
                  <span className="rybovNet">Шаблонов нет</span>
                )}
              {rybaList &&
                rybaList
                  .filter(
                    (a) =>
                      !neuroArray.includes(a.protocol) &&
                      !mskArray.includes(a.protocol) &&
                      !uroGinoArray.includes(a.protocol) &&
                      !abdArray.includes(a.protocol) &&
                      !spinalArray.includes(a.protocol)
                  )
                  .map((ryba) => (
                    <Link
                      to={`/primary/${ryba._id}`}
                      key={(keyCounter += 1)}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        setRybaContext(ryba);
                        rybaOBJECT.current = ryba;
                        setOpenContext(true);
                        setLeft(e.pageX);
                        setTop(e.pageY);
                      }}
                    >
                      <li ryba={ryba._id} key={(keyCounter += 1)}>
                        {ryba.title}
                      </li>
                    </Link>
                  ))}
            </div>
          </div>
        </div>
      </div>
      <div className="rybaListSubcontainer2">
        <div className="modalityrybaList2" key={(keyCounter += 1)}>
          <p>Все шаблоны</p>

          {!loaded && <div className="loaderSpinner"></div>}

          {rybaList && (
            <div className="rybaRowShapka">
              <div className="rybaRowProtocolShapka">Протокол</div>
              <div className="rybaRowTitleShapka">Мои шаблоны</div>
              <div className="rybaRowCreatedAtShapka">Количество описаний</div>
              <div className="rybaRowCreatedAtShapka">Публичный или личный</div>
              <div className="rybaRowTitleShapka">Стандартные шаблоны</div>
            </div>
          )}
          {rybaList &&
            protocolsMRI.map((protocol) => (
              <RybaRow
                {...{
                  protocol,
                  rybaList,
                  standardRybas,
                  setRybaContext,
                  setOpenContext,
                  setTop,
                  setLeft,
                  rybaOBJECT,
                  dateNormalizer,
                  navigate,
                  duplicateRyba,
                  setAreYouSureOpen,
                  setHowToImport,
                  rybaID,
                }}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default RybaListMRI2;
