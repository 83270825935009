import TextareaAutosize from "react-textarea-autosize";
import DeleteIcon from "../../svg/DeleteIcon";
import { useState } from "react";
import ArrowDown from "../../svg/ArrowDown";
import ArrowUp from "../../svg/ArrowUp";
import Icon from "../../redactorComponents/icons/icons";

const ChoicesConstructorZakl = ({
  buttonItself,
  setChoicesZakl,
  buttonType,
  organIndex,
  segmentIndex,
  buttonIndex,
  radioIndex,
  optionIndex,
  choicesZaklOpen,
  setChoicesZaklOpen,
}) => {
  const [addingOption, setAddingOption] = useState(false);
  const [optionText, setOptionText] = useState();
  const [whichOption, setWhichOption] = useState();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [optionFocus, setOptionFocus] = useState(null);

  return (
    <>
      <div
        className="choices"
        style={{
          width: "533px",
          // marginLeft: "280px",

          position: "relative",
        }}
      >
        <div
          style={{
            width: "2px",
            left: "-50px",
            top: "15px",
            position: "absolute",
            textAlign: "center",
            scale: "2",
          }}
        >
          {" "}
          <span>
            <Icon title="addChoicesZakl" fill="orange" />
          </span>
        </div>

        <div
          style={{
            marginBottom: "10px",
            marginTop: "5px",
            color: "darkgrey",
          }}
        >
          {choicesZaklOpen && (
            <span
              className="pointer"
              onClick={() => {
                setChoicesZaklOpen(false);
              }}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
              }}
            >
              <Icon title="close" />
            </span>
          )}
        </div>

        <>
          <div
            className="optionsTextPreview"
            style={{
              marginBottom: "10px",
              marginTop: "5px",
              whiteSpace: "pre-line",
              padding: "10px",

              // textDecoration: "underline",
            }}
          >
            {buttonItself.zakl !== undefined &&
              buttonItself.zakl.split("...").map((chunk, index) => (
                <span>
                  {chunk}
                  <span
                    onClick={() => {
                      if (optionFocus === null) {
                        setOptionFocus(index + 1);
                      }
                      if (optionFocus !== null && optionFocus !== index + 1) {
                        setOptionFocus(index + 1);
                      }
                      if (optionFocus !== null && optionFocus === index + 1) {
                        setOptionFocus(null);
                      }
                    }}
                    className="numberOfOption"
                    style={{
                      background: optionFocus === index + 1 && "orange",
                      display:
                        index + 1 === buttonItself.zakl.split("...").length &&
                        "none",
                    }}
                  >
                    {index < buttonItself.zakl.split("...").length - 1 &&
                      index + 1}
                  </span>
                </span>
              ))}
            {optionFocus !== null && (
              <div
                style={{
                  fontSize: "0.8rem",
                  color: "grey",
                  marginTop: "8px",
                }}
              >
                {buttonItself.zakl.split("...").map((chunk, index) => (
                  <span>
                    {chunk}
                    <span
                      style={{
                        display:
                          index + 1 === buttonItself.zakl.split("...").length &&
                          "none",
                      }}
                    >
                      {index + 1 === optionFocus ||
                      buttonItself.choicesZakl[index] === undefined ? (
                        <span
                          style={{
                            color: "red",
                          }}
                        >
                          {"..."}
                        </span>
                      ) : (
                        buttonItself.choicesZakl[index][0]
                      )}
                    </span>
                  </span>
                ))}
              </div>
            )}
          </div>

          {buttonItself.choicesZakl.map((c, i) =>
            optionFocus === null ? (
              <div className="choicesSegmentContainer">
                <div
                  style={{
                    fontSize: "20px",
                    width: "fit-content",
                    textAlign: "center",
                    padding: "3px",
                    paddingRight: "7px",
                    // border: "0.5px solid grey",
                    borderRadius: "50px",
                    // background: "#bcbcbc",
                    // color: "black",
                  }}
                >
                  {" "}
                  {i + 1}
                </div>

                <div
                  className="choicesSegment"
                  // style={{
                  //   display: "flex",
                  // }}
                >
                  <div
                    style={{
                      fontStyle: "italic",
                    }}
                  >
                    {buttonItself.zakl.split("...").length - 1 < i + 1 ||
                    buttonItself.zakl.split("...")[i] + "..." ===
                      "undefined..." ? (
                      <span
                        style={{
                          color: "pink",
                        }}
                      >
                        Добавьте "..." в текст кнопки
                      </span>
                    ) : (
                      buttonItself.zakl.split("...")[i] + "..."
                    )}
                    {buttonItself.zakl.split("...").length - 1 === i + 1 &&
                      buttonItself.zakl.split("...")[i + 1]}
                  </div>
                  {buttonItself.choicesZakl[i].map((c, choiceIndex) => (
                    <div
                      style={{
                        display: "flex",
                        whiteSpace: "pre-line",
                      }}
                    >
                      <TextareaAutosize
                        className="choicesOption"
                        style={{
                          width: "420px",
                        }}
                        value={c}
                        onChange={(e) => {
                          buttonItself.choicesZakl[i][choiceIndex] =
                            e.target.value;
                          setChoicesZakl(
                            buttonType,
                            buttonItself.choicesZakl,
                            organIndex,
                            segmentIndex,
                            buttonIndex,
                            radioIndex,
                            optionIndex
                          );
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp" && choiceIndex > 0) {
                            let a =
                              buttonItself.choicesZakl[i][choiceIndex - 1];
                            let b = buttonItself.choicesZakl[i][choiceIndex];
                            buttonItself.choicesZakl[i][choiceIndex - 1] = b;
                            buttonItself.choicesZakl[i][choiceIndex] = a;
                            setChoicesZakl(
                              buttonType,
                              buttonItself.choicesZakl,
                              organIndex,
                              segmentIndex,
                              buttonIndex,
                              radioIndex,
                              optionIndex
                            );
                          }
                          if (
                            e.key === "ArrowDown" &&
                            choiceIndex + 1 < buttonItself.choicesZakl[i].length
                          ) {
                            let a =
                              buttonItself.choicesZakl[i][choiceIndex + 1];
                            let b = buttonItself.choicesZakl[i][choiceIndex];
                            buttonItself.choicesZakl[i][choiceIndex + 1] = b;
                            buttonItself.choicesZakl[i][choiceIndex] = a;
                            setChoicesZakl(
                              buttonType,
                              buttonItself.choicesZakl,
                              organIndex,
                              segmentIndex,
                              buttonIndex,
                              radioIndex,
                              optionIndex
                            );
                          }
                        }}
                      >
                        {c}
                      </TextareaAutosize>
                      <div>
                        <span
                          className="choiceDeleteSpan add"
                          onClick={() => {
                            buttonItself.choicesZakl[i].splice(choiceIndex, 1);
                            setChoicesZakl(
                              buttonType,
                              buttonItself.choicesZakl,
                              organIndex,
                              segmentIndex,
                              buttonIndex,
                              radioIndex,
                              optionIndex
                            );
                          }}
                        >
                          <DeleteIcon width={16} height={16} />
                        </span>
                      </div>
                    </div>
                  ))}
                  {!addingOption && (
                    <div
                      style={{
                        marginTop: "1px",
                        marginBottom: "1px",
                        fontSize: "15px",
                      }}
                    >
                      <span
                        style={{
                          marginTop: "1px",
                          marginBottom: "1px",
                          marginLeft: "2px",
                          fontSize: "14px",
                        }}
                        className="choicesButton add "
                        onClick={() => {
                          setAddingOption(true);
                          setWhichOption(i);
                        }}
                      >
                        Добавить опцию
                      </span>
                    </div>
                  )}

                  {i === whichOption && addingOption && (
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <TextareaAutosize
                        autoFocus
                        className="choicesTextarea"
                        value={optionText}
                        onChange={(e) => {
                          setOptionText(e.target.value);
                        }}
                        style={{
                          width: "325px",
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            if (!optionText) {
                              setAddingOption(false);
                            }
                            if (optionText === "") return;
                            buttonItself.choicesZakl[i].push(optionText);
                            setChoicesZakl(
                              buttonType,
                              buttonItself.choicesZakl,
                              organIndex,
                              segmentIndex,
                              buttonIndex,
                              radioIndex,
                              optionIndex
                            );
                            setAddingOption(false);
                            setOptionText("");
                          }
                        }}
                      >
                        {optionText}
                      </TextareaAutosize>
                      <div
                        className="choicesButton"
                        onClick={() => {
                          if (!optionText) {
                            setAddingOption(false);
                          }
                          if (optionText === "") return;

                          buttonItself.choicesZakl[i].push(optionText);
                          setChoicesZakl(
                            buttonType,
                            buttonItself.choicesZakl,
                            organIndex,
                            segmentIndex,
                            buttonIndex,
                            radioIndex,
                            optionIndex
                          );
                          setAddingOption(false);
                          setOptionText("");
                        }}
                      >
                        OK
                      </div>
                      <div
                        className="choicesButton"
                        onClick={() => {
                          setAddingOption(false);
                        }}
                        style={{
                          width: "70px",
                        }}
                      >
                        Отменить
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    scale: "0.8",
                  }}
                >
                  <div
                    className="choicesDelete add"
                    onClick={() => {
                      buttonItself.choicesZakl.splice(i, 1);
                      setChoicesZakl(
                        buttonType,
                        buttonItself.choicesZakl,
                        organIndex,
                        segmentIndex,
                        buttonIndex,
                        radioIndex,
                        optionIndex
                      );
                    }}
                  >
                    <DeleteIcon width={24} height={24} />
                  </div>{" "}
                  {i > 0 && (
                    <div
                      className="choicesDelete add"
                      onClick={() => {
                        let a = buttonItself.choicesZakl[i - 1];
                        let b = buttonItself.choicesZakl[i];
                        buttonItself.choicesZakl[i - 1] = b;
                        buttonItself.choicesZakl[i] = a;
                        setChoicesZakl(
                          buttonType,
                          buttonItself.choicesZakl,
                          organIndex,
                          segmentIndex,
                          buttonIndex,
                          radioIndex,
                          optionIndex
                        );
                      }}
                    >
                      <ArrowUp width={24} height={24} />
                    </div>
                  )}
                  {buttonItself.choicesZakl.length > i + 1 && (
                    <div
                      className="choicesDelete add"
                      onClick={() => {
                        let a = buttonItself.choicesZakl[i + 1];
                        let b = buttonItself.choicesZakl[i];
                        buttonItself.choicesZakl[i + 1] = b;
                        buttonItself.choicesZakl[i] = a;
                        setChoicesZakl(
                          buttonType,
                          buttonItself.choicesZakl,
                          organIndex,
                          segmentIndex,
                          buttonIndex,
                          radioIndex,
                          optionIndex
                        );
                      }}
                    >
                      <ArrowDown width={24} height={24} />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              optionFocus === i + 1 && (
                <div className="choicesSegmentContainer">
                  <div
                    style={{
                      fontSize: "20px",
                      width: "fit-content",
                      textAlign: "center",
                      padding: "3px",
                      paddingRight: "7px",
                      // background: "#bcbcbc",
                      // color: "black",
                    }}
                  >
                    {" "}
                    {i + 1}
                  </div>

                  <div className="choicesSegment">
                    <div
                      style={{
                        fontStyle: "italic",
                      }}
                    >
                      {/* {i + 1} */}
                      {buttonItself.zakl.split("...").length - 1 < i + 1 ||
                      buttonItself.zakl.split("...")[i] + "..." ===
                        "undefined..." ? (
                        <span
                          style={{
                            color: "pink",
                          }}
                        >
                          Добавьте "..." в текст кнопки
                        </span>
                      ) : (
                        buttonItself.zakl.split("...")[i] + "..."
                      )}
                      {buttonItself.zakl.split("...").length - 1 === i + 1 &&
                        buttonItself.zakl.split("...")[i + 1]}
                    </div>
                    {buttonItself.choicesZakl[i].map((c, choiceIndex) => (
                      <div
                        style={{
                          display: "flex",
                          whiteSpace: "pre-line",
                        }}
                      >
                        <TextareaAutosize
                          className="choicesOption"
                          style={{
                            width: "376px",
                          }}
                          value={c}
                          onChange={(e) => {
                            buttonItself.choicesZakl[i][choiceIndex] =
                              e.target.value;
                            setChoicesZakl(
                              buttonType,
                              buttonItself.choicesZakl,
                              organIndex,
                              segmentIndex,
                              buttonIndex,
                              radioIndex,
                              optionIndex
                            );
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "ArrowUp" && choiceIndex > 0) {
                              let a =
                                buttonItself.choicesZakl[i][choiceIndex - 1];
                              let b = buttonItself.choicesZakl[i][choiceIndex];
                              buttonItself.choicesZakl[i][choiceIndex - 1] = b;
                              buttonItself.choicesZakl[i][choiceIndex] = a;
                              setChoicesZakl(
                                buttonType,
                                buttonItself.choicesZakl,
                                organIndex,
                                segmentIndex,
                                buttonIndex,
                                radioIndex,
                                optionIndex
                              );
                            }
                            if (
                              e.key === "ArrowDown" &&
                              choiceIndex + 1 <
                                buttonItself.choicesZakl[i].length
                            ) {
                              let a =
                                buttonItself.choicesZakl[i][choiceIndex + 1];
                              let b = buttonItself.choicesZakl[i][choiceIndex];
                              buttonItself.choicesZakl[i][choiceIndex + 1] = b;
                              buttonItself.choicesZakl[i][choiceIndex] = a;
                              setChoicesZakl(
                                buttonType,
                                buttonItself.choicesZakl,
                                organIndex,
                                segmentIndex,
                                buttonIndex,
                                radioIndex,
                                optionIndex
                              );
                            }
                          }}
                        >
                          {c}
                        </TextareaAutosize>
                        <div>
                          <span
                            className="choiceDeleteSpan add"
                            onClick={() => {
                              buttonItself.choicesZakl[i].splice(
                                choiceIndex,
                                1
                              );
                              setChoicesZakl(
                                buttonType,
                                buttonItself.choicesZakl,
                                organIndex,
                                segmentIndex,
                                buttonIndex,
                                radioIndex,
                                optionIndex
                              );
                            }}
                          >
                            <DeleteIcon width={16} height={16} />
                          </span>
                        </div>
                      </div>
                    ))}
                    {!addingOption && (
                      <div
                        style={{
                          marginTop: "1px",
                          marginBottom: "1px",
                          fontSize: "15px",
                        }}
                      >
                        <span
                          style={{
                            marginTop: "1px",
                            marginBottom: "1px",
                            marginLeft: "2px",
                            fontSize: "14px",
                          }}
                          className="choicesButton add "
                          onClick={() => {
                            setAddingOption(true);
                            setWhichOption(i);
                          }}
                        >
                          Добавить опцию
                        </span>
                      </div>
                    )}

                    {i === whichOption && addingOption && (
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <TextareaAutosize
                          autoFocus
                          className="choicesTextarea"
                          value={optionText}
                          onChange={(e) => {
                            setOptionText(e.target.value);
                          }}
                          style={{
                            width: "260px",
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              buttonItself.choicesZakl[i].push(optionText);
                              setChoicesZakl(
                                buttonType,
                                buttonItself.choicesZakl,
                                organIndex,
                                segmentIndex,
                                buttonIndex,
                                radioIndex,
                                optionIndex
                              );
                              setAddingOption(false);
                              setOptionText("");
                            }
                          }}
                        >
                          {optionText}
                        </TextareaAutosize>
                        <div
                          className="choicesButton"
                          onClick={() => {
                            if (optionText === "") return;
                            buttonItself.choicesZakl[i].push(optionText);
                            setChoicesZakl(
                              buttonType,
                              buttonItself.choicesZakl,
                              organIndex,
                              segmentIndex,
                              buttonIndex,
                              radioIndex,
                              optionIndex
                            );
                            setAddingOption(false);
                            setOptionText("");
                          }}
                        >
                          OK
                        </div>
                        <div
                          className="choicesButton"
                          onClick={() => {
                            setAddingOption(false);
                          }}
                          style={{
                            width: "70px",
                          }}
                        >
                          Отменить
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      scale: "0.8",
                    }}
                  >
                    <div
                      className="choicesDelete add"
                      onClick={() => {
                        buttonItself.choicesZakl.splice(i, 1);
                        setChoicesZakl(
                          buttonType,
                          buttonItself.choicesZakl,
                          organIndex,
                          segmentIndex,
                          buttonIndex,
                          radioIndex,
                          optionIndex
                        );
                      }}
                    >
                      <DeleteIcon width={24} height={24} />
                    </div>{" "}
                    {i > 0 && (
                      <div
                        className="choicesDelete add"
                        onClick={() => {
                          let a = buttonItself.choicesZakl[i - 1];
                          let b = buttonItself.choicesZakl[i];
                          buttonItself.choicesZakl[i - 1] = b;
                          buttonItself.choicesZakl[i] = a;
                          setChoicesZakl(
                            buttonType,
                            buttonItself.choicesZakl,
                            organIndex,
                            segmentIndex,
                            buttonIndex,
                            radioIndex,
                            optionIndex
                          );
                        }}
                      >
                        <ArrowUp width={24} height={24} />
                      </div>
                    )}
                    {buttonItself.choicesZakl.length > i + 1 && (
                      <div
                        className="choicesDelete add"
                        onClick={() => {
                          let a = buttonItself.choicesZakl[i + 1];
                          let b = buttonItself.choicesZakl[i];
                          buttonItself.choicesZakl[i + 1] = b;
                          buttonItself.choicesZakl[i] = a;
                          setChoicesZakl(
                            buttonType,
                            buttonItself.choicesZakl,
                            organIndex,
                            segmentIndex,
                            buttonIndex,
                            radioIndex,
                            optionIndex
                          );
                        }}
                      >
                        <ArrowDown width={24} height={24} />
                      </div>
                    )}
                  </div>
                </div>
              )
            )
          )}

          <div
            style={{
              margin: "10px",
            }}
          >
            <span
              className="choicesButton"
              onClick={() => {
                buttonItself.choicesZakl.push([]);
                setChoicesZakl(
                  buttonType,
                  buttonItself.choicesZakl,
                  organIndex,
                  segmentIndex,
                  buttonIndex,
                  radioIndex,
                  optionIndex
                );
              }}
            >
              Добавить группу опций
            </span>
          </div>
        </>
      </div>
    </>
  );
};
export default ChoicesConstructorZakl;
