import React, { useCallback, useEffect, useState } from "react";
import AWS from "aws-sdk";
import { useAuthContext } from "../hooks/useAuthContext";

const ImageUploader = ({ calculator, setCalculator }) => {
  const [image, setImage] = useState(null);
  const [error, setError] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadUrl, setUploadUrl] = useState("");
  const [previousFileKey, setPreviousFileKey] = useState(null); // Track the previously uploaded file's key
  const { user } = useAuthContext();
  // s3 config
  const s3 = new AWS.S3({
    endpoint: "https://s3.timeweb.cloud", // Replace with your S3 endpoint or Swift URL
    accessKeyId: "UAWATKPL3MV9365PK8IH", // Replace with your S3 access key or Swift access key
    secretAccessKey: "Fxz1K4IHXO2AvGyxqPR4ZD6aFz7uuiVfFNCZJMfo", // Replace with your S3 secret access key or Swift secret access key
    s3ForcePathStyle: true, // Required for most S3-compatible services
    signatureVersion: "v4", // Use v4 for most S3-compatible services
  });
  useEffect(() => {}, []);
  // Validate image file
  const validateImage = (file) => {
    if (!file) return false;
    if (file.size > 1024 * 1024) {
      setError("File size must be less than 1MB.");
      return false;
    }
    if (!["image/jpeg", "image/png", "image/gif"].includes(file.type)) {
      setError("Only JPEG, PNG, and GIF images are allowed.");
      return false;
    }
    setError("");
    return true;
  };

  // Handle file selection/upload
  const handleFileChange = (file) => {
    if (validateImage(file)) {
      setImage(file);
      handleUpload(file); // Automatically upload the file
      setImage(URL.createObjectURL(file));
    }
  };

  // Drag-and-drop handler
  const onDrop = useCallback((event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleFileChange(file);
  }, []);

  const onPaste = async (event) => {
    try {
      const clipboardItems = await navigator.clipboard.read();
      for (const item of clipboardItems) {
        for (const type of item.types) {
          if (type.startsWith("image/")) {
            const blob = await item.getType(type);
            const timestamp = Date.now(); // Get the current timestamp
            const fileName = `pasted-image-${timestamp}.png`; // Add timestamp to the file name
            const file = new File([blob], fileName, { type });
            handleFileChange(file);
            return;
          }
        }
      }
      setError("Ничего не скопировано");
    } catch (error) {
      console.error("Error pasting from clipboard:", error);
      setError("Failed to paste image from clipboard.");
    }
  };

  // File input change handler
  const onFileInputChange = (event) => {
    const file = event.target.files[0];
    setImage(URL.createObjectURL(file)); // Set the image URL for preview
    handleFileChange(file);
  };

  // Delete the previously uploaded file
  const deleteFile = async (fileKey) => {
    if (!calculator.image.key) return;

    const params = {
      Bucket: "cd5c3c6d-7735d894-d309-4ef5-87a5-a3d5741fd815", // Replace with your bucket name
      Key: fileKey, // Key of the file to delete
    };

    try {
      await s3.deleteObject(params).promise();
      console.log("Previous file deleted successfully:", fileKey);
    } catch (error) {
      console.error("Error deleting previous file:", error);
      throw error;
    } finally {
      let c = { ...calculator };
      c.image = {
        URL: undefined,
        key: undefined,
        name: undefined,
      };
      setCalculator(c);
      setUploadUrl("");
    }
  };

  // Upload file to S3-compatible storage
  const handleUpload = async (file) => {
    if (!file) return;

    setUploading(true);
    setError("");

    const fileKey = `uploads/calcImages/${user.id}/${file.name}`; // File path in the bucket

    try {
      // Delete the previously uploaded file (if any)
      if (calculator.image?.key) {
        await deleteFile(calculator.image?.key);
      }

      // Upload the new file
      const params = {
        Bucket: "cd5c3c6d-7735d894-d309-4ef5-87a5-a3d5741fd815", // Replace with your bucket name
        Key: fileKey, // File path in the bucket
        Body: file, // File object
        ContentType: file.type, // MIME type of the file
        ACL: "public-read", // Set file permissions (optional)
      };

      const data = await s3.upload(params).promise();
      console.log("File uploaded successfully:", data.Location);

      // Update state with the new file's URL and key
      let c = { ...calculator };
      if (!Object.hasOwn(c, "image")) {
        c.image = {
          URL: undefined,
          key: undefined,
          name: undefined,
        };
      }
      c.image.URL = data.Location;
      c.image.key = fileKey;
      c.image.name = file.name;
      console.log(c.image);
      setUploadUrl(data.Location);
      setPreviousFileKey(fileKey);
      setCalculator(c);
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("Failed to upload the file. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div
      onDrop={onDrop}
      onDragOver={(event) => event.preventDefault()}
      onPaste={onPaste}
      style={{
        border: "2px dashed #ccc",
        padding: "20px",
        textAlign: "center",
      }}
    >
      <p>Перетащите, вставьте или выберите картинку из файловой системы.</p>
      <input
        type="file"
        accept="image/jpeg, image/png, image/gif"
        onChange={onFileInputChange}
        style={{ display: "none" }}
        id="file-input"
      />
      <label
        htmlFor="file-input"
        style={{
          cursor: "pointer",
          border: "1px dotted grey",
          borderRadius: "6px",
          padding: "3px",
          width: "100px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        Выбрать файл
      </label>{" "}
      <label
        onClick={onPaste}
        style={{
          cursor: "pointer",
          border: "1px dotted grey",
          borderRadius: "6px",
          padding: "3px",
          width: "200px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "5px",
        }}
      >
        Вставить из буфера обмена (скопированную)
      </label>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {calculator.image?.URL && (
        <div>
          <p>Выбранный файл: {calculator.image.name}</p>
          <img
            src={calculator.image.URL}
            alt="Preview"
            style={{ maxWidth: "100%", maxHeight: "400px" }}
          />
        </div>
      )}
      {calculator.image?.URL && (
        <button
          className="deleteButtonUniversal"
          onClick={() => {
            deleteFile(calculator.image.key);
          }}
        >
          Удалить картинку
        </button>
      )}
      {uploading && <p>Uploading...</p>}
      {uploadUrl && (
        <div>
          <p>Файл успешно загружен!</p>
          {/* <a href={uploadUrl} target="_blank" rel="noopener noreferrer">
            View Uploaded File
          </a> */}
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
