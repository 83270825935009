import "./Ryba4ButtonEdit.css";
import { useState, useRef } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import CheckboxRedactor from "./smallComponents/buttonsRedactorVariants/CheckboxRedactor";
import ConstructorRedactor from "./smallComponents/buttonsRedactorVariants/ConstructorRedactor";
import RadioRedactor from "./smallComponents/buttonsRedactorVariants/RadioRedactor";
import SelectRedactor from "./smallComponents/buttonsRedactorVariants/SelectRedactor";
import CheckboxsVG from "./svg/CheckboxSVG";
import ConstructorSVG from "./svg/ConstructorSVG";
import Dropdown from "./svg/Dropdown";
import RadioSVG from "./svg/RadioSVG";
import TextareaAutosize from "react-textarea-autosize";
import CloseButton from "./CloseButton";
import Icon from "./redactorComponents/icons/icons";
import ChoicesConstructor from "./smallComponents/buttonsRedactorVariants/ChoicesConstructor";
import ChoicesConstructorZakl from "./smallComponents/buttonsRedactorVariants/ChoicesConstructorZakl";
import EmojiPicker from "emoji-picker-react";

const ButtonEditWindow = ({
  rybaObject,
  radioINDEX,
  buttonINDEX,
  organINDEX,
  segmentINDEX,
  setRybaObject,
  editedButton,
  setEditedButton,
  editedButtonREF,
  editedButtonTypeREF,
  scrollY,
  setEditButtonOpen,
  saveRybaChanges,
  originalRybaJSON,
  editedButtonJSON,
}) => {
  const [choicesOpen, setChoicesOpen] = useState(false);
  const [choicesZaklOpen, setChoicesZaklOpen] = useState(false);
  const [emoji, setEmoji] = useState(false);
  const [sure, setSure] = useState(false);
  const setChoices = (
    buttonType,
    choicesObject,
    organIndex,
    segmentIndex,
    buttonIndex,
    radioIndex,
    optionIndex
  ) => {
    let r = { ...rybaObject };
    if (buttonType === "checkbox") {
      r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex].choices =
        choicesObject;
    }
    if (buttonType === "radio") {
      r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex].radios[
        radioIndex
      ].choices = choicesObject;
    }
    if (buttonType === "select") {
      r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex].options[
        optionIndex
      ].choices = choicesObject;
    }
    setRybaObject(r);
  };

  const setChoicesZakl = (
    buttonType,
    choicesObject,
    organIndex,
    segmentIndex,
    buttonIndex,
    radioIndex,
    optionIndex
  ) => {
    let r = { ...rybaObject };
    if (buttonType === "checkbox") {
      r.organs[organIndex].segments[segmentIndex].buttons[
        buttonIndex
      ].choicesZakl = choicesObject;
    }
    if (buttonType === "radio") {
      r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex].radios[
        radioIndex
      ].choicesZakl = choicesObject;
    }
    if (buttonType === "select") {
      r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex].options[
        optionIndex
      ].choicesZakl = choicesObject;
    }
    setRybaObject(r);
  };
  function createNewObject(obj) {
    const newObj = {};

    if (obj.hasOwnProperty("title")) {
      newObj.title = obj.title;
    }

    if (obj.hasOwnProperty("type")) {
      newObj.type = obj.type;
    }

    if (obj.hasOwnProperty("content")) {
      newObj.content = obj.content;
    }

    if (obj.hasOwnProperty("radios")) {
      newObj.radios = obj.radios;
    }

    if (obj.hasOwnProperty("zakl")) {
      newObj.zakl = obj.zakl;
    }

    if (obj.hasOwnProperty("options")) {
      newObj.options = obj.options;
    }

    if (obj.hasOwnProperty("choices")) {
      newObj.choices = obj.choices;
    }

    if (obj.hasOwnProperty("choicesZakl")) {
      newObj.choicesZakl = obj.choicesZakl;
    }

    return newObj;
  }

  return (
    <div
      className="buttonEditContainer"
      style={{
        top: `${scrollY + 150}px`,
      }}
    >
      <div className="boxShadowMaker"></div>
      <div className="buttonEditWindow">
        <div className="closeButtonButtonEdit">
          <CloseButton
            onClick={() => {
              setEditButtonOpen(false);
            }}
            size={40}
            color="#888"
            hoverColor="#ff4d4d"
          />
        </div>
        <h2>Редактор кнопки</h2>

        <div className="textareaAndLabel">
          <p>Название</p>

          <TextareaAutosize
            value={editedButton.title}
            onChange={(e) => {
              let b = { ...editedButton };
              b.title = e.target.value;
              setEditedButton(b);
            }}
          />
          <div
            className="pointer"
            style={{
              marginRight: "auto",
              marginTop: "auto",
              marginBottom: "auto",
            }}
            onClick={() => {
              if (emoji) {
                return setEmoji(false);
              }
              setEmoji(true);
            }}
          >
            😁
          </div>
        </div>

        {emoji && (
          <div
            style={{
              alignItems: "center",
            }}
          >
            <EmojiPicker
              emojiStyle="auto"
              onEmojiClick={(e) => {
                let b = { ...editedButton };
                b.title += e.emoji;
                setEditedButton(b);
              }}
              theme="dark"
              height="600px"
              width="450px"
              // searchDisabled={true}
            />
          </div>
        )}
        <div className="textareaAndLabel">
          <p>В описание</p>

          <TextareaAutosize
            value={editedButton.content}
            onChange={(e) => {
              let b = { ...editedButton };
              b.content = e.target.value;
              setEditedButton(b);
            }}
          />

          <div className="optionsDiv">
            {!Object.hasOwn(editedButton, "choices") && (
              <div
                className="choicesMenuSegment"
                style={{
                  paddingLeft: "9px",
                  width: "117px",
                  height: "26px",
                  fontSize: "15px",
                  fontWeight: "100",
                  // padding: "7px",
                  border: "0.5px solid #333",
                }}
                onClick={() => {
                  let i = { ...editedButton };
                  i.choices = [];
                  setEditedButton(i);
                  setChoicesOpen(true);
                }}
              >
                {/* <Icon
                  title="addChoices"
                  fill={choicesOpen ? "orange" : "white"}
                /> */}
                Добавить опции
              </div>
            )}
            {Object.hasOwn(editedButton, "choices") && (
              <div
                className="choicesMenuSegment"
                style={{
                  paddingLeft: "5px",
                  width: "27px",
                  border: "0.5px solid #333",
                }}
                onClick={() => {
                  if (choicesOpen) {
                    setChoicesOpen(false);
                  } else setChoicesOpen(true);
                  if (choicesZaklOpen) {
                    setChoicesZaklOpen(false);
                  }
                }}
              >
                <Icon
                  title="addChoices"
                  fill={choicesOpen ? "orange" : "white"}
                />
              </div>
            )}
          </div>
        </div>
        {choicesOpen && (
          <div className="choicesDivButtonEdit">
            <ChoicesConstructor
              {...{
                buttonItself: editedButton,
                setChoices,
                buttonType: editedButtonTypeREF.current,
                organIndex: organINDEX.current,
                segmentIndex: segmentINDEX.current,
                buttonIndex: buttonINDEX.current,
                radioIndex: radioINDEX.current,
                optionIndex: radioINDEX.current,
                choicesOpen,
                setChoicesOpen,
              }}
            />
          </div>
        )}
        <div className="textareaAndLabel">
          <p>В заключение</p>

          <TextareaAutosize
            value={editedButton.zakl}
            onChange={(e) => {
              let b = { ...editedButton };
              b.zakl = e.target.value;
              setEditedButton(b);
            }}
          />

          <div className="optionsDiv">
            {Object.hasOwn(editedButton, "choicesZakl") && (
              <div
                className="choicesMenuSegment"
                style={{
                  paddingLeft: "5px",
                  width: "27px",
                  border: "0.5px solid #333",
                }}
                onClick={() => {
                  if (choicesZaklOpen) {
                    setChoicesZaklOpen(false);
                  } else setChoicesZaklOpen(true);
                  if (choicesOpen) {
                    setChoicesOpen(false);
                  }
                }}
              >
                <Icon
                  title="addChoices"
                  fill={choicesZaklOpen ? "orange" : "white"}
                />
              </div>
            )}
            {!Object.hasOwn(editedButton, "choicesZakl") && (
              <div
                className="choicesMenuSegment"
                style={{
                  paddingLeft: "9px",
                  width: "117px",
                  height: "26px",
                  fontSize: "15px",
                  fontWeight: "100",
                  // padding: "7px",
                  border: "0.5px solid #333",
                }}
                onClick={() => {
                  let i = { ...editedButton };
                  i.choicesZakl = [];
                  setEditedButton(i);
                  setChoicesZaklOpen(true);
                }}
              >
                {/* <Icon
                  title="addChoices"
                  fill={choicesOpen ? "orange" : "white"}
                /> */}
                Добавить опции
              </div>
            )}
          </div>
        </div>
        {choicesZaklOpen && (
          <div
            className="choicesDivButtonEdit"
            style={{
              paddingLeft: "100px",
            }}
          >
            <ChoicesConstructorZakl
              {...{
                buttonItself: editedButton,
                setChoicesZakl,
                buttonType: editedButtonTypeREF.current,
                organIndex: organINDEX.current,
                segmentIndex: segmentINDEX.current,
                buttonIndex: buttonINDEX.current,
                radioIndex: radioINDEX.current,
                optionIndex: radioINDEX.current,
                choicesOpen,
                setChoicesOpen,
              }}
            />
          </div>
        )}
        <div
          style={{
            textAlign: "center",
          }}
        >
          <div>
            <button
              className="saveButton"
              style={{
                fontSize: "19px",
                padding: "15px 20px",
              }}
              onClick={() => {
                let r = { ...rybaObject };
                if (editedButtonTypeREF.current === "checkbox") {
                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current] = editedButton;
                }
                if (editedButtonTypeREF.current === "radio") {
                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].radios[radioINDEX.current] =
                    editedButton;
                }
                if (editedButtonTypeREF.current === "select") {
                  r.organs[organINDEX.current].segments[
                    segmentINDEX.current
                  ].buttons[buttonINDEX.current].options[radioINDEX.current] =
                    editedButton;
                }
                // setRybaObject(r);
                saveRybaChanges(undefined, undefined, r);
                setEditButtonOpen(false);
              }}
            >
              Сохранить изменения
            </button>
          </div>
          <div>
            {" "}
            {!sure && (
              <button
                className="saveButton"
                onClick={() => {
                  setSure(true);
                }}
              >
                Сохранить для всех
              </button>
            )}
            {sure && (
              <div
                style={{
                  display: "flex",
                  padding: "10px",
                  borderRadius: "6px",
                  background: "black",
                }}
              >
                <div
                  style={{
                    width: "300px",
                    textWrap: "pretty",
                    color: "pink",
                  }}
                >
                  Это действие применит изменения ко ВСЕМ таким же кнопкам во
                  всех Ваших шаблонах, продолжить?
                </div>
                <button
                  className="saveButton"
                  onClick={() => {
                    let r = { ...rybaObject };
                    if (editedButtonTypeREF.current === "checkbox") {
                      r.organs[organINDEX.current].segments[
                        segmentINDEX.current
                      ].buttons[buttonINDEX.current] = editedButton;
                    }
                    if (editedButtonTypeREF.current === "radio") {
                      r.organs[organINDEX.current].segments[
                        segmentINDEX.current
                      ].buttons[buttonINDEX.current].radios[
                        radioINDEX.current
                      ] = editedButton;
                    }
                    if (editedButtonTypeREF.current === "select") {
                      r.organs[organINDEX.current].segments[
                        segmentINDEX.current
                      ].buttons[buttonINDEX.current].options[
                        radioINDEX.current
                      ] = editedButton;
                    }
                    // setRybaObject(r);
                    saveRybaChanges(
                      undefined,
                      undefined,
                      r,
                      createNewObject(JSON.parse(editedButtonJSON)),
                      createNewObject(
                        r.organs[organINDEX.current].segments[
                          segmentINDEX.current
                        ].buttons[buttonINDEX.current]
                      ),
                      true
                    );
                    setEditButtonOpen(false);
                  }}
                >
                  ДА
                </button>
                <button
                  className="saveButton"
                  onClick={() => {
                    setSure(false);
                  }}
                >
                  Отмена
                </button>
              </div>
            )}
          </div>

          <button
            className="saveButton"
            onClick={() => {
              setEditButtonOpen(false);
            }}
          >
            Отменить изменения
          </button>
        </div>
      </div>
    </div>
  );
};

export default ButtonEditWindow;
