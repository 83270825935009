import { Link } from "react-router-dom";

const RybaRow = ({
  protocol,
  rybaList,
  standardRybas,
  setRybaContext,
  setOpenContext,
  setTop,
  setLeft,
  rybaOBJECT,
  dateNormalizer,
  navigate,
  duplicateRyba,
  setAreYouSureOpen,
  loadStandardRyba,
  rybaID,
  setHowToImport,
}) => {
  const array = rybaList.filter((a) => a.protocol === protocol);
  const array2 = standardRybas.filter((a) => a.protocol === protocol);

  if (array.length === 0) {
    return (
      <div className="rybaRow">
        <div className="rybaRowProtocol">{protocol}</div>
        <div className="rybaRowTitle">-</div>
        <div className="rybaRowCreatedAt">-</div>
        <div className="rybaRowCreatedAt ">-</div>

        <div className="rybaRowCreatedAt">
          {array2.length > 0 && (
            <div>
              {array2.map((p) => (
                <p
                  className="standardRybaTitle"
                  onClick={() => {
                    rybaID.current = p._id;
                    setHowToImport(true);
                  }}
                >
                  {p.title}
                </p>
              ))}
            </div>
          )}
        </div>

        {/* <span
          className="rybaRowButtonEdit"
          onClick={() => {
            duplicateRyba(ryba);
          }}
        >
          Дублировать
        </span>

        <span
          className="rybaRowButtonDelete"
          onClick={() => {
            rybaOBJECT.current = ryba;
            setAreYouSureOpen(true);
          }}
        >
          Удалить
        </span> */}
      </div>
    );
  }

  if (array.length > 0) {
    return array.map((ryba, index) => (
      <div className="rybaRow">
        <div className="rybaRowProtocol">{index === 0 && ryba.protocol}</div>
        <div className="rybaRowTitle">
          <Link
            to={`/primary/${ryba._id}`}
            onContextMenu={(e) => {
              e.preventDefault();
              setRybaContext(ryba);
              rybaOBJECT.current = ryba;
              setOpenContext(true);
              setLeft(e.pageX);
              setTop(e.pageY);
            }}
          >
            <li ryba={ryba._id}>
              {ryba.title}
              {ryba.isChild && " 🆕"}
            </li>
          </Link>
        </div>
        {/* <div className="rybaRowCreatedAt">
          {ryba.shared ? (
            <span
              style={{
                color: "white",
                fontWeight: "100",
              }}
            >
              Публичный
            </span>
          ) : (
            "Личный"
          )}
        </div> */}
        <div className="rybaRowCreatedAt">
          {array2.length > 0 && index === 0 && (
            <div>
              {array2.map((p) => (
                <p className="standardRybaTitle">
                  {p.numberTimesUsed ? p.numberTimesUsed : "-"}
                </p>
              ))}
            </div>
          )}
        </div>{" "}
        <div className="rybaRowCreatedAt">
          {array2.length > 0 && index === 0 && (
            <div>
              {array2.map((p) => (
                <p className="standardRybaTitle">
                  {p.shared ? "Публичный" : "-"}
                </p>
              ))}
            </div>
          )}
        </div>
        <div className="rybaRowCreatedAt">
          {array2.length > 0 && index === 0 && (
            <div>
              {array2.map((p) => (
                <p
                  className="standardRybaTitle"
                  onClick={() => {
                    rybaID.current = p._id;
                    setHowToImport(true);
                  }}
                >
                  {p.title}
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
    ));
  }
};
export default RybaRow;
