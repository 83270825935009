import TextareaAutosize from "react-textarea-autosize";
import { useState } from "react";
import EmojiPicker from "emoji-picker-react";
import { Theme } from "emoji-picker-react";
import { EmojiStyle } from "emoji-picker-react";

const AddButton = ({
  organINDEX,
  segmentINDEX,
  rybaObject,
  setAddButtonOpen,
  addButtonToRyba4,
  setFindButtonOpen,
  scrollY,
}) => {
  let p = 0;
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [zakl, setZakl] = useState("");
  const [emoji, setEmoji] = useState(false);

  // enter handler
  const submitEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (title === "") return alert("введите название");
      addButtonToRyba4(title, content, zakl);
      setAddButtonOpen(false);
    }
    if (e.key === "Tab") {
      e.preventDefault();
    }
    if (e.key === "Escape") {
      setAddButtonOpen(false);
    }
  };

  return (
    <div className="popupContainer" key={(p += 1)}>
      <div
        className="popupContent"
        style={{
          width: "450px",
          position: "absolute",
          top: `${scrollY + 150}px`,
        }}
        key={(p += 1)}
      >
        {/* <span>Название кнопки</span> */}
        <div
          style={{
            display: "flex",
          }}
        >
          <TextareaAutosize
            autoFocus
            className="inputPromptTextarea"
            placeholder="Название кнопки"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            onKeyDown={submitEnter}
          ></TextareaAutosize>
          <div
            className="pointer"
            style={{
              margin: "auto",
            }}
            onClick={() => {
              if (emoji) {
                return setEmoji(false);
              }
              setEmoji(true);
            }}
          >
            😁
          </div>
        </div>

        {emoji && (
          <div
            style={{
              alignItems: "center",
            }}
          >
            <EmojiPicker
              onEmojiClick={(e) => {
                console.log(e);
                setTitle((t) => (t += e.emoji));
              }}
              theme="dark"
              height="600px"
              width="450px"
              // searchDisabled={true}
            />
          </div>
        )}
        {/* <span>Текст в описание</span>
        <span
          style={{
            fontSize: "14px",
            color: "grey",
          }}
        >
          Формулировка будет вставляться в описание
        </span> */}
        <TextareaAutosize
          placeholder="Этот текст будет вставляться в описание"
          className="inputPromptTextarea"
          value={content}
          onChange={(e) => {
            setContent(e.target.value);
          }}
          onKeyDown={submitEnter}
        ></TextareaAutosize>
        {/* <span>Текст в заключение</span>
        <span
          style={{
            fontSize: "14px",
            color: "grey",
          }}
        >
          Формулировка будет вставляться в заключение. Можно оставить пустой
        </span> */}
        <TextareaAutosize
          className="inputPromptTextarea"
          placeholder="Это будет вставляться в заключение. Можно оставить пустой"
          value={zakl}
          onChange={(e) => {
            setZakl(e.target.value);
          }}
          onKeyDown={submitEnter}
        ></TextareaAutosize>

        <button
          className="saveChanges"
          onClick={(e) => {
            if (title === "") return alert("введите название");
            addButtonToRyba4(title, content, zakl);
            setAddButtonOpen(false);
          }}
        >
          Создать (Enter)
        </button>

        <button
          className="closeTextEditWindow"
          onClick={() => {
            setAddButtonOpen(false);
          }}
        >
          Закрыть (Esc)
        </button>
      </div>
    </div>
  );
};
export default AddButton;
