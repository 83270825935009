import React, { useRef, useState } from "react";
import "./SearchComponent.css"; // Import custom CSS for styling
import { useAuthContext } from "../../hooks/useAuthContext";
import CheckboxsVG from "../svg/CheckboxSVG";
import RadioSVG from "../svg/RadioSVG";
import Dropdown from "../svg/Dropdown";
import { protocolsCT } from "../../data/protocolsCT";
import { protocolsMRI } from "../../data/protocolsMRI";
import ConstructorSVG from "../svg/ConstructorSVG";

const SearchComponent = ({
  organINDEX,
  segmentINDEX,
  rybaObject,
  addButtonToRyba4,
  setAddButtonOpen,
  setFindButtonOpen,
}) => {
  const [data, setData] = useState();
  const [searchTerm, setSearchTerm] = React.useState(""); // State for search term
  const { user } = useAuthContext();
  const [loaded, setLoaded] = useState(true);
  const [page, setPage] = useState(1);
  const [type, setType] = useState([
    "checkbox",
    "radio",
    "select",
    "calculator",
  ]);
  // FILTERS //
  let protocolREF = useRef("&protocol=any");

  // Function to handle search button click
  const handleSearch = () => {
    console.log("Search triggered with term:", searchTerm);
    megaRequest();
    // You can call your database query function here with `searchTerm`
  };

  // Function to handle page change
  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(data.numFound / 30); // Calculate total pages based on results
    if (newPage >= 1 && newPage <= totalPages) {
      megaRequest(newPage);
      setPage(newPage);
    }
  };

  const searchQuery = async function (req, res, URL) {
    const response = await fetch(URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();
    if (response.status === 401) {
      window.localStorage.removeItem("user");
      window.location.reload();
    }

    if (response.ok) {
      // console.log(res.);
      console.log("Ryba list", json);
      setData(json);
      setLoaded(true);
    }
  };

  const megaRequest = (newPage) => {
    setLoaded(false);
    setData(undefined);
    let query = `${
      process.env.REACT_APP_BACKEND_URL
    }/shared/filters/findButtons/?modality=${
      rybaObject.modality
    }&title=${searchTerm}&page=${page}${protocolREF.current}&type=${type.join(
      "*"
    )}`;
    if (!newPage && page !== 1) {
      query = `${
        process.env.REACT_APP_BACKEND_URL
      }/shared/filters/findButtons/?modality=${
        rybaObject.modality
      }&title=${searchTerm}&page=${1}${protocolREF.current}&type=${type.join(
        "*"
      )}`;
      setPage(1);
    }
    if (newPage) {
      query = `${
        process.env.REACT_APP_BACKEND_URL
      }/shared/filters/findButtons/?modality=${
        rybaObject.modality
      }&title=${searchTerm}&page=${page}${protocolREF.current}&type=${type.join(
        "*"
      )}`;
    }
    console.log(query);
    searchQuery(undefined, undefined, query);
    console.log(query);
  };

  // enter handler
  const submitEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setPage(1);
      megaRequest();
      // setFindButtonOpen(false);
    }
    if (e.key === "Tab") {
      e.preventDefault();
    }
    if (e.key === "Escape") {
      // setFindButtonOpen(false);
    }
  };
  const replaceDots = (text, replacements) => {
    if (!text || !replacements) return text;

    const parts = text.split("...");
    return parts.map((part, index) => {
      const replacement = replacements[index]?.join("/") || null; // Проверяем замену

      if (replacement !== null) {
        // Если есть замена, возвращаем массив с текстом и <span>
        return [
          part,
          <span
            key={index}
            className="replacement"
            style={{
              color: "grey",
              textDecoration: "underline",
            }}
          >
            {replacement}
          </span>,
        ];
      } else {
        // Если нет замены, возвращаем только текущую часть + "..." (если это не последняя часть)
        if (index < parts.length - 1) {
          return [part, "..."];
        }
        return part; // Для последней части ничего не добавляем
      }
    });
  };

  const addOrRemoveType = (typeArgument) => {
    if (type.includes(typeArgument)) {
      let t = [...type];
      t.splice(t.indexOf(typeArgument), 1);
      setType(t);
    } else {
      let t = [...type];
      t.push(typeArgument);
      setType(t);
    }
    console.log(type);
  };

  return (
    <div className="search-container">
      {/* Search Input and Button */}
      <div className="search-input-container">
        <textarea
          className="search-textarea"
          placeholder="Введите текст для поиска..."
          value={searchTerm}
          onKeyDown={(e) => {
            submitEnter(e);
          }}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="search-button" onClick={handleSearch}>
          Искать
        </button>{" "}
        <button
          className="search-button"
          onClick={() => {
            setFindButtonOpen(false);
          }}
        >
          Закрыть
        </button>
      </div>
      <div className="filtersContainer">
        <div className="filtersSegment">
          <div
            style={{
              width: "200px",
              padding: "3px",
            }}
          >
            Фильтр по протоколу
          </div>
          <div>
            {/* {user.id === "67093dbd69080216404eb5e8" && ( */}
            <select
              style={{
                width: "500px",
              }}
              className="myFirstDevSelect"
              // value={idea.status}
              onChange={(e) => {
                protocolREF.current = `&protocol=${e.target.value}`;
                megaRequest();
              }}
            >
              <option value="any">Все протоколы</option>
              {rybaObject.modality === "КТ" &&
                protocolsCT.map((p) => <option value={p}>{p}</option>)}
              {rybaObject.modality === "МРТ" &&
                protocolsMRI.map((p) => <option value={p}>{p}</option>)}
            </select>
            {/* )} */}
          </div>
        </div>
        <div className="filtersSegment">
          <div
            style={{
              width: "200px",
              padding: "3px",
            }}
          >
            Фильтр по типу
          </div>
          <div>
            <label>
              Обычная
              <input
                onClick={(e) => {
                  addOrRemoveType("checkbox");
                }}
                type="checkbox"
                checked={type.includes("checkbox") ? true : false}
              />
            </label>{" "}
            <label>
              Группа
              <input
                onClick={(e) => {
                  addOrRemoveType("radio");
                }}
                type="checkbox"
                checked={type.includes("radio") ? true : false}
              />
            </label>{" "}
            <label>
              Меню
              <input
                onClick={(e) => {
                  addOrRemoveType("select");
                }}
                type="checkbox"
                checked={type.includes("select") ? true : false}
              />
            </label>{" "}
            <label>
              Калькулятор
              <input
                onClick={(e) => {
                  addOrRemoveType("calculator");
                }}
                type="checkbox"
                checked={type.includes("calculator") ? true : false}
              />
            </label>
          </div>
        </div>
      </div>

      {/* Results Count */}
      {data && data.buttons.length > 0 && (
        <span className="resultsCount">
          {data && data.numFound >= 300
            ? `Найдено ${data.numFound} кнопок, дубликаты скрыты, покажем первые 300`
            : `Найдено ${data.numFound} кнопок, дубликаты скрыты`}
          {". Нажмите левой кнопкой, чтобы добавить"}
        </span>
      )}
      {/* Pagination */}
      {data && (
        <div className="pagination-container">
          {Array.from(
            { length: Math.min(10, Math.ceil(data.numFound / 30)) },
            (_, i) => i + 1
          ).map((pageNum) => (
            <span
              key={pageNum}
              className={`page-number ${pageNum === page ? "active-page" : ""}`}
              onClick={() => handlePageChange(pageNum)}
              onMouseEnter={(e) => (e.target.style.cursor = "pointer")}
            >
              {pageNum}
            </span>
          ))}
        </div>
      )}
      {!loaded && <div className="loaderSpinner"></div>}
      {/* Search Results */}
      <div className="results-container">
        {data && data.buttons.length > 0 ? (
          data.buttons.map((item, index) => (
            <div
              key={index}
              className="result-item"
              onClick={() => {
                addButtonToRyba4(1, 1, 1, item.buttons);
                setFindButtonOpen(false);
              }}
            >
              {item.buttons.type === "checkbox" && (
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CheckboxsVG width={24} height={24} color={"grey"} />
                  </div>
                  <p className="result-title" style={{ fontWeight: "500" }}>
                    {item.buttons.title}
                  </p>
                  <p
                    className="result-content"
                    style={{ fontWeight: "100", fontSize: "14px" }}
                  >
                    {/* Обработка content с заменой ... */}
                    {item.buttons.content
                      ? replaceDots(item.buttons.content, item.buttons.choices)
                      : null}

                    <br />

                    {/* Обработка zakl с заменой ... */}
                    {item.buttons.zakl ? (
                      <span
                        style={{
                          fontStyle: "italic",
                          fontSize: "12px",
                          color: "grey",
                        }}
                      >
                        ЗАКЛЮЧЕНИЕ:{" "}
                        {replaceDots(
                          item.buttons.zakl,
                          item.buttons.choicesZakl
                        )}
                      </span>
                    ) : (
                      <span
                        style={{
                          fontStyle: "italic",
                          fontSize: "12px",
                          color: "grey",
                        }}
                      >
                        Заключение не прописано
                      </span>
                    )}
                  </p>
                </div>
              )}
              {item.buttons.type === "calculator" && (
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ConstructorSVG
                      width={24}
                      height={24}
                      color={"lightyellow"}
                    />
                  </div>
                  <p className="result-title" style={{ fontWeight: "500" }}>
                    {item.buttons.title}
                  </p>
                </div>
              )}
              {item.buttons.type === "radio" && (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    <RadioSVG width={24} height={24} color={"grey"} />
                  </div>
                  <div
                    style={{
                      display: "flex" /* Enable Flexbox */,
                      alignItems: "center" /* Vertically center the content */,
                      height:
                        "100%" /* Ensure the div takes full height (if needed) */,
                    }}
                  >
                    <p
                      className="result-title"
                      style={{
                        fontWeight: "500",
                        margin: 0 /* Remove default margin for better alignment */,
                        paddingLeft: "10px",
                        overflowWrap: "break-word",
                        paddingRight: "10px",
                      }}
                    >
                      {item.buttons.title}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {item.buttons.radios.map((r, index) => (
                      <div
                        className="radioSearch"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",

                            alignItems:
                              "center" /* Vertically center the content */,
                            height: "100%",
                          }}
                        >
                          <span
                            className="result-title"
                            style={{
                              fontWeight: "100",
                              width: "150px",
                              color: "darkgrey",
                              fontSize: "16px",
                              padding: "4px",
                              overflowWrap: "break-word",
                            }}
                          >
                            {r.title}
                          </span>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span
                              className="result-content"
                              style={{
                                fontWeight: "100",
                                fontSize: "14px",
                                width: "650px",
                              }}
                            >
                              {/* Обработка content с заменой ... */}
                              {r.content
                                ? replaceDots(r.content, r.choices)
                                : null}

                              <br />

                              {/* Обработка zakl с заменой ... */}
                              {r.zakl ? (
                                <span
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "12px",
                                    color: "grey",
                                  }}
                                >
                                  ЗАКЛЮЧЕНИЕ:{" "}
                                  {replaceDots(r.zakl, r.choicesZakl)}
                                </span>
                              ) : (
                                <span
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "12px",
                                    color: "grey",
                                  }}
                                >
                                  Заключение не прописано
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {item.buttons.type === "select" && (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    <Dropdown width={24} height={24} color={"grey"} />
                  </div>
                  <div
                    style={{
                      display: "flex" /* Enable Flexbox */,
                      alignItems: "center" /* Vertically center the content */,
                      height:
                        "100%" /* Ensure the div takes full height (if needed) */,
                    }}
                  >
                    <p
                      className="result-title"
                      style={{
                        fontWeight: "500",
                        margin: 0 /* Remove default margin for better alignment */,
                        paddingLeft: "10px",
                        overflowWrap: "break-word",
                        paddingRight: "10px",
                      }}
                    >
                      {item.buttons.title}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {item.buttons.options.map((r, index) => (
                      <div
                        className="radioSearch"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",

                            alignItems:
                              "center" /* Vertically center the content */,
                            height: "100%",
                          }}
                        >
                          <span
                            className="result-title"
                            style={{
                              fontWeight: "100",
                              width: "150px",
                              color: "darkgrey",
                              fontSize: "16px",
                              padding: "4px",
                              overflowWrap: "break-word",
                            }}
                          >
                            {r.title}
                          </span>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span
                              className="result-content"
                              style={{
                                fontWeight: "100",
                                fontSize: "14px",
                                width: "650px",
                              }}
                            >
                              {/* Обработка content с заменой ... */}
                              {r.content
                                ? replaceDots(r.content, r.choices)
                                : null}

                              <br />

                              {/* Обработка zakl с заменой ... */}
                              {r.zakl ? (
                                <span
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "12px",
                                    color: "grey",
                                  }}
                                >
                                  ЗАКЛЮЧЕНИЕ:{" "}
                                  {replaceDots(r.zakl, r.choicesZakl)}
                                </span>
                              ) : (
                                <span
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "12px",
                                    color: "grey",
                                  }}
                                >
                                  Заключение не прописано
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}{" "}
              <div className="foundButtonStats">
                <p>кликов: {item.buttons.used}</p>
              </div>
            </div>
          ))
        ) : (
          <p className="no-results">Нет результатов</p>
        )}
      </div>
    </div>
  );
};

export default SearchComponent;
