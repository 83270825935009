const AreYouSureGeneric = ({ setAreYouSureOpen, shureFunction, text }) => {
  const enter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      shureFunction();
      setAreYouSureOpen(false);
    }
  };

  return (
    <div
      className="popupContainer"
      onKeyDown={(e) => {
        enter(e);
      }}
    >
      <div
        className="constructorWindow"
        style={{
          position: "fixed",
        }}
      >
        <p className="name">{text}</p>
        <button
          onClick={() => {
            setAreYouSureOpen(false);
            shureFunction();
          }}
        >
          ОК
        </button>
        <button
          onClick={() => {
            setAreYouSureOpen(false);
          }}
        >
          Отмена
        </button>
      </div>
    </div>
  );
};
export default AreYouSureGeneric;
