import React, { useEffect, useState } from "react";
import { evaluate } from "mathjs";
import TextareaAutosize from "react-textarea-autosize";
import ConstructorOfLogic from "./ConstructorOfLogic";
import "./CalculatorRedactor.css";
import Icon from "../../redactorComponents/icons/icons";
import { notificationTheBest } from "../../../rootComponents/NotificationService";
import CloseButton from "../../CloseButton";
import ImageUploader from "../../../AIcomponents/ImageUploader";

const CalculatorRedactor = ({
  button,
  rybaObject,
  organIndex,
  segmentIndex,
  buttonIndex,
  setRybaObject,
  setCalculatorEditorOpen,
  saveRybaChanges,
  isInRyba4 = false,
  setOriginalRyba = (r) => {
    console.log(r);
  },
  id,
}) => {
  // ORIGINAL CALCULATOR
  const [calculator, setCalculator] = useState({ ...button });
  const [calculations, setCalculations] = useState([]);
  const [opisanie, setOpisanie] = useState("");
  const [zakluchenie, setZakluchenie] = useState("");
  // State for adding formula
  const [addFormula, setAddFormula] = useState(false);
  const [addVariableOpen, setAddVariableOpen] = useState(false);
  const [addCheckboxOpen, setAddCheckboxOpen] = useState(false);
  const [addRadiosOpen, setAddRadiosOpen] = useState(false);
  const [addListOpen, setAddListOpen] = useState(false);
  const [addMenuOpen, setAddMenuOpen] = useState(false);
  const [currentFormula, setCurrentFormula] = useState([]);
  const [editedFormulaIndex, setEditedFormulaIndex] = useState(undefined);
  const [isEditingFormula, setIsEditingFormula] = useState(false);
  //temporary state
  const [varTitle, setVarTitle] = useState("");
  const [questionTitle, setQuestionTitle] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [listDefault, setListDefault] = useState({
    title: "",
    type: "list",
    value: undefined,
    radios: [
      {
        title: "",
        checked: false,
      },
      {
        title: "",
        checked: false,
      },
    ],
  });
  const [radioDefault, setRadioDefault] = useState({
    title: "",
    inline: false,
    value: undefined,
    type: "radio",
    radios: ["", ""],
  });
  const [menuDefault, setMenuDefault] = useState({
    title: "",
    value: undefined,
    type: "menu",
    radios: ["", ""],
  });
  /////// defaults for adding
  const emptyStep = {
    conditions: [],
  };
  const radioGroupDefault = {
    title: "",
    type: "radio",
    inline: false,
    value: undefined,
    radios: ["", ""],
  };
  const listGroupDefault = {
    title: "",
    type: "list",
    value: undefined,
    radios: [
      {
        title: "",
        checked: false,
      },
      {
        title: "",
        checked: false,
      },
    ],
  };
  const menuGroupDefault = {
    title: "",
    value: undefined,
    type: "menu",
    radios: ["", ""],
  };

  const addVariable = (title, placeholder = "цифра") => {
    if (varTitle === "") return alert("Введи переменную");
    let c = { ...calculator };
    c.values.push({
      title,
      placeholder,
      type: "variable",
      value: undefined,
    });
    setCalculator(c);

    setVarTitle("");
    setPlaceholder("");
  };
  const addQuestion = (title) => {
    if (questionTitle === "") return alert("Введи вопрос");
    let c = { ...calculator };
    c.values.push({
      title,
      value: "Нет",
      type: "boolean",
    });
    setCalculator(c);
    setQuestionTitle("");
  };

  function replaceTitlesWithValuesLiteral(inputString, objectsArray) {
    let modifiedString = inputString;

    objectsArray.forEach((obj) => {
      // Create a regex to match the title as a whole word
      const regex = new RegExp(`${obj.title}`, "g");
      modifiedString = modifiedString.replace(
        regex,
        obj.value !== undefined ? obj.value : ""
      ); // Replace title with value
    });

    return modifiedString;
  }
  function replaceTitlesWithValues(input, objects) {
    objects.forEach((obj) => {
      const escapedTitle = obj.title.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      const regex = new RegExp(`\\(${escapedTitle}\\)`, "g");
      input = input.replace(regex, obj.value !== undefined ? obj.value : "");
    });

    return input;
  }

  const oiDaSamSmogu = (n) => {
    if (n < 1) {
      return n.toFixed(2);
    } else if (n >= 1 && n <= 10) {
      return n.toFixed(1);
    } else if (n > 10) {
      return n.toFixed();
    } else {
      return n.toFixed(3);
    }
  };
  const megaCalculation = (preview) => {
    let finalString = "";
    let finalStringZakl = "";
    let cs = [];
    let variablesAndFormulasCalculations = [...calculator.values];

    calculator.formulas.map((formula, i) => {
      let calculation = [];

      formula.map((f) => {
        calculation.push(f.title);
      });

      let finalCalcString = replaceTitlesWithValuesLiteral(
        calculation.join(""),
        calculator.values.filter((a) => a.type === "variable")
      );
      let calcResult = undefined;
      try {
        calcResult = evaluate(finalCalcString);
      } catch (err) {
        console.log(err);
        calcResult = "не ввели циферки";
      }

      variablesAndFormulasCalculations.push({
        title: `формула${i + 1}`,
        value:
          calcResult !== "не ввели циферки"
            ? oiDaSamSmogu(calcResult)
            : "не ввели циферки",
      });
    });
    ////////
    function getValueByTitle(title, array) {
      const foundObject = array.find((obj) => obj.title === title);
      return foundObject ? foundObject.value : undefined;
    }
    ////////
    console.log("итоговое: ", variablesAndFormulasCalculations);

    [...calculator.steps, ...calculator.stepsZakl].map((s, index) => {
      let ifAllConditionsAreTrue = [];
      if (s.logic.length === 0) {
        finalString += s.output;
        finalStringZakl += s.outputZakl;
        return;
      }
      s.logic.map((l, lindex) => {
        const value = Number(
          getValueByTitle(l.title, variablesAndFormulasCalculations)
        );
        // if (value === undefined) return;

        if (l.type === ">" && value > Number(l.value)) {
          ifAllConditionsAreTrue.push(true);
        } else if (l.type === ">" && value <= Number(l.value)) {
          ifAllConditionsAreTrue.push(false);
        }
        if (l.type === ">=" && value >= Number(l.value)) {
          ifAllConditionsAreTrue.push(true);
        } else if (l.type === ">=" && value < Number(l.value)) {
          ifAllConditionsAreTrue.push(false);
        }
        if (l.type === "<" && value < Number(l.value)) {
          ifAllConditionsAreTrue.push(true);
        } else if (l.type === "<" && value >= Number(l.value)) {
          ifAllConditionsAreTrue.push(false);
        }
        if (l.type === "<=" && value <= Number(l.value)) {
          ifAllConditionsAreTrue.push(true);
        } else if (l.type === "<=" && value > Number(l.value)) {
          ifAllConditionsAreTrue.push(false);
        }
        if (l.type === "=" && value === Number(l.value)) {
          ifAllConditionsAreTrue.push(true);
        } else if (l.type === "=" && value !== Number(l.value)) {
          ifAllConditionsAreTrue.push(false);
        }
        if (l.type === "comparison") {
          const value2 = Number(
            getValueByTitle(l.title2, variablesAndFormulasCalculations)
          );
          // if (value2 === undefined) return;
          if (l.type2 === ">" && value > value2) {
            ifAllConditionsAreTrue.push(true);
          } else if (l.type2 === ">" && value <= value2) {
            ifAllConditionsAreTrue.push(false);
          }
          if (l.type2 === ">=" && value >= value2) {
            ifAllConditionsAreTrue.push(true);
          } else if (l.type2 === ">=" && value < value2) {
            ifAllConditionsAreTrue.push(false);
          }
          if (l.type2 === "<" && value < value2) {
            ifAllConditionsAreTrue.push(true);
          } else if (l.type2 === "<" && value >= value2) {
            ifAllConditionsAreTrue.push(false);
          }
          if (l.type2 === "<=" && value <= value2) {
            ifAllConditionsAreTrue.push(true);
          } else if (l.type2 === "<=" && value > value2) {
            ifAllConditionsAreTrue.push(false);
          }
          if (l.type2 === "=" && value === value2) {
            ifAllConditionsAreTrue.push(true);
          } else if (l.type2 === "=" && value !== value2) {
            ifAllConditionsAreTrue.push(false);
          }
        }
        if (
          l.type === "boolean" &&
          l.value ===
            calculator.values[
              calculator.values.findIndex(
                (i) => i.title === l.title && i.type === "boolean"
              )
            ].value
        ) {
          ifAllConditionsAreTrue.push(true);
        } else if (
          l.type === "boolean" &&
          l.value !==
            calculator.values[
              calculator.values.findIndex(
                (i) => i.title === l.title && i.type === "boolean"
              )
            ].value
        ) {
          ifAllConditionsAreTrue.push(false);
        }
        if (
          l.type === "isDefined" &&
          l.value === "Да" &&
          calculator.values[
            calculator.values.findIndex((i) => i.title === l.title)
          ].value !== undefined
        ) {
          ifAllConditionsAreTrue.push(true);
        } else if (
          l.type === "isDefined" &&
          l.value === "Нет" &&
          calculator.values[
            calculator.values.findIndex((i) => i.title === l.title)
          ].value === undefined
        ) {
          ifAllConditionsAreTrue.push(true);
        } else if (
          l.type === "isDefined" &&
          l.value === "Нет" &&
          calculator.values[
            calculator.values.findIndex((i) => i.title === l.title)
          ].value !== undefined
        ) {
          ifAllConditionsAreTrue.push(false);
        } else if (
          l.type === "isDefined" &&
          l.value === "Да" &&
          calculator.values[
            calculator.values.findIndex((i) => i.title === l.title)
          ].value === undefined
        ) {
          ifAllConditionsAreTrue.push(false);
        }
        if (
          l.type === "radio" &&
          l.value ===
            calculator.values[
              calculator.values.findIndex((i) => i.title === l.title)
            ].value
        ) {
          ifAllConditionsAreTrue.push(true);
        } else if (
          l.type === "radio" &&
          l.value !==
            calculator.values[
              calculator.values.findIndex((i) => i.title === l.title)
            ].value
        ) {
          ifAllConditionsAreTrue.push(false);
        }
        if (
          l.type === "menu" &&
          l.value ===
            calculator.values[
              calculator.values.findIndex(
                (i) => i.title === l.title && i.type === "menu"
              )
            ].value
        ) {
          ifAllConditionsAreTrue.push(true);
        } else if (
          l.type === "menu" &&
          l.value !==
            calculator.values[
              calculator.values.findIndex(
                (i) => i.title === l.title && i.type === "menu"
              )
            ].value
        ) {
          ifAllConditionsAreTrue.push(false);
        }
      });
      if (s.logicType === "and") {
        if (
          ifAllConditionsAreTrue.length > 0 &&
          !ifAllConditionsAreTrue.includes(false)
        ) {
          finalString += s.output;
          finalStringZakl += s.outputZakl;
        }
      }
      if (s.logicType === "or") {
        if (
          ifAllConditionsAreTrue.length > 0 &&
          ifAllConditionsAreTrue.includes(true)
        ) {
          finalString += s.output;
          finalStringZakl += s.outputZakl;
        }
      }
    });

    setCalculations(cs);
    setOpisanie(
      replaceTitlesWithValues(finalString, variablesAndFormulasCalculations)
    );
    setZakluchenie(
      replaceTitlesWithValues(finalStringZakl, variablesAndFormulasCalculations)
    );
    setCalculations(variablesAndFormulasCalculations);
  };
  const addNumber = (title) => {
    let c = [...currentFormula];
    c.push({
      title,
      type: "number",
    });
    setCurrentFormula(c);
  };

  const setAllValuesUndefined = () => {
    let c = { ...calculator };
    c.values.map((v) => {
      v.value = undefined;
      if (v.type === "list") {
        v.radios.map((r) => (r.checked = false));
      }
    });
    return c;
  };

  const handleValuesDisplayREDACTOR = (value, index) => {
    if (value.type === "variable") {
      return (
        <div className="redactorElement variableRedactor">
          {" "}
          <div
            style={{
              paddingTop: "8px",
              marginRight: "8px",
            }}
          >
            ПЕРЕМЕННАЯ
          </div>
          <div>
            <input
              style={{
                color: "orange",
                fontSize: "1.05rem",
                textAlign: "center",
                background: "#121212",
                border: "none",
                padding: "3px",
                width: "100px",
                borderRadius: "6px",
                margin: "3px",
              }}
              type="text"
              placeholder={"переменная"}
              value={value.title}
              onChange={(e) => {
                let c = { ...calculator };
                c.values[index].title = e.target.value;
                setCalculator(c);
              }}
            />

            <input
              style={{
                color: "white",
                fontWeight: "100",
                background: "#121212",
                border: "none",
                padding: "3px",
                // width: "30px",
                borderRadius: "6px",
                margin: "3px",
              }}
              type="text"
              placeholder={"пояснялка"}
              value={value.placeholder}
              onChange={(e) => {
                let c = { ...calculator };
                c.values[index].placeholder = e.target.value;
                setCalculator(c);
              }}
            />

            <button
              className="deleteButtonUniversal"
              onClick={() => {
                let c = { ...calculator };
                c.values.splice(index, 1);
                setCalculator(c);
              }}
            >
              Удалить переменную
            </button>
          </div>
        </div>
      );
    }
    if (value.type === "boolean") {
      return (
        <div className="redactorElement booleanEditing">
          <div
            style={{
              padding: "8px",
            }}
          >
            ВОПРОС
          </div>
          <TextareaAutosize
            style={{
              width: "300px",
              margin: "5px",
            }}
            placeholder="Введите вопрос"
            value={value.title}
            onChange={(e) => {
              let c = { ...calculator };
              c.values[index].title = e.target.value;
              setCalculator(c);
            }}
          />

          <button
            className="deleteButtonUniversal"
            onClick={() => {
              let c = { ...calculator };
              c.values.splice(index, 1);
              setCalculator(c);
            }}
          >
            Удалить вопрос
          </button>
        </div>
      );
    }
    if (value.type === "radio") {
      return (
        <div className="redactorElement menuRedactor">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <p
                style={{
                  padding: "0px",
                  margin: "0px",
                }}
              >
                ТЕСТИК{" "}
              </p>
            </div>
            <div>
              <TextareaAutosize
                placeholder="Название группы кнопок"
                style={{
                  textAlign: "center",
                  width: "150px",
                }}
                value={value.title}
                onChange={(e) => {
                  let c = { ...calculator };
                  c.values[index].title = e.target.value;
                  setCalculator(c);
                }}
              />
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  value={calculator.values[index].inline}
                  onClick={(e) => {
                    let c = { ...calculator };
                    if (calculator.values[index].inline === false) {
                      c.values[index].inline = true;
                    } else {
                      c.values[index].inline = false;
                    }
                    setCalculator(c);
                  }}
                />
                Отображать в линию
              </label>
            </div>
          </div>

          <div>
            <div>
              {value.radios.map((r, radioIndex) => (
                <div
                  style={{
                    display: "flex",
                    width: "300px",
                    alignItems: "left",
                    marginLeft: "10px",
                  }}
                >
                  <TextareaAutosize
                    type="text"
                    value={r}
                    onChange={(e) => {
                      let c = { ...calculator };
                      c.values[index].radios[radioIndex] = e.target.value;
                      setCalculator(c);
                    }}
                    placeholder="название опции"
                  />
                  <span>
                    <span
                      className="pointer"
                      onClick={() => {
                        let c = { ...calculator };
                        c.values[index].radios.splice(radioIndex, 0, r);

                        setCalculator(c);
                      }}
                    >
                      <Icon title="duplicate" width={18} />
                    </span>
                    {radioIndex > 0 && (
                      <span
                        className="pointer"
                        onClick={() => {
                          let c = { ...calculator };
                          let a = c.values[index].radios[radioIndex];
                          let b = c.values[index].radios[radioIndex - 1];
                          c.values[index].radios[radioIndex] = b;
                          c.values[index].radios[radioIndex - 1] = a;
                          setCalculator(c);
                        }}
                      >
                        <Icon title="arrowUp" width={20} />
                      </span>
                    )}
                    {radioIndex <
                      calculator.values[index].radios.length - 1 && (
                      <span
                        className="pointer"
                        onClick={() => {
                          let c = { ...calculator };
                          let a = c.values[index].radios[radioIndex];
                          let b = c.values[index].radios[radioIndex + 1];
                          c.values[index].radios[radioIndex] = b;
                          c.values[index].radios[radioIndex + 1] = a;
                          setCalculator(c);
                        }}
                      >
                        <Icon title="arrowDown" width={20} />
                      </span>
                    )}
                    <span
                      className="pointer"
                      onClick={() => {
                        let c = { ...calculator };
                        c.values[index].radios.splice(radioIndex, 1);
                        setCalculator(c);
                      }}
                    >
                      <Icon title={"delete"} width={20} />
                    </span>
                  </span>
                </div>
              ))}
              <button
                onClick={() => {
                  let c = { ...calculator };
                  c.values[index].radios.push("");
                  setCalculator(c);
                }}
              >
                +
              </button>
            </div>
          </div>
        </div>
      );
    }
    if (value.type === "list") {
      return (
        <div className="redactorElement menuRedactor">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <p
                style={{
                  padding: "0px",
                  margin: "0px",
                }}
              >
                ПЕРЕЧИСЛЯТОР{" "}
              </p>
            </div>
            <div>
              <TextareaAutosize
                placeholder="Что перечисляем (например, сегменты лёгких)"
                style={{
                  textAlign: "center",
                  width: "150px",
                }}
                value={value.title}
                onChange={(e) => {
                  let c = { ...calculator };
                  c.values[index].title = e.target.value;
                  setCalculator(c);
                }}
              />
            </div>
          </div>

          <div>
            <div>
              {value.radios.map((r, radioIndex) => (
                <div
                  style={{
                    display: "flex",
                    width: "300px",
                    alignItems: "left",
                    marginLeft: "10px",
                  }}
                >
                  <span
                    style={{
                      padding: "2px",
                      margin: "2px",
                    }}
                  ></span>
                  <TextareaAutosize
                    type="text"
                    value={r.title}
                    onChange={(e) => {
                      let c = { ...calculator };
                      c.values[index].radios[radioIndex].title = e.target.value;
                      setCalculator(c);
                    }}
                    placeholder="название опции"
                  />
                  <span>
                    <span
                      className="pointer"
                      onClick={() => {
                        let c = { ...calculator };
                        c.values[index].radios.splice(radioIndex, 0, { ...r });

                        setCalculator(c);
                      }}
                    >
                      <Icon title="duplicate" width={18} />
                    </span>
                    {radioIndex > 0 && (
                      <span
                        className="pointer"
                        onClick={() => {
                          let c = { ...calculator };
                          let a = c.values[index].radios[radioIndex];
                          let b = c.values[index].radios[radioIndex - 1];
                          c.values[index].radios[radioIndex] = b;
                          c.values[index].radios[radioIndex - 1] = a;
                          setCalculator(c);
                        }}
                      >
                        <Icon title="arrowUp" width={20} />
                      </span>
                    )}
                    {radioIndex <
                      calculator.values[index].radios.length - 1 && (
                      <span
                        className="pointer"
                        onClick={() => {
                          let c = { ...calculator };
                          let a = c.values[index].radios[radioIndex];
                          let b = c.values[index].radios[radioIndex + 1];
                          c.values[index].radios[radioIndex] = b;
                          c.values[index].radios[radioIndex + 1] = a;
                          setCalculator(c);
                        }}
                      >
                        <Icon title="arrowDown" width={20} />
                      </span>
                    )}
                    <span
                      className="pointer"
                      onClick={() => {
                        let c = { ...calculator };
                        c.values[index].radios.splice(radioIndex, 1);
                        setCalculator(c);
                      }}
                    >
                      <Icon title={"delete"} width={20} />
                    </span>
                  </span>
                </div>
              ))}
              <button
                onClick={() => {
                  let c = { ...calculator };
                  c.values[index].radios.push({
                    title: "",
                    checked: false,
                  });
                  setCalculator(c);
                }}
              >
                +
              </button>
            </div>
          </div>
        </div>
      );
    }
    if (value.type === "menu") {
      return (
        <div className="redactorElement menuRedactor">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <p
                style={{
                  padding: "0px",
                  margin: "0px",
                }}
              >
                МЕНЮ{" "}
              </p>
            </div>
            <div>
              <TextareaAutosize
                placeholder="Название меню"
                style={{
                  textAlign: "center",
                  width: "150px",
                }}
                value={value.title}
                onChange={(e) => {
                  let c = { ...calculator };
                  c.values[index].title = e.target.value;
                  setCalculator(c);
                }}
              />
            </div>
          </div>

          <div>
            <div>
              {value.radios.map((r, radioIndex) => (
                <div
                  style={{
                    display: "flex",
                    width: "300px",
                    alignItems: "left",
                    marginLeft: "10px",
                  }}
                >
                  <TextareaAutosize
                    type="text"
                    value={r}
                    onChange={(e) => {
                      let c = { ...calculator };
                      c.values[index].radios[radioIndex] = e.target.value;
                      setCalculator(c);
                    }}
                    placeholder="название опции"
                  />
                  <span>
                    <span
                      className="pointer"
                      onClick={() => {
                        let c = { ...calculator };
                        c.values[index].radios.splice(radioIndex, 0, r);

                        setCalculator(c);
                      }}
                    >
                      <Icon title="duplicate" width={18} />
                    </span>
                    {radioIndex > 0 && (
                      <span
                        className="pointer"
                        onClick={() => {
                          let c = { ...calculator };
                          let a = c.values[index].radios[radioIndex];
                          let b = c.values[index].radios[radioIndex - 1];
                          c.values[index].radios[radioIndex] = b;
                          c.values[index].radios[radioIndex - 1] = a;
                          setCalculator(c);
                        }}
                      >
                        <Icon title="arrowUp" width={20} />
                      </span>
                    )}
                    {radioIndex <
                      calculator.values[index].radios.length - 1 && (
                      <span
                        className="pointer"
                        onClick={() => {
                          let c = { ...calculator };
                          let a = c.values[index].radios[radioIndex];
                          let b = c.values[index].radios[radioIndex + 1];
                          c.values[index].radios[radioIndex] = b;
                          c.values[index].radios[radioIndex + 1] = a;
                          setCalculator(c);
                        }}
                      >
                        <Icon title="arrowDown" width={20} />
                      </span>
                    )}
                    <span
                      className="pointer"
                      onClick={() => {
                        let c = { ...calculator };
                        c.values[index].radios.splice(radioIndex, 1);
                        setCalculator(c);
                      }}
                    >
                      <Icon title={"delete"} width={20} />
                    </span>
                  </span>
                </div>
              ))}
              <button
                onClick={() => {
                  let c = { ...calculator };
                  c.values[index].radios.push("");
                  setCalculator(c);
                }}
              >
                +
              </button>
            </div>
          </div>
        </div>
      );
    }
  };
  const handleValuesDisplay = (value, index) => {
    if (value.type === "variable") {
      return (
        <div className="variableContainer">
          {" "}
          <span>{value.placeholder}</span>
          <input
            style={{
              background: value.value && "rgba(70, 126, 177, 0.306)",
            }}
            type="number"
            placeholder={"введите цифру"}
            value={value.value}
            onChange={(e) => {
              let c = { ...calculator };
              c.values[index].value = e.target.value;
              if (e.target.value === "") {
                c.values[index].value = undefined;
              }
              setCalculator(c);
            }}
          />
        </div>
      );
    }
    if (value.type === "boolean") {
      return (
        <div className="booleanContainer">
          <label
            className="pointer"
            style={{
              color: calculator.values[index].value === "Да" && "#00bbff",
            }}
          >
            <input
              className="pointer"
              type="checkbox"
              value={value.value}
              onChange={(e) => {
                let c = { ...calculator };
                c.values[index].value === "Да"
                  ? (c.values[index].value = "Нет")
                  : (c.values[index].value = "Да");
                setCalculator(c);
              }}
            ></input>{" "}
            {value.title}
          </label>
        </div>
      );
    }
    if (value.type === "radio") {
      return (
        <div
          className="radioContainer"
          style={{
            // background: "#202020",
            margin: "5px",
            padding: "7px",
            height: "100%",
            // border: "1px solid green",
            display: !value.inline && "flex",
            justifyContent: !value.inline && "center",
          }}
        >
          <div
            style={{
              display: "inline-block",
              width: "350px",
              textAlign: !value.inline && "right",
              height: "100%",
            }}
          >
            <p
              style={{
                display: "inline-block",
                marginRight: "20px",
                marginLeft: "20px",
                marginBottom: "3px",
                marginTop: "10px",
                fontSize: "1.05rem",
                fontWeight: "100",
                textAlign: "right",
              }}
            >
              {value.title}{" "}
            </p>
          </div>

          <div
            style={{
              width: !value.inline && "300px",
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: !value.inline && "left",
            }}
          >
            {value.radios.map((r, radioIndex) => (
              <div
                style={{
                  display: value.inline && "inline-block",
                }}
              >
                <label
                  style={{
                    color: calculator.values[index].value === r && "#00bbff",
                  }}
                >
                  <input
                    type="radio"
                    value={r}
                    name={value.title}
                    onChange={(e) => {
                      let c = { ...calculator };
                      c.values[index].value = e.target.value;
                      console.log(c.values[index]);
                      setCalculator(c);
                    }}
                  />
                  {r}
                </label>
              </div>
            ))}
          </div>
        </div>
      );
    }
    if (value.type === "list") {
      return (
        <div
          className="radioContainer"
          style={{
            // background: "#202020",
            margin: "5px",
            padding: "7px",
            height: "100%",
            fontWeight: "100",
            // border: "1px solid green",
            // display: !value.inline && "flex",
            // justifyContent: !value.inline && "center",
          }}
        >
          <div
            style={{
              display: "inline-block",
              width: "350px",
              // textAlign: !value.inline && "right",
              height: "100%",
            }}
          >
            <p
              style={{
                display: "inline-block",
                marginRight: "20px",
                marginLeft: "20px",
                marginBottom: "5px",
                marginTop: "0px",
                fontSize: "1.05rem",
                fontWeight: "100",
                textAlign: "right",
              }}
            >
              {value.title}{" "}
            </p>
          </div>

          <div
            style={{
              // width: !value.inline && "300px",
              marginLeft: "auto",
              marginRight: "auto",
              // textAlign: !value.inline && "left",
            }}
          >
            {value.radios.map((r, radioIndex) => (
              <span
                className={`listSpan pointer ${r.checked && "checkedListSpan"}`}
                type="checkbox"
                onClick={() => {
                  let c = { ...calculator };
                  if (r.checked === true) {
                    c.values[index].radios[radioIndex].checked = false;
                  } else {
                    c.values[index].radios[radioIndex].checked = true;
                  }
                  let aggregate = [];
                  c.values[index].radios.map((v) => {
                    if (v.checked) {
                      aggregate.push(v.title);
                    }
                  });
                  if (aggregate.length === 0) {
                    c.values[index].value = undefined;
                  }
                  if (aggregate.length === 1) {
                    c.values[index].value = aggregate[0];
                  }
                  if (aggregate.length > 1) {
                    c.values[index].value = aggregate.join(", ");
                  }
                  setCalculator(c);
                }}
              >
                {r.title}
              </span>
            ))}
          </div>
        </div>
      );
    }
    if (value.type === "menu") {
      return (
        <div className="menuContainer">
          <span
            style={{
              padding: "3px",
            }}
          >
            {value.title}
          </span>
          <select
            className="calculatorSelect seseseSelect"
            style={{
              width: "120px",
              textAlign: "center",
              borderColor: value.value !== undefined && "#00bbff90",
              background: value.value !== undefined && "#5cbfe34e",
            }}
            onChange={(e) => {
              let rrr = { ...calculator };
              console.log("значение до смены", rrr.values[index].value);
              rrr.values[index].value =
                e.target.value === "" ? undefined : e.target.value;

              setCalculator(rrr);
            }}
          >
            <option value={""}>{"-"}</option>
            {value.radios.map((r) => (
              <option value={r} name={value.title}>
                {r}
              </option>
            ))}
          </select>
        </div>
      );
    }
  };
  function findFirstUnusedLetter(arr) {
    // Create a set to track used letters
    const usedLetters = new Set();

    // Iterate through the array of objects
    arr.forEach((obj) => {
      if (
        obj.title &&
        typeof obj.title === "string" &&
        obj.title.length === 1 &&
        /[a-z]/.test(obj.title)
      ) {
        usedLetters.add(obj.title); // Add the lowercase letter to the set
      }
    });

    // Iterate through the lowercase alphabet to find the first unused letter
    for (let charCode = 97; charCode <= 122; charCode++) {
      const letter = String.fromCharCode(charCode);
      if (!usedLetters.has(letter)) {
        console.log(letter); // Log the first unused letter
        return letter; // Return the first unused letter (optional)
      }
    }

    // If all letters are used, log a message
    console.log("All lowercase letters are used.");
    return null; // Return null if all letters are used
  }
  return (
    <div className={`calcRedactorContainer ${isInRyba4 && "forRyba4Fix"}`}>
      <div>
        <button
          onClick={() => {
            let c = { ...calculator };
            // c.image = {};
            // c.image.URL = undefined;
            // c.image.name = undefined;
            // c.image.key = undefined;
            console.log(c);
            // setCalculator(c);
          }}
        >
          Показать кальк
        </button>
        <button
          className="deleteButtonUniversal greenOnHover"
          onClick={() => {
            // setCalculatorEditorOpen(false);
            let r = { ...rybaObject };
            r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex] = {
              ...setAllValuesUndefined(),
            };
            saveRybaChanges();

            if (!isInRyba4) {
              setOriginalRyba(r);
            }
            setRybaObject(r);
          }}
        >
          {" "}
          Сохранить изменения и не выходить
        </button>{" "}
        <button
          className="deleteButtonUniversal greenOnHover"
          onClick={() => {
            setCalculatorEditorOpen(false);
            let r = { ...rybaObject };
            r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex] = {
              ...setAllValuesUndefined(),
            };

            saveRybaChanges();

            if (!isInRyba4) {
              setOriginalRyba(r);
            }
            setRybaObject(r);
          }}
        >
          {" "}
          Сохранить изменения и выйти
        </button>{" "}
        <button
          className="deleteButtonUniversal"
          onClick={() => {
            setCalculatorEditorOpen(false);
          }}
        >
          Закрыть редактор конструктора
        </button>{" "}
      </div>
      <div>
        <TextareaAutosize
          style={{
            fontSize: "20px",
            width: "300px",
            textAlign: "center",
            padding: "5px 10px",
          }}
          value={calculator.title}
          onChange={(e) => {
            let c = { ...calculator };
            c.title = e.target.value;
            setCalculator(c);
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // width: "1600px",
          // margin: "auto",
          paddingLeft: "auto",
          paddingRight: "auto",
        }}
      >
        <div>
          <fieldset
            style={{
              width: "850px",
              alignItems: "center",
            }}
          >
            <legend>Конструктор суперкнопки/калькулятора</legend>
            <fieldset>
              <legend>Создание элементов</legend>
              <div
                className="addElementsCalcContainer"
                style={{
                  display: "flex",
                }}
              >
                <button
                  onClick={() => {
                    setVarTitle(
                      findFirstUnusedLetter(
                        calculator.values.filter((v) => v.type === "variable")
                      )
                    );
                    setAddVariableOpen(true);
                  }}
                >
                  Добавить переменную
                </button>
                <button
                  onClick={() => {
                    setCurrentFormula([]);
                    setEditedFormulaIndex(undefined);
                    setAddFormula(true);
                  }}
                >
                  Добавить формулу
                </button>
                <button onClick={() => setAddCheckboxOpen(true)}>
                  Вопрос да/нет
                </button>
                <button onClick={() => setAddRadiosOpen(true)}>Тестик</button>
                <button onClick={() => setAddMenuOpen(true)}>Меню</button>
                <button onClick={() => setAddListOpen(true)}>
                  Перечислятор
                </button>
              </div>
              <div
                style={{
                  // border: "1px solid red",
                  padding: "10px",
                }}
              >
                {addVariableOpen && (
                  <div className="redactorElement variableRedactor">
                    {" "}
                    <div
                      style={{
                        paddingTop: "5px",
                        marginRight: "5px",
                        width: "100px",
                      }}
                    >
                      Создать переменную
                    </div>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <TextareaAutosize
                        style={{
                          margin: "2px",
                          textAlign: "center",
                          color: "orange",
                          width: "100px",
                        }}
                        type="number"
                        placeholder={"Название (a, b, c, например)"}
                        value={varTitle}
                        onChange={(e) => setVarTitle(e.target.value)}
                      />
                      <TextareaAutosize
                        style={{
                          fontWeight: "100",
                          textAlign: "center",
                        }}
                        type="text"
                        placeholder={"Пояснялка (рядом с полем ввода)"}
                        value={placeholder}
                        onChange={(e) => setPlaceholder(e.target.value)}
                      />

                      <button
                        style={{
                          background: "lightgreen",
                          padding: "6px 12px",
                          border: "none",
                          borderRadius: "6px",
                          marginLeft: "5px",
                        }}
                        onClick={() => {
                          addVariable(varTitle, placeholder);
                          setVarTitle("");
                          setPlaceholder("");
                          setAddVariableOpen(false);
                        }}
                      >
                        Добавить переменную
                      </button>
                      <button
                        className="deleteButtonUniversal"
                        onClick={() => {
                          setVarTitle("");
                          setPlaceholder("");
                          setAddVariableOpen(false);
                        }}
                      >
                        Отмена
                      </button>
                    </div>
                  </div>
                )}

                {addCheckboxOpen && (
                  <div className="redactorElement booleanEditing">
                    <div
                      style={{
                        padding: "8px",
                      }}
                    >
                      ВОПРОС
                    </div>
                    <TextareaAutosize
                      style={{
                        width: "300px",
                        margin: "5px",
                      }}
                      placeholder="Введите вопрос"
                      value={questionTitle}
                      onChange={(e) => setQuestionTitle(e.target.value)}
                    />

                    <button
                      style={{
                        background: "lightgreen",
                        padding: "6px 12px",
                        border: "none",
                        borderRadius: "6px",
                      }}
                      onClick={() => {
                        addQuestion(questionTitle);
                        setQuestionTitle("");
                        setAddCheckboxOpen(false);
                      }}
                    >
                      Добавить вопрос
                    </button>
                    <button
                      className="deleteButtonUniversal"
                      onClick={() => {
                        setQuestionTitle("");
                        setAddCheckboxOpen(false);
                      }}
                    >
                      Отмена
                    </button>
                  </div>
                )}
                {addListOpen && (
                  <div className="redactorElement menuRedactor">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            padding: "0px",
                            margin: "0px",
                          }}
                        >
                          ПЕРЕЧИСЛЯТОР{" "}
                        </p>
                      </div>
                      <div>
                        <TextareaAutosize
                          placeholder="Что перечисляем (например, сегменты лёгкого)"
                          style={{
                            textAlign: "center",
                          }}
                          value={listDefault.title}
                          onChange={(e) => {
                            let c = { ...listDefault };
                            c.title = e.target.value;
                            setListDefault(c);
                          }}
                        />
                      </div>
                    </div>

                    <div className="redactorElement menuRedactor">
                      <div>
                        {listDefault.radios.map((r, index) => (
                          <div>
                            <span
                              style={{
                                padding: "2px",
                                margin: "2px",
                              }}
                            >
                              {index + 1}
                            </span>
                            <TextareaAutosize
                              style={{
                                width: "200px",
                              }}
                              type="text"
                              value={r.title}
                              onChange={(e) => {
                                let c = { ...listDefault };
                                c.radios[index].title = e.target.value;
                                setListDefault(c);
                              }}
                              placeholder="элемент списка"
                            />
                            <span>
                              <span
                                className="pointer"
                                onClick={() => {
                                  let c = { ...listDefault };
                                  c.radios.splice(index, 1);
                                  setListDefault(c);
                                }}
                              >
                                <Icon title={"delete"} width={20} />
                              </span>
                            </span>
                          </div>
                        ))}
                        <button
                          onClick={() => {
                            let c = { ...listDefault };
                            c.radios.push({
                              title: "",
                              checked: false,
                            });
                            setListDefault(c);
                          }}
                        >
                          +
                        </button>
                      </div>
                    </div>

                    <button
                      style={{
                        background: "lightgreen",
                        padding: "6px 12px",
                        border: "none",
                        borderRadius: "6px",
                        margin: "auto",
                      }}
                      onClick={() => {
                        let c = { ...calculator };
                        c.values.push({ ...listDefault });
                        setCalculator(c);
                        setAddListOpen(false);
                        setListDefault(listGroupDefault);
                      }}
                    >
                      Добавить перечислятор
                    </button>
                    <button
                      style={{ margin: "auto" }}
                      className="deleteButtonUniversal"
                      onClick={() => {
                        setAddListOpen(false);
                        setListDefault(listGroupDefault);
                      }}
                    >
                      Отмена
                    </button>
                  </div>
                )}
                {addRadiosOpen && (
                  <div className="redactorElement menuRedactor">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            padding: "0px",
                            margin: "0px",
                          }}
                        >
                          ТЕСТИК{" "}
                        </p>
                      </div>
                      <div>
                        <TextareaAutosize
                          placeholder="Какой вопрос"
                          style={{
                            textAlign: "center",
                          }}
                          value={radioDefault.title}
                          onChange={(e) => {
                            let c = { ...radioDefault };
                            c.title = e.target.value;
                            setRadioDefault(c);
                          }}
                        />
                      </div>
                    </div>

                    <div>
                      <div>
                        {radioDefault.radios.map((r, index) => (
                          <div>
                            <span
                              style={{
                                padding: "2px",
                                margin: "2px",
                              }}
                            >
                              {index + 1}
                            </span>
                            <TextareaAutosize
                              style={{
                                width: "200px",
                              }}
                              type="text"
                              value={r}
                              onChange={(e) => {
                                let c = { ...radioDefault };
                                c.radios[index] = e.target.value;
                                setRadioDefault(c);
                              }}
                              placeholder="вариант ответа"
                            />
                            <span>
                              <span
                                className="pointer"
                                onClick={() => {
                                  let c = { ...radioDefault };
                                  c.radios.splice(index, 1);
                                  setRadioDefault(c);
                                }}
                              >
                                <Icon title={"delete"} width={20} />
                              </span>
                            </span>
                          </div>
                        ))}
                        <button
                          onClick={() => {
                            let c = { ...radioDefault };
                            c.radios.push("");
                            setRadioDefault(c);
                          }}
                        >
                          +
                        </button>
                      </div>
                    </div>

                    <button
                      style={{
                        background: "lightgreen",
                        padding: "6px 12px",
                        border: "none",
                        borderRadius: "6px",
                        margin: "auto",
                      }}
                      onClick={() => {
                        let c = { ...calculator };
                        c.values.push({ ...radioDefault });
                        setCalculator(c);
                        setAddRadiosOpen(false);
                        setRadioDefault(radioGroupDefault);
                      }}
                    >
                      Добавить кнопки
                    </button>
                    <button
                      style={{ margin: "auto" }}
                      className="deleteButtonUniversal"
                      onClick={() => {
                        setAddRadiosOpen(false);
                        setRadioDefault(radioGroupDefault);
                      }}
                    >
                      Отмена
                    </button>
                  </div>
                )}
                {addMenuOpen && (
                  <div className="redactorElement menuRedactor">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            padding: "0px",
                            margin: "0px",
                          }}
                        >
                          МЕНЮ{" "}
                        </p>
                      </div>
                      <div>
                        <TextareaAutosize
                          placeholder="Название меню"
                          style={{
                            textAlign: "center",
                          }}
                          value={menuDefault.title}
                          onChange={(e) => {
                            let c = { ...menuDefault };
                            c.title = e.target.value;
                            setMenuDefault(c);
                          }}
                        />
                      </div>
                    </div>

                    <div>
                      <div>
                        {menuDefault.radios.map((r, index) => (
                          <div>
                            <span
                              style={{
                                padding: "2px",
                                margin: "2px",
                              }}
                            >
                              {index + 1}
                            </span>
                            <TextareaAutosize
                              style={{
                                width: "200px",
                              }}
                              type="text"
                              value={r}
                              onChange={(e) => {
                                let c = { ...menuDefault };
                                c.radios[index] = e.target.value;
                                setMenuDefault(c);
                              }}
                              placeholder="название опции"
                            />
                            <span>
                              <span
                                className="pointer"
                                onClick={() => {
                                  let c = { ...menuDefault };
                                  c.radios.splice(index, 1);
                                  setMenuDefault(c);
                                }}
                              >
                                <Icon title={"delete"} width={20} />
                              </span>
                            </span>
                          </div>
                        ))}
                        <button
                          onClick={() => {
                            let c = { ...menuDefault };
                            c.radios.push("");
                            setMenuDefault(c);
                          }}
                        >
                          +
                        </button>
                      </div>
                    </div>

                    <button
                      style={{
                        background: "lightgreen",
                        padding: "6px 12px",
                        border: "none",
                        borderRadius: "6px",
                        margin: "auto",
                      }}
                      onClick={() => {
                        let r = { ...calculator };
                        r.values.push({ ...menuDefault });
                        setCalculator(r);
                        setMenuDefault(menuGroupDefault);
                        setAddMenuOpen(false);
                      }}
                    >
                      Добавить меню
                    </button>
                    <button
                      style={{ margin: "auto" }}
                      className="deleteButtonUniversal"
                      onClick={() => {
                        setMenuDefault(menuGroupDefault);
                        setAddMenuOpen(false);
                      }}
                    >
                      Отмена
                    </button>
                  </div>
                )}
                {addFormula && (
                  <div
                    style={{
                      width: "300px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <fieldset
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <legend>Редактор формулы</legend>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="formulaDisplay"
                          style={
                            {
                              // border: "2px solid darkgrey",
                              // borderRadius: "6px",
                              // width: "200px",
                              // margin: "3px",
                              // display: "inline-block",
                              // minHeight: "32px",
                            }
                          }
                        >
                          {currentFormula &&
                            currentFormula.map((f) => (
                              <span
                                style={{
                                  // fontStyle: "italic",
                                  color: f.type === "variable" && "orange",
                                }}
                              >
                                {f.type !== "boolean" &&
                                  f.title !== "sqrt" &&
                                  f.title}
                                {f.title === "sqrt" && "√"}
                              </span>
                            ))}
                          {currentFormula.length === 0 && (
                            <span
                              style={{
                                fontStyle: "italic",
                                color: "grey",
                              }}
                            >
                              Добавьте цифру или переменную
                            </span>
                          )}
                        </div>{" "}
                        <div
                          className="pointer"
                          style={{
                            background: "transparent",
                            padding: "0px",
                            margin: "0px",

                            display: "inline-block",
                          }}
                          onClick={() => {
                            let c = [...currentFormula];
                            c.pop();
                            setCurrentFormula(c);
                          }}
                        >
                          <Icon title="backspace" width={28} height={28} />
                        </div>
                      </div>

                      <div
                        style={{
                          padding: "4px",
                        }}
                      >
                        {calculator.values
                          .filter((v) => v.type === "variable")
                          .map((v) => (
                            <span
                              className="pointer"
                              style={{
                                background: "grey",
                                padding: "2px 4px",
                                borderRadius: "6px",
                                marginLeft: "4px",
                                color: "orange",
                              }}
                              onClick={() => {
                                let c = [...currentFormula];
                                c.push({
                                  title: v.title,
                                  value: undefined,
                                  type: "variable",
                                });

                                setCurrentFormula(c);
                              }}
                            >
                              {v.title}
                            </span>
                          ))}
                        {calculator.formulas.map((formula, formulaIndex) => (
                          <span
                            className="pointer"
                            style={{
                              background: "grey",
                              padding: "2px 4px",
                              borderRadius: "6px",
                              marginLeft: "4px",
                              color: "orange",
                            }}
                            onClick={() => {
                              let c = [...currentFormula];
                              c.push(...calculator.formulas[formulaIndex]);

                              setCurrentFormula(c);
                            }}
                          >
                            {`формула${formulaIndex + 1}`}
                          </span>
                        ))}
                      </div>
                      <div>
                        <div className="calculatorContainerFormula">
                          <button
                            onClick={() => {
                              addNumber("1");
                            }}
                          >
                            1
                          </button>
                          <button
                            onClick={() => {
                              addNumber("2");
                            }}
                          >
                            2
                          </button>
                          <button
                            onClick={() => {
                              addNumber("3");
                            }}
                          >
                            3
                          </button>
                          <button
                            onClick={() => {
                              addNumber("+");
                            }}
                          >
                            +
                          </button>
                        </div>
                        <div className="calculatorContainerFormula">
                          <button
                            onClick={() => {
                              addNumber("4");
                            }}
                          >
                            4
                          </button>
                          <button
                            onClick={() => {
                              addNumber("5");
                            }}
                          >
                            5
                          </button>
                          <button
                            onClick={() => {
                              addNumber("6");
                            }}
                          >
                            6
                          </button>
                          <button
                            onClick={() => {
                              addNumber("-");
                            }}
                          >
                            -
                          </button>
                        </div>
                        <div className="calculatorContainerFormula">
                          <button
                            onClick={() => {
                              addNumber("7");
                            }}
                          >
                            7
                          </button>
                          <button
                            onClick={() => {
                              addNumber("8");
                            }}
                          >
                            8
                          </button>
                          <button
                            onClick={() => {
                              addNumber("9");
                            }}
                          >
                            9
                          </button>
                          <button
                            onClick={() => {
                              addNumber("*");
                            }}
                          >
                            *
                          </button>
                        </div>
                        <div>
                          <button
                            onClick={() => {
                              addNumber("0");
                            }}
                          >
                            0
                          </button>
                          <button
                            onClick={() => {
                              addNumber(".");
                            }}
                          >
                            .
                          </button>
                          <button
                            onClick={() => {
                              addNumber("(");
                            }}
                          >
                            {"("}
                          </button>
                          <button
                            onClick={() => {
                              addNumber(")");
                            }}
                          >
                            {")"}
                          </button>
                          <button
                            onClick={() => {
                              addNumber("/");
                            }}
                          >
                            /
                          </button>{" "}
                        </div>
                        <div>
                          <button
                            onClick={() => {
                              addNumber("^");
                            }}
                          >
                            ^ возвести в степень
                          </button>
                        </div>
                        <div>
                          <button
                            onClick={() => {
                              addNumber("sqrt");
                            }}
                          >
                            √ (цифру в скобки)
                          </button>
                        </div>
                        <div>
                          <button
                            style={{
                              background: "lightgreen",
                            }}
                            onClick={() => {
                              let c = { ...calculator };
                              if (isEditingFormula === false) {
                                c.formulas.push([...currentFormula]);
                              }
                              if (isEditingFormula === true) {
                                c.formulas[editedFormulaIndex] = [
                                  ...currentFormula,
                                ];

                                setIsEditingFormula(false);
                                setEditedFormulaIndex(undefined);
                              }
                              setAddFormula(false);
                              setCurrentFormula([]);
                              setCalculator(c);
                            }}
                          >
                            Сохранить формулу
                          </button>
                        </div>
                        <div>
                          <button
                            style={{
                              background: "darkgrey",
                            }}
                            onClick={() => {
                              setAddFormula(false);
                              setCurrentFormula([]);
                              setIsEditingFormula(false);
                              setEditedFormulaIndex(undefined);
                            }}
                          >
                            Закрыть
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                )}
              </div>
            </fieldset>
            <div>
              <fieldset>
                <legend>Формулы</legend>
                {calculator.formulas.length === 0 && (
                  <span
                    style={{
                      fontStyle: "italic",
                      color: "grey",
                      width: "200px",
                    }}
                  >
                    Здесь будут отображаться добавленные Вами формулы
                  </span>
                )}
                {calculator.formulas.map((ff, ii) => (
                  <div className="redactorElement formulaEditing">
                    <span
                      style={{
                        marginTop: "8px",
                      }}
                    >
                      формула{ii + 1}
                    </span>
                    <div
                      style={{
                        border: "2px solid darkgrey",
                        borderRadius: "6px",
                        width: "fit-content",
                        padding: "4px",
                        margin: "3px",
                        display: "inline-block",
                      }}
                    >
                      {ff.map((f) => (
                        <span
                          style={{
                            fontStyle: "italic",
                            color: f.type === "variable" && "orange",
                          }}
                        >
                          {f.type !== "boolean" &&
                            f.title !== "sqrt" &&
                            f.title}
                          {f.title === "sqrt" && "√"}
                        </span>
                      ))}
                    </div>
                    <button
                      className="deleteButtonUniversal"
                      style={{
                        background: "#121212",
                        color: "white",
                      }}
                      onClick={() => {
                        setCurrentFormula(ff);
                        setIsEditingFormula(true);
                        setEditedFormulaIndex(ii);
                        setAddFormula(true);
                      }}
                    >
                      Редактировать формулу
                    </button>
                    <button
                      className="deleteButtonUniversal"
                      onClick={() => {
                        let c = { ...calculator };
                        c.formulas.splice(ii, 1);
                        setCalculator(c);
                      }}
                    >
                      Удалить формулу
                    </button>
                  </div>
                ))}
              </fieldset>
            </div>
            <div>
              {" "}
              <fieldset>
                <legend>Редактор элементов</legend>
                {[...calculator.values, ...calculator.formulas].length ===
                  0 && (
                  <span className="greySpan">
                    Добавьте элемент с помощью кнопок в разделе "Создание
                    элементов"
                  </span>
                )}
                <div className="redactorsCalcContainer">
                  {calculator.values.length > 0 &&
                    calculator.values.map((value, index) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "right",
                        }}
                      >
                        {handleValuesDisplayREDACTOR(value, index)}
                        <div
                          className="valueRedactorSegment"
                          style={{
                            display: "flex",
                            width: "170px",
                            justifyItems: "right",
                            justifyContent: "right",
                            alignItems: "right",
                          }}
                        >
                          <button
                            onClick={() => {
                              let c = { ...calculator };
                              c.values.splice(
                                index,
                                0,
                                JSON.parse(JSON.stringify(value))
                              );

                              setCalculator(c);
                            }}
                          >
                            <Icon title="duplicate" />
                          </button>
                          {index > 0 && (
                            <button
                              onClick={() => {
                                let c = { ...calculator };
                                let a = c.values[index];
                                let b = c.values[index - 1];
                                c.values[index] = b;
                                c.values[index - 1] = a;
                                setCalculator(c);
                              }}
                            >
                              <Icon title="arrowUp" />
                            </button>
                          )}
                          {index < calculator.values.length - 1 && (
                            <button
                              onClick={() => {
                                let c = { ...calculator };
                                let a = c.values[index];
                                let b = c.values[index + 1];
                                c.values[index] = b;
                                c.values[index + 1] = a;
                                setCalculator(c);
                              }}
                            >
                              <Icon title="arrowDown" />
                            </button>
                          )}
                          <button
                            onClick={() => {
                              let c = { ...calculator };
                              c.values.splice(index, 1);
                              setCalculator(c);
                            }}
                          >
                            <Icon title="delete" />
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
                <div>
                  <TextareaAutosize
                    placeholder="Здесь можно оставить описание калькулятора/инструкцию"
                    style={{
                      fontSize: "14px",
                      width: "400px",
                      textAlign: "Left",
                      padding: "5px 10px",
                      fontWeight: "100",
                    }}
                    value={calculator.description}
                    onChange={(e) => {
                      let c = { ...calculator };
                      c.description = e.target.value;
                      setCalculator(c);
                    }}
                  />
                </div>
              </fieldset>
            </div>

            <fieldset>
              <legend>Предпросмотр</legend>
              <div>
                <div className="descriptionCalc">{calculator.description}</div>
                {[...calculator.values, ...calculator.formulas].length ===
                  0 && (
                  <span className="greySpan">
                    Здесь можно будет увидеть, как будет выглядеть калькулятор
                    уже при использовании
                  </span>
                )}
                {calculator.values.length > 0 &&
                  calculator.values.map((value, index) =>
                    handleValuesDisplay(value, index)
                  )}
              </div>
              <button
                onClick={() => {
                  megaCalculation();
                }}
              >
                МЕГАРАЩОТ
              </button>
            </fieldset>

            <div
              style={{
                background: "#242424",
                padding: "10px",
                margin: "15px",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: "6px",
                width: "500px",
              }}
            >
              <p>
                {" "}
                <span style={{}}>ОПИСАНИЕ:</span>{" "}
                <span
                  style={{
                    fontWeight: "100",
                    color: "lightblue",
                  }}
                >
                  {opisanie === "" ? "-" : opisanie}
                </span>
              </p>
              <p>
                {" "}
                <span style={{}}>ЗАКЛЮЧЕНИЕ:</span>{" "}
                <span
                  style={{
                    fontWeight: "100",
                    color: "lightblue",
                  }}
                >
                  {zakluchenie === "" ? "-" : zakluchenie}
                </span>
              </p>
            </div>

            <p>
              {calculations.map((c, i) => (
                <p>{`${c.title}: ${c.value}`}</p>
              ))}
            </p>
          </fieldset>
          <fieldset>
            <legend>Картинка</legend>
            <div className="imageContainerCalc">
              <ImageUploader {...{ calculator, setCalculator }} />
            </div>
          </fieldset>
        </div>
        <div>
          {" "}
          <ConstructorOfLogic
            {...{
              calculator,
              setCalculator,
            }}
          />
        </div>
      </div>

      {/* <div>
        <fieldset>
          <legend>Выход</legend>
          <button onClick={() => {}}>Пощетат</button>
          {result && <p>ЦИФРА: {result}</p>}
          {resultString && <p>ОПИСАНИЕ: {resultString}</p>}
          {resultStringZakl && <p>ЗАКЛЮЧЕНИЕ: {resultStringZakl}</p>}
        </fieldset>
      </div> */}
    </div>
  );
};

export default CalculatorRedactor;
