import TextareaAutosize from "react-textarea-autosize";
import Duplicate from "../../svg/Duplicate";
import ArrowUp from "../../svg/ArrowUp";
import ArrowDown from "../../svg/ArrowDown";
import DeleteIcon from "../../svg/DeleteIcon";
import { useState, useRef } from "react";
import ChoicesConstructor from "./ChoicesConstructor";
import ChoicesConstructorZakl from "./ChoicesConstructorZakl";
import { notificationTheBest } from "../../../rootComponents/NotificationService";
import OpenMenu from "../../../AIcomponents/OpenMenu";
import Icon from "../../redactorComponents/icons/icons";

const newRadio = {
  title: "Название кнопки",
  content: "Текст кнопки. ",
  zakl: "",
  used: 0,
};

const RadioRedactor = ({
  button,
  rybaObject,
  organIndex,
  segmentIndex,
  buttonIndex,
  setRybaObject,
  setChoicesZakl,
  setChoices,
}) => {
  const [addingOption, setAddingOption] = useState(false);
  const [optionText, setOptionText] = useState();
  const [whichOption, setWhichOption] = useState();
  const [whichRadio, setWhichRadio] = useState();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [radioMenuOpen, setRadioMenuOpen] = useState(false);
  const [radioMenuOpenIndex, setRadioMenuOpenIndex] = useState("");
  const radioContextMenuRef = useRef();
  const [choicesOpen, setChoicesOpen] = useState(false);
  const [choicesZaklOpen, setChoicesZaklOpen] = useState(false);
  const [radioIndex, setRadioIndex] = useState(undefined);
  const [radioIndexZakl, setRadioIndexZakl] = useState(undefined);

  const closeOpenOrganContextMenu = (e) => {
    if (radioMenuOpen && !radioContextMenuRef.current?.contains(e.target)) {
      setRadioMenuOpen(false);
    }
  };
  document.addEventListener("mousedown", closeOpenOrganContextMenu);

  return (
    <>
      <div className="buttonEditingSegmentRADIOorSELECT">
        <div className="buttonTITLE2">Название кнопки</div>
        <div className="buttonCONTENT2">Текст кнопки</div>
        <div className="buttonZAKL2">В заключение</div>
      </div>
      {button &&
        button.radios.map((radio, index) => (
          <div
          // style={{
          //   border: "1px solid red",
          //   background: "brown",
          //   marginBottom: "5px",
          // }}
          >
            <div
              className="buttonEditingSegmentRADIOorSELECT"
              style={{
                marginBottom: "3px",
              }}
            >
              <div className="buttonTITLE2">
                <TextareaAutosize
                  value={
                    rybaObject.organs[organIndex].segments[segmentIndex]
                      .buttons[buttonIndex].radios[index].title
                  }
                  placeholder="Введите название кнопки"
                  onChange={(e) => {
                    console.log(e.target.value);
                    let r = { ...rybaObject };
                    r.organs[organIndex].segments[segmentIndex].buttons[
                      buttonIndex
                    ].radios[index].title = e.target.value;
                    setRybaObject(r);
                  }}
                ></TextareaAutosize>
              </div>
              <div className="buttonCONTENT2">
                {" "}
                <TextareaAutosize
                  placeholder="-"
                  value={
                    rybaObject.organs[organIndex].segments[segmentIndex]
                      .buttons[buttonIndex].radios[index].content
                  }
                  onChange={(e) => {
                    let r = { ...rybaObject };
                    r.organs[organIndex].segments[segmentIndex].buttons[
                      buttonIndex
                    ].radios[index].content = e.target.value;
                    setRybaObject(r);
                  }}
                ></TextareaAutosize>
              </div>

              <div className="buttonZAKL2">
                <TextareaAutosize
                  placeholder="-"
                  value={
                    rybaObject.organs[organIndex].segments[segmentIndex]
                      .buttons[buttonIndex].radios[index].zakl
                  }
                  onChange={(e) => {
                    let r = { ...rybaObject };
                    r.organs[organIndex].segments[segmentIndex].buttons[
                      buttonIndex
                    ].radios[index].zakl = e.target.value;
                    setRybaObject(r);
                  }}
                ></TextareaAutosize>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                {Object.hasOwn(radio, "choices") && (
                  <div
                    className="choicesMenuSegment"
                    onClick={() => {
                      setRadioIndex(index);
                      if (choicesOpen) {
                        setChoicesOpen(false);
                      } else setChoicesOpen(true);
                      if (choicesZaklOpen) {
                        setChoicesZaklOpen(false);
                      }
                    }}
                  >
                    <Icon
                      title="addChoices"
                      fill={
                        choicesOpen && radioIndex === index ? "orange" : "white"
                      }
                    />
                  </div>
                )}
                {Object.hasOwn(radio, "choicesZakl") && (
                  <div
                    className="choicesMenuSegment"
                    onClick={() => {
                      setRadioIndexZakl(index);
                      if (choicesZaklOpen) {
                        setChoicesZaklOpen(false);
                      } else setChoicesZaklOpen(true);
                      if (choicesOpen) {
                        setChoicesOpen(false);
                      }
                    }}
                  >
                    <Icon
                      title="addChoicesZakl"
                      fill={
                        choicesZaklOpen && radioIndexZakl === index
                          ? "orange"
                          : "white"
                      }
                    />
                  </div>
                )}
              </div>
              <div
                className="optionsandradioseditdiv"
                style={{
                  marginLeft: "3px",
                }}
              >
                <button
                  className="svgButton"
                  onClick={() => {
                    let r = { ...rybaObject };
                    const newRadio = JSON.parse(
                      JSON.stringify(
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].radios[index]
                      )
                    );

                    r.organs[organIndex].segments[segmentIndex].buttons[
                      buttonIndex
                    ].radios.splice(index, 0, newRadio);

                    setRybaObject(r);
                  }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    notificationTheBest("Опция скопирована");
                    let r = { ...rybaObject };
                    window.localStorage.setItem(
                      `copiedRADIO`,
                      JSON.stringify(
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].radios[index]
                      )
                    );
                  }}
                >
                  <Duplicate />
                </button>

                {index > 0 && (
                  <button
                    className="svgButton"
                    onClick={() => {
                      let r = { ...rybaObject };
                      let a =
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].radios[index];
                      let b =
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].radios[index - 1];
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].radios[index] = b;
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].radios[index - 1] = a;
                      setRybaObject(r);
                    }}
                  >
                    <ArrowUp />
                  </button>
                )}
                {index < button.radios.length - 1 && (
                  <button
                    className="svgButton"
                    onClick={() => {
                      let r = { ...rybaObject };
                      let a =
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].radios[index];
                      let b =
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].radios[index + 1];
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].radios[index] = b;
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].radios[index + 1] = a;
                      setRybaObject(r);
                    }}
                  >
                    <ArrowDown />
                  </button>
                )}

                {rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                  buttonIndex
                ].radios.length > 1 && (
                  <button
                    className="svgButton"
                    onClick={() => {
                      let r = { ...rybaObject };
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].radios.splice(index, 1);
                      setRybaObject(r);
                    }}
                  >
                    <DeleteIcon width={24} height={24} />
                  </button>
                )}
                <OpenMenu
                  onClick={() => {
                    setRadioMenuOpen(true);
                    setRadioMenuOpenIndex(index);
                  }}
                />
                {radioMenuOpen && radioMenuOpenIndex === index && (
                  <div
                    style={{
                      position: "relative",
                    }}
                    ref={radioContextMenuRef}
                  >
                    <div
                      className="contextMenu"
                      style={{
                        top: "5px",
                        left: "0",
                        zIndex: "50",
                        width: "130px",
                        position: "absolute",
                        height: "fit-content",
                      }}
                    >
                      <span
                        style={{
                          paddingBottom: "5px",
                          paddingTop: "5px",
                        }}
                        className="contextMenuButton"
                        onClick={(e) => {
                          notificationTheBest("Кнопка скопирована");
                          let r = { ...rybaObject };
                          window.localStorage.setItem(
                            `copiedRADIOBUTTON`,
                            JSON.stringify(
                              r.organs[organIndex].segments[segmentIndex]
                                .buttons[buttonIndex].radios[index]
                            )
                          );
                          setRadioMenuOpen(false);
                        }}
                      >
                        <Icon title="copy" />
                        Копировать
                      </span>
                      <span
                        style={{
                          paddingBottom: "5px",
                          paddingTop: "5px",
                        }}
                        className="contextMenuButton"
                        onClick={(e) => {
                          if (
                            !window.localStorage.getItem("copiedRADIOBUTTON")
                          ) {
                            alert("В буфере обмена нет скопированных кнопок");
                            return;
                          }
                          let r = { ...rybaObject };
                          const newButton = JSON.parse(
                            window.localStorage.getItem("copiedRADIOBUTTON")
                          );
                          r.organs[organIndex].segments[segmentIndex].buttons[
                            buttonIndex
                          ].radios.splice(index + 1, 0, newButton);
                          notificationTheBest(
                            "Кнопка из буфера обмена добавлена"
                          );
                          setRybaObject(r);
                          setRadioMenuOpen(false);
                        }}
                      >
                        <Icon title="pasteBelow" />
                        Вставить
                      </span>
                      <span
                        style={{
                          paddingBottom: "5px",
                          paddingTop: "5px",
                        }}
                        className="contextMenuButton"
                        onClick={(e) => {
                          let r = { ...rybaObject };
                          let newButton =
                            r.organs[organIndex].segments[segmentIndex].buttons[
                              buttonIndex
                            ].radios[index];
                          newButton.type = "checkbox";
                          r.organs[organIndex].segments[
                            segmentIndex
                          ].buttons.splice(buttonIndex + 1, 0, newButton);
                          if (
                            r.organs[organIndex].segments[segmentIndex].buttons[
                              buttonIndex
                            ].radios.length === 1
                          ) {
                            r.organs[organIndex].segments[segmentIndex].buttons[
                              buttonIndex
                            ].radios = [];
                          }
                          r.organs[organIndex].segments[segmentIndex].buttons[
                            buttonIndex
                          ].radios.splice(index, 1);
                          notificationTheBest("Кнопка извлечена из группы");
                          setRybaObject(r);
                          setRadioMenuOpen(false);
                        }}
                      >
                        <Icon title="remove" />
                        Достать из группы
                      </span>
                      {!Object.hasOwn(
                        rybaObject.organs[organIndex].segments[segmentIndex]
                          .buttons[buttonIndex].radios[index],
                        "choices"
                      ) && (
                        <span
                          className="contextMenuButton"
                          onClick={(e) => {
                            let r = { ...rybaObject };

                            r.organs[organIndex].segments[segmentIndex].buttons[
                              buttonIndex
                            ].radios[index].choices = [[]];

                            console.log(r);
                            notificationTheBest("Поле опций добавлено");
                            setRybaObject(r);
                            setRadioMenuOpen(false);
                          }}
                        >
                          <Icon title="addChoices" />
                          Добавить опции для описания
                        </span>
                      )}

                      {Object.hasOwn(
                        rybaObject.organs[organIndex].segments[segmentIndex]
                          .buttons[buttonIndex].radios[index],
                        "choices"
                      ) && (
                        <span
                          className="contextMenuButton"
                          onClick={(e) => {
                            let r = { ...rybaObject };

                            delete r.organs[organIndex].segments[segmentIndex]
                              .buttons[buttonIndex].radios[index].choices;

                            notificationTheBest("Поле опций удалено");
                            setRybaObject(r);
                            setRadioMenuOpen(false);
                          }}
                        >
                          <Icon title="deleteChoices" />
                          Удалить опции для описания
                        </span>
                      )}
                      {!Object.hasOwn(
                        rybaObject.organs[organIndex].segments[segmentIndex]
                          .buttons[buttonIndex].radios[index],
                        "choicesZakl"
                      ) && (
                        <span
                          className="contextMenuButton"
                          onClick={(e) => {
                            let r = { ...rybaObject };

                            r.organs[organIndex].segments[segmentIndex].buttons[
                              buttonIndex
                            ].radios[index].choicesZakl = [[]];

                            console.log(r);
                            notificationTheBest("Поле опций добавлено");
                            setRybaObject(r);
                            setRadioMenuOpen(false);
                          }}
                        >
                          <Icon title="addChoicesZakl" />
                          Добавить опции для заключения
                        </span>
                      )}

                      {Object.hasOwn(
                        rybaObject.organs[organIndex].segments[segmentIndex]
                          .buttons[buttonIndex].radios[index],
                        "choicesZakl"
                      ) && (
                        <span
                          className="contextMenuButton"
                          onClick={(e) => {
                            let r = { ...rybaObject };

                            delete r.organs[organIndex].segments[segmentIndex]
                              .buttons[buttonIndex].radios[index].choicesZakl;

                            notificationTheBest("Поле опций удалено");
                            setRybaObject(r);
                            setRadioMenuOpen(false);
                          }}
                        >
                          <Icon title="deleteChoicesZakl" />
                          Удалить опции для заключения
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={
                  {
                    // paddingLeft: "200px",
                  }
                }
              >
                {Object.hasOwn(radio, "choices") &&
                  choicesOpen &&
                  radioIndex === index && (
                    <ChoicesConstructor
                      buttonItself={radio}
                      setChoices={setChoices}
                      buttonType={"radio"}
                      organIndex={organIndex}
                      segmentIndex={segmentIndex}
                      buttonIndex={buttonIndex}
                      radioIndex={index}
                      optionIndex={undefined}
                    />
                  )}
              </div>
              <div>
                {Object.hasOwn(radio, "choicesZakl") &&
                  choicesZaklOpen &&
                  radioIndexZakl === index && (
                    <ChoicesConstructorZakl
                      buttonItself={radio}
                      setChoicesZakl={setChoicesZakl}
                      buttonType={"radio"}
                      organIndex={organIndex}
                      segmentIndex={segmentIndex}
                      buttonIndex={buttonIndex}
                      radioIndex={index}
                      optionIndex={undefined}
                    />
                  )}
              </div>
            </div>
          </div>
        ))}
      <div>
        <button
          onClick={() => {
            let r = { ...rybaObject };
            let nr = { ...newRadio };
            // if (
            //   Object.hasOwn(
            //     r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex]
            //       .radios[0],
            //     "choices"
            //   )
            // ) {
            //   nr.choices = [];
            // }
            // if (
            //   Object.hasOwn(
            //     r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex]
            //       .radios[0],
            //     "choicesZakl"
            //   )
            // ) {
            //   nr.choicesZakl = [];
            // }
            r.organs[organIndex].segments[segmentIndex].buttons[
              buttonIndex
            ].radios.push(nr);
            setRybaObject(r);
          }}
        >
          ➕
        </button>
      </div>
    </>
  );
};
export default RadioRedactor;
