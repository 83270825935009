import { useEffect, useState, useRef } from "react";
import InputPrompt from "./editing/InputPrompt";
import { useRecoilState } from "recoil";
import { textState } from "../state/atoms/textareaText";
import { promptStateAtom } from "../state/atoms/promptState";
import { formStateAtom } from "../state/atoms/formState";
import { fullRYBAatom2 } from "../state/atoms/fullRYBAatom2";
import { zaklStateAtom } from "../state/atoms/zaklState";
import { undoStateAtom } from "../state/atoms/undoState";
import { useParams } from "react-router-dom";
import Notification from "./editing/NotificationWindow";
import "./Ryba4.css";
import "./RybaDinamica.css";
import "./editing/NotificationWindow";
import { useAuthContext } from "../hooks/useAuthContext";
import CopyAll from "./svg/CopyAll";
import ResetToNorm from "./svg/ResetToNorm";
import EditRyba from "./svg/EditRyba";
import PDF from "./svg/PDF";
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import React from "react";
import ConstructorRouter from "./smallComponents/constructorRouter";
import CopyProtocol from "./svg/CopyProtocol";
import CopyImpression from "./svg/CopyImpression";
import TextareaAutosize from "react-textarea-autosize";
import Buttons from "../components/smallComponents/buttons";
import { textStateFollowup } from "../state/atoms/textareaTextFollowup";
import ButtonsFollowup from "./smallComponents/buttonsFollowup";
import ZoomOut from "./svg/ZoomOut";
import ZoomIn from "./svg/ZoomIn";
import DOC from "./svg/DOC";
import TXT from "./svg/TXT";
import { Document, Packer, Paragraph, TextRun, AlignmentType } from "docx";
import * as docx from "docx";
import { saveAs } from "file-saver";
import SaveButton from "./svg/SaveButton";
import OK from "./svg/OK";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { notificationTheBest } from "../rootComponents/NotificationService";
import InputPromptZakl from "./editing/InputPromptZakl";
import { settingsAtom } from "../state/atoms/settings";

const RybaDinamica = () => {
  const [rybaObject, setRybaObject] = useRecoilState(fullRYBAatom2);
  const [text, setText] = useRecoilState(textStateFollowup); // recoil state of the textarea
  const [promptText, setPromptText] = useRecoilState(promptStateAtom);
  const [formState, setFormState] = useRecoilState(formStateAtom);
  const [zaklState, setZaklState] = useRecoilState(zaklStateAtom);
  const [undoBuffer, setUndoBuffer] = useRecoilState(undoStateAtom);
  const { id } = useParams();
  const [notificationWindowOpen, setNotificationWindowOpen] = useState(false);

  const [rated, setRated] = useState(true);
  const { user } = useAuthContext();
  ////////// Ryba4 state ////////
  const [organFocus, setOrganFocus] = useState(2000);
  const [rybaLoaded, setRybaLoaded] = useState(false);
  const [reset, setReset] = useState(false);
  const [constructorOpen, setConstructorOpen] = useState(false);
  const [settings, setSettings] = useRecoilState(settingsAtom);
  const [printData, setPrintData] = useState({});
  const [dinamika, setDinamika] = useState(true);
  const [notes, setNotes] = useState();
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(-360);
  const [pressed, setPressed] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [nameReportOpen, setNameReportOpen] = useState(false);
  const [reportTitle, setReportTitle] = useState(null);
  const [reportTitle2, setReportTitle2] = useState(null);
  const [saved, setSaved] = useState(false);
  const [savedText, setSavedText] = useState();
  const [topMenu, setTopMenu] = useState();
  const [leftMenu, setLeftMenu] = useState();
  const [buttonMenuOpen, setButtonMenuOpen] = useState(false);
  const [focusedOrgan, setFocusedOrgan] = useState();
  const [buttonMenuSegmentsOpen, setButtonSegmentsMenuOpen] = useState(false);
  ////// prompt states
  const [choicesCount, setChoicesCount] = useState(0);
  const [promptButton, setPromptButton] = useState();
  const [inputPromptOpen, setInputPromptOpen] = useState(false);
  const [inputPromptZaklOpen, setInputPromptZaklOpen] = useState(false);
  const [promptDotsOpisanie, setPromptDotsOpisanie] = useState();

  let promptButtonREF = useRef();
  useEffect(() => {
    const getFullRyba = async (req, res) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/full/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();
      if (response.status === 401) {
        window.localStorage.removeItem("user");
        window.location.reload();
      }
      // setting up ryba obj
      let r = { ...json };
      console.log("rybaObject: ", r);
      const localStorageTXT = JSON.parse(localStorage.getItem("followup" + id));
      const notes = JSON.parse(localStorage.getItem("notes" + id));
      if (notes) {
        setNotes(notes);
      }
      if (response.ok) {
        // console.log(r);
        setRybaObject(r);
        setRybaLoaded(true);
        // set text state

        if (localStorageTXT) {
          setText(localStorageTXT);
        }
        if (!localStorageTXT) {
          setText("");
        }
      }
    };
    if (user) {
      getFullRyba();
    }
  }, [user]);

  // счётчики сегментов и текстовых полей рыбы
  let keyCounter = 0;
  let zaklRef = useRef("");
  let notificationText = useRef();
  let buttonINDEX = useRef("");
  let segmentINDEX = useRef("");
  let organINDEX = useRef("");
  let constructorREF = useRef({});
  let buttonMenuRef = useRef();
  let textareaRef = useRef();

  const pasteTextAtCursor = (textarea, text, zakl) => {
    if (!textarea) return;
    if (!zakl) {
      zakl = "";
    }
    // Получаем текущее значение текстовой области
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    const currentValue = textarea.value;

    // Вставляем текст на место курсора или заменяем выделенный текст
    let newValue =
      currentValue.substring(0, startPos) +
      text +
      currentValue.substring(endPos);
    if (!text.includes(zakl) && zakl !== "") {
      newValue = newValue.trim() + " " + zakl.trim();
    }

    // Обновляем значение текстовой области
    textarea.value = newValue;

    // Устанавливаем курсор после вставленного текста
    textarea.selectionStart = startPos + text.length;
    textarea.selectionEnd = startPos + text.length;
    setButtonMenuOpen(false);
    // Возвращаем новое значение (для React)
    return newValue;
  };

  const handlePaste = (textToPaste, zakl, buttonItself) => {
    checkForDots(textToPaste, buttonItself);
    if (textToPaste !== undefined && textToPaste.includes("...")) return;
    if (zakl !== undefined && zakl.includes("...")) return;
    if (textareaRef.current) {
      const newValue = pasteTextAtCursor(
        textareaRef.current,
        textToPaste,
        zakl
      );
      setText(newValue); // Обновляем состояние
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////
  ///////// DATE AND TIME FUNCTIONS ///////////
  const createDate = () => {
    const currentdate = new Date();
    const day = `${
      currentdate.getDate() > 9
        ? currentdate.getDate()
        : "0" + currentdate.getDate()
    }`;
    const month = `${
      currentdate.getMonth() + 1 > 9
        ? currentdate.getMonth() + 1
        : "0" + Number(currentdate.getMonth() + 1)
    }`;
    const year = `${currentdate.getFullYear()}`;
    return `${day}.${month}.${year}`;
  };
  const createTime = () => {
    const currentdate = new Date();
    const minutes = `${
      currentdate.getMinutes() > 9
        ? currentdate.getMinutes()
        : "0" + currentdate.getMinutes()
    }`;
    const hours = `${
      currentdate.getHours() > 9
        ? currentdate.getHours()
        : "0" + currentdate.getHours()
    }`;
    return `${hours}:${minutes}`;
  };

  // set input prompt value
  const setPromptInputValue = (button) => {
    let q = [...promptText];
    const i = q[0] + q[1] + q[2];
    if (i.includes("...")) {
      setInputPromptOpen(true);
      let g = [];
      let y = i;
      g[0] = y.slice(0, y.indexOf("..."));
      g[1] = "";
      g[2] = y.slice(y.indexOf("...") + 3);

      return setPromptText(g);
    }
    // let t = `${text}`;
    // navigator.clipboard.writeText(i);
    // if (!t.includes(button.zakl) && button.zakl) {
    //   t += button.zakl;
    // }

    // setText(t);
    // setCopied(i);
    // window.localStorage.setItem(`${"followup" + id}`, JSON.stringify(t));
    // notificationTheBest("Текст кнопки скопирован");

    setInputPromptOpen(false);
  };

  //////////////////// RATE IMPORTED RYBA ////////////////////
  const rateSharedRyba = async (req, res, parentRybaIdAttribute, grade) => {
    /// get parent ryba
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/pond/${parentRybaIdAttribute}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const rate = Number(grade);
    const parentRyba = await response.json();
    let ratings = [...parentRyba.ratings, rate];
    const averageRating =
      ratings.reduce((a, b) => a * 1 + b * 1) / ratings.length;

    /// patch parent ryba
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/pond/${parentRybaIdAttribute}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          averageRating,
          ratings,
          ratingsLength: ratings.length,
        }),
      }
    );
    // reset this ryba isChild (so you can't rate it again)
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/pond/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        isChild: false,
      }),
    });

    setRated(false);
  };

  ///////
  const rateButtons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  // dinamika constructor function
  const dinamikaConstructorFunction = function (opisanie, zakl) {
    navigator.clipboard.writeText(opisanie);
    notificationTheBest("Текст для описания скопирован");
    let t = `${text}`;
    if (!t.includes(zakl) && zakl) {
      t += zakl;
    }
    setText(t);
    // setCopied(opisanie);
  };

  const rateColor = [
    "red",
    "red",
    "orange",
    "orange",
    "yellow",
    "yellow",
    "lightgreen",
    "lightgreen",
    "green",
    "green",
  ];

  // BUTTON adding text to the textarea
  const checkForDots = function (bTextContent, button) {
    if (
      bTextContent === "" ||
      bTextContent === undefined ||
      bTextContent === null
    ) {
      return;
    }
    let y = bTextContent;
    console.log("check for dots Y: ", y);

    if (y.includes("...")) {
      setChoicesCount(0);
      setPromptButton(button);
      setInputPromptOpen(true);
      let g = [];
      g[0] = y.slice(0, y.indexOf("..."));
      g[1] = "";
      g[2] = y.slice(y.indexOf("...") + 3);

      return setPromptText(g);
    }
  };
  const checkForDotsZakl = function (zaklText, button) {
    if (zaklText === "" || zaklText === undefined || zaklText === null) {
      return;
    }
    let y = zaklText;
    console.log("check for dots ZAKL: ", y);

    if (y.includes("...")) {
      setChoicesCount(0);
      setPromptButton(button);
      setInputPromptZaklOpen(true);
      let g = [];
      g[0] = y.slice(0, y.indexOf("..."));
      g[1] = "";
      g[2] = y.slice(y.indexOf("...") + 3);

      return setPromptText(g);
    }
  };

  const exportWORD = async () => {
    ////////// CREATE REPORT ///////
    let rybaString = text;
    /////// CREATE DOCX //////

    const doc = new Document({
      styles: {
        default: {
          document: {
            run: {
              size: "12pt",
              font: "Arial",
            },
          },
        },
      },
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              text: settings.rybaHeaders[0],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: "ФИО: ",
                }),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Дата рождения: ",
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Пол: ",
                }),
              ],
            }),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: rybaObject.protocol,
                  bold: true,
                  size: "16pt",
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({}),
            new Paragraph({}),

            new Paragraph({}),
            new Paragraph({
              children: rybaString
                .split("\n")
                .map((line) => new TextRun({ break: 1, text: line })),
            }),

            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Врач-рентгенолог",
                  alignment: AlignmentType.LEFT,
                }),
                new TextRun({}),
              ],
            }),
            new Paragraph({
              text: `${settings.lastname} ${settings.firstname.slice(
                0,
                1
              )}. ${settings.middlename.slice(0, 1)}.`,
              alignment: AlignmentType.RIGHT,
            }),
            new Paragraph({
              text: `Дата: ${createDate()}`,
            }),
          ],
        },
      ],
    });
    //////////////////////////
    Packer.toBlob(doc).then((blob) => {
      // saveAs from FileSaver will download the file
      saveAs(blob, `${createDate()}.docx`);
    });

    ////////////////////////////////
    // const file = new Blob([doc], { type: "text/plain;charset=utf-8" });
    // saveAs(file, `${rybaObject.protocol} ${date}.docx`);
  };

  const exportTXT = () => {
    ////////// CREATE REPORT ///////

    let rybaString = "";
    rybaString += "Модальность: " + rybaObject.modality + "\n";
    rybaString += "Исследование: " + rybaObject.protocol + "\n";

    rybaString += "Дата: " + createDate() + "\n\n";
    rybaString += text;

    ////////////////////////////////
    // const fileData = JSON.stringify(rybaString);
    const file = new Blob([rybaString], { type: "text/plain;charset=utf-8" });
    saveAs(file, `${rybaObject.protocol} ${createDate()}.txt`);
  };

  const saveReport = () => {
    ////////////////////////////////
    const reports = JSON.parse(localStorage.getItem(`savedReports${user.id}`));

    let reportObject = {
      id: id,
      title: reportTitle,
      date: createDate(),
      time: createTime(),
      content: text,
      contentForExport: text,
      protocol: rybaObject.protocol,
      modality: rybaObject.modality,
      timeFull: Date.now(),
      dinamika: true,
    };
    if (!reports) {
      window.localStorage.setItem(
        `savedReports${user.id}`,
        JSON.stringify([reportObject])
      );
    }

    if (reports) {
      reports.unshift(reportObject);
      window.localStorage.setItem(
        `savedReports${user.id}`,
        JSON.stringify(reports)
      );
    }
    notificationTheBest("Описание успешно сохранено");
    setReportTitle("");

    setSaved(true);
    setSavedText(JSON.stringify(text));
  };

  const autosaveReport = async () => {
    ////////// CREATE REPORT ///////
    const lastSaved = JSON.parse(
      localStorage.getItem(`lastSavedDinamica${user.id}`)
    );
    let rybaString = text;

    ////////////////////////////////
    const reports = JSON.parse(
      localStorage.getItem(`autosavedReports${user.id}`)
    );
    if (
      reports.filter((a) => {
        return a.id === id && a.content === text;
      }).length !== 0
    ) {
      return;
    }

    let reportObject = {
      id: id,
      title: "Автосохранение",
      date: createDate(),
      time: createTime(),
      content: text,
      zakl: `ДИНАМИКА "...${rybaString.slice(-150, -1).trim()}..." `,
      contentForExport: text,
      // zakl: x[rybaObject.organs.length - 1].join(" ").slice(0, 150) + "...",
      protocol: rybaObject.protocol,
      rybaTitle: rybaObject.title,
      modality: rybaObject.modality,
      timeFull: Date.now(),
      dinamika: true,
    };

    let s = { ...settings };
    const numCharacters = Number(text.length);
    const prevCharacters = Number(s.stats.numCharactersAllTime);

    // Check if an object with the given property exists in the array
    const existingObject = s.stats.protocolsUsed.find((obj) =>
      obj.hasOwnProperty(rybaObject.protocol)
    );

    if (existingObject) {
      // If the object exists, increment its value by 1
      if (lastSaved !== id) {
        existingObject[rybaObject.protocol] += 1;
      }
    } else {
      // If no such object exists, add a new object with the property set to 1
      if (lastSaved !== id) {
        s.stats.protocolsUsed.push({ [rybaObject.protocol]: 1 });
      }
    }
    if (lastSaved !== id) {
      s.stats.numCharactersAllTime = numCharacters + prevCharacters;
      s.stats.totalProtocols += 1;
    }
    // setSettings(s);

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/settings/updatestats`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          ...s.stats,
        }),
      }
    );
    const json = await response.json();

    if (response.ok) {
      setSettings(json);
      if (lastSaved === null || lastSaved !== id) {
        notificationTheBest(
          "Автосохранили ваше описание (можно открыть в сохранённых)"
        );
      }
      if (lastSaved === id) {
        notificationTheBest("Подправили автосохранение 🖊️");
      }
    }
    if (!reports) {
      return window.localStorage.setItem(
        `autosavedReports${user.id}`,
        JSON.stringify([reportObject])
      );
    }

    if (reports && !reports.includes(reportObject)) {
      if (lastSaved === id) {
        console.log("LAST SAVED === ID");
        reports[0] = reportObject;
        window.localStorage.setItem(
          `autosavedReports${user.id}`,
          JSON.stringify(reports)
        );
      }
      console.log("дошёл досюда");
      if (lastSaved === null || lastSaved !== id) {
        console.log("LAST SAVED !== ID");
        reports.unshift(reportObject);
        window.localStorage.setItem(
          `autosavedReports${user.id}`,
          JSON.stringify(reports)
        );
        window.localStorage.setItem(
          `lastSavedDinamica${user.id}`,
          JSON.stringify(id)
        );
        await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/pond/numberTimesUsed/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
      }
    }

    setReportTitle("");
    /// patch parent ryba
  };

  // universal patch request
  const patchUser = async function () {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/settings`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({ ...settings }),
      }
    );
    const json = await response.json();

    if (response.ok) {
      console.log("patched settings: ", json);
    }
  };

  const updateSettings = useDebouncedCallback((text) => {
    patchUser();
  }, 3000);

  useEffect(() => {
    updateSettings();
  }, [settings]);

  // BUTTON copy text from ALL textareas
  const goToNorm = function (e) {
    window.localStorage.removeItem(`lastSavedDinamica${user.id}`);
    notificationTheBest(`Описание сброшено до нормы`);
    let finalRyba = "";
    rybaObject.organs.map((organ, organIndex) => {
      let finalText = "";
      let segmentsNorms = "";
      organ.segments.map((s) => {
        segmentsNorms += s.norm;
      });
      finalText = organ.title + ": " + segmentsNorms;
      if (organ.title === "ШАПКА") {
        finalText = "\n" + segmentsNorms.trim();
      }
      if (organ.title === "КЛИНИЧЕСКИЕ ДАННЫЕ") {
        finalText += "\n";
      }
      if (organ.title === "ЗАКЛЮЧЕНИЕ") {
        finalText = "\n" + "\n" + organ.title + ": " + segmentsNorms.trim();
      }

      finalRyba += "\n" + finalText;
    });

    setText(finalRyba.trim());
  };

  const closeOpenOrganContextMenu = (e) => {
    if (buttonMenuOpen && !buttonMenuRef.current?.contains(e.target)) {
      setButtonMenuOpen(false);
    }
  };
  document.addEventListener("mousedown", closeOpenOrganContextMenu);
  const handlePasteConstructor = (
    organIndex,
    segmentIndex,
    content,
    zakl,
    dinamica
  ) => {
    if (dinamica) {
      handlePaste(content, zakl);
      return;
    }
    let t = [...text];
    if (
      t[organIndex][segmentIndex] ===
      rybaObject.organs[organIndex].segments[segmentIndex].norm
    ) {
      t[organIndex][segmentIndex] = "";
    }
    const rybaLength = rybaObject.organs.length - 1;

    if (
      // t[rybaLength][0] &&
      !t[rybaLength][0].includes(zakl) &&
      zakl !== undefined &&
      // zakl !== "" &&
      !zakl.includes("...")
    ) {
      if (t[rybaLength][0] === rybaObject.organs[rybaLength].segments[0].norm) {
        t[rybaLength][0] = "";
      }
      if (!t[rybaLength][0].includes(zakl.trim())) {
        t[rybaLength][0] = t[rybaLength][0].trim() + " " + zakl.trim();
      }
      t[rybaLength][0] = t[rybaLength][0].trim();
    }

    t[organIndex][segmentIndex] =
      t[organIndex][segmentIndex].trim() + " " + content.trim();

    setText(t);
    // let b = [...undoBuffer];

    // b.splice(0, 0, t);
    // setUndoBuffer(b);
    // undoCounter.current = 0;
    // console.log(b);
  };

  // Refs to store the start time, previous text, and deleted characters count
  const startTimeRef = useRef(null);
  const previousTextRef = useRef("");
  const deletedCharsRef = useRef(0);
  const totalCharsRef = useRef(0); // Track total characters typed (including deleted ones)
  // Add a new ref to track the previous text length for delta calculation
  const previousLengthRef = useRef(0);

  // Debounced callback for calculating typing speed and accuracy
  const calculateTypingSpeed = useDebouncedCallback((textWhole) => {
    const endTime = Date.now();
    const startTime = startTimeRef.current;

    // Calculate time elapsed in minutes
    const timeElapsed = (endTime - startTime) / 1000 / 60;

    // Calculate DELTA characters since the last call (fixes overcounting)
    const currentLength = textWhole.length;
    const deltaCharacters = currentLength - previousLengthRef.current;
    previousLengthRef.current = currentLength; // Update for next calculation

    const wordsTyped = textWhole.split(/\s+/).filter(Boolean).length;

    // Calculate accuracy using DELTA characters and deletions in this period
    const totalChars = deltaCharacters + deletedCharsRef.current;
    const correctChars = Math.max(0, deltaCharacters - deletedCharsRef.current);
    const accuracy =
      totalChars > 0 ? Math.round((correctChars / totalChars) * 100) : 100;

    const charactersPerMinute = Math.round(deltaCharacters / timeElapsed);
    const wordsPerMinute = Math.round(wordsTyped / timeElapsed);

    if (charactersPerMinute >= 100 && charactersPerMinute <= 1300) {
      let prevSettings = { ...settings };

      let newTypingTime = prevSettings.stats.typingTime + timeElapsed;

      // Update arrays (same as before)
      let newWordsPerMinute = [...prevSettings.stats.wordsPerMinute];
      let newCharactersPerMinute = [...prevSettings.stats.charactersPerMinute];
      let newAccuracy = [...prevSettings.stats.accuracy];

      if (newWordsPerMinute.length >= 50) newWordsPerMinute.shift();
      if (newCharactersPerMinute.length >= 50) newCharactersPerMinute.shift();
      if (newAccuracy.length >= 50) newAccuracy.shift();

      newWordsPerMinute.push(wordsPerMinute);
      newCharactersPerMinute.push(charactersPerMinute);
      newAccuracy.push(accuracy);

      // Use DELTA to avoid overcounting
      let newTypedCharacters =
        Number(prevSettings.stats.typedCharacters) + deltaCharacters;

      console.log("Delta:", deltaCharacters, "Total:", newTypedCharacters);

      prevSettings.stats.wordsPerMinute = newWordsPerMinute;
      prevSettings.stats.charactersPerMinute = newCharactersPerMinute;
      prevSettings.stats.accuracy = newAccuracy;
      prevSettings.stats.typingTime = newTypingTime;
      prevSettings.stats.typedCharacters = newTypedCharacters;
      console.log(prevSettings.stats);
      setSettings(prevSettings);
    }

    // Reset counters for next interval
    startTimeRef.current = Date.now();
    deletedCharsRef.current = 0;
    previousTextRef.current = textWhole;
  }, 1000);

  // Updated handleChange to work with delta
  const handleChange = (e) => {
    const textWhole = e.target.value;

    // Track deletions compared to previous state
    if (textWhole.length < previousTextRef.current.length) {
      deletedCharsRef.current +=
        previousTextRef.current.length - textWhole.length;
    }

    // Initialize start time on first keystroke
    if (!startTimeRef.current) {
      startTimeRef.current = Date.now();
    }

    // Trigger debounced calculation
    calculateTypingSpeed(textWhole);

    // Update previous text state AFTER debounced call
    previousTextRef.current = textWhole;
  };
  function addedWithButtons(length) {
    const numCharacters = Number(length.length);
    let s = { ...settings };
    const prevCharacters = Number(s.stats.addedWithButtons);
    s.stats.addedWithButtons = numCharacters + prevCharacters;
    setSettings(s);
  }

  return (
    rybaLoaded &&
    settings && (
      <div className="RYBA4rybaContainer">
        {buttonMenuOpen && (
          <div
            className="buttonMenu"
            ref={buttonMenuRef}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "250px",
              position: "absolute",
              top: `${topMenu}px`,
              left: `${leftMenu}px`,
            }}
          >
            {/* 
const[focusedOrgan, setFocusedOrgan] = useState()
const [buttonMenuSegmentsOpen, setButtonSegmentsMenuOpen] = useState(false); */}
            {rybaObject.organs.map((organ, organIndex) => (
              <div
                onMouseEnter={() => {
                  setFocusedOrgan(organIndex);
                }}
                className="organFromButtonMenu"
                style={{
                  position: "relative",
                }}
              >
                <span
                  style={{
                    padding: "4px",
                  }}
                >
                  {organ.title}
                </span>
                {buttonMenuOpen && organIndex === focusedOrgan && (
                  <div
                    className="segmentsMenu"
                    style={{
                      position: "absolute",
                      left: "250px",
                      right: "0px",
                      top: "0",
                    }}
                  >
                    {rybaObject.organs[focusedOrgan].segments.map(
                      (segment, segmentIndex) => (
                        <div
                          className="segmentOne"
                          style={{
                            margin: "0px",
                          }}
                        >
                          {rybaObject.organs[focusedOrgan].segments.length >
                            1 && (
                            <div className="fieldsetLegend">
                              {segment.title}
                            </div>
                          )}
                          {segment.buttons.length === 0 && (
                            <span
                              style={{
                                color: "grey",
                                fontSize: "13px",
                              }}
                            >
                              Кнопок в подразделе нет
                            </span>
                          )}
                          {segment &&
                            segment.buttons.map((button, buttonIndex) => (
                              <div>
                                <ButtonsFollowup
                                  {...{
                                    button,
                                    buttonIndex,
                                    buttonINDEX,
                                    organINDEX,
                                    segmentINDEX,
                                    zaklRef,
                                    setConstructorOpen,
                                    constructorREF,
                                    organIndex,
                                    segmentIndex,
                                    rybaObject,
                                    setRybaObject,
                                    id,
                                    reset,
                                    setReset,
                                    text,
                                    setText,
                                    handlePaste,
                                    handlePasteConstructor,
                                  }}
                                />
                              </div>
                            ))}
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        {nameReportOpen && (
          <div className="popupContainer">
            <div
              className="popupContent"
              style={{
                position: "fixed",
                top: "250px",
              }}
            >
              <p>Название сохраняемого документа</p>
              <TextareaAutosize
                autoFocus
                className="inputPromptTextarea"
                value={reportTitle}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    saveReport();
                    setNameReportOpen(false);
                  }
                  if (e.key === "Escape") {
                    setNameReportOpen(false);
                    setReportTitle("");
                  }
                }}
                onChange={(e) => {
                  setReportTitle(e.target.value);
                  setReportTitle2(e.target.value);
                }}
              ></TextareaAutosize>
              {/* <p>Дополнительная информация (что угодно; необязательно)</p>
            <TextareaAutosize
              autoFocus
              className="inputPromptTextarea"
              value={info}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  saveReport();
                  setNameReportOpen(false);
                }
                if (e.key === "Escape") {
                  setNameReportOpen(false);
                  setReportTitle("");
                }
              }}
              onChange={(e) => {
                setInfo(e.target.value);
              }}
            ></TextareaAutosize> */}
              <button
                className="saveChanges"
                onClick={() => {
                  saveReport();
                  setNameReportOpen(false);
                  setReportTitle("");
                }}
              >
                Сохранить (Enter)
              </button>
              <button
                className="closeTextEditWindow"
                onClick={() => {
                  setNameReportOpen(false);
                  setReportTitle("");
                }}
              >
                Отмена (Esc)
              </button>
            </div>
          </div>
        )}
        {constructorOpen && (
          <ConstructorRouter
            {...{
              constructorREF,
              setConstructorOpen,
              formState,
              setFormState,
              text,
              setText,
              zaklState,
              setZaklState,
              rybaObject,
              dinamika,
              dinamikaConstructorFunction,
              handlePasteConstructor,
              addedWithButtons,
            }}
          />
        )}
        <div
          className="RYBA4ryba"
          key={(keyCounter += 1)}
          style={{
            zoom: `${settings.zoom}`,
            width: "870px",
          }}
        >
          <div className="anchorForNotes">
            <div
              className="notes"
              style={{
                top: `${top}px`,
                left: `${left}px`,
                cursor: `${pressed ? "grab" : "pointer"}`,
              }}
            >
              <div
                className="notesTitle"
                onMouseDown={(e) => {
                  setPressed(true);
                }}
                onMouseUp={(e) => {
                  setPressed(false);
                }}
                onMouseMove={(e) => {
                  if (pressed) {
                    setLeft((l) => l + e.movementX);
                    setTop((t) => t + e.movementY);
                  }
                }}
                onMouseLeave={() => {
                  setPressed(false);
                }}
                onMouseOut={() => {
                  setPressed(false);
                }}
              >
                Заметки
              </div>
              <div className="notesText">
                <TextareaAutosize
                  value={notes}
                  onChange={(e) => {
                    setNotes(e.target.value);

                    window.localStorage.setItem(
                      `notes${id}`,
                      JSON.stringify(e.target.value)
                    );
                  }}
                ></TextareaAutosize>
              </div>
            </div>
          </div>
          {!rybaLoaded && <div class="loader"></div>}
          {rybaLoaded && settings && (
            <>
              {inputPromptOpen && (
                <InputPrompt
                  {...{
                    setPromptInputValue,
                    setPromptText,
                    promptText,
                    setInputPromptOpen,
                    choicesCount,
                    promptButton,
                    setChoicesCount,
                    // organINDEX,
                    // segmentINDEX,
                    // buttonINDEX,
                    text,
                    setText,
                    rybaObject,
                    checkForDotsZakl,
                    dinamica: true,
                    handlePaste,
                    promptDotsOpisanie,
                    setPromptDotsOpisanie,
                    addedWithButtons,
                  }}
                />
              )}
              {inputPromptZaklOpen && (
                <InputPromptZakl
                  {...{
                    setPromptInputValue,
                    setInputPromptZaklOpen,
                    setPromptText,
                    promptText,
                    setInputPromptOpen,
                    choicesCount,
                    promptButton,
                    setChoicesCount,
                    // organINDEX,
                    // segmentINDEX,
                    // buttonINDEX,
                    text,
                    setText,
                    rybaObject,
                    checkForDotsZakl,
                    dinamica: true,
                    handlePaste,
                    promptDotsOpisanie,
                    setPromptDotsOpisanie,
                    addedWithButtons,
                  }}
                />
              )}

              {notificationWindowOpen && (
                <Notification notificationText={notificationText.current} />
              )}
              {rybaObject.organs.length > 2 && (
                <div
                  className="RYBA4topButtonsDinamika"
                  key={(keyCounter += 1)}
                >
                  <div className="RYBA4topButtonsBorder">
                    <div
                      className="RYBA4topButton copyAll"
                      key={(keyCounter += 1)}
                      onClick={() => {
                        notificationTheBest("Описание скопировано");
                        navigator.clipboard.writeText(text);
                        autosaveReport();
                      }}
                      data-tooltip-id="skopirovatVse"
                      data-tooltip-delay-show="300"
                    >
                      <CopyAll height="96" width="96" />
                      <Tooltip
                        id="skopirovatVse"
                        content="Скопировать весь текст"
                        place="bottom"
                        style={{ background: "black" }}
                      />
                    </div>

                    <div className="RYBA4topButtonsSmallAndTxtFormatDinamika">
                      <span
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          marginLeft: "43px",
                        }}
                      >
                        Работа с шаблоном
                      </span>
                      <div className="RYBA4ropButtonsSmall">
                        <div
                          className="RYBA4topButton"
                          key={(keyCounter += 1)}
                          onClick={() => {
                            goToNorm();
                          }}
                          data-tooltip-id="goToNorm"
                          data-tooltip-delay-show="300"
                        >
                          <ResetToNorm height="48" width="48" />
                          <Tooltip
                            id="goToNorm"
                            content="Сбросить всё до нормы"
                            place="bottom"
                            style={{ background: "black" }}
                          />
                        </div>

                        <Link
                          to={`/editRyba2/${rybaObject._id}`}
                          style={{
                            border: "none",
                            padding: "0px",
                            margin: "0px",
                          }}
                          // target="_blank"
                        >
                          <div
                            className="RYBA4topButton"
                            data-tooltip-id="editRYba"
                            data-tooltip-delay-show="300"
                          >
                            <EditRyba />
                            <Tooltip
                              id="editRYba"
                              content="Редактировать шаблон"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                          </div>
                        </Link>
                        <div
                          className="RYBA4topButton saveButtonDiv"
                          data-tooltip-id="Save"
                          data-tooltip-delay-show="300"
                          onClick={() => {
                            setNameReportOpen(true);
                          }}
                        >
                          <SaveButton />
                          <Tooltip
                            id="Save"
                            content="Сохранить заключение"
                            place="bottom"
                            style={{ background: "black" }}
                          />
                          {saved && (
                            <div className="saveButtonDivOK">
                              <OK />
                            </div>
                          )}
                        </div>
                        <div>
                          <div
                            style={{
                              margin: "0px",
                            }}
                            className="RYBA4topButton"
                            data-tooltip-id="TXT"
                            data-tooltip-delay-show="300"
                            onClick={() => {
                              exportTXT();
                            }}
                          >
                            <TXT />
                            <Tooltip
                              id="TXT"
                              content="Экспорт в TXT"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                          </div>
                          <div
                            className="RYBA4topButton"
                            data-tooltip-id="DOC"
                            data-tooltip-delay-show="300"
                            onClick={() => {
                              exportWORD();
                            }}
                            style={{
                              margin: "0px",
                            }}
                          >
                            <DOC />
                            <Tooltip
                              id="DOC"
                              content="Экспорт в WORD"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                          </div>
                        </div>

                        {/* <div
                        className="RYBA4topButton"
                        data-tooltip-id="PDF"
                        data-tooltip-delay-show="300"
                        onClick={() => {
                          print();
                          window.open("/exportPDF", "_blank");
                        }}
                      >
                        <PDF />
                        <Tooltip
                          id="PDF"
                          content="Экспорт в PDF"
                          place="bottom"
                          style={{ background: "black" }}
                        />
                      </div> */}
                      </div>
                      <div className="dinamikaAndPrimary">
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            className="RYBA4topButton"
                            onClick={(e) => {
                              let s = { ...settings };
                              s.zoom += 0.05;
                              setSettings(s);
                            }}
                            data-tooltip-id="ZoomIn"
                            data-tooltip-delay-show="300"
                          >
                            <ZoomIn />
                            <Tooltip
                              id="ZoomIn"
                              content="Увеличить мастштаб (приблизить)"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                          </div>
                          <div
                            className="RYBA4topButton"
                            onClick={(e) => {
                              let s = { ...settings };
                              s.zoom -= 0.05;
                              setSettings(s);
                            }}
                            data-tooltip-id="ZoomOut"
                            data-tooltip-delay-show="300"
                          >
                            <ZoomOut />
                            <Tooltip
                              id="ZoomOut"
                              content="Уменьшить масштаб (отдалить)"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                          </div>
                        </div>
                        <div>
                          <Link
                            to={`/primary/${rybaObject._id}`}
                            style={{
                              border: "none",
                              padding: "0px",
                              margin: "0px",
                            }}
                            // target="_blank"
                          >
                            <button className="dinamika dinInactive">
                              Конструктор
                            </button>
                          </Link>

                          <button className="dinamika dinActive">
                            Полотно
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className="RYBA4topButtonsBorder2"
                      style={{
                        minWidth: "440px",
                        background: "transparent",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          color: "grey",
                          fontSize: "0.8rem",
                          paddingTop: "10px",
                          margin: "14px",
                          marginBottom: "13px",
                        }}
                      >
                        {/* Протокол:  */}
                        {rybaObject && rybaObject.protocol}
                      </p>
                      <h2
                        className="RYBA4title"
                        key={(keyCounter += 1)}
                        style={{}}
                      >
                        {rybaObject.title}
                      </h2>
                    </div>
                    {saved && (
                      <div className="copiedText">
                        <p
                          style={{
                            padding: "0px",
                            margin: "0px",
                            marginBottom: "5px",

                            marginTop: "-6px",
                            fontWeight: "100",
                          }}
                        >
                          Описание сохранено под названием "{reportTitle2}"
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {rybaObject.isChild && rated && (
                <div className="RYBA4rateRybaDiv">
                  <p>
                    Вы импортировали этот шаблон. Пожалуйста, оцените его от 1
                    до 10, это поможет другим коллегам в выборе!
                  </p>
                  {rateButtons.map((n, index) => (
                    <button
                      parentryba={rybaObject.parentRybaSharedID}
                      style={{
                        background: rateColor[index],
                      }}
                      value={n}
                      onClick={(e) => {
                        rateSharedRyba(
                          undefined,
                          undefined,
                          e.target.attributes[0].value,
                          e.target.value
                        );
                      }}
                      className="RYBA4rateRyba"
                    >
                      {n}
                    </button>
                  ))}
                </div>
              )}
              {rybaObject.organs.length === 3 && (
                <>
                  <p style={{ textAlign: "center", paddingTop: "20px" }}>
                    Это пустой шаблон. Нажмите кнопку ниже, чтобы начать
                    добавлять в неё содержимое.
                  </p>

                  <Link
                    to={`/editRyba2/${rybaObject._id}`}
                    style={{
                      border: "none",
                      padding: "0px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <div
                      className="RYBA4topButton"
                      data-tooltip-id="editRYba2"
                      data-tooltip-delay-show="300"
                      style={{
                        border: "none",
                        padding: "10px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <EditRyba />
                      <Tooltip
                        id="editRYba2"
                        content="Редактировать рыбку"
                        place="bottom"
                        style={{ background: "black", zIndex: "10" }}
                      />
                    </div>
                  </Link>
                </>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {" "}
                <div className="RYBAdinamika">
                  <TextareaAutosize
                    ref={textareaRef}
                    style={{
                      padding: "55px",
                      paddingTop: "55px",
                    }}
                    value={text}
                    onChange={(e) => {
                      let x = [...text];
                      x = e.target.value;
                      handleChange(e);
                      setText(x);
                      window.localStorage.setItem(
                        `${"followup" + id}`,
                        JSON.stringify(x)
                      );
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setTopMenu(e.pageY);
                      setLeftMenu(e.pageX);
                      setButtonMenuOpen(true);
                    }}
                  ></TextareaAutosize>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    )
  );
};

export default RybaDinamica;
