const {
  notificationTheBest,
} = require("../rootComponents/NotificationService");

const copyButton = (
  organINDEX,
  segmentINDEX,
  buttonINDEX,
  radioINDEX,
  rybaObject,
  setButtonContextMenuOpen,
  buttonType
) => {
  if (buttonType === "checkbox") {
    notificationTheBest("Кнопка скопирована");
    window.localStorage.setItem(
      `copiedBUTTON`,
      JSON.stringify(
        rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
          .buttons[buttonINDEX.current]
      )
    );
  }
  if (buttonType === "radio") {
    notificationTheBest("Кнопка скопирована");
    let r = { ...rybaObject };
    window.localStorage.setItem(
      `copiedRADIO`,
      JSON.stringify(
        r.organs[organINDEX.current].segments[segmentINDEX.current].buttons[
          buttonINDEX.current
        ].radios[radioINDEX.current]
      )
    );
  }
  if (buttonType === "select") {
    notificationTheBest("Кнопка скопирована");
    window.localStorage.setItem(
      `copiedRADIO`,
      JSON.stringify(
        rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
          .buttons[buttonINDEX.current].options[radioINDEX.current]
      )
    );
  }
  setButtonContextMenuOpen(false);
};

module.exports = { copyButton };
