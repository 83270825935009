import TextareaAutosize from "react-textarea-autosize";
import { useState } from "react";
import PleuralEff from "../../images/PleuralEff.png";

const PleuralEffusion = ({
  constructorREF,
  setConstructorOpen,
  formState,
  setFormState,
  text,
  setText,
  zaklState,
  setZaklState,
  rybaObject,
  dinamika,
  handlePasteConstructor,
  addedWithButtons,
}) => {
  const [notallsizes, setNotallsizes] = useState(false);
  const [right, setRight] = useState();
  const [left, setLeft] = useState();
  const [right2, setRight2] = useState();
  const [left2, setLeft2] = useState();
  const [calculated, setCalculated] = useState(false);
  let resultR;
  let resultL;
  let resultR2;
  let resultL2;
  let resultR2string = "";
  let resultL2string = "";

  // Volume (mL) = 0.365 × b3 – 4.529 × b2 + 159.723 × b – 88.377

  let p = 0;

  const [string, setString] = useState("");
  const organIndex = constructorREF.current.organIndex;
  const segmentIndex = constructorREF.current.segmentIndex;
  const buttonIndex = constructorREF.current.buttonIndex;

  //////////////

  const textHandler = function () {
    ///////
    let zaklStringFinal = "";
    let finalMeasure = "";
    if (!right && !left) {
      setNotallsizes(true);
    } else {
      setNotallsizes(false);
    }
    if (right < 6) {
      resultR = 0;
    }
    if (left < 6) {
      resultL = 0;
    }
    if (right >= 6) {
      const a = right;
      resultR = Math.round(
        (0.365 * a * a * a) / 1000 -
          (4.529 * a * a) / 100 +
          (159.723 * a) / 10 -
          88.377
      );
    }
    if (left >= 6) {
      const b = left;
      resultL = Math.round(
        (0.365 * b * b * b) / 1000 -
          (4.529 * b * b) / 100 +
          (159.723 * b) / 10 -
          88.377
      );
    }
    if (right2 >= 6) {
      const a = right2;
      resultR2 = Math.round(
        (0.365 * a * a * a) / 1000 -
          (4.529 * a * a) / 100 +
          (159.723 * a) / 10 -
          88.377
      );
      resultR2string = `, ранее ${resultR2} мл`;
    }
    if (left2 >= 6) {
      const a = left2;
      resultL2 = Math.round(
        (0.365 * a * a * a) / 1000 -
          (4.529 * a * a) / 100 +
          (159.723 * a) / 10 -
          88.377
      );
      resultL2string = `, ранее ${resultL2} мл`;
    }

    if ((right >= 6 && left < 6) || left === undefined) {
      finalMeasure =
        "Жидкость в правой плевральной полости приблизительным расчётным объёмом до " +
        resultR +
        ` мл${resultR2string}, гидроторакса слева не отмечено. `;
      zaklStringFinal = `Правосторонний гидроторакс (${resultR} мл${resultR2string}). `;
    }
    if (right < 6 || (right === undefined && left >= 6)) {
      finalMeasure =
        "Жидкость в левой плевральной полости приблизительным расчётным объёмом до " +
        resultL +
        ` мл${resultL2string}, гидроторакса справа не отмечено. `;
      zaklStringFinal = `Левосторонний гидроторакс (${resultL} мл${resultL2string}). `;
    }
    if (right >= 6 && left >= 6) {
      finalMeasure =
        "Жидкость в правой плевральной полости приблизительным расчётным объёмом до " +
        resultR +
        ` мл${resultR2string}, слева до ` +
        resultL +
        ` мл${resultL2string}. `;
      zaklStringFinal = `Двусторонний гидроторакс (справа до ${resultR} мл${resultR2string}, слева до ${resultL} мл${resultL2string}). `;
    }
    if (right < 6 && left < 6) {
      finalMeasure = "Свободной жидкости в плевральных полостях не отмечено. ";
      zaklStringFinal = "";
    }

    ///////
    if (dinamika) {
      handlePasteConstructor(
        organIndex,
        segmentIndex,
        finalMeasure,
        zaklStringFinal,
        dinamika
      );
      addedWithButtons(finalMeasure);
      addedWithButtons(zaklStringFinal);
      setConstructorOpen(false);
      return;
    }

    let btnObj =
      rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex];
    let rybaLength = rybaObject.organs.length - 1;

    let t = [...text];
    if (
      t[organIndex][segmentIndex] ===
      rybaObject.organs[organIndex].segments[segmentIndex].norm
    ) {
      t[organIndex][segmentIndex] = "";
    }
    t[organIndex][segmentIndex] += btnObj.newline
      ? "\n" + finalMeasure
      : finalMeasure;

    t[organIndex][segmentIndex] = t[organIndex][segmentIndex].trim();
    if (t[rybaLength][0] === rybaObject.organs[rybaLength].segments[0].norm) {
      t[rybaLength][0] = "";
    }

    t[rybaLength][0] += " " + zaklStringFinal;
    addedWithButtons(finalMeasure);
    addedWithButtons(zaklStringFinal);
    setText(t);
    setConstructorOpen(false);
  };
  const enter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // calculate();
      textHandler();
      setConstructorOpen(false);
    }
  };

  return (
    // <div className="popupContainerConstructor" key={(p += 1)}>
    <div className="constructorWindow" key={(p += 1)}>
      <p className="name">Гидроторакс</p>
      <div className="legend">
        <p>Наибольшая толщина слоя жидкости в мм, не менее 6 мм</p>
        <p>Если только с одной стороны, заполнять другое поле не нужно</p>
      </div>

      <img
        src={PleuralEff}
        alt="pleural effusion"
        style={{
          borderRadius: "10px",
          margin: "10px",
          width: "200px",
          boxShadow: "0px 4px 5px grey",
        }}
      />

      <div
        className="inputVertical"
        style={{ flexDirection: "row", display: "block" }}
        key={calculated}
      >
        <p style={{ fontSize: "17px", display: "block" }}>Сейчас</p>
        <input
          type="text"
          autoFocus
          placeholder="Справа, мм"
          value={right}
          onChange={(e) => setRight(e.target.value)}
          onKeyDown={enter}
        />
        <input
          type="text"
          placeholder="Слева, мм"
          value={left}
          onChange={(e) => setLeft(e.target.value)}
          onKeyDown={enter}
        />
      </div>
      <div
        className="inputVertical"
        style={{ flexDirection: "row", display: "block" }}
        key={calculated}
      >
        <p style={{ fontSize: "15px", display: "block" }}>
          Ранее (динамика, если есть)
        </p>
        <div>
          <input
            type="text"
            placeholder="Справа, мм"
            value={right2}
            onChange={(e) => setRight2(e.target.value)}
            onKeyDown={enter}
          />
          <input
            type="text"
            placeholder="Слева, мм"
            value={left2}
            onChange={(e) => setLeft2(e.target.value)}
            onKeyDown={enter}
          />
        </div>
      </div>
      <button
        className="calculate"
        onClick={() => {
          textHandler();
        }}
      >
        Рассчитать (Enter)
      </button>
      <div>
        <button
          className="calculate"
          onClick={() => {
            setConstructorOpen(false);
          }}
        >
          Закрыть
        </button>
      </div>
      <div className="legend">
        <>
          <p>{string}</p>
        </>

        {notallsizes && (
          <>
            <p style={{ color: "red" }}> ВВЕДИТЕ РАЗМЕРЫ</p>
          </>
        )}
      </div>
    </div>
    // </div>
  );
};
export default PleuralEffusion;
