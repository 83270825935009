import { useState, useRef } from "react";
import { notificationTheBest } from "../../rootComponents/NotificationService";

const SpleenCalc = ({ notification }) => {
  const [notallsizes, setNotallsizes] = useState(false);
  const [result, setResult] = useState("0");
  const [result2, setResult2] = useState("0");

  const [calculated, setCalculated] = useState(false);
  const [size1, setSize1] = useState();
  const [size2, setSize2] = useState();
  const [size3, setSize3] = useState();

  const calculate = () => {
    if (!size1 || !size2 || !size3) {
      return setNotallsizes(true);
    }
    if (size1 && size2 && size3) {
      setNotallsizes(false);
    }
    let x = 30 + size1 * size2 * size3 * 0.58;
    x = Math.round(x);
    setResult(x);
    let y = size1 * size2 * size3;
    y = Math.round(y);
    setResult2(y);

    if (x > 314)
      navigator.clipboard.writeText(
        `Селезёнка увеличена в размерах (измерения ${size1}x${size2}x${size3} см, расчётный объём составляет ${x} см3, норма <314см3, селезёночный индекс ${y}, норма <480. `
      );
    if (x <= 314)
      navigator.clipboard.writeText(
        `Селезёнка не увеличена (измерения ${size1}x${size2}x${size3} см, объём составляет ${x} см3, норма <314см3, селезёночный индекс ${y}, норма <480. `
      );
    notificationTheBest(
      "Формулировка для заключения автоматически скопирована в буфер обмена",
      3000
    );

    setCalculated((c) => (c = !c));
    setSize1(undefined);
    setSize2(undefined);
    setSize3(undefined);
  };

  const enter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      calculate();
    }
  };

  return (
    <div className="calculator">
      <p className="name">Объём селезёнки</p>
      <div className="legend">
        <p>{"Размеры в сантиметрах"}</p>
      </div>
      <div className="inputVertical" key={calculated}>
        <input
          key={1}
          type="number"
          placeholder="Размер 1 в cм"
          value={size1}
          onChange={(e) => {
            setSize1(e.target.value);
          }}
          onKeyDown={enter}
        />
        <input
          key={2}
          type="number"
          placeholder="Размер 2 в cм"
          value={size2}
          onChange={(e) => {
            setSize2(e.target.value);
          }}
          onKeyDown={enter}
        />
        <input
          key={3}
          type="number"
          placeholder="Размер 3 в cм"
          value={size3}
          onChange={(e) => {
            setSize3(e.target.value);
          }}
          onKeyDown={enter}
        />
      </div>
      <div className="results" style={{ fontSize: "22px" }}>
        Объём: {result} см3
      </div>
      <div className="results" style={{ fontSize: "22px" }}>
        Индекс: {result2}
      </div>
      <button className="calculate" onClick={calculate}>
        Рассчитать (Enter)
      </button>
      <div className="legend">
        {result !== "0" && result <= 314 && (
          <>
            <p
              key={5}
            >{`Селезёнка не увеличена (объём составляет ${result} см3, норма <314см3, селезёночный индекс ${result2}, норма <480.`}</p>
          </>
        )}
        {result !== "0" && result > 314 && (
          <>
            <p
              key={6}
            >{`Селезёнка увеличена в размерах (объём составляет ${result} см3, норма <314см3, селезёночный индекс ${result2}, норма <480.`}</p>
          </>
        )}
        {notallsizes && <p style={{ color: "red" }}> ВВЕДИТЕ ВСЕ РАЗМЕРЫ</p>}
      </div>
    </div>
  );
};

export default SpleenCalc;
