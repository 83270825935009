import TextareaAutosize from "react-textarea-autosize";
import { useEffect, useState } from "react";
import { evaluate } from "mathjs";
import Icon from "../redactorComponents/icons/icons";

const CALCULATOR = ({
  organIndex,
  segmentIndex,
  buttonIndex,
  rybaObject,
  buttonClickCalculator,
  scrollY,
  setCALCULATORopen,
}) => {
  const [calculator, setCalculator] = useState(
    rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex]
  );
  const [opisanie, setOpisanie] = useState("");
  const [zakluchenie, setZakluchenie] = useState("");
  const [pre, setPre] = useState(false);
  const [calculations, setCalculations] = useState([]);

  function replaceTitlesWithValuesLiteral(inputString, objectsArray) {
    let modifiedString = inputString;

    objectsArray.forEach((obj) => {
      // Create a regex to match the title as a whole word
      const regex = new RegExp(`${obj.title}`, "g");
      modifiedString = modifiedString.replace(
        regex,
        obj.value !== undefined ? obj.value : ""
      ); // Replace title with value
    });

    return modifiedString;
  }
  function replaceTitlesWithValues(input, objects) {
    objects.forEach((obj) => {
      const escapedTitle = obj.title.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      const regex = new RegExp(`\\(${escapedTitle}\\)`, "g");
      input = input.replace(regex, obj.value !== undefined ? obj.value : "");
    });

    return input;
  }
  const oiDaSamSmogu = (n) => {
    if (n < 1) {
      return n.toFixed(2);
    } else if (n >= 1 && n <= 10) {
      return n.toFixed(1);
    } else if (n > 10) {
      return n.toFixed();
    } else {
      return n.toFixed(3);
    }
  };

  const megaCalculation = (preview) => {
    let finalString = "";
    let finalStringZakl = "";
    let cs = [];
    let variablesAndFormulasCalculations = [...calculator.values];

    calculator.formulas.map((formula, i) => {
      let calculation = [];

      formula.map((f) => {
        calculation.push(f.title);
      });

      let finalCalcString = replaceTitlesWithValuesLiteral(
        calculation.join(""),
        calculator.values.filter((a) => a.type === "variable")
      );
      let calcResult = undefined;
      try {
        calcResult = evaluate(finalCalcString);
      } catch (err) {
        console.log(err);
        calcResult = "не ввели циферки";
      }

      variablesAndFormulasCalculations.push({
        title: `формула${i + 1}`,
        value:
          calcResult !== "не ввели циферки"
            ? oiDaSamSmogu(calcResult)
            : "не ввели циферки",
      });
    });
    ////////
    function getValueByTitle(title, array) {
      const foundObject = array.find((obj) => obj.title === title);
      return foundObject ? foundObject.value : undefined;
    }
    ////////

    [...calculator.steps, ...calculator.stepsZakl].map((s, index) => {
      let ifAllConditionsAreTrue = [];
      if (s.logic.length === 0) {
        finalString += s.output;
        finalStringZakl += s.outputZakl;
        return;
      }
      s.logic.map((l, lindex) => {
        const value = Number(
          getValueByTitle(l.title, variablesAndFormulasCalculations)
        );
        if (value === undefined) return;

        if (l.type === ">" && value > Number(l.value)) {
          ifAllConditionsAreTrue.push(true);
        } else if (l.type === ">" && value <= Number(l.value)) {
          ifAllConditionsAreTrue.push(false);
        }
        if (l.type === ">=" && value >= Number(l.value)) {
          ifAllConditionsAreTrue.push(true);
        } else if (l.type === ">=" && value < Number(l.value)) {
          ifAllConditionsAreTrue.push(false);
        }
        if (l.type === "<" && value < Number(l.value)) {
          ifAllConditionsAreTrue.push(true);
        } else if (l.type === "<" && value >= Number(l.value)) {
          ifAllConditionsAreTrue.push(false);
        }
        if (l.type === "<=" && value <= Number(l.value)) {
          ifAllConditionsAreTrue.push(true);
        } else if (l.type === "<=" && value > Number(l.value)) {
          ifAllConditionsAreTrue.push(false);
        }
        if (l.type === "=" && value === Number(l.value)) {
          ifAllConditionsAreTrue.push(true);
        } else if (l.type === "=" && value !== Number(l.value)) {
          ifAllConditionsAreTrue.push(false);
        }
        if (l.type === "comparison") {
          const value2 = Number(
            getValueByTitle(l.title2, variablesAndFormulasCalculations)
          );
          if (value2 === undefined) return;
          if (l.type2 === ">" && value > value2) {
            ifAllConditionsAreTrue.push(true);
          } else if (l.type2 === ">" && value <= value2) {
            ifAllConditionsAreTrue.push(false);
          }
          if (l.type2 === ">=" && value >= value2) {
            ifAllConditionsAreTrue.push(true);
          } else if (l.type2 === ">=" && value < value2) {
            ifAllConditionsAreTrue.push(false);
          }
          if (l.type2 === "<" && value < value2) {
            ifAllConditionsAreTrue.push(true);
          } else if (l.type2 === "<" && value >= value2) {
            ifAllConditionsAreTrue.push(false);
          }
          if (l.type2 === "<=" && value <= value2) {
            ifAllConditionsAreTrue.push(true);
          } else if (l.type2 === "<=" && value > value2) {
            ifAllConditionsAreTrue.push(false);
          }
          if (l.type2 === "=" && value === value2) {
            ifAllConditionsAreTrue.push(true);
          } else if (l.type2 === "=" && value !== value2) {
            ifAllConditionsAreTrue.push(false);
          }
        }
        if (
          l.type === "boolean" &&
          l.value ===
            calculator.values[
              calculator.values.findIndex(
                (i) => i.title === l.title && i.type === "boolean"
              )
            ].value
        ) {
          ifAllConditionsAreTrue.push(true);
        } else if (
          l.type === "boolean" &&
          l.value !==
            calculator.values[
              calculator.values.findIndex(
                (i) => i.title === l.title && i.type === "boolean"
              )
            ].value
        ) {
          ifAllConditionsAreTrue.push(false);
        }
        if (
          l.type === "isDefined" &&
          l.value === "Да" &&
          calculator.values[
            calculator.values.findIndex((i) => i.title === l.title)
          ].value !== undefined
        ) {
          ifAllConditionsAreTrue.push(true);
        } else if (
          l.type === "isDefined" &&
          l.value === "Нет" &&
          calculator.values[
            calculator.values.findIndex((i) => i.title === l.title)
          ].value === undefined
        ) {
          ifAllConditionsAreTrue.push(true);
        } else if (
          l.type === "isDefined" &&
          l.value === "Нет" &&
          calculator.values[
            calculator.values.findIndex((i) => i.title === l.title)
          ].value !== undefined
        ) {
          ifAllConditionsAreTrue.push(false);
        } else if (
          l.type === "isDefined" &&
          l.value === "Да" &&
          calculator.values[
            calculator.values.findIndex((i) => i.title === l.title)
          ].value === undefined
        ) {
          ifAllConditionsAreTrue.push(false);
        }
        if (
          l.type === "radio" &&
          l.value ===
            calculator.values[
              calculator.values.findIndex((i) => i.title === l.title)
            ].value
        ) {
          ifAllConditionsAreTrue.push(true);
        } else if (
          l.type === "radio" &&
          l.value !==
            calculator.values[
              calculator.values.findIndex((i) => i.title === l.title)
            ].value
        ) {
          ifAllConditionsAreTrue.push(false);
        }
        if (
          l.type === "menu" &&
          l.value ===
            calculator.values[
              calculator.values.findIndex(
                (i) => i.title === l.title && i.type === "menu"
              )
            ].value
        ) {
          ifAllConditionsAreTrue.push(true);
        } else if (
          l.type === "menu" &&
          l.value !==
            calculator.values[
              calculator.values.findIndex(
                (i) => i.title === l.title && i.type === "menu"
              )
            ].value
        ) {
          ifAllConditionsAreTrue.push(false);
        }
      });
      if (s.logicType === "and") {
        if (
          ifAllConditionsAreTrue.length > 0 &&
          !ifAllConditionsAreTrue.includes(false)
        ) {
          finalString += s.output;
          finalStringZakl += s.outputZakl;
        }
      }
      if (s.logicType === "or") {
        if (
          ifAllConditionsAreTrue.length > 0 &&
          ifAllConditionsAreTrue.includes(true)
        ) {
          finalString += s.output;
          finalStringZakl += s.outputZakl;
        }
      }
    });

    const opis = replaceTitlesWithValues(
      finalString,
      variablesAndFormulasCalculations
    );
    const zakl = replaceTitlesWithValues(
      finalStringZakl,
      variablesAndFormulasCalculations
    );
    if (!preview) {
      buttonClickCalculator(opis, zakl, calculator.newline);
      setCALCULATORopen(false);
      return;
    }
    setCalculations(cs);
    setOpisanie(opis);
    setZakluchenie(zakl);
    setCalculations(variablesAndFormulasCalculations);
  };
  const handleValuesDisplay = (value, index) => {
    if (value.type === "variable") {
      return (
        <div className="variableContainer">
          {" "}
          <span>{value.placeholder}</span>
          <input
            style={{
              background: value.value && "rgba(70, 126, 177, 0.306)",
            }}
            type="number"
            placeholder={"введите цифру"}
            value={value.value}
            onChange={(e) => {
              let c = { ...calculator };
              c.values[index].value = e.target.value;
              if (e.target.value === "") {
                c.values[index].value = undefined;
              }
              setCalculator(c);
            }}
          />
        </div>
      );
    }
    if (value.type === "boolean") {
      return (
        <div className="booleanContainer">
          <label
            className="pointer"
            style={{
              color: calculator.values[index].value === "Да" && "#00bbff",
            }}
          >
            <input
              className="pointer"
              type="checkbox"
              value={value.value}
              onChange={(e) => {
                let c = { ...calculator };
                c.values[index].value === "Да"
                  ? (c.values[index].value = "Нет")
                  : (c.values[index].value = "Да");
                setCalculator(c);
              }}
            ></input>{" "}
            {value.title}
          </label>
        </div>
      );
    }
    if (value.type === "radio") {
      return (
        <div
          className="radioContainer"
          style={{
            // background: "#202020",
            margin: "5px",
            padding: "7px",
            height: "100%",
            // border: "1px solid green",
            display: !value.inline && "flex",
            justifyContent: !value.inline && "center",
          }}
        >
          <div
            style={{
              display: "inline-block",
              width: "350px",
              textAlign: !value.inline && "right",
              height: "100%",
            }}
          >
            <p
              style={{
                display: "inline-block",
                marginRight: "20px",
                marginLeft: "20px",
                marginBottom: "3px",
                marginTop: "10px",
                fontSize: "1.05rem",
                fontWeight: "100",
                textAlign: "right",
              }}
            >
              {value.title}{" "}
            </p>
          </div>

          <div
            style={{
              width: !value.inline && "300px",
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: !value.inline && "left",
            }}
          >
            {value.radios.map((r, radioIndex) => (
              <div
                style={{
                  display: value.inline && "inline-block",
                }}
              >
                <label
                  style={{
                    color: calculator.values[index].value === r && "#00bbff",
                  }}
                >
                  <input
                    type="radio"
                    value={r}
                    name={value.title}
                    onChange={(e) => {
                      let c = { ...calculator };
                      c.values[index].value = e.target.value;
                      console.log(c.values[index]);
                      setCalculator(c);
                    }}
                  />
                  {r}
                </label>
              </div>
            ))}
          </div>
        </div>
      );
    }
    if (value.type === "list") {
      return (
        <div
          className="radioContainer"
          style={{
            // background: "#202020",
            margin: "5px",
            padding: "7px",
            height: "100%",
            fontWeight: "100",
            // border: "1px solid green",
            // display: !value.inline && "flex",
            // justifyContent: !value.inline && "center",
          }}
        >
          <div
            style={{
              display: "inline-block",
              width: "350px",
              // textAlign: !value.inline && "right",
              height: "100%",
            }}
          >
            <p
              style={{
                display: "inline-block",
                marginRight: "20px",
                marginLeft: "20px",
                marginBottom: "5px",
                marginTop: "0px",
                fontSize: "1.05rem",
                fontWeight: "100",
                textAlign: "right",
              }}
            >
              {value.title}{" "}
            </p>
          </div>

          <div
            style={{
              // width: !value.inline && "300px",
              marginLeft: "auto",
              marginRight: "auto",
              // textAlign: !value.inline && "left",
            }}
          >
            {value.radios.map((r, radioIndex) => (
              <span
                className={`listSpan pointer ${r.checked && "checkedListSpan"}`}
                type="checkbox"
                onClick={() => {
                  let c = { ...calculator };
                  if (r.checked === true) {
                    c.values[index].radios[radioIndex].checked = false;
                  } else {
                    c.values[index].radios[radioIndex].checked = true;
                  }
                  let aggregate = [];
                  c.values[index].radios.map((v) => {
                    if (v.checked) {
                      aggregate.push(v.title);
                    }
                  });
                  if (aggregate.length === 0) {
                    c.values[index].value = undefined;
                  }
                  if (aggregate.length === 1) {
                    c.values[index].value = aggregate[0];
                  }
                  if (aggregate.length > 1) {
                    c.values[index].value = aggregate.join(", ");
                  }
                  setCalculator(c);
                }}
              >
                {r.title}
              </span>
            ))}
          </div>
        </div>
      );
    }
    if (value.type === "menu") {
      return (
        <div className="menuContainer">
          <span
            style={{
              padding: "3px",
            }}
          >
            {value.title}
          </span>
          <select
            className="calculatorSelect seseseSelect"
            style={{
              width: "120px",
              textAlign: "center",
              borderColor: value.value !== undefined && "#00bbff90",
              background: value.value !== undefined && "#5cbfe34e",
            }}
            onChange={(e) => {
              let rrr = { ...calculator };
              console.log("значение до смены", rrr.values[index].value);
              rrr.values[index].value =
                e.target.value === "" ? undefined : e.target.value;

              setCalculator(rrr);
            }}
          >
            <option value={""}>{"-"}</option>
            {value.radios.map((r) => (
              <option value={r} name={value.title}>
                {r}
              </option>
            ))}
          </select>
        </div>
      );
    }
  };

  const setAllValuesUndefined = () => {
    let c = { ...calculator };
    c.values.map((v) => {
      v.value = undefined;
      if (v.type === "list") {
        v.radios.map((r) => (r.checked = false));
      }
    });
    return c;
  };
  useEffect(() => {
    setCalculator(setAllValuesUndefined());
  }, []);

  return (
    <div className="popupContainer" key={1}>
      <div
        className="popupContent"
        style={{
          minWidth: calculator.image?.URL ? "1150px" : "650px",
          position: "absolute",
          top: `${scrollY + 150}px`,
        }}
        key={3}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <p
            style={{
              fontWeight: "500",
              fontSize: "24px",
              color: "darkgrey",
              paddingTop: "0px",
              marginTop: "0px",
              marginBottom: "20px",
            }}
          >
            {calculator.title}
          </p>
          <button
            style={{
              position: "absolute",
              top: "-20px",
              right: "-10px",
              padding: "7px",
            }}
            onClick={() => {
              setCALCULATORopen(false);
              setCalculator(setAllValuesUndefined());
            }}
          >
            <Icon title="close" height="20px" width="20px" />
          </button>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {calculator.image?.URL && (
            <div
              style={{
                width: "500px",
              }}
              className="calculatorImage"
            >
              {calculator.image?.URL && (
                <div
                  style={{
                    // display: "flex",
                    // justifyContent: "center",
                    // justifyItems: "center",
                    // alignContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <img
                    src={calculator.image.URL}
                    alt="Preview"
                    style={{
                      maxHeight: "100%",
                      maxWidth: "490px",
                      borderRadius: "6px",
                      boxShadow: "0px 5px 10px 3px rgba(0, 0, 0, 0.7)",
                    }}
                  />
                </div>
              )}
            </div>
          )}
          <div
            style={{
              width: "650px",
            }}
          >
            <div className="calculatorFinalContainer">
              {calculator.description !== "" && (
                <div className="descriptionCalc">{calculator.description}</div>
              )}
              {calculator.values.length > 0 &&
                calculator.values.map((value, index) =>
                  handleValuesDisplay(value, index)
                )}
            </div>
            <button
              onClick={() => {
                megaCalculation();
                setCalculator(setAllValuesUndefined());
              }}
            >
              МЕГАРАЩОТ
            </button>
            <button
              onClick={() => {
                megaCalculation(true);
                setPre(true);
              }}
            >
              Предпросмотр
            </button>

            {pre && (
              <div
                style={{
                  background: "#242424",
                  padding: "10px",
                  margin: "15px",
                  borderRadius: "6px",
                }}
              >
                <p>
                  {" "}
                  <span
                    style={
                      {
                        // fontWeight: "700",
                        // color: "lightblue",
                      }
                    }
                  >
                    ОПИСАНИЕ:
                  </span>{" "}
                  <span
                    style={{
                      fontWeight: "100",
                      color: "lightblue",
                    }}
                  >
                    {opisanie === "" ? "-" : opisanie}
                  </span>
                </p>
                <p>
                  {" "}
                  <span
                    style={
                      {
                        // fontWeight: "700",
                        // color: "lightyellow",
                      }
                    }
                  >
                    ЗАКЛЮЧЕНИЕ:
                  </span>{" "}
                  <span
                    style={{
                      fontWeight: "100",
                      color: "lightyellow",
                    }}
                  >
                    {zakluchenie === "" ? "-" : zakluchenie}
                  </span>
                </p>

                {/* <p>
                <p>Вычисления:</p>
                  {calculations.map((c, i) => (
                    <p>{`${c.title}: ${c.value}`}</p>
                  ))}
                </p> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CALCULATOR;
