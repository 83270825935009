import React, { useState, useEffect, useRef, useCallback } from "react";
import styles from "./HelperWindow.module.css";

const HelperWindow = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [contentCallback, setContentCallback] = useState(() => <></>);
  const [onCloseCallback, setOnCloseCallback] = useState(null);
  const windowRef = useRef(null);

  const displayHelper = useCallback((title, contentCallback, onClose) => {
    setTitle(title);
    setContentCallback(() => contentCallback);
    setOnCloseCallback(onClose);
    setIsVisible(true);
  }, []);

  const closeHelper = useCallback(() => {
    if (onCloseCallback) {
      onCloseCallback();
    }
    setIsVisible(false);
  }, [onCloseCallback]);

  // Function to close the helper when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (windowRef.current && !windowRef.current.contains(event.target)) {
        closeHelper();
      }
    }

    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, closeHelper]);

  // Make displayHelper globally accessible
  useEffect(() => {
    window.displayHelper = displayHelper;

    return () => {
      delete window.displayHelper;
    };
  }, [displayHelper]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.overlay}>
      <div className={styles.window} ref={windowRef}>
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
        </div>
        <div className={styles.content}>{contentCallback()}</div>
        <div
          className={styles.footer}
          style={{
            padding: "10px",
          }}
        >
          <button className={styles.button} onClick={closeHelper}>
            Понятно
          </button>
        </div>
      </div>
    </div>
  );
};

export default HelperWindow;
