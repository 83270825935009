import TextareaAutosize from "react-textarea-autosize";
import { useState, useRef } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import SearchComponent from "./SearchComponent";

const FindButton = ({
  organINDEX,
  segmentINDEX,
  rybaObject,
  setFindButtonOpen,
  updateRybaObject,
  addButtonToRyba4,
  scrollY,
}) => {
  let p = 0;
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [data, setData] = useState();
  const [loaded, setLoaded] = useState(true);
  const { user } = useAuthContext();

  // FILTERS //
  let titleQuery = useRef("");
  let contentQuery = useRef("");
  // let protocolREF = useRef("&protocol=any");
  // SORTING //
  let sortRef = useRef("");
  let pageLimitRef = useRef("&limit=20");
  ////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////// QUERY FUNCTION /////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  const searchQuery = async function (req, res, URL) {
    const response = await fetch(URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();
    if (response.status === 401) {
      window.localStorage.removeItem("user");
      window.location.reload();
    }

    if (response.ok) {
      // console.log(res.);
      console.log("Ryba list", json);
      setData(json);
      setLoaded(true);
    }
  };

  const megaRequest = () => {
    setLoaded(false);
    setData(undefined);
    const query = `${process.env.REACT_APP_BACKEND_URL}/shared/filters/findButtons/?shared=true&modality=${rybaObject.modality}${titleQuery.current}${contentQuery.current}`;
    console.log(query);
    searchQuery(undefined, undefined, query);
    console.log(query);
  };

  // enter handler
  const submitEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      megaRequest();
      // setFindButtonOpen(false);
    }
    if (e.key === "Tab") {
      e.preventDefault();
    }
    if (e.key === "Escape") {
      // setFindButtonOpen(false);
    }
  };

  return (
    <div className="popupContainer" key={(p += 1)}>
      <div
        className="popupContent"
        style={{
          width: "1250px",
          position: "absolute",
          top: `${scrollY + 150}px`,
        }}
        key={(p += 1)}
      >
        {" "}
        <span>Найти по названию или тексту</span>
        <SearchComponent
          {...{
            organINDEX,
            segmentINDEX,
            rybaObject,
            setFindButtonOpen,
            updateRybaObject,
            addButtonToRyba4,
          }}
        />
        {/* <TextareaAutosize
          style={{
            width: "350px",
          }}
          autoFocus
          className="inputPromptTextarea"
          // value={title}
          onChange={(e) => {
            titleQuery.current = `&title=${e.target.value}`;
            if (e.target.value === "") {
              titleQuery.current = "";
            }
            // if (e.target.value === "") {
            //   setTitle("");
            //   return;
            // }
            // setTitle(`title=${e.target.value}`);
          }}
          onKeyDown={submitEnter}
        ></TextareaAutosize> */}
        {/* <div>
          <button
            style={{
              width: "350px",
              margin: "10px",
            }}
            className="saveChanges"
            onClick={(e) => {
              megaRequest();

              // setFindButtonOpen(false);
            }}
          >
            Искать (Enter)
          </button>

          <button
            style={{
              width: "350px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            className="closeTextEditWindow"
            onClick={() => {
              setFindButtonOpen(false);
            }}
          >
            Закрыть (Esc)
          </button>
        </div> */}
        {/* <div className="buttonSearchResults">
          {!loaded && (
            <div
              className="loaderSpinner"
              style={{
                marginTop: "50px",
                marginBottom: "50px",
                scale: "1.5",
              }}
            ></div>
          )}
          {data &&
            data.checkboxes.map((b, index) => (
              <div className="foundButton">
                <p key={index}>{b.title}</p>
                <span
                  style={{
                    fontSize: "14px",
                    color: "grey",
                    margin: "0px",
                  }}
                >
                  {b.content}
                </span>
              </div>
            ))}
        </div> */}
      </div>
    </div>
  );
};
export default FindButton;
