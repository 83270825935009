import React, { useState, useEffect } from "react";

// Notification service module
const notificationService = {
  addListener: null,
  nextId: 0,
  listeners: new Set(),
  addNotification(text, duration = 3000) {
    const id = ++this.nextId;
    this.listeners.forEach((listener) => listener({ id, text, duration }));
    return id;
  },
  subscribe(callback) {
    this.listeners.add(callback);
    return () => this.listeners.delete(callback);
  },
};

// Export the notification function
export const notificationTheBest = (text, duration) =>
  notificationService.addNotification(text, duration);

// Notification component
const Notifications = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const unsubscribe = notificationService.subscribe((newNotification) => {
      setNotifications((prev) => [newNotification, ...prev]);
    });
    return unsubscribe;
  }, []);

  const removeNotification = (id) => {
    setNotifications((prev) => prev.filter((n) => n.id !== id));
  };

  return (
    <div
      style={{
        position: "fixed",
        right: "20px",
        top: "180px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        zIndex: 1000,
      }}
    >
      {notifications.map(({ id, text, duration }) => (
        <NotificationItem
          key={id}
          id={id}
          text={text}
          duration={duration}
          onClose={removeNotification}
        />
      ))}
    </div>
  );
};

// Individual notification component
const NotificationItem = ({ id, text, duration, onClose }) => {
  const [isExiting, setIsExiting] = useState(false); // State to track if the
  // notification is exiting

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsExiting(true); // Trigger the exit animation
    }, duration - 300); // Start fadeOut animation 300ms before removal

    const removeTimeout = setTimeout(() => onClose(id), duration); // Remove
    // after full duration

    return () => {
      clearTimeout(timeout);
      clearTimeout(removeTimeout);
    };
  }, [id, duration, onClose]);

  return (
    <div
      style={{
        background: "#FEFCDD",
        padding: "15px 40px 15px 20px",
        borderRadius: "8px",
        boxShadow: "0 2px 10px rgba(0,0,0,0.7)",
        position: "relative",
        minWidth: "250px",
        animation: isExiting
          ? "fadeOut 0.3s ease-out forwards"
          : "slideIn 0.3s ease-out", // Apply fadeOut animation
      }}
    >
      <div style={{ wordBreak: "break-word" }}>{text}</div>
      <button
        onClick={() => {
          setIsExiting(true); // Trigger the exit animation on manual close
          setTimeout(() => onClose(id), 300); // Remove after animation
          // completes
        }}
        style={{
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          background: "none",
          border: "none",
          cursor: "pointer",
          color: "#666",
          padding: "5px",
          fontSize: "16px",
          lineHeight: "1",
        }}
        aria-label="Close"
      >
        &times;
      </button>
    </div>
  );
};

// Export the Notifications component
export default Notifications;

// Define CSS animations using a style tag
const styles = `
 @keyframes slideIn {
 from {
 transform: translateX(100%);
 opacity: 0;
 }
 to {
 transform: translateX(0);
 opacity: 1;
 }
 }


 @keyframes fadeOut {
 from {
 opacity: 1;
 }
 to {
 opacity: 0;
 transform: translateY(-20px);
 }
 }
`;

// Inject the styles into the document
if (typeof window !== "undefined") {
  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);
}
