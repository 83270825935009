import TextareaAutosize from "react-textarea-autosize";
import { useState } from "react";
import Radio from "./Radio";
import ICAstenosisIMG from "../../images/ICAstenosisIMG.png";

const AdrenalWashout = ({
  constructorREF,
  setConstructorOpen,
  formState,
  setFormState,
  text,
  setText,
  zaklState,
  setZaklState,
  rybaObject,
  dinamika,
  handlePasteConstructor,
  addedWithButtons,
}) => {
  let p = 0;

  const organIndex = constructorREF.current.organIndex;
  const segmentIndex = constructorREF.current.segmentIndex;
  const buttonIndex = constructorREF.current.buttonIndex;

  //////////////////////
  const [size1, setSize1] = useState(null);
  const [size2, setSize2] = useState(null);
  const [size3, setSize3] = useState(null);
  const [notallsizes, setNotallsizes] = useState(false);

  //////////////

  const textHandler = function () {
    ///////
    let zaklStringFinal = "";
    let nat = `${size1}`;
    let ven = `${size2}`;
    let del = `${size3}`;
    if (!size1 || !size2 || !size3) {
      return setNotallsizes(true);
    }

    if (size1 && size2 && size3) {
      setNotallsizes(false);
    }
    let absoluteWashout = ((ven - del) / (ven - nat)) * 100;
    absoluteWashout = Math.round(absoluteWashout);
    let relativeWashout = ((ven - del) / ven) * 100;
    relativeWashout = Math.round(relativeWashout);

    let finalMeasure = "";

    finalMeasure = `Плотность объёмного образования нативно ${nat} HU, в венозную фазу контрастирования (60-75 сек) ${ven} HU, в отсроченную фазу контрастирования (15 мин) ${del} HU, относительный индекс вымывания - ${relativeWashout}% ${
      relativeWashout >= 40
        ? "(>40% - с высокой долей вероятности указывает на аденому надпочечника, чувствительность 96%, специфичность 100%)"
        : "(<40% - менее характерно для аденом, образование требует дальнейшего дообследования)"
    }, абсолютный индекс вымывания - ${absoluteWashout}% ${
      absoluteWashout >= 60
        ? "(>60% - с высокой долей вероятности указывает на аденому надпочечника, чувствительность 87%, специфичность 94%)"
        : "(<60% - менее характерно для аденом, образование требует дальнейшего дообследования)"
    }.`;

    // zaklStringFinal = `${accessVolumeZakl(age, volume)}`;

    console.log(finalMeasure);
    ///////
    ///////
    if (dinamika) {
      handlePasteConstructor(
        organIndex,
        segmentIndex,
        finalMeasure,
        zaklStringFinal,
        dinamika
      );
      addedWithButtons(finalMeasure);
      addedWithButtons(zaklStringFinal);

      setConstructorOpen(false);
      return;
    }

    let btnObj =
      rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex];
    let rybaLength = rybaObject.organs.length - 1;

    let t = [...text];
    if (
      t[organIndex][segmentIndex] ===
      rybaObject.organs[organIndex].segments[segmentIndex].norm
    ) {
      t[organIndex][segmentIndex] = "";
    }
    t[organIndex][segmentIndex] += btnObj.newline
      ? "\n" + finalMeasure
      : finalMeasure;

    t[organIndex][segmentIndex] = t[organIndex][segmentIndex].trim();
    if (t[rybaLength][0] === rybaObject.organs[rybaLength].segments[0].norm) {
      t[rybaLength][0] = "";
    }
    addedWithButtons(finalMeasure);
    addedWithButtons(zaklStringFinal);
    setText(t);
    setConstructorOpen(false);
  };
  const enter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // calculate();
      textHandler();
      setConstructorOpen(false);
    }
  };

  return (
    // <div className="popupContainerConstructor" key={(p += 1)}>
    <div className="constructorWindow" key={(p += 1)}>
      <h2
        key={(p += 1)}
        style={{
          margin: "0px",
        }}
      >
        Расчёт индексов вымывания контрастного вещества образованием
        надпочечников
      </h2>

      {/* <img
        src={ICAstenosisIMG}
        alt="spleen"
        style={{
          width: "300px",
          borderRadius: "10px",
          margin: "10px",
          boxShadow: "0px 3px 10px grey",
        }}
      /> */}
      <div>
        <div
          className="inputVertical"
          style={{
            display: "flex",
            marginTop: "20px",
          }}
        >
          Плотность нативно
          <input
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
            autoFocus
            type="number"
            placeholder="HU"
            value={size1}
            ы
            onChange={(e) => setSize1(e.target.value)}
            onKeyDown={enter}
          />
          Плотность в венозную фазу 60-75 сек
          <input
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
            type="number"
            placeholder="HU"
            value={size2}
            onChange={(e) => setSize2(e.target.value)}
            onKeyDown={enter}
          />
          Плотность в отсроченную фазу 15 мин
          <input
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
            type="number"
            placeholder="HU"
            value={size3}
            onChange={(e) => setSize3(e.target.value)}
            onKeyDown={enter}
          />
        </div>

        <button
          className="calculate"
          onClick={() => {
            // calculate();
            textHandler();
          }}
        >
          Рассчитать
        </button>
        {notallsizes && <p style={{ color: "red" }}> ВВЕДИТЕ ВСЕ ПОКАЗАТЕЛИ</p>}

        <button
          className="calculate"
          onClick={() => {
            // calculate();
            setConstructorOpen(false);
          }}
        >
          Закрыть
        </button>
      </div>
    </div>
  );
};
export default AdrenalWashout;
