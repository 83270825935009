import React from "react";

const OpenMenu = ({ onClick }) => {
  return (
    <div
      className="menuHover"
      style={{
        display: "inline-block",
        cursor: "pointer",
        position: "relative",
        width: "24px",
        height: "24px",
      }}
      onClick={onClick}
    >
      {/* Vertical dots */}
      <div
        style={{
          position: "absolute",
          top: "60%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "15px",
        }}
      >
        {[1, 2, 3].map((dot, index) => (
          <div
            key={index}
            style={{
              width: "3px",
              height: "3px",
              margin: "0px",
              top: "3px",
              backgroundColor: "currentColor",
              borderRadius: "50%",
              transition: "background-color 0.3s ease",
            }}
          />
        ))}
      </div>

      {/* Hover effect */}
      <div
        style={{
          //   position: "absolute",
          //   inset: 0,
          background: "grey",
          transition: "background 0.3s ease",
          //   opacity: 0,
          //   pointerEvents: "none",
        }}
      />
    </div>
  );
};

export default OpenMenu;
