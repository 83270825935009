import { useState } from "react";
import { notificationTheBest } from "../../rootComponents/NotificationService";

const ProstateCalc = ({ notification }) => {
  const [notallsizes, setNotallsizes] = useState(false);
  const [result, setResult] = useState("0");
  const [expVol, setExpVol] = useState("0");
  const [size1, setSize1] = useState();
  const [size2, setSize2] = useState();
  const [size3, setSize3] = useState();
  const [weight, setWeight] = useState(null);
  const [height, setHeight] = useState(null);
  const [calculated, setCalculated] = useState(false);
  const [string, setString] = useState(undefined);

  const calculate = () => {
    if (!size1 || !size2 || !size3) {
      return setNotallsizes(true);
    }
    if (size1 && size2 && size3) {
      setNotallsizes(false);
    }
    let volume = size1 * size2 * size3 * 0.31;
    volume = Math.round(volume);

    let BSA;
    let expectedVolume;
    if (weight && height) {
      const w = weight;
      const h = height;
      BSA = Math.sqrt((w * h) / 3600);
      expectedVolume = Math.round(1267.28 * BSA - 794.41);
    }

    let finalMeasure = "";
    const assessVolume = function (volume, expectedVolume) {
      if (volume > 1700) {
        return `- увеличение относительно абсолютного референсного значения 1700см3 (является усреднённым, требуется оценка в рамках конкретного клинического контекста)${
          expectedVolume
            ? `${
                expectedVolume < volume
                  ? `; объём печени выше значения ожидаемого объёма, рассчитанного на основе роста и веса (${expectedVolume} см3), что является более специфичной методикой выявления гепатомегалии`
                  : `; объём печени не превышает значения ожидаемого объёма, рассчитанного на основе роста и веса (${expectedVolume} см3)`
              }`
            : ""
        }`;
      }
      if (volume <= 1700) {
        return `- не увеличен относительно абсолютного референсного значения 1700см3 (является усреднённым, требуется оценка в рамках конкретного клинического контекста)${
          expectedVolume
            ? `${
                expectedVolume < volume
                  ? `; объём печени выше значения ожидаемого объёма, рассчитанного на основе роста и веса (${expectedVolume} см3), что является более специфичной методикой выявления гепатомегалии`
                  : `; объём печени не превышает значения ожидаемого объёма, рассчитанного на основе роста и веса (${expectedVolume} см3)`
              }`
            : ""
        }`;
      }
    };

    finalMeasure = `Максимальные размеры печени в трёх плоскостях ${size1}х${size2}x${size3} см, расчётный объём ${volume} мл ${assessVolume(
      volume,
      expectedVolume
    )}.`;
    setString(finalMeasure);
    setExpVol(expectedVolume);
    if (!height && !weight) {
      setExpVol("(для расчёта введите рост и вес)");
    }
    setResult(volume);
    notificationTheBest(
      "Формулировка для заключения автоматически скопирована в буфер обмена",
      3000
    );
    setCalculated((c) => (c = !c));
    setSize1(undefined);
    setSize2(undefined);
    setSize3(undefined);
    setHeight(undefined);
    setWeight(undefined);
  };

  const enter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      calculate();
    }
  };

  return (
    <div className="calculator">
      <p className="name">Объём печени</p>
      <div className="legend">
        <p>
          {
            "Если добавить возраст и вес, ещё посчитается ожидаемый объём печени (более точный референс)"
          }
        </p>
      </div>

      <div className="inputVertical">
        <fieldset
          style={{
            justifyContent: "center",
            borderRadius: "6px",
            border: "none",
            background: "#171717",
            color: "grey",
            margin: "5px",
          }}
          key={calculated}
        >
          {/* <legend>Расчёт ожидаемого объёма печени (опционален)</legend> */}
          Расчёт ожидаемого объёма печени (опционален)
          <label>
            <input
              placeholder="вес в кг"
              type="number"
              name="age"
              onChange={(e) => {
                setWeight(e.target.value);
              }}
            />
          </label>
          <label>
            <input
              placeholder="рост в см"
              type="number"
              name="age"
              onChange={(e) => {
                setHeight(e.target.value);
              }}
            />
          </label>
        </fieldset>
      </div>

      <div className="inputVertical" key={calculated}>
        <input
          type="number"
          placeholder="Размер 1 в см"
          value={size1}
          onChange={(e) => setSize1(e.target.value)}
          onKeyDown={enter}
        />
        <input
          type="number"
          placeholder="Размер 2 в см"
          value={size2}
          onChange={(e) => setSize2(e.target.value)}
          onKeyDown={enter}
        />
        <input
          type="number"
          placeholder="Размер 3 в см"
          value={size3}
          onChange={(e) => setSize3(e.target.value)}
          onKeyDown={enter}
        />
      </div>
      <div className="results">{result} см3</div>
      <p>Ожидаемый объём: {expVol} см3</p>
      <button
        className="calculate"
        onClick={() => {
          calculate();
        }}
      >
        Рассчитать (Enter)
      </button>

      <div className="legend">
        <p>{string}</p>
        {notallsizes && <p style={{ color: "red" }}> ВВЕДИТЕ ВСЕ РАЗМЕРЫ</p>}
      </div>
    </div>
  );
};

export default ProstateCalc;
