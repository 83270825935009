import React, { useState } from "react";
import { notificationTheBest } from "../rootComponents/NotificationService";
import Icon from "../components/redactorComponents/icons/icons";
import { copyButton } from "../components/Ryba4EditFunctions";

const ButtonEditContextMenu = ({
  buttonContextRef,
  rybaObject,
  organINDEX,
  segmentINDEX,
  buttonINDEX,
  radioINDEX,
  setButtonContextMenuOpen,
  setRybaObject,
  buttonType,
  setEditButtonOpen,
  editedButtonREF,
  setScrollY,
  setSureDeleteButton,
  duplicateButton,
  editedButtonJSON,
  setEditedButtonJSON,
}) => {
  return (
    <div
      className="contextMenu"
      ref={buttonContextRef}
      style={{
        position: "absolute",
        top: `22.5px`,
        left: `0px`,
        zIndex: "50",
        width: "130px",
      }}
    >
      <button
        className="contextMenuButton"
        onClick={(e) => {
          e.preventDefault();
          copyButton(
            organINDEX,
            segmentINDEX,
            buttonINDEX,
            radioINDEX,
            rybaObject,
            setButtonContextMenuOpen,
            buttonType
          );
        }}
      >
        <Icon title="copy" />
        Копировать кнопку
      </button>
      {/* <button
        className="contextMenuButton"
        onClick={(e) => {
          let r = { ...rybaObject };
          window.localStorage.setItem(
            `copiedBUTTON`,
            JSON.stringify(
              r.organs[organINDEX.current].segments[segmentINDEX.current]
                .buttons[buttonINDEX.current]
            )
          );
          //   deleteButtonRequest(
          //     e,
          //     buttonINDEX.current,
          //     segmentINDEX.current,
          //     organINDEX.current
          //   );
          setButtonContextMenuOpen(false);
        }}
      >
        <Icon title="cut" />
        Вырезать кнопку
      </button> */}
      {/* <button
        className="contextMenuButton"
        onClick={(e) => {
          e.preventDefault();
          if (!window.localStorage.getItem("copiedBUTTON")) {
            alert("В буфере обмена нет скопированных кнопок");
            return;
          }
          let r = { ...rybaObject };
          const newButton = JSON.parse(
            window.localStorage.getItem("copiedBUTTON")
          );
          r.organs[organINDEX.current].segments[
            segmentINDEX.current
          ].buttons.splice(buttonINDEX.current + 1, 0, newButton);
          notificationTheBest("Кнопка из буфера обмена добавлена");
          setRybaObject(r);
          setButtonContextMenuOpen(false);
        }}
      >
        <Icon title="pasteBelow" />
        Вставить ниже
      </button> */}
      {/* {rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
        .buttons[buttonINDEX.current].type === "radio" &&
        window.localStorage.getItem("copiedBUTTON") !== undefined && (
          <button
            className="contextMenuButton"
            onClick={(e) => {
              if (!window.localStorage.getItem("copiedBUTTON")) {
                alert("В буфере обмена нет скопированных кнопок");
                return;
              }
              let r = { ...rybaObject };
              const newButton = JSON.parse(
                window.localStorage.getItem("copiedBUTTON")
              );
              delete newButton.type;
              r.organs[organINDEX.current].segments[
                segmentINDEX.current
              ].buttons[buttonINDEX.current].radios.push(newButton);
              notificationTheBest("Кнопка из буфера обмена добавлена");
              setRybaObject(r);
              setButtonContextMenuOpen(false);
            }}
          >
            <Icon title="addToMenu" />
            Вставить кнопку в группу
          </button>
        )}
      {rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
        .buttons[buttonINDEX.current].type === "radio" &&
        window.localStorage.getItem("copiedBUTTON") !== undefined && (
          <button
            className="contextMenuButton"
            onClick={(e) => {
              if (!window.localStorage.getItem("copiedBUTTON")) {
                alert("В буфере обмена нет скопированных кнопок");
                return;
              }
              let r = { ...rybaObject };
              const newButton = JSON.parse(
                window.localStorage.getItem("copiedBUTTON")
              );
              delete newButton.type;
              r.organs[organINDEX.current].segments[
                segmentINDEX.current
              ].buttons[buttonINDEX.current].radios.push(newButton);
              notificationTheBest("Кнопка из буфера обмена добавлена");
              setRybaObject(r);
              setButtonContextMenuOpen(false);
            }}
          >
            <Icon title={"transformToMenu"} />
            Превратить группу в меню
          </button>
        )} */}
      {/* {rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
        .buttons[buttonINDEX.current].type === "select" &&
        window.localStorage.getItem("copiedBUTTON") !== undefined && (
          <button
            className="contextMenuButton"
            onClick={(e) => {
              if (!window.localStorage.getItem("copiedBUTTON")) {
                alert("В буфере обмена нет скопированных кнопок");
                return;
              }
              let r = { ...rybaObject };
              const newButton = JSON.parse(
                window.localStorage.getItem("copiedBUTTON")
              );
              delete newButton.type;
              r.organs[organINDEX.current].segments[
                segmentINDEX.current
              ].buttons[buttonINDEX.current].options.push(newButton);
              notificationTheBest("Кнопка из буфера обмена добавлена");
              setRybaObject(r);
              setButtonContextMenuOpen(false);
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M12 4v16m-4-4 4 4 4-4"></path>
              <path d="M8 4H4v4"></path>
              <path d="M16 4h4v4"></path>
            </svg>
            Вставить кнопку в меню
          </button>
        )} */}
      <button
        className="contextMenuButton redOnHover"
        onClick={(e) => {
          setEditButtonOpen(true);
          setScrollY(window.scrollY);
          setButtonContextMenuOpen(false);
        }}
      >
        <Icon title="edit" />
        Редактировать
      </button>{" "}
      <button
        className="contextMenuButton"
        onClick={(e) => {
          duplicateButton(
            organINDEX,
            segmentINDEX,
            buttonINDEX,
            radioINDEX,
            rybaObject,
            setRybaObject,
            setButtonContextMenuOpen,
            buttonType
          );
          setButtonContextMenuOpen(false);
        }}
      >
        <Icon title="duplicate" />
        Дублировать
      </button>
      {/* {buttonINDEX.current > 0 && (
        <button
          className="contextMenuButton"
          onClick={(e) => {
            // moveButtonUpward(
            //   organINDEX.current,
            //   segmentINDEX.current,
            //   buttonINDEX.current
            // );
            setButtonContextMenuOpen(false);
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M12 19V5"></path>
            <path d="M5 12l7-7 7 7"></path>
          </svg>
          Сдвинуть вверх
        </button>
      )}

      {!Object.hasOwn(
        rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
          .buttons[buttonINDEX.current],
        "choices"
      ) &&
        rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
          .buttons[buttonINDEX.current].type === "checkbox" &&
        rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
          .buttons[buttonINDEX.current].type !== "constructor" && (
          <button
            className="contextMenuButton"
            onClick={(e) => {
              let r = { ...rybaObject };
              const button =
                r.organs[organINDEX.current].segments[segmentINDEX.current]
                  .buttons[buttonINDEX.current];

              r.organs[organINDEX.current].segments[
                segmentINDEX.current
              ].buttons[buttonINDEX.current].choices = [[]];

              if (button.type === "radio") {
                r.organs[organINDEX.current].segments[
                  segmentINDEX.current
                ].buttons[buttonINDEX.current].radios.map((r) => {
                  r.choices = [[]];
                });
              }
              if (button.type === "select") {
                r.organs[organINDEX.current].segments[
                  segmentINDEX.current
                ].buttons[buttonINDEX.current].options.map((o) => {
                  o.choices = [[]];
                });
              }
              console.log(r);
              notificationTheBest("Поле опций добавлено");
              setRybaObject(r);
              setButtonContextMenuOpen(false);
            }}
          >
            <Icon title="addChoices" />
            Добавить опции для описания
          </button>
        )}

      {Object.hasOwn(
        rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
          .buttons[buttonINDEX.current],
        "choices"
      ) &&
        rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
          .buttons[buttonINDEX.current].type === "checkbox" && (
          <button
            className="contextMenuButton"
            onClick={(e) => {
              let r = { ...rybaObject };
              let button =
                r.organs[organINDEX.current].segments[segmentINDEX.current]
                  .buttons[buttonINDEX.current];
              if (button.type === "checkbox") {
                delete r.organs[organINDEX.current].segments[
                  segmentINDEX.current
                ].buttons[buttonINDEX.current].choices;
              }
              if (button.type === "radio") {
                r.organs[organINDEX.current].segments[
                  segmentINDEX.current
                ].buttons[buttonINDEX.current].radios.map((r) => {
                  delete r.choices;
                });
              }
              if (button.type === "select") {
                r.organs[organINDEX.current].segments[
                  segmentINDEX.current
                ].buttons[buttonINDEX.current].options.map((o) => {
                  delete o.choices;
                });
              }
              delete r.organs[organINDEX.current].segments[segmentINDEX.current]
                .buttons[buttonINDEX.current].choices;
              notificationTheBest("Поле опций удалено");
              setRybaObject(r);
              setButtonContextMenuOpen(false);
            }}
          >
            <Icon title="deleteChoices" />
            Удалить опции для описания
          </button>
        )}
      {!Object.hasOwn(
        rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
          .buttons[buttonINDEX.current],
        "choicesZakl"
      ) &&
        rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
          .buttons[buttonINDEX.current].type === "checkbox" && (
          <button
            className="contextMenuButton"
            onClick={(e) => {
              let r = { ...rybaObject };

              r.organs[organINDEX.current].segments[
                segmentINDEX.current
              ].buttons[buttonINDEX.current].choicesZakl = [[]];

              console.log(r);
              notificationTheBest("Поле опций добавлено");
              setRybaObject(r);
              setButtonContextMenuOpen(false);
            }}
          >
            <Icon title="addChoicesZakl" />
            Добавить опции для заключения
          </button>
        )}

      {Object.hasOwn(
        rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
          .buttons[buttonINDEX.current],
        "choicesZakl"
      ) &&
        rybaObject.organs[organINDEX.current].segments[segmentINDEX.current]
          .buttons[buttonINDEX.current].type === "checkbox" && (
          <button
            className="contextMenuButton"
            onClick={(e) => {
              let r = { ...rybaObject };
              let button =
                r.organs[organINDEX.current].segments[segmentINDEX.current]
                  .buttons[buttonINDEX.current];
              if (button.type === "checkbox") {
                delete r.organs[organINDEX.current].segments[
                  segmentINDEX.current
                ].buttons[buttonINDEX.current].choicesZakl;
              }
              if (button.type === "radio") {
                r.organs[organINDEX.current].segments[
                  segmentINDEX.current
                ].buttons[buttonINDEX.current].radios.map((r) => {
                  delete r.choicesZakl;
                });
              }
              if (button.type === "select") {
                r.organs[organINDEX.current].segments[
                  segmentINDEX.current
                ].buttons[buttonINDEX.current].options.map((o) => {
                  delete o.choicesZakl;
                });
              }
              delete r.organs[organINDEX.current].segments[segmentINDEX.current]
                .buttons[buttonINDEX.current].choicesZakl;
              notificationTheBest("Поле опций удалено");
              setRybaObject(r);
              setButtonContextMenuOpen(false);
            }}
          >
            <Icon title="deleteChoicesZakl" />
            Удалить опции для заключения
          </button>
        )} */}
      <button
        className="contextMenuButton redOnHover"
        onClick={(e) => {
          setButtonContextMenuOpen(false);
          setSureDeleteButton(true);
        }}
      >
        <Icon title="delete" />
        Удалить
      </button>
    </div>
  );
};

export default ButtonEditContextMenu;
