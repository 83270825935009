import React from "react";
import "./AboutUs.css"; // Import the CSS file for styling

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="about-us-header">
        <h1>О нас</h1>
        <p className="subtitle">
          Лучшие рентгенологические шаблоны солнечной системы.
        </p>
      </div>

      <div className="about-us-content">
        <div className="about-us-card">
          <h2>Нажимайте кнопочки</h2>
          <p>Большая часть описаний состоит из повторяющихся формулировок.</p>
          <p>
            Зачем в сотый раз заново печатать одно и то же, когда можно просто
            щёлкнуть мышкой?
          </p>
        </div>

        <div className="about-us-card">
          <h2>Забудьте про рутину</h2>
          <p>
            Интересные случаи попадаются не так часто, всё-таки большая часть
            работы - это стандартное и неинтересное.
          </p>
          <p>
            Приложение поможет вам раскидывать банальщину за секунды без вреда
            для пациента и уделять время случаям, которые того требуют.
          </p>
        </div>

        <div className="about-us-card">
          <h2>Экономьте время</h2>
          <p>
            Есть лучшие способы провести время, чем быть высоко(или не
            очень)оплачиваемым секретарём.
          </p>{" "}
          <p>Пусть печатает приложение, а вы дайте себе отдохнуть.</p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
