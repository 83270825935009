import "./buttons.css";
import { useRef, useState } from "react";
import CheckboxsVG from "../svg/CheckboxSVG";
import RadioSVG from "../svg/RadioSVG";

import ConstructorSVG from "../svg/ConstructorSVG";
import Dropdown from "../svg/Dropdown";
import ButtonEditRyba4 from "../../AIcomponents/ButtonEditRyba4";
import ButtonEditContextMenu from "../../AIcomponents/ButtonEditRyba4";
import ContextMenuCalculatorRyba4 from "../../AIcomponents/ContextMenuCalculatorRyba4";

const Buttons = ({
  button,
  buttonIndex,
  zaklRef,
  setConstructorOpen,
  constructorREF,
  organIndex,
  segmentIndex,
  buttonClick,
  radioINDEX,
  buttonINDEX,
  organINDEX,
  segmentINDEX,
  reset,
  setReset,
  showOverlayImages,
  topButtonContextMenu,
  leftButtonContextMenu,
  rybaObject,
  setRybaObject,
  editedButtonTypeREF,
  editedButtonREF,
  setSureDeleteButton,
  setEditButtonOpen,
  editedButton,
  setEditedButton,
  setScrollY,
  duplicateButton,
  editedButtonJSON,
  setEditedButtonJSON,
  setCALCULATORopen,
  calculatorEditorOpen,
  setCalculatorEditorOpen,
}) => {
  const [buttonContextMenuOpen, setButtonContextMenuOpen] = useState(false);
  const [radioIndex, setRadioIndex] = useState();
  let buttonContextRef = useRef();
  const closeOpenOrganContextMenu = (e) => {
    if (!buttonContextMenuOpen) return;
    if (
      buttonContextMenuOpen &&
      !buttonContextRef.current?.contains(e.target)
    ) {
      setButtonContextMenuOpen(false);
    }
  };
  document.addEventListener("mousedown", closeOpenOrganContextMenu);

  if (button.type === "radio")
    return (
      <>
        <div className="buttonBox" key={buttonIndex + button.title}>
          <p
            style={{
              paddingTop: "0px",
              marginTop: "0px",
            }}
          >
            {button.title}
          </p>

          {button.radios.map((r, index) => (
            <span
              key={index + r.title}
              style={{
                position: "relative",
              }}
            >
              <button
                key={r.title + index + "radio"}
                style={{
                  outline:
                    buttonContextMenuOpen &&
                    index === radioIndex &&
                    "1.5px solid orange",
                }}
                value={r.content}
                onClick={(e) => {
                  e.preventDefault();
                  console.log(e);
                  organINDEX.current = organIndex;
                  segmentINDEX.current = segmentIndex;
                  buttonINDEX.current = buttonIndex;
                  zaklRef.current = button.zakl;

                  buttonClick(
                    e,
                    organIndex,
                    segmentIndex,
                    buttonIndex,
                    r.zakl,
                    r
                  );
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  setEditedButtonJSON(
                    JSON.stringify(
                      rybaObject.organs[organIndex].segments[segmentIndex]
                        .buttons[buttonIndex]
                    )
                  );
                  setRadioIndex(index);
                  organINDEX.current = organIndex;
                  segmentINDEX.current = segmentIndex;
                  buttonINDEX.current = buttonIndex;
                  radioINDEX.current = index;
                  editedButtonTypeREF.current = button.type;
                  setEditedButton(r);
                  setButtonContextMenuOpen(true);
                }}
              >
                {r.title}
              </button>
              {buttonContextMenuOpen && index === radioIndex && (
                <ButtonEditContextMenu
                  {...{
                    buttonContextRef,
                    topButtonContextMenu,
                    leftButtonContextMenu,
                    rybaObject,
                    organINDEX,
                    segmentINDEX,
                    buttonINDEX,
                    radioINDEX,
                    setButtonContextMenuOpen,
                    setRybaObject,
                    buttonType: button.type,
                    setEditButtonOpen,
                    setScrollY,
                    setSureDeleteButton,
                    duplicateButton,
                  }}
                />
              )}
            </span>
          ))}
        </div>
      </>
    );
  if (button.type === "checkbox")
    return (
      <>
        <span
          key={buttonIndex + button.title}
          style={{
            position: "relative",
          }}
        >
          {" "}
          <button
            style={{
              outline: buttonContextMenuOpen && "1.5px solid orange",
              textAlign: "left",
            }}
            className="simpleButton3"
            value={button.content}
            onClick={(e) => {
              organINDEX.current = organIndex;
              segmentINDEX.current = segmentIndex;
              buttonINDEX.current = buttonIndex;
              zaklRef.current = button.zakl;
              buttonClick(
                e,
                organIndex,
                segmentIndex,
                buttonIndex,
                button.zakl,
                button
              );
              let s = [...reset];
              s[organIndex][segmentIndex][buttonIndex] = true;
              setReset(s);
            }}
            onContextMenu={(e) => {
              e.preventDefault();
              setEditedButtonJSON(
                JSON.stringify(
                  rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ]
                )
              );
              // setRadioIndex(index);
              organINDEX.current = organIndex;
              segmentINDEX.current = segmentIndex;
              buttonINDEX.current = buttonIndex;
              // radioINDEX.current = index;
              editedButtonTypeREF.current = button.type;
              setEditedButton(button);
              setButtonContextMenuOpen(true);
            }}
          >
            <span
              style={{
                marginTop: "9px",
              }}
            >
              <CheckboxsVG
                width={12}
                height={12}
                color={
                  // reset[organIndex][segmentIndex][buttonIndex] ? "yellow" :
                  "grey"
                }
              />{" "}
            </span>
            {button.title}
          </button>{" "}
          {buttonContextMenuOpen && (
            <ButtonEditContextMenu
              {...{
                buttonContextRef,
                topButtonContextMenu,
                leftButtonContextMenu,
                rybaObject,
                organINDEX,
                segmentINDEX,
                buttonINDEX,
                radioINDEX,
                setButtonContextMenuOpen,
                setRybaObject,
                buttonType: button.type,
                setEditButtonOpen,
                setScrollY,
                setSureDeleteButton,
                duplicateButton,
              }}
            />
          )}
        </span>
      </>
    );
  if (button.type === "constructor")
    return (
      <>
        <ConstructorSVG
          width={16}
          height={16}
          color={
            // reset[organIndex][segmentIndex][buttonIndex] ? "yellow" :
            "grey"
          }
        />{" "}
        <button
          key={buttonIndex + button.title}
          className="simpleButton2"
          value={button.title}
          onClick={(e) => {
            organINDEX.current = organIndex;
            segmentINDEX.current = segmentIndex;
            buttonINDEX.current = buttonIndex;
            constructorREF.current.constructor = button.title;
            constructorREF.current.organIndex = organIndex;
            constructorREF.current.segmentIndex = segmentIndex;
            constructorREF.current.buttonIndex = buttonIndex;
            setConstructorOpen(true);
          }}
        >
          {button.title} 🎛️
        </button>
      </>
    );
  if (button.type === "calculator")
    return (
      <span
        key={buttonIndex + button.title}
        style={{
          position: "relative",
          outline: buttonContextMenuOpen && "1.5px solid orange",
        }}
      >
        <ConstructorSVG
          width={16}
          height={16}
          color={
            // reset[organIndex][segmentIndex][buttonIndex] ? "yellow" :
            "grey"
          }
        />{" "}
        <button
          key={buttonIndex + button.title}
          className="simpleButton2"
          value={button.title}
          onClick={(e) => {
            organINDEX.current = organIndex;
            segmentINDEX.current = segmentIndex;
            buttonINDEX.current = buttonIndex;
            setScrollY(window.scrollY);
            setCALCULATORopen(true);
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            setEditedButtonJSON(
              JSON.stringify(
                rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                  buttonIndex
                ]
              )
            );
            // setRadioIndex(index);
            organINDEX.current = organIndex;
            segmentINDEX.current = segmentIndex;
            buttonINDEX.current = buttonIndex;
            // radioINDEX.current = index;
            editedButtonTypeREF.current = button.type;
            setEditedButton(button);
            setButtonContextMenuOpen(true);
          }}
        >
          {button.title} 🎛️
        </button>
        {buttonContextMenuOpen && (
          <ContextMenuCalculatorRyba4
            {...{
              buttonContextRef,
              topButtonContextMenu,
              leftButtonContextMenu,
              rybaObject,
              organINDEX,
              segmentINDEX,
              buttonINDEX,
              radioINDEX,
              setButtonContextMenuOpen,
              setRybaObject,
              buttonType: button.type,
              setEditButtonOpen,
              setScrollY,
              setSureDeleteButton,
              duplicateButton,
              calculatorEditorOpen,
              setCalculatorEditorOpen,
            }}
          />
        )}
      </span>
    );
  if (button.type === "select")
    return (
      <>
        <label key={buttonIndex + button.title}>
          <Dropdown
            width={16}
            height={16}
            color={
              // reset[organIndex][segmentIndex][buttonIndex] ? "yellow" :
              "grey"
            }
          />{" "}
          <select
            key={buttonIndex + button.title + 1}
            onChange={(e) => {
              zaklRef.current = button.options[e.target.selectedIndex].zakl;
              buttonINDEX.current = buttonIndex;
              organINDEX.current = organIndex;
              segmentINDEX.current = segmentIndex;
              buttonClick(
                e,
                organIndex,
                segmentIndex,
                buttonIndex,
                button.options[e.target.selectedIndex].zakl,
                button.options[e.target.selectedIndex]
              );
              e.target.value = "";
            }}
          >
            {button.options.map((s, index) => (
              <option value={s.content} key={s.title + index}>
                {s.title}{" "}
              </option>
            ))}
          </select>
          {button.title}
        </label>
      </>
    );
};

export default Buttons;
