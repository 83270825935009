import TextareaAutosize from "react-textarea-autosize";
import { useState } from "react";
import Radio from "./Radio";
import ICAstenosisIMG from "../../images/ICAstenosisIMG.png";

const STENOSISgeneral = ({
  constructorREF,
  setConstructorOpen,
  formState,
  setFormState,
  text,
  setText,
  zaklState,
  setZaklState,
  rybaObject,
  dinamika,
  handlePasteConstructor,
  addedWithButtons,
}) => {
  let p = 0;

  const organIndex = constructorREF.current.organIndex;
  const segmentIndex = constructorREF.current.segmentIndex;
  const buttonIndex = constructorREF.current.buttonIndex;

  //////////////////////
  const [size1, setSize1] = useState(null);
  const [size2, setSize2] = useState(null);
  const [side, setSide] = useState([]);
  const [side2, setSide2] = useState("");
  const [notallsizes, setNotallsizes] = useState(false);

  //////////////

  const textHandler = function () {
    ///////
    let zaklStringFinal = "";
    let xx = `${size1}`;
    let zz = `${size2}`;

    if (!size1 || !size2) {
      return setNotallsizes(true);
    }
    if (!side) return alert("Укажите сторону");
    if (xx && zz) {
      setNotallsizes(false);
    }
    let stenosis = -(size1 / size2 - 1) * 100;
    stenosis = Math.round(stenosis);

    let finalMeasure = "";

    finalMeasure = `Сужение просвета${side2} ${
      side.length !== 0 ? "на уровне " + String(side).trim() : ""
    } до ${size1} мм, просвет дистальнее уровня сужения ${size2} мм, расчётный процент стеноза ${stenosis}%.`;

    zaklStringFinal = "";

    if (dinamika) {
      handlePasteConstructor(
        organIndex,
        segmentIndex,
        finalMeasure,
        zaklStringFinal,
        dinamika
      );
      addedWithButtons(finalMeasure);
      addedWithButtons(zaklStringFinal);
      setConstructorOpen(false);
      return;
    }

    let btnObj =
      rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex];
    let rybaLength = rybaObject.organs.length - 1;

    let t = [...text];
    if (
      t[organIndex][segmentIndex] ===
      rybaObject.organs[organIndex].segments[segmentIndex].norm
    ) {
      t[organIndex][segmentIndex] = "";
    }
    t[organIndex][segmentIndex] += btnObj.newline
      ? "\n" + finalMeasure
      : finalMeasure;

    t[organIndex][segmentIndex] = t[organIndex][segmentIndex].trim();
    // if (t[rybaLength][0] === rybaObject.organs[rybaLength].segments[0].norm) {
    //   t[rybaLength][0] = "";
    // }
    // if (zaklStringFinal !== "") {
    //   t[rybaLength][0] += zaklStringFinal;
    // }
    addedWithButtons(finalMeasure);
    addedWithButtons(zaklStringFinal);
    setText(t);
    setConstructorOpen(false);
  };

  const enter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // calculate();
      textHandler();
      setConstructorOpen(false);
    }
  };

  return (
    // <div className="popupContainerConstructor" key={(p += 1)}>
    <div className="constructorWindow" key={(p += 1)}>
      <h2
        key={(p += 1)}
        style={{
          margin: "0px",
        }}
      >
        Расчёт стеноза артерии
      </h2>

      <img
        src="https://swift.timeweb.cloud/v1/cd5c3c6d-7735d894-d309-4ef5-87a5-a3d5741fd815/calculatorsImages/StenosisGeneral.png"
        alt="spleen"
        style={{
          width: "300px",
          borderRadius: "10px",
          margin: "10px",
          boxShadow: "0px 3px 10px grey",
        }}
      />
      <div>
        <fieldset
          style={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "6px",
            justifyItems: "left",
          }}
        >
          <legend>Уровень стеноза</legend>
          <label>
            <input
              type="checkbox"
              name="side"
              onClick={(e) => {
                if (e.target.checked === true) {
                  let x = [...side];
                  x.push(" устья");
                  setSide(x);
                }
                if (e.target.checked === false) {
                  let x = [...side];

                  x = x.splice(x.indexOf(" устья"), 1);
                  setSide(x);
                }
              }}
            />
            На уровне устья
          </label>
          <label>
            <input
              type="checkbox"
              name="side"
              onClick={(e) => {
                if (e.target.checked === true) {
                  let x = [...side];
                  x.push(" проксимальной трети");
                  setSide(x);
                }
                if (e.target.checked === false) {
                  let x = [...side];

                  x = x.splice(x.indexOf(" проксимальной трети"), 1);
                  setSide(x);
                }
              }}
            />
            Проксимальная треть
          </label>{" "}
          <label>
            <input
              type="checkbox"
              name="side"
              onClick={(e) => {
                if (e.target.checked === true) {
                  let x = [...side];
                  x.push(" средней трети");
                  setSide(x);
                }
                if (e.target.checked === false) {
                  let x = [...side];

                  x = x.splice(x.indexOf(" средней трети"), 1);
                  setSide(x);
                }
              }}
            />
            Средняя треть
          </label>{" "}
          <label>
            <input
              type="checkbox"
              name="side"
              onClick={(e) => {
                if (e.target.checked === true) {
                  let x = [...side];
                  x.push(" дистальной трети");
                  setSide(x);
                }
                if (e.target.checked === false) {
                  let x = [...side];

                  x = x.splice(x.indexOf(" дистальной трети"), 1);
                  setSide(x);
                }
              }}
            />
            Дистальная треть
          </label>
        </fieldset>
      </div>
      <div>
        <fieldset
          style={{
            display: "flex",
            justifyContent: "center",
            borderRadius: "6px",
            color: "grey",
          }}
        >
          <legend>Сторона (если есть)</legend>
          <label>
            <input
              type="radio"
              name="side"
              onClick={(e) => {
                setSide2(" справа");
              }}
            />
            СПРАВА
          </label>
          <label>
            <input
              type="radio"
              name="side"
              onClick={(e) => {
                setSide2(" слева");
              }}
            />
            СЛЕВА
          </label>
        </fieldset>
      </div>
      <div
        className="inputVertical"
        style={{
          display: "flex",
        }}
      >
        <label>
          <input
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
            autoFocus
            type="number"
            placeholder="1 в мм"
            value={size1}
            ы
            onChange={(e) => setSize1(e.target.value)}
            onKeyDown={enter}
          />
          {"  "}Диаметр просвета на уровне стеноза
        </label>
        <label>
          <input
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
            type="number"
            placeholder="2 в мм"
            value={size2}
            onChange={(e) => setSize2(e.target.value)}
            onKeyDown={enter}
          />
          {"  "}Диаметр просвета дистальнее уровня стеноза
        </label>
      </div>

      <button
        className="calculate"
        onClick={() => {
          // calculate();
          textHandler();
        }}
      >
        Рассчитать
      </button>
      {notallsizes && <p style={{ color: "red" }}> ВВЕДИТЕ ВСЕ РАЗМЕРЫ</p>}
      {!side && <p style={{ color: "red" }}>СТОРОНА НЕ ВЫБРАНА</p>}
      <button
        className="calculate"
        onClick={() => {
          // calculate();
          setConstructorOpen(false);
        }}
      >
        Закрыть
      </button>
    </div>
    // </div>
  );
};
export default STENOSISgeneral;
