import TextareaAutosize from "react-textarea-autosize";
import ChoicesConstructorZakl from "./ChoicesConstructorZakl";
import ChoicesConstructor from "./ChoicesConstructor";
import { useState } from "react";
import Icon from "../../redactorComponents/icons/icons";

const CheckboxRedactor = ({
  rybaObject,
  organIndex,
  segmentIndex,
  buttonIndex,
  setRybaObject,
  setChoicesZakl,
  setChoices,
}) => {
  const [choicesOpen, setChoicesOpen] = useState(false);
  const [choicesZaklOpen, setChoicesZaklOpen] = useState(false);

  const button =
    rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex];
  return (
    <div>
      <div className="buttonEditingSegment">
        <div className="buttonCONTENT">Текст кнопки</div>
        <div className="buttonZAKL">В заключение</div>
      </div>
      <div className="buttonEditingSegment">
        <div className="buttonCONTENT">
          <TextareaAutosize
            value={
              rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                buttonIndex
              ].content
            }
            onChange={(e) => {
              console.log(e.target.value);
              let r = { ...rybaObject };
              r.organs[organIndex].segments[segmentIndex].buttons[
                buttonIndex
              ].content = e.target.value;
              setRybaObject(r);
            }}
          ></TextareaAutosize>
        </div>
        <div className="buttonZAKL">
          <TextareaAutosize
            className="buttonZAKL"
            placeholder="-"
            value={
              rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                buttonIndex
              ].zakl
            }
            onChange={(e) => {
              console.log(e.target.value);
              let r = { ...rybaObject };
              r.organs[organIndex].segments[segmentIndex].buttons[
                buttonIndex
              ].zakl = e.target.value;
              setRybaObject(r);
            }}
          ></TextareaAutosize>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          {Object.hasOwn(button, "choices") && (
            <div
              className="choicesMenuSegment"
              onClick={() => {
                if (choicesOpen) {
                  setChoicesOpen(false);
                } else setChoicesOpen(true);
                if (choicesZaklOpen) {
                  setChoicesZaklOpen(false);
                }
              }}
            >
              <Icon
                title="addChoices"
                fill={choicesOpen ? "orange" : "white"}
              />
            </div>
          )}
          {Object.hasOwn(button, "choicesZakl") && (
            <div
              className="choicesMenuSegment"
              onClick={() => {
                if (choicesZaklOpen) {
                  setChoicesZaklOpen(false);
                } else setChoicesZaklOpen(true);
                if (choicesOpen) {
                  setChoicesOpen(false);
                }
              }}
            >
              <Icon
                title="addChoicesZakl"
                fill={choicesZaklOpen ? "orange" : "white"}
              />
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          paddingLeft: "200px",
        }}
      >
        <div>
          {Object.hasOwn(button, "choices") && choicesOpen && (
            <ChoicesConstructor
              buttonItself={
                rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                  buttonIndex
                ]
              }
              setChoices={setChoices}
              buttonType={"checkbox"}
              organIndex={organIndex}
              segmentIndex={segmentIndex}
              buttonIndex={buttonIndex}
              radioIndex={undefined}
              optionIndex={undefined}
              choicesOpen={choicesOpen}
              setChoicesOpen={setChoicesOpen}
            />
          )}
        </div>
        <div>
          {Object.hasOwn(button, "choicesZakl") && choicesZaklOpen && (
            <ChoicesConstructorZakl
              buttonItself={
                rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                  buttonIndex
                ]
              }
              setChoicesZakl={setChoicesZakl}
              buttonType={"checkbox"}
              organIndex={organIndex}
              segmentIndex={segmentIndex}
              buttonIndex={buttonIndex}
              radioIndex={undefined}
              optionIndex={undefined}
              choicesZaklOpen={choicesZaklOpen}
              setChoicesZaklOpen={setChoicesZaklOpen}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default CheckboxRedactor;
