import React from "react";
import "./StatusBar.css"; // Import the CSS file

const StatusBar = ({ percent }) => {
  // Ensure the percent is within 0-100
  const clampedPercent = Math.min(100, Math.max(0, percent));

  return (
    <div className="status-bar-container">
      <div className="status-bar" style={{ width: `${clampedPercent}%` }}></div>
      <span className="status-bar-label">{clampedPercent}%</span>
    </div>
  );
};

export default StatusBar;
