import TextareaAutosize from "react-textarea-autosize";
import Duplicate from "../../svg/Duplicate";
import ArrowUp from "../../svg/ArrowUp";
import ArrowDown from "../../svg/ArrowDown";
import DeleteIcon from "../../svg/DeleteIcon";
import { useState, useRef } from "react";
import ChoicesConstructor from "./ChoicesConstructor";
import ChoicesConstructorZakl from "./ChoicesConstructorZakl";
import { notificationTheBest } from "../../../rootComponents/NotificationService";
import OpenMenu from "../../../AIcomponents/OpenMenu";
import Icon from "../../redactorComponents/icons/icons";

const newOption = {
  title: "Название опции",
  content: "Текст опции. ",
  zakl: "",
  used: 0,
};

const SelectRedactor = ({
  button,
  rybaObject,
  organIndex,
  segmentIndex,
  buttonIndex,
  setRybaObject,
  notification,
  setChoices,
  setChoicesZakl,
}) => {
  const [addingOption, setAddingOption] = useState(false);
  const [optionText, setOptionText] = useState();
  const [whichOption, setWhichOption] = useState();
  const [whichRadio, setWhichRadio] = useState();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [radioMenuOpen, setRadioMenuOpen] = useState(false);
  const [radioMenuOpenIndex, setRadioMenuOpenIndex] = useState("");
  const radioContextMenuRef = useRef();
  const [choicesOpen, setChoicesOpen] = useState(false);
  const [choicesZaklOpen, setChoicesZaklOpen] = useState(false);
  const [radioIndex, setRadioIndex] = useState(undefined);
  const [radioIndexZakl, setRadioIndexZakl] = useState(undefined);

  const closeOpenOrganContextMenu = (e) => {
    if (radioMenuOpen && !radioContextMenuRef.current?.contains(e.target)) {
      setRadioMenuOpen(false);
    }
  };
  document.addEventListener("mousedown", closeOpenOrganContextMenu);

  return (
    <>
      <div className="buttonEditingSegmentRADIOorSELECT">
        <div className="buttonTITLE2">Название кнопки</div>
        <div className="buttonCONTENT2">Текст кнопки</div>
        <div className="buttonZAKL2">В заключение</div>
      </div>
      {button.options.map(
        (option, index) =>
          index > 0 && (
            <>
              <div
                className="buttonEditingSegmentRADIOorSELECT"
                style={{
                  marginBottom: "3px",
                }}
              >
                <div className="buttonTITLE2">
                  <TextareaAutosize
                    value={
                      rybaObject.organs[organIndex].segments[segmentIndex]
                        .buttons[buttonIndex].options[index].title
                    }
                    onChange={(e) => {
                      console.log(e.target.value);
                      let r = { ...rybaObject };
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].options[index].title = e.target.value;
                      setRybaObject(r);
                    }}
                  ></TextareaAutosize>
                </div>
                <div className="buttonCONTENT2">
                  <TextareaAutosize
                    placeholder="-"
                    value={
                      rybaObject.organs[organIndex].segments[segmentIndex]
                        .buttons[buttonIndex].options[index].content
                    }
                    onChange={(e) => {
                      console.log(e.target.value);
                      let r = { ...rybaObject };
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].options[index].content = e.target.value;
                      setRybaObject(r);
                    }}
                  ></TextareaAutosize>
                </div>

                <div className="buttonZAKL2">
                  <TextareaAutosize
                    placeholder="-"
                    value={
                      rybaObject.organs[organIndex].segments[segmentIndex]
                        .buttons[buttonIndex].options[index].zakl
                    }
                    onChange={(e) => {
                      console.log(e.target.value);
                      let r = { ...rybaObject };
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].options[index].zakl = e.target.value;
                      setRybaObject(r);
                    }}
                  ></TextareaAutosize>
                </div>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  {Object.hasOwn(option, "choices") && (
                    <div
                      className="choicesMenuSegment"
                      onClick={() => {
                        setRadioIndex(index);
                        if (choicesOpen) {
                          setChoicesOpen(false);
                        } else setChoicesOpen(true);
                        if (choicesZaklOpen) {
                          setChoicesZaklOpen(false);
                        }
                      }}
                    >
                      <Icon
                        title="addChoices"
                        fill={
                          choicesOpen && radioIndex === index
                            ? "orange"
                            : "white"
                        }
                      />
                    </div>
                  )}
                  {Object.hasOwn(option, "choicesZakl") && (
                    <div
                      className="choicesMenuSegment"
                      onClick={() => {
                        setRadioIndexZakl(index);
                        if (choicesZaklOpen) {
                          setChoicesZaklOpen(false);
                        } else setChoicesZaklOpen(true);
                        if (choicesOpen) {
                          setChoicesOpen(false);
                        }
                      }}
                    >
                      <Icon
                        title="addChoicesZakl"
                        fill={
                          choicesZaklOpen && radioIndexZakl === index
                            ? "orange"
                            : "white"
                        }
                      />
                    </div>
                  )}
                </div>
                <div
                  className="optionsandradioseditdiv"
                  style={{
                    marginLeft: "3px",
                  }}
                >
                  {index > 0 && (
                    <button
                      className="svgButton"
                      onClick={() => {
                        let r = { ...rybaObject };
                        const newOption = JSON.parse(
                          JSON.stringify(
                            r.organs[organIndex].segments[segmentIndex].buttons[
                              buttonIndex
                            ].options[index]
                          )
                        );

                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].options.splice(index, 0, newOption);

                        setRybaObject(r);
                      }}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        notificationTheBest("Кнопка скопирована");
                        let r = { ...rybaObject };
                        window.localStorage.setItem(
                          `copiedOPTION`,
                          JSON.stringify(
                            r.organs[organIndex].segments[segmentIndex].buttons[
                              buttonIndex
                            ].options[index]
                          )
                        );
                      }}
                    >
                      <Duplicate />
                    </button>
                  )}
                  {index > 1 && (
                    <button
                      className="svgButton"
                      onClick={() => {
                        let r = { ...rybaObject };
                        let a =
                          r.organs[organIndex].segments[segmentIndex].buttons[
                            buttonIndex
                          ].options[index];
                        let b =
                          r.organs[organIndex].segments[segmentIndex].buttons[
                            buttonIndex
                          ].options[index - 1];
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].options[index] = b;
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].options[index - 1] = a;
                        setRybaObject(r);
                      }}
                    >
                      <ArrowUp />
                    </button>
                  )}
                  {index > 0 && index < button.options.length - 1 && (
                    <button
                      className="svgButton"
                      onClick={() => {
                        let r = { ...rybaObject };
                        let a =
                          r.organs[organIndex].segments[segmentIndex].buttons[
                            buttonIndex
                          ].options[index];
                        let b =
                          r.organs[organIndex].segments[segmentIndex].buttons[
                            buttonIndex
                          ].options[index + 1];
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].options[index] = b;
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].options[index + 1] = a;
                        setRybaObject(r);
                      }}
                    >
                      <ArrowDown />
                    </button>
                  )}
                  {rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ].options.length > 2 && (
                    <button
                      className="svgButton"
                      onClick={() => {
                        let r = { ...rybaObject };
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].options.splice(index, 1);

                        setRybaObject(r);
                      }}
                    >
                      <DeleteIcon width={24} height={24} />
                    </button>
                  )}
                  <OpenMenu
                    onClick={() => {
                      setRadioMenuOpen(true);
                      setRadioMenuOpenIndex(index);
                    }}
                  />
                  {radioMenuOpen && radioMenuOpenIndex === index && (
                    <div
                      style={{
                        position: "relative",
                      }}
                      ref={radioContextMenuRef}
                    >
                      <div
                        className="contextMenu"
                        style={{
                          top: "5px",
                          left: "0",
                          zIndex: "50",
                          width: "130px",
                          position: "absolute",
                          height: "fit-content",
                        }}
                      >
                        <span
                          style={{
                            paddingBottom: "5px",
                            paddingTop: "5px",
                          }}
                          className="contextMenuButton"
                          onClick={(e) => {
                            notificationTheBest("Кнопка скопирована");
                            let r = { ...rybaObject };
                            window.localStorage.setItem(
                              `copiedSELECTBUTTON`,
                              JSON.stringify(
                                r.organs[organIndex].segments[segmentIndex]
                                  .buttons[buttonIndex].options[index]
                              )
                            );
                            setRadioMenuOpen(false);
                          }}
                        >
                          <Icon title="copy" />
                          Копировать
                        </span>
                        <span
                          style={{
                            paddingBottom: "5px",
                            paddingTop: "5px",
                          }}
                          className="contextMenuButton"
                          onClick={(e) => {
                            if (
                              !window.localStorage.getItem("copiedSELECTBUTTON")
                            ) {
                              alert("В буфере обмена нет скопированных кнопок");
                              return;
                            }
                            let r = { ...rybaObject };
                            const newButton = JSON.parse(
                              window.localStorage.getItem("copiedSELECTBUTTON")
                            );
                            r.organs[organIndex].segments[segmentIndex].buttons[
                              buttonIndex
                            ].options.splice(index + 1, 0, newButton);
                            notificationTheBest(
                              "Кнопка из буфера обмена добавлена"
                            );
                            setRybaObject(r);
                            setRadioMenuOpen(false);
                          }}
                        >
                          <Icon title="pasteBelow" />
                          Вставить
                        </span>
                        <span
                          style={{
                            paddingBottom: "5px",
                            paddingTop: "5px",
                          }}
                          className="contextMenuButton"
                          onClick={(e) => {
                            let r = { ...rybaObject };
                            let newButton =
                              r.organs[organIndex].segments[segmentIndex]
                                .buttons[buttonIndex].options[index];
                            newButton.type = "checkbox";
                            r.organs[organIndex].segments[
                              segmentIndex
                            ].buttons.splice(buttonIndex + 1, 0, newButton);
                            if (
                              r.organs[organIndex].segments[segmentIndex]
                                .buttons[buttonIndex].options.length === 1
                            ) {
                              r.organs[organIndex].segments[
                                segmentIndex
                              ].buttons[buttonIndex].options = [];
                            }
                            r.organs[organIndex].segments[segmentIndex].buttons[
                              buttonIndex
                            ].options.splice(index, 1);
                            notificationTheBest("Кнопка извлечена из группы");
                            setRybaObject(r);
                            setRadioMenuOpen(false);
                          }}
                        >
                          <Icon title="remove" />
                          Достать из меню
                        </span>
                        {!Object.hasOwn(
                          rybaObject.organs[organIndex].segments[segmentIndex]
                            .buttons[buttonIndex].options[index],
                          "choices"
                        ) && (
                          <span
                            className="contextMenuButton"
                            onClick={(e) => {
                              let r = { ...rybaObject };

                              r.organs[organIndex].segments[
                                segmentIndex
                              ].buttons[buttonIndex].options[index].choices = [
                                [],
                              ];

                              console.log(r);
                              notificationTheBest("Поле опций добавлено");
                              setRybaObject(r);
                              setRadioMenuOpen(false);
                            }}
                          >
                            <Icon title="addChoices" />
                            Добавить опции для описания
                          </span>
                        )}

                        {Object.hasOwn(
                          rybaObject.organs[organIndex].segments[segmentIndex]
                            .buttons[buttonIndex].options[index],
                          "choices"
                        ) && (
                          <span
                            className="contextMenuButton"
                            onClick={(e) => {
                              let r = { ...rybaObject };

                              delete r.organs[organIndex].segments[segmentIndex]
                                .buttons[buttonIndex].options[index].choices;

                              notificationTheBest("Поле опций удалено");
                              setRybaObject(r);
                              setRadioMenuOpen(false);
                            }}
                          >
                            <Icon title="deleteChoices" />
                            Удалить опции для описания
                          </span>
                        )}
                        {!Object.hasOwn(
                          rybaObject.organs[organIndex].segments[segmentIndex]
                            .buttons[buttonIndex].options[index],
                          "choicesZakl"
                        ) && (
                          <span
                            className="contextMenuButton"
                            onClick={(e) => {
                              let r = { ...rybaObject };

                              r.organs[organIndex].segments[
                                segmentIndex
                              ].buttons[buttonIndex].options[
                                index
                              ].choicesZakl = [[]];

                              console.log(r);
                              notificationTheBest("Поле опций добавлено");
                              setRybaObject(r);
                              setRadioMenuOpen(false);
                            }}
                          >
                            <Icon title="addChoicesZakl" />
                            Добавить опции для заключения
                          </span>
                        )}

                        {Object.hasOwn(
                          rybaObject.organs[organIndex].segments[segmentIndex]
                            .buttons[buttonIndex].options[index],
                          "choicesZakl"
                        ) && (
                          <span
                            className="contextMenuButton"
                            onClick={(e) => {
                              let r = { ...rybaObject };

                              delete r.organs[organIndex].segments[segmentIndex]
                                .buttons[buttonIndex].options[index]
                                .choicesZakl;

                              notificationTheBest("Поле опций удалено");
                              setRybaObject(r);
                              setRadioMenuOpen(false);
                            }}
                          >
                            <Icon title="deleteChoicesZakl" />
                            Удалить опции для заключения
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  {Object.hasOwn(option, "choices") &&
                    choicesOpen &&
                    radioIndex === index && (
                      <ChoicesConstructor
                        buttonItself={option}
                        setChoices={setChoices}
                        buttonType={"radio"}
                        organIndex={organIndex}
                        segmentIndex={segmentIndex}
                        buttonIndex={buttonIndex}
                        radioIndex={index}
                        optionIndex={undefined}
                      />
                    )}
                </div>
                <div>
                  {Object.hasOwn(option, "choicesZakl") &&
                    choicesZaklOpen &&
                    radioIndexZakl === index && (
                      <ChoicesConstructorZakl
                        buttonItself={option}
                        setChoicesZakl={setChoicesZakl}
                        buttonType={"radio"}
                        organIndex={organIndex}
                        segmentIndex={segmentIndex}
                        buttonIndex={buttonIndex}
                        radioIndex={index}
                        optionIndex={undefined}
                      />
                    )}
                </div>
              </div>
            </>
          )
      )}
      <div>
        <button
          onClick={() => {
            let r = { ...rybaObject };
            let nr = { ...newOption };
            if (
              Object.hasOwn(
                r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex]
                  .options[0],
                "choices"
              )
            ) {
              nr.choices = [];
            }
            if (
              Object.hasOwn(
                r.organs[organIndex].segments[segmentIndex].buttons[buttonIndex]
                  .options[0],
                "choicesZakl"
              )
            ) {
              nr.choicesZakl = [];
            }
            r.organs[organIndex].segments[segmentIndex].buttons[
              buttonIndex
            ].options.push(nr);
            setRybaObject(r);
          }}
        >
          ➕
        </button>
      </div>
    </>
  );
};
export default SelectRedactor;
