import { useState } from "react";
import sdh from "../images/SDH.png";
import { notificationTheBest } from "../../rootComponents/NotificationService";

const SubduralHematoma = ({ notification }) => {
  const [notallsizes, setNotallsizes] = useState(false);

  const [result, setResult] = useState(0);
  const [size1, setSize1] = useState();
  const [size2, setSize2] = useState();
  const [size3, setSize3] = useState();
  const [calculated, setCalculated] = useState(false);

  const calculate = () => {
    console.log("размер 1: ", size1, "размер 2: ", size2, "размер3: ", size3);
    if (!size1 || !size2 || !size3) {
      return setNotallsizes(true);
    }
    if (size1 && size2 && size3) {
      setNotallsizes(false);
    }
    let x = (size1 * size2 * size3) / 2000;
    x = Math.round(x);
    setResult(x);
    navigator.clipboard.writeText(
      `В ... субдуральная гематома размерами до ${size1}x${size2}x${size3} мм, приблизительным расчётным объёмом до ${x} cм3. `
    );
    notificationTheBest(
      "Формулировка для заключения автоматически скопирована в буфер обмена",
      3000
    );

    setCalculated((c) => (c = !c));
    setSize1(undefined);
    setSize2(undefined);
    setSize3(undefined);
    console.log(typeof x);
  };

  const enter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      calculate();
    }
  };

  return (
    <div className="calculator">
      <p className="name">Объём субдуральной гематомы</p>
      <div className="legend"></div>

      <div className="inputVertical" key={calculated}>
        <input
          type="number"
          placeholder="A мм"
          value={size1}
          onChange={(e) => setSize1(e.target.value)}
          onKeyDown={enter}
        />
        <input
          type="number"
          placeholder="B мм"
          value={size2}
          onChange={(e) => setSize2(e.target.value)}
          onKeyDown={enter}
        />
        <input
          type="number"
          placeholder="C мм"
          value={size3}
          onChange={(e) => setSize3(e.target.value)}
          onKeyDown={enter}
        />
      </div>
      <div className="results">{result} см3</div>
      <button className="calculate" onClick={calculate}>
        Рассчитать (Enter)
      </button>
      <div className="legend">
        {result !== 0 && (
          <>
            <p>
              {`В ... субдуральная гематома расчётным объёмом до ${result} cм3. `}
            </p>
          </>
        )}
        {notallsizes && <p style={{ color: "red" }}> ВВЕДИТЕ ВСЕ РАЗМЕРЫ</p>}
      </div>
    </div>
  );
};

export default SubduralHematoma;
