import React, { useState } from "react";
import { notificationTheBest } from "../rootComponents/NotificationService";
import Icon from "../components/redactorComponents/icons/icons";
import { copyButton } from "../components/Ryba4EditFunctions";

const ContextMenuCalculatorRyba4 = ({
  buttonContextRef,
  rybaObject,
  organINDEX,
  segmentINDEX,
  buttonINDEX,
  radioINDEX,
  setButtonContextMenuOpen,
  setRybaObject,
  buttonType,
  setEditButtonOpen,
  editedButtonREF,
  setScrollY,
  setSureDeleteButton,
  duplicateButton,
  editedButtonJSON,
  setEditedButtonJSON,
  calculatorEditorOpen,
  setCalculatorEditorOpen,
}) => {
  return (
    <div
      className="contextMenu"
      ref={buttonContextRef}
      style={{
        position: "absolute",
        top: `22.5px`,
        left: `0px`,
        zIndex: "50",
        width: "130px",
      }}
    >
      <button
        className="contextMenuButton"
        onClick={(e) => {
          e.preventDefault();
          copyButton(
            organINDEX,
            segmentINDEX,
            buttonINDEX,
            radioINDEX,
            rybaObject,
            setButtonContextMenuOpen,
            buttonType
          );
        }}
      >
        <Icon title="copy" />
        Копировать кнопку
      </button>
      <button
        className="contextMenuButton redOnHover"
        onClick={(e) => {
          setCalculatorEditorOpen(true);
          setButtonContextMenuOpen(false);
        }}
      >
        <Icon title="edit" />
        Редактировать
      </button>{" "}
      <button
        className="contextMenuButton"
        onClick={(e) => {
          duplicateButton(
            organINDEX,
            segmentINDEX,
            buttonINDEX,
            radioINDEX,
            rybaObject,
            setRybaObject,
            setButtonContextMenuOpen,
            buttonType
          );
          setButtonContextMenuOpen(false);
        }}
      >
        <Icon title="duplicate" />
        Дублировать
      </button>
      <button
        className="contextMenuButton redOnHover"
        onClick={(e) => {
          setButtonContextMenuOpen(false);
          setSureDeleteButton(true);
        }}
      >
        <Icon title="delete" />
        Удалить
      </button>
    </div>
  );
};

export default ContextMenuCalculatorRyba4;
