import React from "react";
import "./CloseButton.css"; // External CSS file for styling

const CloseButton = ({ onClick, size, color, hoverColor, disabled }) => {
  return (
    <button
      disabled={disabled}
      className="close-button"
      style={{
        "--size": `${size}px`,
        "--color": color,
        "--hover-color": hoverColor,
      }}
      onClick={onClick}
      aria-label="Close"
    >
      <span className="x-icon"></span>
    </button>
  );
};

export default CloseButton;
