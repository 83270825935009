import { useState } from "react";
import { useSignup } from "../hooks/useSignup";
import { Link } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const { user } = useAuthContext();
  // const [username, setUsername] = useState("");
  // const [firstname, setFirstname] = useState("");
  // const [middlename, setMiddlename] = useState("");
  // const [lastname, setLastname] = useState("");
  const { signup, error, isLoading } = useSignup();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirm) {
      return alert("Введённые пароли не совпадают");
    }

    await signup(email, password);

    console.log(email, password);
  };

  return (
    <form
      className="signup login"
      onSubmit={handleSubmit}
      style={{
        position: "relative",
        marginTop: "150px",
      }}
    >
      {!user && (
        <>
          <h3
            style={{
              position: "absolute",
              right: "-440px",
              fontSize: "2.9rem",
              fontWeight: "100",
              top: "-50px",
              textAlign: "left",
            }}
          >
            Регистрация
            <div>в нашем водоёме</div>
          </h3>
          <div
            style={{
              position: "absolute",
              right: "-330px",
              top: "130px",
            }}
          >
            <img
              style={{
                width: "250px",
                borderRadius: "50px",
              }}
              src="https://swift.timeweb.cloud/v1/cd5c3c6d-7735d894-d309-4ef5-87a5-a3d5741fd815/image/png/Registration.png"
              alt=""
            />
            <div
              style={{
                zIndex: "100000",
                width: "115%",
                height: "110%",
                // border: "1px solid red",
                position: "absolute",
                top: "0px",
                right: "-30px",
                background:
                  "radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(10,10,10,1) 92.9%, rgba(0,0,0,0.2) 100%)",
              }}
            ></div>
          </div>
          <p style={{ fontSize: "13px" }}>
            Укажите адрес почты, которой{" "}
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {" "}
              пользуетесь
            </span>
            , чтобы в случае чего можно было сбросить забытый пароль
          </p>

          {/* <label>Электронная почта:</label> */}
          <input
            className="inputLoginSignup"
            type="email"
            placeholder="Электронная почта"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          {/* <label>Придумайте пароль:</label> */}
          <input
            className="inputLoginSignup"
            placeholder="Придумайте пароль"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          {/* <label>Введите придуманный пароль ещё раз:</label> */}
          <input
            className="inputLoginSignup"
            type="password"
            placeholder="Тот же пароль ещё раз"
            onChange={(e) => setPasswordConfirm(e.target.value)}
            value={passwordConfirm}
          />

          <button
            disabled={isLoading}
            className="loginButton"
            style={{
              width: "200px",
            }}
          >
            Зарегистрироваться
          </button>
          <p style={{ fontSize: "15px" }}>
            Регистрируясь, вы даёте согласие с нашей{" "}
            <Link to="/privacypolicy" target="_blank">
              политикой обработки персональных данных
            </Link>
          </p>
          {/* <p style={{ fontSize: "13px" }}>Уже есть аккаунт?</p>
          <Link to="/login">
            <button
              className="loginButton"
              style={{
                scale: "0.8",
              }}
            >
              У меня есть аккаунт
            </button>
          </Link> */}
        </>
      )}
      {user && (
        <>
          <h2>Спасибо за регистрацию!</h2>
          <Link to="/login">
            <button>Начать заканчивать пораньше</button>
          </Link>
        </>
      )}
      {error && (
        <div
          className="error"
          style={{
            color: "red",
          }}
        >
          {error}
        </div>
      )}
    </form>
  );
};

export default Signup;
