import { useRef } from "react";
import "./constructors/constructor.css";
import Splenomegaly from "./constructors/Splenomegaly";
import ProstateCalculator from "./constructors/ProstateCalculator";
import PleuralEffusion from "./constructors/PleuralEffusion";

import ICAstenosis from "./constructors/ICAstenosis";
import OvarianVolume from "./constructors/OvarianVolume";
import TesticularVolume from "./constructors/TesticularVolume";
import LiverVolume from "./constructors/LiverVolume";
import AdrenalWashout from "./constructors/AdrenalWashout";
import PleuralEffusionTutorial from "./constructors/PleuralEffusionTutorial";
import STENOSISgeneral from "./constructors/STENOSISgeneral";

const ConstructorRouter = ({
  constructorREF,
  setConstructorOpen,
  formState,
  setFormState,
  text,
  setText,
  zaklState,
  setZaklState,
  rybaObject,
  dinamika,
  dinamikaConstructorFunction,
  tut9,
  setTut9,
  setTut10,
  setOrganFocus,
  handlePasteConstructor,
  addedWithButtons,
}) => {
  const props = {
    constructorREF,
    setConstructorOpen,
    formState,
    setFormState,
    text,
    setText,
    zaklState,
    setZaklState,
    rybaObject,
    dinamika,
    dinamikaConstructorFunction,
    setTut9,
    setTut10,
    tut9,
    setOrganFocus,
    handlePasteConstructor,
    addedWithButtons,
  };

  if (constructorREF.current.constructor === "Спленомегалия")
    return (
      <Splenomegaly
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Объём простаты")
    return (
      <ProstateCalculator
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Гидроторакс" && tut9 === true)
    return (
      <PleuralEffusionTutorial
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Гидроторакс" && tut9 === false)
    return (
      <PleuralEffusion
        {...{
          ...props,
        }}
      />
    );

  if (constructorREF.current.constructor === "Стеноз ВСА по ECST и NASCET")
    return (
      <ICAstenosis
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Объём яичника")
    return (
      <OvarianVolume
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Объём яичка")
    return (
      <TesticularVolume
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Объём печени")
    return (
      <LiverVolume
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Индекс вымывания (надпочечники)")
    return (
      <AdrenalWashout
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Калькулятор стеноза артерии")
    return (
      <STENOSISgeneral
        {...{
          ...props,
        }}
      />
    );
};

export default ConstructorRouter;
