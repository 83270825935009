import { useState, useRef } from "react";
import ple from "../images/PleuralEff.png";
import { notificationTheBest } from "../../rootComponents/NotificationService";

const PleuralEffusion = ({ notification }) => {
  const [notallsizes, setNotallsizes] = useState(false);
  const [string, setString] = useState(false);

  const [right, setRight] = useState();
  const [left, setLeft] = useState();
  const [num1, setNum1] = useState(null);
  const [num2, setNum2] = useState(null);
  const [calculated, setCalculated] = useState(false);
  const [resultRState, setResultRState] = useState();
  const [resultLState, setResultLState] = useState();
  let resultR;
  let resultL;

  // Volume (mL) = 0.365 × b3 – 4.529 × b2 + 159.723 × b – 88.377
  const calculate = () => {
    if (!right && !left) {
      setNotallsizes(true);
    } else {
      setNotallsizes(false);
    }
    if (right < 6) {
      resultR = 0;
      setNum1(resultR);
    }
    if (left < 6) {
      resultL = 0;
      setNum2(resultL);
    }
    if (right >= 6) {
      const a = right;
      resultR = Math.round(
        (0.365 * a * a * a) / 1000 -
          (4.529 * a * a) / 100 +
          (159.723 * a) / 10 -
          88.377
      );
      setNum1(resultR);
    }
    if (left >= 6) {
      const b = left;
      resultL = Math.round(
        (0.365 * b * b * b) / 1000 -
          (4.529 * b * b) / 100 +
          (159.723 * b) / 10 -
          88.377
      );
      setNum2(resultL);
    }

    if ((right >= 6 && left < 6) || left === undefined) {
      const x =
        "Жидкость в правой плевральной полости приблизительным расчётным объёмом до " +
        resultR +
        " мл, гидроторакса слева не отмечено. ";
      navigator.clipboard.writeText(x);
      setString((string) => (string = x));
    }
    if (right < 6 || (right === undefined && left >= 6)) {
      const x =
        "Жидкость в левой плевральной полости приблизительным расчётным объёмом до " +
        resultL +
        " мл, гидроторакса справа не отмечено. ";
      navigator.clipboard.writeText(x);
      setString((string) => (string = x));
    }
    if (right >= 6 && left >= 6) {
      const x =
        "Жидкость в правой плевральной полости приблизительным расчётным объёмом до " +
        resultR +
        " мл, слева до " +
        resultL +
        " мл. ";
      navigator.clipboard.writeText(x);
      setString((string) => (string = x));
    }
    if (right < 6 && left < 6) {
      const x = "Свободной жидкости в плевральных полостях не отмечено. ";
      navigator.clipboard.writeText(x);
      setString((string) => (string = x));
    }

    notificationTheBest(
      "Формулировка для заключения автоматически скопирована в буфер обмена",
      3000
    );

    setCalculated((x) => (x = !x));
    setRight(undefined);
    setLeft(undefined);
  };

  const enter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      calculate();
    }
  };

  // const copyNumber = () => {
  //   navigator.clipboard.writeText(result.current);
  // };

  // const report = () => {

  // };

  return (
    <div className="calculator">
      <p className="name">Гидроторакс</p>
      <div className="legend">
        <p>Наибольшая толщина слоя жидкости в мм, не менее 6 мм</p>
      </div>

      <div
        className="inputVertical"
        style={{ flexDirection: "row" }}
        key={calculated}
      >
        <input
          type="number"
          placeholder="Справа, мм"
          value={right}
          onChange={(e) => setRight(e.target.value)}
          onKeyDown={enter}
        />
        <input
          type="number"
          placeholder="Слева, мм"
          value={left}
          onChange={(e) => setLeft(e.target.value)}
          onKeyDown={enter}
        />
      </div>
      <div
        className="inputVertical"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <div className="results" style={{ marginRight: "30px" }}>
          {num1} мл
        </div>{" "}
        <div className="results">{num2} мл</div>
      </div>

      <button
        className="calculate"
        onClick={() => {
          calculate();
        }}
      >
        Рассчитать (Enter)
      </button>

      <div className="legend">
        <>
          <p>{string}</p>
        </>

        {notallsizes && (
          <>
            <p style={{ color: "red" }}> ВВЕДИТЕ РАЗМЕРЫ</p>
          </>
        )}
      </div>
    </div>
  );
};

export default PleuralEffusion;
