import React, { useEffect, useState } from "react";
import "./Stats.css"; // Import the CSS file
import { useAuthContext } from "./hooks/useAuthContext";
import StatusBar from "./AIcomponents/StatusBar";
import { settingsAtom } from "./state/atoms/settings";
import { useRecoilState } from "recoil";

const StatsPage = () => {
  const [settings, setSettings] = useRecoilState(settingsAtom);
  const { user } = useAuthContext();
  const [leaderboard, setLeaderboard] = useState();
  const [userRank, setUserRank] = useState();
  const [userid] = useState(user.id);
  useEffect(() => {
    const getStats = async function () {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/settings/getstats`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();
      console.log("settings object: ", json);
      if (response.status === 401) {
        window.localStorage.removeItem("user");
        window.location.reload();
      }
      if (response.ok) {
        // setSettings(json.settings);
        setLeaderboard(json);
      }
    };

    if (user) {
      getStats();
      //   typingLeaderboard();
    }
  }, []);

  function calculateMedian(arr) {
    if (arr.length === 0) {
      return 0;
    }

    // Step 1: Sort the array in ascending order
    const sortedArr = arr.slice().sort((a, b) => a - b);

    // Step 2: Find the middle index
    const midIndex = Math.floor(sortedArr.length / 2);

    // Step 3: Calculate the median
    if (sortedArr.length % 2 === 0) {
      // If the array has an even number of elements, return the average of the two middle values
      return (sortedArr[midIndex - 1] + sortedArr[midIndex]) / 2;
    } else {
      // If the array has an odd number of elements, return the middle value
      return sortedArr[midIndex];
    }
  }
  function calculateAverage(arr) {
    if (arr.length === 0) {
      return 0;
    }

    // Step 1: Sum all the numbers in the array
    const sum = arr.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    // Step 2: Divide the sum by the number of elements
    return Math.round(sum / arr.length);
  }
  function pagesInHour(char, words) {
    const pages1 = (char / 3000) * 60;
    const pages2 = (words / 500) * 60;
    return pages1 + pages2 / 2;
  }
  function getProperForm(number, forms) {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return forms[2]; // Use the plural form for numbers like 11-19
    }

    if (lastDigit === 1) {
      return forms[0]; // Singular form (e.g., "1 минута", "1 час", "1 день")
    }

    if (lastDigit >= 2 && lastDigit <= 4) {
      return forms[1]; // Special plural form (e.g., "2 минуты", "2 часа", "2 дня")
    }

    return forms[2]; // General plural form (e.g., "5 минут", "5 часов", "5 дней")
  }
  function formatTime(minutes) {
    if (minutes < 60) {
      // Less than 60 minutes: output in minutes
      return `${minutes} ${getProperForm(minutes, [
        "минуту",
        "минуты",
        "минут",
      ])}`;
    } else if (minutes < 1440) {
      // Between 60 minutes and 1439 minutes (23 hours, 59 minutes): output in hours and residual minutes
      const hours = Math.floor(minutes / 60);
      const residualMinutes = minutes % 60;
      const hoursString = `${hours} ${getProperForm(hours, [
        "час",
        "часа",
        "часов",
      ])}`;
      const minutesString = residualMinutes
        ? `${residualMinutes} ${getProperForm(residualMinutes, [
            "минута",
            "минуты",
            "минут",
          ])}`
        : "";
      return residualMinutes ? `${hoursString} ${minutesString}` : hoursString;
    } else {
      // 1440 minutes or more (24 hours or more): output in days and residual hours
      const days = Math.floor(minutes / 1440);
      const residualHours = Math.floor((minutes % 1440) / 60);
      const daysString = `${days} ${getProperForm(days, [
        "сутки",
        "суток",
        "суток",
      ])}`;
      const hoursString = residualHours
        ? `${residualHours} ${getProperForm(residualHours, [
            "час",
            "часа",
            "часов",
          ])}`
        : "";
      return residualHours ? `${daysString} ${hoursString}` : daysString;
    }
  }
  function compareTypingSpeed(typingSpeed) {
    const medianTypingSpeed = 207; // General median typing speed in characters per minute

    // Calculate the percentage difference
    const difference = Math.abs(typingSpeed - medianTypingSpeed);
    const percentageDifference = (
      (difference / medianTypingSpeed) *
      100
    ).toFixed(2); // Rounded to 2 decimal places

    // Determine the output message
    if (typingSpeed > medianTypingSpeed) {
      return `Ваша скорость печати на ${percentageDifference}% выше, чем среднестатистическая`;
    } else if (typingSpeed < medianTypingSpeed) {
      return `Ваша скорость печати на ${percentageDifference}% ниже, чем среднестатистическая, но не отчаивайтесь, у нас и тексты сложнее среднестатистических`;
    } else {
      return "Ваша скорость печати равна среднестатистической";
    }
  }
  function sortObjectsByPropertyValue(array) {
    // Sort the array based on the numeric value of the property in each object
    return array.sort((a, b) => {
      const valueA = Object.values(a)[0]; // Extract the value of the first (and only) property in object A
      const valueB = Object.values(b)[0]; // Extract the value of the first (and only) property in object B

      // Compare the values and sort in descending order (highest to lowest)
      return valueB - valueA;
    });
  }

  return (
    settings &&
    leaderboard && (
      <div className="stats-page">
        <h1>Статистика</h1>
        <div className="finalStatsDiv">
          {/* <p>
            Вставлено с помощью рыбки: {settings.stats.addedWithButtons}{" "}
            символов
          </p> */}
          <p
            style={{
              lineHeight: "1.4",
            }}
          >
            При рассчёте с Вашей личной скоростью печати с учётом ещё парочки
            факторов получается, что вы сэкономили приблизительно{" "}
            <div>
              <span className="savedTimeDiv">
                {formatTime(
                  Math.round(
                    (settings.stats.addedWithButtons /
                      settings.stats.medianTypingSpeed) *
                      1.5
                  )
                )}
              </span>
            </div>
          </p>
        </div>
        {/* Grid layout for statistics */}
        <div className="stats-grid">
          {/* Typing Speed Section */}
          <div className="stats-card">
            <h2>Скорость печати</h2>
            <div className="stat-item">
              <span>Символов в минуту медиана:</span>
              <span className="stat-value">
                {settings.stats.medianTypingSpeed}
              </span>
            </div>
            <div className="stat-item">
              <span>Символов в минуту среднее:</span>
              <span className="stat-value">
                {settings.stats.charactersPerMinute.length !== 0 &&
                  calculateAverage(settings.stats.charactersPerMinute)}
              </span>
            </div>

            <div className="stat-item">
              <span>Точность печати:</span>
              <span className="stat-value">
                {calculateAverage(settings.stats.accuracy)}%
              </span>
            </div>
            <p
              style={{
                textAlign: "center",
                color: "beige",
              }}
            >
              {compareTypingSpeed(settings.stats.medianTypingSpeed)}
            </p>
          </div>

          {/* Leaderboard Section */}
          <div className="stats-card">
            <h2>Топ шаблонов</h2>
            {sortObjectsByPropertyValue(settings.stats.protocolsUsed)
              .slice(0, 10)
              .map((p) => (
                <div
                  className="stat-item"
                  style={{
                    fontSize: "14px",
                    margin: "2px",
                    padding: "1px",
                    fontWeight: "100",
                  }}
                >
                  <span
                    style={{
                      width: "250px",
                    }}
                  >
                    {Object.keys(p)}
                  </span>
                  <span className="stat-value">
                    {Math.round(
                      (Object.values(p) / settings.stats.totalProtocols) * 100
                    )}
                    %{" "}
                    <span className="countTotalRybasSpan">
                      {Object.values(p)}
                    </span>
                  </span>
                </div>
              ))}
          </div>

          {/* Additional Statistics Sections */}
          <div className="stats-card">
            <h2>Статистика описаний</h2>
            <div className="stat-item">
              <span>Всего описаний:</span>
              <span className="stat-value">
                {/* {formatTime(2552)} */}
                {settings.stats.totalProtocols}
              </span>
            </div>
            {/* <div className="stat-item">
              <span>Всего символов во всех описаниях:</span>
              <span className="stat-value">
                {settings.stats.numCharactersAllTime}
              </span>
            </div> */}
            <div className="stat-item">
              <span>Добавлено кнопками:</span>
              <span
                className="stat-value"
                style={{
                  textAlign: "right",
                }}
              >
                {settings.stats.addedWithButtons} символов
              </span>
            </div>
            <div className="stat-item">
              <span
                style={{
                  width: "150px",
                }}
              >
                Сколько писали бы это руками*:
              </span>
              <span className="stat-value">
                {settings.stats.addedWithButtons === 0
                  ? 0
                  : formatTime(
                      Math.round(
                        settings.stats.addedWithButtons /
                          settings.stats.medianTypingSpeed
                      )
                    )}
              </span>
            </div>
            <p
              style={{
                color: "grey",
                fontWeight: "100",
                fontSize: "13px",
                padding: "0px",
                marginBottom: "9px",
              }}
            >
              * Расчёт на основе Вашей скорости печати и количества символов,
              добавленных кнопками. Истинная цифра должна быть больше, так как
              не учтено время на подумать, вспомнить, сформулировать и
              коэффициент рыбной магии
            </p>
            <div className="stat-item">
              <span>Вы печатали руками:</span>
              <span className="stat-value">
                {/* {formatTime(2552)} */}
                {formatTime(Math.round(settings.stats.typingTime))}
              </span>
            </div>{" "}
            <div className="stat-item">
              <span>Напечатали руками:</span>
              <span className="stat-value">
                {settings.stats.typedCharacters} букев
              </span>
            </div>
            <p
              style={{
                color: "grey",
                fontWeight: "100",
                fontSize: "15px",
                padding: "0px",
                marginBottom: "9px",
              }}
            >
              Сколько Войны и мира напечатано руками
            </p>
            <div className="stat-item">
              <StatusBar
                percent={(settings.stats.typedCharacters / 2966547) * 100}
              />
            </div>
            <div
              className="stat-item"
              style={{
                margin: "0px",
              }}
            >
              <span
                style={{
                  color: "darkgrey",
                  width: "250px",
                  fontWeight: "100",
                  fontSize: "15px",
                  padding: "0px",
                  marginBottom: "9px",
                }}
              >
                Процент описаний, напечатанный вручную:
              </span>
              <span className="stat-value">
                {/* {formatTime(2552)} */}
                {settings.stats.numCharactersAllTime !== 0 &&
                  (
                    (settings.stats.typedCharacters /
                      settings.stats.numCharactersAllTime) *
                    100
                  ).toFixed(1)}
                %
              </span>
            </div>
            <div
              className="stat-item"
              style={{
                margin: "0px",
              }}
            >
              <span
                style={{
                  color: "darkgrey",
                  width: "250px",
                  fontWeight: "100",
                  fontSize: "15px",
                  padding: "0px",
                  marginBottom: "9px",
                }}
              >
                Средний процент описания, добавленный кнопками:
              </span>
              <span className="stat-value">
                {/* {formatTime(2552)} */}
                {settings.stats.numCharactersAllTime !== 0 &&
                  (
                    (settings.stats.addedWithButtons /
                      settings.stats.numCharactersAllTime) *
                    100
                  ).toFixed(1)}
                %
              </span>
            </div>
            <div
              className="stat-item"
              style={{
                margin: "0px",
              }}
            >
              <span
                style={{
                  color: "darkgrey",
                  width: "250px",
                  fontWeight: "100",
                  fontSize: "15px",
                  padding: "0px",
                  marginBottom: "9px",
                }}
              >
                Процент шаблонов, остающийся без изменений:
              </span>
              <span className="stat-value">
                {/* {formatTime(2552)} */}
                {settings.stats.numCharactersAllTime !== 0 &&
                  (
                    100 -
                    (
                      (settings.stats.addedWithButtons /
                        settings.stats.numCharactersAllTime) *
                      100
                    ).toFixed(1) -
                    (
                      (settings.stats.typedCharacters /
                        settings.stats.numCharactersAllTime) *
                      100
                    ).toFixed(1)
                  ).toFixed(1)}
                %
              </span>
            </div>
          </div>
        </div>
        <div className="stats-grid">
          <div
            className="stats-card"
            style={{
              marginTop: "30px",
              //   width: "400px",
            }}
          >
            <h2>Лидеры скорости печати</h2>
            <div className="">
              <p className="yourPlace">Вы на {leaderboard.userRank} месте</p>
              <p
                style={{
                  fontSize: "14px",
                  color: "darkgrey",
                  textAlign: "right",
                }}
              >
                Медианная скорость символов/мин
              </p>
              {leaderboard.rankedUsers.map((user, index) => (
                <div
                  key={index}
                  className="leaderboard-item"
                  style={{
                    background: user.userID === userid && "black",
                  }}
                >
                  <span
                    className="rank"
                    style={{
                      color: user.userID === userid && "orange",
                    }}
                  >
                    {user.rank}
                  </span>
                  <span
                    className="name"
                    style={{
                      color: user.userID === userid && "orange",
                    }}
                  >
                    {user.nickname}
                  </span>
                  <span
                    className="speed"
                    style={{
                      fontSize: "24px",
                      color: user.userID === userid && "orange",
                    }}
                  >
                    {user.medianTypingSpeed}{" "}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div
            className="stats-card"
            style={{
              marginTop: "30px",
              //   width: "400px",
            }}
          >
            <h2>Творческие личности</h2>
            <div className="">
              <p className="yourPlace">
                Вы на {leaderboard.userRankByTypedPercentageOfAllTime} месте
              </p>
              <p
                style={{
                  fontSize: "14px",
                  color: "darkgrey",
                  textAlign: "right",
                }}
              >
                Напечатано вручную текста из всех описаний
              </p>
              {leaderboard.rankedByTypedPercentageOfAllTime.map(
                (user, index) => (
                  <div
                    key={index}
                    className="leaderboard-item"
                    style={{
                      background: user.userID === userid && "black",
                    }}
                  >
                    <span
                      className="rank"
                      style={{
                        color: user.userID === userid && "orange",
                      }}
                    >
                      {user.rank}
                    </span>
                    <span
                      className="name"
                      style={{
                        color: user.userID === userid && "orange",
                      }}
                    >
                      {user.nickname}
                    </span>
                    <span
                      className="speed"
                      style={{
                        color: user.userID === userid && "orange",

                        fontSize: "24px",
                      }}
                    >
                      {user.typedPercentageOfAllTime.toFixed(1)}%
                    </span>
                  </div>
                )
              )}
            </div>
          </div>
          <div
            className="stats-card"
            style={{
              marginTop: "30px",
              //   width: "400px",
            }}
          >
            <h2>Топ оптимизации</h2>
            <div className="">
              <p className="yourPlace">
                Вы на {leaderboard.userRankByButtonPercentage} месте
              </p>
              <p
                style={{
                  fontSize: "14px",
                  color: "darkgrey",
                  textAlign: "right",
                }}
              >
                Процент текста, добавленного кнопками, от всех описаний
              </p>
              {leaderboard.rankedByButtonPercentage.map((user, index) => (
                <div
                  key={index}
                  className="leaderboard-item"
                  style={{
                    background: user.userID === userid && "black",
                  }}
                >
                  <span
                    className="rank"
                    style={{
                      color: user.userID === userid && "orange",
                    }}
                  >
                    {user.rank}
                  </span>
                  <span
                    className="name"
                    style={{
                      color: user.userID === userid && "orange",
                    }}
                  >
                    {user.nickname}
                  </span>
                  <span
                    className="speed"
                    style={{
                      color: user.userID === userid && "orange",

                      fontSize: "24px",
                    }}
                  >
                    {user.buttonPercentage.toFixed(1)}%
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="stats-grid">
          <div
            className="stats-card"
            style={{
              marginTop: "30px",
              //   width: "400px",
            }}
          >
            <h2>От работы кони дохнут</h2>
            <div className="">
              <p className="yourPlace">
                Вы на {leaderboard.userRankByTotalProtocols} месте
              </p>
              <p
                style={{
                  fontSize: "14px",
                  color: "darkgrey",
                  textAlign: "right",
                }}
              >
                Сделано описаний
              </p>
              {leaderboard.rankedByTotalProtocols.map((user, index) => (
                <div
                  key={index}
                  className="leaderboard-item"
                  style={{
                    background: user.userID === userid && "black",
                  }}
                >
                  <span
                    className="rank"
                    style={{
                      color: user.userID === userid && "orange",
                    }}
                  >
                    {user.rank}
                  </span>
                  <span
                    className="name"
                    style={{
                      color: user.userID === userid && "orange",
                    }}
                  >
                    {user.nickname}
                  </span>
                  <span
                    className="speed"
                    style={{
                      fontSize: "24px",
                      color: user.userID === userid && "orange",
                    }}
                  >
                    {user.totalProtocols}{" "}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div
            className="stats-card"
            style={{
              marginTop: "30px",
              //   width: "400px",
            }}
          >
            <h2>Кликбейт</h2>
            <div className="">
              <p className="yourPlace">
                Вы на {leaderboard.userRankByButtonsUse} месте
              </p>
              <p
                style={{
                  fontSize: "14px",
                  color: "darkgrey",
                  textAlign: "right",
                }}
              >
                Символов добавлено кнопками
              </p>
              {leaderboard.rankedByButtonsUse.map((user, index) => (
                <div
                  key={index}
                  className="leaderboard-item"
                  style={{
                    background: user.userID === userid && "black",
                  }}
                >
                  <span
                    className="rank"
                    style={{
                      color: user.userID === userid && "orange",
                    }}
                  >
                    {user.rank}
                  </span>
                  <span
                    className="name"
                    style={{
                      color: user.userID === userid && "orange",
                    }}
                  >
                    {user.nickname}
                  </span>
                  <span
                    className="speed"
                    style={{
                      color: user.userID === userid && "orange",

                      fontSize: "24px",
                    }}
                  >
                    {user.addedWithButtons}{" "}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div
            className="stats-card"
            style={{
              marginTop: "30px",
              //   width: "400px",
            }}
          >
            <h2>Соло на клавиатуре</h2>
            <div className="">
              <p className="yourPlace">
                Вы на {leaderboard.userRankByTypedCharacters} месте
              </p>
              <p
                style={{
                  fontSize: "14px",
                  color: "darkgrey",
                  textAlign: "right",
                }}
              >
                Напечатано букев вручную
              </p>
              {leaderboard.rankedByTypedCharacters.map((user, index) => (
                <div
                  key={index}
                  className="leaderboard-item"
                  style={{
                    background: user.userID === userid && "black",
                  }}
                >
                  <span
                    className="rank"
                    style={{
                      color: user.userID === userid && "orange",
                    }}
                  >
                    {user.rank}
                  </span>
                  <span
                    className="name"
                    style={{
                      color: user.userID === userid && "orange",
                    }}
                  >
                    {user.nickname}
                  </span>
                  <span
                    className="speed"
                    style={{
                      fontSize: "24px",
                      color: user.userID === userid && "orange",
                    }}
                  >
                    {user.typedCharacters}{" "}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default StatsPage;
