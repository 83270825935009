import React, { useState, useEffect, useRef } from "react";
import "./Ideas.css";
import TextareaAutosize from "react-textarea-autosize";
import { useAuthContext } from "../hooks/useAuthContext";
import { getDayMonthYear, getHoursMinutes } from "../hooks/MiscFunctions";
import Icon from "./redactorComponents/icons/icons";
import { notificationTheBest } from "../rootComponents/NotificationService";
import AreYouSureGeneric from "./smallComponents/AreYouShureGeneric";
import Tag from "../AIcomponents/Tag";
import CommentSection from "../AIcomponents/CommentSection";
import { settingsAtom } from "../state/atoms/settings";
import { useRecoilState } from "recoil";

const Ideas = () => {
  const [ideas, setIdeas] = useState([]);
  const [stats, setStats] = useState();
  const [openComments, setOpenComments] = useState(false);
  const [whichIdeaComments, setWhichIdeaComments] = useState(1);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [tags, setTags] = useState([]);
  const [status, setStatus] = useState("new");
  const [data, setData] = useState();
  const [loaded, setLoaded] = useState(true);
  const [showTextArea, setShowTextArea] = useState(false);
  const [showTextAreaEdit, setShowTextAreaEdit] = useState(false);
  const [areYouSureOpen, setAreYouSureOpen] = useState(false);
  const [settings, setSettings] = useRecoilState(settingsAtom);
  const tagsArray = [
    "#Дизайн/оформление",
    "#Функционал",
    "#Шаблоны",
    "#КТ",
    "#МРТ",
    "#Рентген",
    "#УЗИ",
  ];

  const { user } = useAuthContext();
  /// default filters for default query
  const [page, setPage] = useState(1);
  let whichToDelete = useRef();
  let filterREF = useRef("new");
  let sortREF = useRef("createdAtNew");
  let editedIdeaREF = useRef();
  const megaRequest = (newPage) => {
    setOpenComments(false);
    setLoaded(false);
    let query = `${process.env.REACT_APP_BACKEND_URL}/idea/?userId=${user.id}&page=${page}&sort=${sortREF.current}&filter=${filterREF.current}`;
    console.log(query);
    if (!newPage && page !== 1) {
      query = `${process.env.REACT_APP_BACKEND_URL}/idea/?userId=${
        user.id
      }&page=${1}&sort=${sortREF.current}&filter=${filterREF.current}`;
      setPage(1);
    }
    if (newPage) {
      query = `${process.env.REACT_APP_BACKEND_URL}/idea/?userId=${user.id}&page=${newPage}&sort=${sortREF.current}&filter=${filterREF.current}`;
    }
    searchQuery(undefined, undefined, query);
  };
  const searchQuery = async function (req, res, URL) {
    const response = await fetch(URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();
    if (response.status === 401) {
      window.localStorage.removeItem("user");
      window.location.reload();
    }

    if (response.ok) {
      // console.log(res.);
      console.log("Idea list", json);
      setIdeas(json.ideas);
      setStats(json.stats);
      setLoaded(true);
    }
  };

  useEffect(() => {
    // Load ideas from local storage on initial render
    megaRequest();
  }, []);

  //// for pagination
  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(stats.totalCount / 15); // Calculate total pages based on results
    if (newPage >= 1 && newPage <= totalPages) {
      megaRequest(newPage);
      setPage(newPage);
    }
  };
  ///// patch TITLE TEXT TAGS
  const patchIdea = async (req, res, idea, action) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/idea/patchIdea/${idea._id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          ...idea,
        }),
      }
    );
    const json = await response.json();
    console.log(json);
    if (response.ok) {
      action === "edit" && notificationTheBest("Изменения успешно сохранены");

      if (action === "like") {
        let i = [...ideas];
        i[ideas.indexOf((i) => i._id === idea._id)] = idea;
        setIdeas(i);
      }

      console.log(json);
    }
    if (!response.ok) {
      notificationTheBest("Что-то пошло не так");
      console.log(json);
    }
  };
  ///// patch TITLE TEXT TAGS
  const ideaStatusChange = async (req, res, idea) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/idea/ideaStatusChange/${idea._id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          ...idea,
        }),
      }
    );
    const json = await response.json();
    console.log(json);
    if (response.ok) {
      let i = [...ideas];
      i[ideas.indexOf((i) => i._id === idea._id)] = idea;
      setIdeas(i);

      console.log(json);
    }
    if (!response.ok) {
      notificationTheBest("Что-то пошло не так");
      console.log(json);
    }
  };

  const deleteIdea = async (req, res) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/idea/delete/${whichToDelete.current}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const json = await response.json();
    console.log(response);
    if (response.ok) {
      notificationTheBest("Идея успешно удалена");
      megaRequest();
      console.log(json);
    }
    if (!response.ok) {
      notificationTheBest("Что-то пошло не так");
      console.log(json);
    }
  };

  const createIdea = async (req, res) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/idea`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        title,
        content,
        tags,
        authorId: user.id,
      }),
    });
    const json = await response.json();
    if (response.ok) {
      filterREF.current = "new";
      sortREF.current = "createdAtNew";
      console.log(json);
      megaRequest(1);
      notificationTheBest(
        "Ваша идея опубликована, большое спасибо за ваше участие!"
      );
    }
    if (!response.ok) {
      console.log(json);
      console.log(response);
    }
  };

  ///// LIKE IDEA
  const likeIdea = async (req, res, ideaId, ideaAuthorId, ideaTitle) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/idea/likeIdea/${ideaId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          userId: user.id,
          nick: settings.nickname,
          ideaAuthorId,
          ideaTitle,
        }),
      }
    );
    const idea = await response.json();

    if (response.ok) {
      let i = [...ideas];
      console.log(i);
      console.log(ideas.findIndex((i) => i._id === ideaId));
      i[ideas.findIndex((i) => i._id === idea._id)] = idea;
      setIdeas(i);
    }
    if (!response.ok) {
      notificationTheBest("Что-то пошло не так");
      console.log(idea);
    }
  };
  ///// DISLIKE IDEA
  const dislikeIdea = async (req, res, ideaId, ideaAuthorId, ideaTitle) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/idea/dislikeIdea/${ideaId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          userId: user.id,
          nick: settings.nickname,
          ideaAuthorId,
          ideaTitle,
        }),
      }
    );
    const idea = await response.json();

    if (response.ok) {
      let i = [...ideas];
      console.log(i);
      console.log(ideas.findIndex((i) => i._id === ideaId));
      i[ideas.findIndex((i) => i._id === idea._id)] = idea;
      setIdeas(i);
    }
    if (!response.ok) {
      notificationTheBest("Что-то пошло не так");
      console.log(idea);
    }
  };

  const handleDislike = (id) => {
    setIdeas((ideas) =>
      ideas.map((idea) => {
        if (idea._id === id) {
          const hasLiked = idea.likes.includes(user.id);
          const hasDisliked = idea.dislikes.includes(user.id);

          let newLikes = [...idea.likes];
          let newDislikes = [...idea.dislikes];

          if (hasDisliked) {
            newDislikes = newDislikes.filter((u) => u !== user.id);
            notificationTheBest("Автор выдохнул");
          } else {
            newDislikes.push(user.id);
            notificationTheBest(
              "Отрицательная оценка помогает не меньше, спасибо"
            );
            if (hasLiked) {
              newLikes = newLikes.filter((u) => u !== user.id);
            }
          }
          patchIdea(
            undefined,
            undefined,
            { ...idea, likes: newLikes, dislikes: newDislikes },
            "dislike"
          );
          return { ...idea, likes: newLikes, dislikes: newDislikes };
        }
        return idea;
      })
    );
  };

  function isWithinOneHour(isoTimeString) {
    const providedTime = new Date(isoTimeString);
    const currentTime = new Date();
    const timeDifference = currentTime - providedTime;
    return timeDifference <= 3600000;
  }
  const handleStatusChange = (status) => {
    filterREF.current = status;
    setPage(1);
    megaRequest(1);
    setStatus(status);
  };
  const handleSortChange = (sort) => {
    sortREF.current = sort;
    setPage(1);
    megaRequest(1);
  };

  const handleCommentsLikes = (newComments, ideaId) => {
    let commentedIdea = ideas.find((idea) => idea._id === ideaId);
    commentedIdea.comments = newComments;
    patchIdea(undefined, undefined, commentedIdea, "like");
  };

  return (
    <div
      className="idea-page-container"
      style={{
        position: "relative",
      }}
    >
      {stats && (
        <div className="sectionsIdeas">
          <div
            className="sectionsIdeasSegment"
            onClick={() => {
              handleStatusChange("new");
            }}
          >
            <Icon
              title="newIdea"
              fill={status === "new" ? "#59afff" : "darkgrey"}
              width={28}
              height={32}
              viewBox="0 0 40 40"
            />
            <p>Новые идеи</p>
            <span>{stats.numNew}</span>
          </div>
          <div
            className="sectionsIdeasSegment"
            onClick={() => {
              handleStatusChange("workingOnIt");
            }}
          >
            <Icon
              title="workingOnIt"
              fill={status === "workingOnIt" ? "#59afff" : "darkgrey"}
              width={28}
              height={32}
              viewBox="0 0 219 256"
            />
            <p>В работе</p>
            <span>{stats.workingOnIt}</span>
          </div>

          <div
            className="sectionsIdeasSegment"
            onClick={() => {
              handleStatusChange("rejected");
            }}
          >
            <Icon
              title="declined"
              fill={status === "rejected" ? "#59afff" : "darkgrey"}
              width={28}
              height={28}
              viewBox="0 0 244 256"
            />
            <p>Отвергнутые</p>
            <span>{stats.rejected}</span>
          </div>
          <div
            className="sectionsIdeasSegment"
            onClick={() => {
              handleStatusChange("done");
            }}
          >
            <Icon
              title="done"
              fill={status === "done" ? "#59afff" : "darkgrey"}
              width={32}
              height={28}
            />
            <p>Сделано</p>
            <span>{stats.done}</span>
          </div>
          <div
            className="sectionsIdeasSegment"
            onClick={() => {
              handleStatusChange("all");
            }}
          >
            <Icon
              title="all"
              viewBox="0 0 256 256"
              fill={status === "all" ? "#59afff" : "darkgrey"}
              width={32}
              height={28}
            />
            <p>Все</p>
            <span>{stats.all}</span>
          </div>
          <div
            className="sectionsIdeasSegment"
            onClick={() => {
              handleStatusChange("mine");
            }}
          >
            <Icon
              title="yoursProfile"
              fill={status === "mine" ? "#59afff" : "darkgrey"}
              width={32}
              height={28}
            />
            <p>Мои идеи</p>
            <span>{stats.mine}</span>
          </div>
        </div>
      )}
      <div
        style={{
          position: "absolute",
          left: "10px",
          top: "29px",
        }}
      >
        <Icon
          title="idea"
          fill="white"
          height={70}
          width={70}
          viewBox="0 0 512 512"
        />
      </div>
      <h1>У вас есть идея?</h1>
      <h2>Помогите рыбке стать лучше</h2>

      {!showTextArea && (
        <button
          className="add-idea-button"
          onClick={() => setShowTextArea(true)}
        >
          Предложить идею
        </button>
      )}

      {showTextAreaEdit && (
        <div
          className="textarea-container-edit"
          style={{
            borderRadius: "6px",
          }}
        >
          <h2>Редактировать идею</h2>
          <p
            style={{
              fontSize: "14px",
              color: "grey",
              fontWeight: "100",
              margin: "0px",
              padding: "0px",
            }}
          >
            (можно только в первый час после публикации)
          </p>
          <p>Краткое название</p>
          <label
            style={{
              color: title.length === 40 ? "red" : "grey",
            }}
          >
            <TextareaAutosize
              style={{
                width: "280px",
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              maxLength={40}
              placeholder="Введите название идеи"
            />{" "}
            {title.length}/40
          </label>
          <p>Чего бы вам хотелось?</p>
          <label
            style={{
              color: content.length === 1000 ? "red" : "grey",
              // marginBottom: "10px",
            }}
          >
            <TextareaAutosize
              style={{
                width: "580px",
              }}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              maxLength={1000}
              placeholder="Опишите свою идею"
            />{" "}
            {content.length}/1000
          </label>
          <div
            style={{
              textAlign: "center",
            }}
          >
            Тэги:{" "}
            {tagsArray.map((t, index) => (
              <Tag tags={tags} setTags={setTags} title={t} />
            ))}
          </div>
          <button
            className="add-idea-button"
            style={{
              position: "relative",
              margin: "10px",
              top: "0px",
              left: "auto",
              right: "auto",
            }}
            onClick={() => {
              if (title !== "" && content !== "") {
                editedIdeaREF.current.title = title;
                editedIdeaREF.current.content = content;
                editedIdeaREF.current.tags = tags;
                // createIdea();
                patchIdea(undefined, undefined, editedIdeaREF.current, "edit");
                setShowTextAreaEdit(false);
              }
              if (title === "") {
                alert("Введите название");
                if (content === "") {
                  alert("Опишите свою идею");
                }
              }
              setTitle("");
              setContent("");
              setTags([]);
            }}
          >
            Отправить идею
          </button>{" "}
          <button
            style={{
              margin: "5px",
              position: "absolute",
              right: "5px",
              border: "none",
              background: "#444444",
              padding: "3px",

              borderRadius: "6px",
            }}
            onClick={() => {
              setShowTextAreaEdit(false);
              setTitle("");
              setContent("");
              setTags([]);
            }}
          >
            <Icon title="close" fill="darkgrey" height={22} />
          </button>
        </div>
      )}
      {showTextArea && (
        <div
          className="textarea-container"
          style={{
            position: "relative",
            width: "90%",
            borderRadius: "6px",
          }}
        >
          <p>Краткое название</p>
          <label
            style={{
              color: title.length === 40 ? "red" : "grey",
            }}
          >
            <TextareaAutosize
              style={{
                width: "280px",
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              maxLength={40}
              placeholder="Введите название идеи"
            />{" "}
            {title.length}/40
          </label>
          <p>Чего бы вам хотелось?</p>
          <label
            style={{
              color: content.length === 1000 ? "red" : "grey",
              // marginBottom: "10px",
            }}
          >
            <TextareaAutosize
              value={content}
              onChange={(e) => setContent(e.target.value)}
              maxLength={1000}
              placeholder="Опишите свою идею"
            />{" "}
            {content.length}/1000
          </label>
          <div
            style={{
              textAlign: "center",
            }}
          >
            Тэги:{" "}
            {tagsArray.map((t, index) => (
              <Tag tags={tags} setTags={setTags} title={t} />
            ))}
          </div>
          <button
            className="add-idea-button"
            style={{
              position: "relative",
              margin: "10px",
              top: "0px",
              left: "auto",
              right: "auto",
            }}
            onClick={() => {
              if (title !== "" && content !== "") {
                createIdea();
                setShowTextArea(false);
              }
              if (title === "") {
                alert("Введите название");
                if (content === "") {
                  alert("Опишите свою идею");
                }
              }
              setTitle("");
              setContent("");
              setTags([]);
            }}
          >
            Отправить идею
          </button>{" "}
          <button
            style={{
              margin: "5px",
              position: "absolute",
              right: "5px",
              border: "none",
              background: "#444444",
              padding: "3px",

              borderRadius: "6px",
            }}
            onClick={() => {
              setShowTextArea(false);
            }}
          >
            <Icon title="close" fill="darkgrey" height={22} />
          </button>
        </div>
      )}

      <div className="ideas-container">
        <div>
          {areYouSureOpen && (
            <AreYouSureGeneric
              {...{
                setAreYouSureOpen,
                shureFunction: deleteIdea,
                text: "Точно хотите удалить идею?",
              }}
            />
          )}
        </div>
        {/* Pagination */}

        <div className="ideas-list">
          <div>
            {ideas && stats && (
              <div className="pagination-container">
                <span
                  style={{
                    marginRight: "auto",
                    fontWeight: "100",
                    color: "grey",
                  }}
                >
                  Найдено {ideas.length > 0 ? stats.totalCount : 0}
                </span>
                {stats.totalCount > 15 &&
                  Array.from(
                    {
                      length: Math.min(10, Math.ceil(stats.totalCount / 15)),
                    },
                    (_, i) => i + 1
                  ).map((pageNum) => (
                    <span
                      key={pageNum}
                      className={`page-number ${
                        pageNum === page ? "active-page" : ""
                      }`}
                      onClick={() => handlePageChange(pageNum)}
                      onMouseEnter={(e) => (e.target.style.cursor = "pointer")}
                    >
                      {pageNum}
                    </span>
                  ))}
              </div>
            )}
          </div>
          <div className="select-container">
            <select
              key={sortREF}
              name=""
              id=""
              onChange={(e) => {
                if (e.target.value === "") return;
                handleSortChange(e.target.value);
              }}
            >
              <option value="">Сортировать</option>
              <option value="createdAtNew">Сначала новые</option>
              <option value="createdAtOld">Сначала старые</option>
              <option value="like">Больше всего лайков</option>
              <option value="dislike">Больше всего дизлайков</option>
              <option value="mostComments">Самые обсуждаемые</option>
            </select>
          </div>
          {!loaded && (
            <div
              style={{
                padding: "30px",
              }}
            >
              <div className="loaderSpinner"></div>
            </div>
          )}
          {loaded &&
            ideas.map((idea, ideaIndex) => (
              <div key={ideaIndex} className="ideasContainer">
                <div
                  key={idea._id}
                  className="idea-item ideaItem"
                  creator={idea.authorId}
                >
                  <p className="ideaTitle">
                    {idea.title}{" "}
                    {idea.status === "done" && (
                      <Icon title="done" fill="lightblue" height={18} />
                    )}
                    {idea.status === "workingOnIt" && (
                      <Icon
                        title="workingOnIt"
                        fill={"lightblue"}
                        width={18}
                        height={18}
                        viewBox="0 0 219 256"
                      />
                    )}{" "}
                    {idea.status === "new" && (
                      <Icon
                        title="newIdea"
                        fill={"lightblue"}
                        width={18}
                        height={18}
                        viewBox="0 0 40 40"
                      />
                    )}
                    {idea.status === "rejected" && (
                      <Icon
                        title="declined"
                        fill={"lightblue"}
                        width={18}
                        height={18}
                        viewBox="0 0 244 256"
                      />
                    )}
                  </p>
                  <div className="ideaContent">{idea.content}</div>
                  <div className="likesContainer">
                    {user.id === "67093dbd69080216404eb5e8" && (
                      <select
                        className="myFirstDevSelect"
                        value={idea.status}
                        onChange={(e) => {
                          if (e.target.value === "") return;
                          editedIdeaREF.current = idea;
                          editedIdeaREF.current.status = e.target.value;
                          ideaStatusChange(
                            undefined,
                            undefined,
                            editedIdeaREF.current
                          );
                          megaRequest();
                        }}
                      >
                        <option value="">Статус указать</option>
                        <option value="new">Новая</option>
                        <option value="workingOnIt">В работе</option>
                        <option value="rejected">Отвергаю</option>
                        <option value="done">Сделал</option>
                      </select>
                    )}
                    {user.id === idea.authorId &&
                      isWithinOneHour(idea.createdAt) && (
                        <button
                          onClick={() => {
                            setTitle(idea.title);
                            setContent(idea.content);
                            setTags(idea.tags);
                            editedIdeaREF.current = idea;
                            setShowTextAreaEdit(true);
                          }}
                        >
                          <Icon
                            title={"edit"}
                            height={18}
                            width={18}
                            fill={"darkgrey"}
                          />{" "}
                        </button>
                      )}

                    {user.id === idea.authorId && (
                      <button
                        onClick={() => {
                          whichToDelete.current = idea._id;
                          setAreYouSureOpen(true);
                        }}
                      >
                        <Icon
                          title={"delete"}
                          height={18}
                          width={18}
                          fill={"darkgrey"}
                        />{" "}
                      </button>
                    )}
                    <button
                      onClick={() =>
                        likeIdea(
                          undefined,
                          undefined,
                          idea._id,
                          idea.authorId,
                          idea.title
                        )
                      }
                    >
                      <Icon
                        title={"like"}
                        height={18}
                        width={18}
                        fill={
                          idea.likes.includes(user.id) ? "#51abff" : "darkgrey"
                        }
                      />{" "}
                      {idea.likes.length}
                    </button>
                    <button
                      onClick={() =>
                        dislikeIdea(
                          undefined,
                          undefined,
                          idea._id,
                          idea.authorId,
                          idea.title
                        )
                      }
                    >
                      <Icon
                        title={"dislike"}
                        height={18}
                        width={18}
                        fill={
                          idea.dislikes.includes(user.id) ? "red" : "darkgrey"
                        }
                      />{" "}
                      {idea.dislikes.length}
                    </button>
                  </div>
                  <div
                    style={{
                      marginRight: "0px",
                    }}
                    className="infoContainer"
                  >
                    <div
                      style={{
                        marginRight: "auto",
                        marginTop: "auto",
                      }}
                    >
                      {" "}
                      {idea.comments.length > 0 && (
                        <span
                          className="commentButton pointer"
                          onClick={() => {
                            setWhichIdeaComments(ideaIndex);
                            setOpenComments(true);
                          }}
                        >
                          {`${idea.comments.length} комментариев`}
                        </span>
                      )}
                      {idea.comments.length === 0 && (
                        <button
                          className="commentButton"
                          onClick={() => {
                            setWhichIdeaComments(ideaIndex);
                            setOpenComments(true);
                          }}
                        >
                          Комментировать
                        </button>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          marginBottom: "8px",
                        }}
                      >
                        <div
                          style={{
                            marginRight: "17px",
                          }}
                        >
                          {idea.tags.length > 0 &&
                            idea.tags.map((t, index) => (
                              <span
                                key={index}
                                style={{
                                  background: "#090909",
                                  padding: "3px",
                                  borderRadius: "6px",
                                  marginRight: "5px",
                                  wordBreak: "normal",
                                }}
                              >
                                {t}
                              </span>
                            ))}
                        </div>
                        <div>
                          <span>{getDayMonthYear(idea.createdAt)}</span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "right",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: "17px",
                            marginRight: "0px",
                          }}
                        >
                          Автор: {idea.authorNickname}
                          {idea.authorId === "67093dbd69080216404eb5e8" && (
                            <>
                              {" "}
                              <span
                                className="creatorName"
                                style={{
                                  paddingRight: "20px",
                                  paddingLeft: "5px",
                                }}
                              >
                                Создатель{"   "}
                                <Icon
                                  title="jesus"
                                  height={24}
                                  width={24}
                                  viewBox="0 0 512 512"
                                />
                              </span>
                            </>
                          )}
                          {idea.authorId === "671b88d9ed7db171b698edb8" && (
                            <span
                              className="creatorName"
                              style={{
                                paddingRight: "20px",
                                paddingLeft: "5px",
                              }}
                            >
                              Главный Внештатный Рыболог
                              <Icon
                                title="jesus2"
                                height={24}
                                width={24}
                                viewBox="0 0 56 56"
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <CommentSection
                    ideaIndex={ideaIndex}
                    whichIdeaComments={whichIdeaComments}
                    openComments={openComments}
                    ideaID={idea._id}
                    handleCommentsLikes={handleCommentsLikes}
                    idea={idea}
                    setOpenComments={setOpenComments}
                    ideas={ideas}
                    setIdeas={setIdeas}
                    settings={settings}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Ideas;
