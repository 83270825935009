import React from "react";
import "./Footer.css"; // Import the CSS file
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <p>
          © {new Date().getFullYear()} Турборыба инкорпорейтед. Все права
          защищены.
        </p>
        <div>
          <Link to="/aboutus">О нас</Link>
          <Link to="/privacypolicy">
            Политика обработки персональных данных
          </Link>
          <Link to="/contacts">Контакты</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
