import { useEffect, useState, useRef } from "react";
import { rybaTitleAtom } from "../state/atoms/rybaTitle";
import { useRecoilState } from "recoil";
import RybaCreate from "./editing/RybaCreate";
import { useAuthContext } from "../hooks/useAuthContext";
import CT from "../components/svg/CT";
import MRI from "../components/svg/MRI";
import XRAY from "../components/svg/XRAY";
import MAMMOGRAPHY from "../components/svg/MAMMOGRAPHY";
import US from "../components/svg/US";
import PET from "../components/svg/PET";
import { Link, useNavigate } from "react-router-dom";
import "./RybaList.css";
import { saveAs } from "file-saver";

const RybaList = () => {
  const [savedReports, setSavedReports] = useState([]);
  const [autosavedReports, setAutosavedReports] = useState(null);
  const [openRybaCreateWindow, setOpenRybaCreateWindow] = useState(false);
  const [modalityState, setModalityState] = useState(null);
  const [rybaTitleEdit, setRybaTitleEdit] = useRecoilState(rybaTitleAtom);
  const [loaded, setLoaded] = useState();
  const { user } = useAuthContext();

  // ref variables
  let modality = useRef("");
  let protocol = useRef("");
  const navigate = useNavigate();
  useEffect(() => {
    const loadReports = async () => {
      const reports = JSON.parse(
        localStorage.getItem(`savedReports${user.id}`)
      );
      const autosavedreports = JSON.parse(
        localStorage.getItem(`autosavedReports${user.id}`)
      );
      setSavedReports(reports);
      setAutosavedReports(autosavedreports);
      setLoaded(true);
    };
    if (user) {
      loadReports();
    }
  }, [user]);

  let keyCounter = 0;
  // handle ryba title input change
  const titleEditHandler = (e) => {
    setRybaTitleEdit(e.target.value);
    console.log(rybaTitleEdit);
  };

  // handle select option change
  const onSelectOptionChangeHandler = (e) => {
    modality.current = `${e.target.value}`;
    setModalityState(e.target.value);
    console.log(modality.current);
  };

  //// create New Ryba

  const createNewRybaRequest = function (e) {
    if (modality.current === "") {
      alert("выберите модальность");
      return;
    }
    if (protocol.current === "") {
      alert("выберите протокол");
      return;
    }

    if (rybaTitleEdit === "") {
      alert("Введите название новой рыбки");
      return;
    }
    const createRyba = async (req, res) => {
      console.log(rybaTitleEdit);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            title: `${rybaTitleEdit}`,
            modality: `${modality.current}`,
            protocol: `${protocol.current}`,
          }),
        }
      );
      const json = await response.json();
      if (response.status === 401) {
        window.localStorage.removeItem("user");
        window.location.reload();
      }
      if (response.ok) {
        console.log(json);
        window.location.reload();
      }
    };
    createRyba();
  };

  // open create ryba window
  const openRybaCreateWindowOnclick = () => {
    setOpenRybaCreateWindow(true);
  };
  // close create rybe window
  const closeRybaCreateWindowOnclick = () => {
    setOpenRybaCreateWindow(false);
  };
  const createTime = () => {
    const currentdate = new Date();
    const minutes = `${
      currentdate.getMinutes() > 9
        ? currentdate.getMinutes()
        : "0" + currentdate.getMinutes()
    }`;
    const hours = `${
      currentdate.getHours() > 9
        ? currentdate.getHours()
        : "0" + currentdate.getHours()
    }`;
    return `${hours}:${minutes}`;
  };
  const exportTXT = (rybaObject) => {
    ////////// CREATE REPORT ///////

    let rybaString = "";
    rybaString += "Модальность: " + rybaObject.modality + "\n";
    rybaString += "Исследование: " + rybaObject.protocol + "\n";
    const currentdate = new Date();
    const date =
      currentdate.getDate() +
      "." +
      (currentdate.getMonth() + 1) +
      "." +
      currentdate.getFullYear();

    rybaString += "Дата: " + date + "\n";
    rybaString += "Время: " + createTime() + "\n\n";

    rybaString += rybaObject.contentForExport;

    ////////////////////////////////
    // const fileData = JSON.stringify(rybaString);
    const file = new Blob([rybaString], { type: "text/plain;charset=utf-8" });
    saveAs(
      file,
      `${rybaObject.title} ${rybaObject.protocol} ${rybaObject.date}.txt`
    );
  };

  const downloadEverything = (allRybas) => {
    let finalString = "";
    const currentdate = new Date();
    const date =
      currentdate.getDate() +
      "." +
      (currentdate.getMonth() + 1) +
      "." +
      currentdate.getFullYear();
    allRybas.map((r) => {
      let rybaString = "\n";
      rybaString += r.title + "\n";
      rybaString += "Модальность: " + r.modality + "\n";
      rybaString += "Исследование: " + r.protocol + "\n";

      rybaString += "Дата: " + r.date + "\n";
      rybaString += "Время: " + r.time + "\n\n";
      rybaString += "ОПИСАНИЕ: " + "\n" + r.contentForExport + "\n\n";
      finalString += rybaString;
    });
    const file = new Blob([finalString], { type: "text/plain;charset=utf-8" });
    saveAs(file, `Архив описаний ${date}.txt`);
  };

  return (
    <>
      {loaded && (
        <div className="rybaListContainer" key={(keyCounter += 1)}>
          {/* <h1 style={{ color: "lightgrey", fontWeight: "100" }}>Мои шаблоны</h1>
          <div className="modalityContainer">
            <Link to="/rybaListCT2" style={{ textDecoration: "none" }}>
              <div className="modalityList" key={(keyCounter += 1)}>
                <div className="modalityIcon">
                  <CT />
                </div>

                <p>КТ</p>
              </div>
            </Link>
            <Link to="/rybaListMRI2" style={{ textDecoration: "none" }}>
              <div
                className="modalityList"
                key={(keyCounter += 1)}
                style={{ textDecoration: "none" }}
              >
                <div className="modalityIcon">
                  <MRI />
                </div>
                <p>МРТ</p>
              </div>
            </Link>
          </div> */}
          <div
            className="savedList"
            style={{
              margin: "40px",
            }}
          >
            <h1>Сохранённые описания</h1>
            {/* {savedReports && savedReports.length === 0 && (
              <span className="rybovNet">Сохранённых описаний нет</span>
            )} */}
            {!savedReports && (
              <span className="rybovNet">Сохранённых описаний нет</span>
            )}
            {savedReports && savedReports.length !== 0 && (
              <div className="rybaRow">
                <div className="savedModality savedShapka">Модальность</div>
                <div className="savedProtocol savedShapka"> Протокол</div>
                {/* <div className="savedProtocol savedShapka"> Доп. инфо</div> */}
                <div className="savedName savedShapka">Название</div>
                <div className="savedDate savedShapka">Дата</div>
                <div className="savedTime savedShapka">Время</div>
                <div
                  className="savedDelete skachat"
                  onClick={(e) => {
                    downloadEverything(savedReports);
                  }}
                >
                  Скачать всё
                </div>
                <div
                  className="savedDelete savedShapka"
                  onClick={(e) => {
                    setSavedReports(undefined);
                    window.localStorage.removeItem(`savedReports${user.id}`);
                  }}
                >
                  Удалить всё
                </div>
              </div>
            )}
            {savedReports &&
              savedReports.map((ryba) => (
                <div className="rybaRow">
                  <div className="savedModality ">{ryba.modality}</div>
                  <div className="savedProtocol savedProtocolTable">
                    {ryba.protocol}
                  </div>
                  {/* <div className="savedProtocol savedProtocolTable">
                    {ryba.info}
                  </div> */}
                  <div
                    className="savedName savedNamehover"
                    onClick={() => {
                      if (ryba.dinamika === false) {
                        window.localStorage.setItem(
                          `${ryba.id}`,
                          JSON.stringify(ryba.content)
                        );
                        navigate(`/primary/${ryba.id}`);
                      }
                      if (ryba.dinamika === true) {
                        window.localStorage.setItem(
                          `followup${ryba.id}`,
                          JSON.stringify(ryba.content)
                        );
                        navigate(`/followup/${ryba.id}`);
                      }
                    }}
                  >
                    {ryba.title}
                  </div>
                  <div className="savedDate">{ryba.date}</div>
                  <div className="savedTime">{ryba.time}</div>
                  <div
                    className="savedDelete skachat"
                    onClick={(e) => {
                      exportTXT(ryba);
                    }}
                  >
                    Скачать
                  </div>{" "}
                  <div
                    className="savedDelete"
                    onClick={(e) => {
                      const r = [...savedReports];
                      let index = r.indexOf(ryba);
                      r.splice(index, 1);
                      setSavedReports(r);
                      JSON.stringify(
                        window.localStorage.setItem(
                          `savedReports${user.id}`,
                          JSON.stringify(r)
                        )
                      );
                    }}
                  >
                    Удалить
                  </div>
                  {/* <span
                    className=""
                    onClick={() => {
                      // rybaOBJECT.current = ryba;
                      // setAreYouSureOpen(true);
                    }}
                  >
                    Удалить
                  </span> */}
                </div>
              ))}
          </div>
          <div className="savedList">
            <h1>Автоматически сохранённые описания </h1>
            {!autosavedReports && (
              <span className="rybovNet">Сохранённых описаний нет</span>
            )}
            {autosavedReports && autosavedReports.length !== 0 && (
              <div className="rybaRow">
                <div className="savedModality savedShapka">Модальность</div>
                <div className="savedProtocol savedShapka"> Протокол</div>
                {/* <div className="savedProtocol savedShapka"> Доп. инфо</div> */}
                <div
                  className="savedName savedShapka"
                  style={{
                    width: "400px",
                  }}
                >
                  Заключение
                </div>
                <div className="savedDate savedShapka">Дата</div>
                <div className="savedTime savedShapka">Время</div>
                <div
                  className="savedDelete skachat"
                  onClick={(e) => {
                    downloadEverything(autosavedReports);
                  }}
                >
                  Скачать всё
                </div>
                <div
                  className="savedDelete savedShapka"
                  onClick={(e) => {
                    setAutosavedReports(undefined);
                    window.localStorage.removeItem(
                      `autosavedReports${user.id}`
                    );
                  }}
                >
                  Удалить всё
                </div>
              </div>
            )}
            {autosavedReports &&
              autosavedReports.map((ryba) => (
                <div className="rybaRow">
                  <div className="savedModality ">{ryba.modality}</div>
                  <div className="savedProtocol savedProtocolTable">
                    {ryba.protocol}
                  </div>
                  {/* <div className="savedProtocol savedProtocolTable">
                    {ryba.info}
                  </div> */}
                  <div
                    className="savedName savedNamehover"
                    style={{
                      fontSize: "12px",
                      width: "400px",
                    }}
                    onClick={() => {
                      if (ryba.dinamika === false) {
                        window.localStorage.setItem(
                          `${ryba.id}`,
                          JSON.stringify(ryba.content)
                        );
                        navigate(`/primary/${ryba.id}`);
                      }
                      if (ryba.dinamika === true) {
                        window.localStorage.setItem(
                          `followup${ryba.id}`,
                          JSON.stringify(ryba.content)
                        );
                        navigate(`/followup/${ryba.id}`);
                      }
                    }}
                  >
                    {ryba.zakl}
                  </div>
                  <div className="savedDate">{ryba.date}</div>
                  <div className="savedTime">{ryba.time}</div>
                  <div
                    className="savedDelete skachat"
                    onClick={(e) => {
                      exportTXT(ryba);
                    }}
                  >
                    Скачать
                  </div>{" "}
                  <div
                    className="savedDelete"
                    onClick={(e) => {
                      const r = [...autosavedReports];
                      let index = r.indexOf(ryba);
                      r.splice(index, 1);
                      setAutosavedReports(r);
                      JSON.stringify(
                        window.localStorage.setItem(
                          `autosavedReports${user.id}`,
                          JSON.stringify(r)
                        )
                      );
                    }}
                  >
                    Удалить
                  </div>
                  {/* <span
                    className=""
                    onClick={() => {
                      // rybaOBJECT.current = ryba;
                      // setAreYouSureOpen(true);
                    }}
                  >
                    Удалить
                  </span> */}
                </div>
              ))}
          </div>
          {openRybaCreateWindow && (
            <RybaCreate
              {...{
                openRybaCreateWindowOnclick,
                closeRybaCreateWindowOnclick,
                createNewRybaRequest,
                titleEditHandler,
                rybaTitleEdit,
                onSelectOptionChangeHandler,
                modalityState,
                protocol,
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default RybaList;
